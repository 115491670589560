import styled from "styled-components";

export const CloseTopDrawer = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        justify-content: flex-end;
    }
`;


export const HeaderDrawer = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 15px;
    border-top: 2px solid var(--border-color);
    border-bottom: 2px solid var(--border-color);
`;

export const BodyDrawer = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 15px;
`;