import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsToEye, faEye, faPen, faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";

//COMPONENTS
import ButtonCustom from "../../../../../../../Components/ButtonCustom";
import GeneratePdfButton from "../../../../../../../Components/relatorio-component";
import { Inline } from "../../../../../../../Components/CustomUi/inline";
import { Render } from "../../../../../../../Components/CustomUi/render";

//PROPS
interface Props {
    disabled_action_buttons: boolean;
    loading_action_buttons: boolean;
    permisao_inclusao: boolean;
    novo_onClick: Function;
    importar_onClick: Function;
    desenhar_onClick: Function;
    ver_propriedade_onClick: Function;
    ver_todas_propriedade_onClick: Function;
    ver_todas_propriedades_loading: boolean;
    ver_todas_propriedades_disabled: boolean;
    pulverizacao_onClick: Function;
    pulverizacao_hidden: boolean;
    dadosEstatisticas: any;
    dadosResultado: any;
    dataTalhao: any[];
    nomePropriedade: string
}

export const Actions: React.FC<Props> = ({ permisao_inclusao, disabled_action_buttons, loading_action_buttons, novo_onClick, 
    importar_onClick, desenhar_onClick, ver_propriedade_onClick, ver_todas_propriedade_onClick, ver_todas_propriedades_loading, 
    ver_todas_propriedades_disabled, pulverizacao_onClick, pulverizacao_hidden, dadosEstatisticas, dadosResultado, dataTalhao, 
    nomePropriedade
 }) => {
    return (
        <Inline>
            <Tooltip title="Cria novo talhão dentro da safra (importando ou desenhando contorno), mas não adiciona na propriedade.">
                <div style={{display: permisao_inclusao ? 'none' : undefined}}>
                <ButtonCustom
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={()=>{novo_onClick()}}
                    disabled={disabled_action_buttons || loading_action_buttons}
                    loading={loading_action_buttons}
                    hidden={permisao_inclusao}
                >
                    Novo
                </ButtonCustom>
                </div>
            </Tooltip>
            
            <Tooltip title="Importa talhões da propriedade para a safra">
                <div style={{display: permisao_inclusao ? 'none' : undefined}}>
                <ButtonCustom
                    icon={<FontAwesomeIcon icon={faUpload} />}
                    onClick={()=>{importar_onClick()}}
                    disabled={disabled_action_buttons || loading_action_buttons}
                    loading={loading_action_buttons}
                    hidden={permisao_inclusao}
                >
                    Importar
                </ButtonCustom>
                </div>
            </Tooltip>
            
            <Tooltip title="Desenhar talhão com mapa de satélite ao fundo dentro da safra (não adiciona na propriedade)">
                <div style={{display: permisao_inclusao ? 'none' : undefined}}>
                <ButtonCustom
                    icon={<FontAwesomeIcon icon={faPen} />}
                    onClick={()=>{desenhar_onClick()}}
                    disabled={disabled_action_buttons || loading_action_buttons}
                    loading={loading_action_buttons}
                    hidden={permisao_inclusao}
                >
                    Desenhar
                </ButtonCustom>
                </div>
            </Tooltip>
            
            <Tooltip title="Mostra os talhões da safra para a propriedade selecionada">
                <div>
                <ButtonCustom
                    icon={<FontAwesomeIcon icon={faEye} />}
                    type="secondary"
                    onClick={()=>{ver_propriedade_onClick()}}
                    disabled={disabled_action_buttons || loading_action_buttons}
                >
                    Ver propriedade
                </ButtonCustom>
                </div>
            </Tooltip>
            
            <Tooltip title="Mostra todos os talhões de todas as propriedades na safra">
                <div>
                <ButtonCustom
                    icon={<FontAwesomeIcon icon={faArrowsToEye} />}
                    type="secondary"
                    onClick={()=>{ver_todas_propriedade_onClick()}}
                    loading={ver_todas_propriedades_loading}
                    disabled={ver_todas_propriedades_disabled}
                >
                    Ver todas propriedades
                </ButtonCustom>
                </div>
            </Tooltip>
            
            <ButtonCustom
                icon={<FontAwesomeIcon icon={faArrowsToEye} />}
                type="secondary"
                onClick={()=>{pulverizacao_onClick()}}
                hidden={pulverizacao_hidden}
                disabled={disabled_action_buttons || loading_action_buttons}
            >
                linhas de pulverização
            </ButtonCustom>
            
            <Tooltip title="Imprimir lista de talhões da propriedade">
                <div>
                <Render condition={localStorage.getItem('tipo-acesso') === 'conta'}>
                    <GeneratePdfButton
                        dados={dadosEstatisticas}
                        title={'Relatório de talhões da safra'}
                        totais={dadosResultado}
                        disable={dataTalhao.length <= 0}
                        titleSecundario={nomePropriedade}
                    />
                </Render>  
                </div>
            </Tooltip>
            
            
            
            
            
            
            
        </Inline>
    )
}