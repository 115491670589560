import styled from "styled-components";

export const Overlay = styled.section`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background: rgba(0,0,0,0.9);
    z-index: 2000000;
    display: flex;
    flex-direction: column;
`

export const Controls = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    div.Controls__buttons {
        display: flex;
        gap: 10px;
    }

    @media (max-width: 521px) {
        height: 100px;
        flex-direction: column;
        gap: 2px;
        position: relative;

        div.Controls__buttons {
            position: absolute;
            bottom: 10px;
            left: 20px;
            gap: 13px;

            :is([data-zoom = true]) {
                left: 146px;
            }
        }

        div.Controls__range {
            position: absolute;
            top: 20px;
            left: 20px;
        }
    }

    button.Controls__rotate {
        width: 30px;
        height: 30px;
        padding: 0;
        cursor: pointer;
        overflow: hidden;
        border-radius: 5px;
        border: none;

        div.Controls__rotate__icon {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    div.Controls__range {
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        input.Controls__range__range {
            width: 200px;
            background: transparent;
            accent-color: #09FF00
        }

        input.Controls__range__number {
            width: 65px;
            height: 100%;
            border-radius: 5px;
            box-sizing: border-box;
            padding-left: 10px;
        }
    }
`;

export const MediaContainer = styled.div`
    width: 100%;
    height: calc(100vh - 160px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;

    :is([data-carousel = false]) {
        height: calc(100vh - 70px);
    }

    img.MediaContainer__img {
        height: 90%;
        z-index: 2;
    }
    img.MediaContainer__bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        filter: blur(30px);
    }

    :is([data-full = true]){
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,1);

        img.MediaContainer__img {
            height: 98vh;
            z-index: 2;
        }
    }

    div.MediaContainer__legenda {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: 500;
        background: black;
        padding: 5px;
        border-radius: 5px;
        z-index: 3;
        position: absolute;
        bottom: 5px;
    }

    button.MediaContainer__move {
        position: absolute;
        left: 5px;
        width: 30px;
        height: 70px;
        background: #000;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        opacity: 0.5;

        :is([data-position='right']) {
            left: auto;
            right: 5px;
        }

        :hover {
            background: #fff;
            color: #000;
            opacity: 1;
        }
    }

    div.MediaContainer__origin {
        width: 60px;
        height: 60px;
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        flex-wrap: wrap;
        z-index: 2;
        opacity: 0.3;
        transition: .4s;

        @media (max-width: 521px) {
            display: none;
        }

        :hover {
            opacity: 1;
        }

        .MediaContainer__origin__item {
            width: 20px;
            height: 20px;
            border: thin solid #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            :hover {
                background: rgba(255,255,255,0.5);
            }

            div.MediaContainer__origin__item__dot {
                width: 7px;
                height: 7px;
                background: #09FF00;
                border-radius: 50%;
            }
        }
    }
`;

export const Carousel = styled.div`
    width: 100%;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background: rgba(0,0,0,0.4);
    position: relative;
    /* overflow-x: hidden;
    overflow-y: auto; */

    @media (max-width: 521px) {
        display: none;
    }

    :is([data-visible = false]) {
        height: 20px;

        button.Carousel__media {
            display: none;
        }
        button.Carousel__collapse svg {
            transition: .1s;
            transform: rotate(180deg);
        }
    }

    button.Carousel__media {
        width: 85px;
        height: 85px;
        background: transparent;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        align-items: center;
        border: none;
        box-sizing: border-box;
        padding: 0;
        cursor: pointer;

        :is([data-select = true]){
            outline: 3px solid #09FF00;

            :hover {
                outline: 3px solid #09FF00;
            }
        }

        img.Carousel__media__img {
            width: 100%;
        }

        :hover {
            outline: 1px solid white;
        }
    }

    button.Carousel__collapse {
        position: absolute;
        top: -7px;
        left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: #000;
        color: #fff;
        cursor: pointer;
        transition: .4s;
        border-radius: 4px;

        :hover {
            color: #000;
            background: #fff;
        }
    }
`;