import React, { useEffect, useState } from 'react'
import { ColorButton, Container, Grid } from './styles'
import { Popover } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faPalette } from '@fortawesome/free-solid-svg-icons'

interface ColorPickerProps {
  selectedColor: string
  setSelectedColor: React.Dispatch<React.SetStateAction<string>>
  colors: any[]
  colorsOriginal: any[]
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  selectedColor,
  setSelectedColor,
  colors,
  colorsOriginal,
}) => {
  const [visible, setVisible] = useState(false)
  const [cores_map, setCores_map] = useState([])

  const handleSelectColor = (color: string) => {
    setSelectedColor(color)
  }
  
  useEffect(() => {
    const coresOriginalPaleta = [...colorsOriginal, ...colors]

    const resultado = coresOriginalPaleta.filter(
      (cor, index) => coresOriginalPaleta.indexOf(cor) === index
    )
    setCores_map(resultado)
  }, [colorsOriginal])

  return (
    <Container>
      <label>Selecione a cor</label>
      <div>
        <Popover
          content={
            <Grid>
              {cores_map.map((color) => (
                <ColorButton
                  key={color}
                  isSelected={selectedColor === color}
                  disable={!!colorsOriginal.find((apiColor) => apiColor === color)}
                  color={color}
                  onClick={() => handleSelectColor(color)}
                >
                  {!!colorsOriginal.find((apiColor) => apiColor === color) && (
                    <FontAwesomeIcon style={{opacity: '0.7'}} color='black' icon={faLock} />
                  )}
                </ColorButton>
              ))}
            </Grid>
          }
          trigger='click'
          open={visible}
          onOpenChange={setVisible}
          placement='right'
        >
          <div className='button__trigger'>
            {selectedColor ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: selectedColor,
                  borderRadius: '3px',
                }}
              ></div>
            ) : (
              <FontAwesomeIcon style={{opacity: '0.4'}} icon={faPalette} />
            )}
          </div>
        </Popover>
      </div>
    </Container>
  )
}

export default ColorPicker
