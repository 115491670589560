import styled from "styled-components";

export const Tabela = styled.div`
    width: 100%;
    /* width: calc(100vw - 305px); */
    height: calc(100vh - 163px);
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;