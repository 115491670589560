import { useQuery } from 'react-query';

//APP
import { api } from '../../../api';
import { localConfig } from '../../../../Configs/localConfig';
import { errorAlert } from '../../../../utils/alert';

//=================================================================================>
// BUSCAR LISTA DE PROPRIEDADES E SEUS TALHÕES
//<=================================================================================

//TYPES
type Item = {
    cod: number | null,
    nome: string | null,
    status: number | null,
    cod_tipo: number | null,
    cod_conta: number | null,
    cod_cliente: number | null,
    arquivo: string | null,
    area: string | null,
    matricula: string | null,
    cod_cidade: number | null,
    ccir: string | null,
    itr: string | null,
    nirf: string | null,
    inscricao_estadual: string | null,
    area_matricula: string | null,
    cod_calcario: number | null,
    cod_consultor: number | null,
    conta_nome: string | null,
    cliente_nome: string | null,
    cliente: {cod: number, nome: string}[];
    cidade: any[];
    talhao: any[]
}

//INTERFACE
export interface UsuarioAssisTecnico {
    itens: Item[]
}

export const useGetPropriedadesComTalhoes = () => {
    let { conta, codCliente } = localConfig();

    const queryKey: any = [`propriedade_${conta}_${codCliente}`];

    const { data, isFetching, refetch, isFetchedAfterMount, isError } = useQuery<UsuarioAssisTecnico, Error>(queryKey, async () => {
        return api.get(`propriedade?codConta=${conta}&codCliente=${codCliente}&embed=talhao`)
            .then((response):any => {
                return response.data
            })
            .catch((error) => {
                errorAlert(error?.response?.data?.error ?? 'Falha ao obter lista de propriedades!');
            })
    },
        {
            enabled: true,
            refetchOnWindowFocus: false,
            staleTime: 300000
        }
    );

    return { 
        data_propriedades: data, 
        carregando_propriedades: isFetching, 
        refetch_data_propriedades: refetch,
        isFetched: isFetchedAfterMount,
        isError: isError
    };
};


//=================================================================================>
// BUSCAR LISTA DE DATAS DE RESUMO
//<=================================================================================

//TYPES
export type Item__Resumo = {
    cod_propriedade: number | null,
    talhao: string | null,
    cod_talhao: number | null,
    data_inicio: string | null,
    cod_especie: number | null,
    especie: string | null,
    cod_variacao_especie: number | null,
    variacao: string | null,
    pontos: number | null,
    repeticao: number | null,
    total: string | null,
    minimo: string | number | null,
    maximo: string | number | null,
    media: string | number | null,
    pontos_acima: number | null,
    pontos_abaixo: number | null,
    nivel_controle: string | null,
    tipo_metrica_nome: string | null,
    inverter_escala: 0 | 1,
    colmo?: string;
}

//INTERFACE
export interface ResumoMonitoramento {
    itens: Item__Resumo[]
}

export const useGetResumoMonitoramento = (codPropriedade: number) => {

    let { conta, codCliente, codSafra } = localConfig();

    const queryKey: any = [`resumo_monitoramento_${codPropriedade}_${codSafra}_${codCliente}_${conta}`];

    const { data, refetch, isFetchedAfterMount, isError, isLoading } = useQuery<ResumoMonitoramento, Error>(queryKey, async () => {
        return api.get(`resumo-monitoramento?codConta=${conta}&codCliente=${codCliente}&pagination=-1&codPropriedade=${codPropriedade}&codSafra=${codSafra}`)
            .then((response):any => {
                return response.data
            })
            .catch((error) => {
                errorAlert(error?.response?.data?.error ?? 'Falha ao obter resumo do monitoramento!');
            })
    },
        {
            enabled: true,
            refetchOnWindowFocus: false,
            staleTime: 300000
        }
    );

    return { 
        data_resumo: data, 
        carregando_resumo: isLoading, 
        // carregando_resumo: isFetching, 
        refetch_data_resumo: refetch,
        isFetched: isFetchedAfterMount,
        isError: isError
    };
};


//=================================================================================>
// BUSCAR LISTA DE TALHÕES DA PROPRIEDADE
//<=================================================================================

//TYPES
export type Item__Talhoes = {
    cod_propriedade: number | null,
    cod: number | null,
    nome: string | null,
    kml: { coordenadas: {latitude: number, longitude: number}[] }
}

//INTERFACE
export interface TalhoesPropriedade {
    itens: Item__Talhoes[]
}

export const useGetTalhoesPropriedade = (codPropriedade: number) => {

    let { conta, codCliente, codSafra } = localConfig();

    const queryKey: any = [`talhoes_propriedade_safra_${codPropriedade}_${codSafra}_${conta}_${codCliente}`];

    const { data, isFetching, refetch, isFetchedAfterMount, isError } = useQuery<TalhoesPropriedade, Error>(queryKey, async () => {
        return api.get(`talhao-safra?codConta=${conta}&codCliente=${codCliente}&pagination=-1&codPropriedade=${codPropriedade}&codSafra=${codSafra}`)
            .then((response):any => {
                return response.data
            })
            .catch((error) => {
                errorAlert(error?.response?.data?.error ?? 'Falha ao obter contorno dos talhões!');
            })
    },
        {
            enabled: true,
            refetchOnWindowFocus: false,
            staleTime: 300000
        }
    );

    return { 
        data_talhoes: data,
        carregando_talhoes: isFetching, 
        refetch_data_talhoes: refetch,
        isFetched: isFetchedAfterMount,
        isError: isError
    };
};