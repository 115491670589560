import React, { useEffect, useState } from 'react'
import { Render } from '../CustomUi/render'
import Carregando from '../CustomUi/loading'
import { HeaderDrawer } from '../CustomUi/headerGaveta'
import { BodyDrawer } from './style'
import Input from '../CustomUi/input'
import ColorPicker from '../CustomUi/colorPicker'
import { SelectMulti } from '../CustomUi/SelectMulti'
import { faRuler, faShapes } from '@fortawesome/free-solid-svg-icons'
import { destroyAlert, loadingAlert } from '../../utils/alert'
import { getGrupoServico } from '../../services/GrupoServicos'
import { analises, profundidades } from './meta'
import { Select } from '../CustomUi/Select'

interface PropsFormGrupoAnalises {
  loadingForm: boolean
  update: number
  nome: string
  setNome: React.Dispatch<React.SetStateAction<string>>
  profundidade: string
  setProfundidade: React.Dispatch<React.SetStateAction<string>>
  setProfundidadeId: React.Dispatch<React.SetStateAction<number>>
  analises_selecionadas: { descr: string; id: number; key?: number }[]
  setAnalises_selecionadas: React.Dispatch<
    React.SetStateAction<{ descr: string; id: number; key?: number }[]>
  >
  selectedColor: string
  setSelectedColor: React.Dispatch<React.SetStateAction<string>>
  colorsUsadas: any[]
  colorsOriginal: any[]
}

export const FormGrupoAnalises: React.FC<PropsFormGrupoAnalises> = ({
  loadingForm,
  update,
  nome,
  setNome,
  profundidade,
  setProfundidade,
  setProfundidadeId,
  analises_selecionadas,
  setAnalises_selecionadas,
  selectedColor,
  setSelectedColor,
  colorsUsadas,
  colorsOriginal,
}) => {
  const [openAnalises, setOpenAnalises] = useState(false)
  const [openProfundidade, setOpenProfundidade] = useState(false)

  return (
    <>
      <Render condition={loadingForm}>
        <Carregando
          animation
          justifyContent='center'
          height={'100%'}
          legenda='Carregando informações...'
        />
      </Render>
      <Render condition={loadingForm === false}>
        <HeaderDrawer>
          {update > 0 ? 'Atualizar grupo de análises' : 'Cadastrar grupo de análises'}
        </HeaderDrawer>
        <BodyDrawer>
          <Input
            placeholder='Digite o nome análises'
            value={nome}
            onChange={setNome}
            label='Nome'
          />
          <ColorPicker
            colorsOriginal={colorsOriginal}
            colors={colorsUsadas}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
          <Select
            label='Profundidade'
            placeholder='Selecione a profundidade'
            value={profundidade}
            onChange={setProfundidade}
            onChangeID={setProfundidadeId}
            open={openProfundidade}
            trigger={setOpenProfundidade}
            items={profundidades}
            icon={faRuler}
            descricaoItem={'nome'}
            idItem={'cod'}
          />
          <SelectMulti
            label='Análises'
            placeholder='Selecione a análise'
            descricaoItem='nome'
            idItem='cod'
            items={analises}
            open={openAnalises}
            trigger={setOpenAnalises}
            // value={nome_talhao}
            icon={faShapes}
            selecteds={analises_selecionadas}
            onChange={setAnalises_selecionadas}
          />
        </BodyDrawer>
      </Render>
    </>
  )
}
