import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'
import { destroyAlert, errorAlert, successAlert } from '../../utils/alert'

export interface TypeGetFornecedor {
  itens: [FornecedorItens]
  total: number
}

export interface FornecedorItens {
  cod: number
  cod_conta: number
  cod_cliente: number
  nome: string
  cpf_cnpj: string
  tipo: 'J' | 'F'
  razao_social: string
  endereco: string
  complemento: string
  cep: number
  cod_cidade: number
  fone: string
  fone2: string
  email: string
  status: 1 | 0
  cod_categoria: number
  endereco_numero: string
  bairro: string
  inscricao_estadual: number
  cidade: [
    {
      cod: number
      codigo: number
      nome: string
      uf: string
      cod_uf: number
      status: 1 | 0
      uf_nome: string
      uf_sigla: string
    }
  ]
  tags: [string]
  create_time?: string
  create_user?: string
  update_time?: string
  update_user?: string
}

interface TypeParamGet {
  codConta?: number | string
  codCliente?: number | string
  codCategoria?: number
  codFornecedor?: number
  pagination?: number | -1
  page?: number | 15
}

export const getFornecedor = async (params?: TypeParamGet) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetFornecedor> = await api.get('/fornecedor', { params })

  return response
}

// export const getFornecedor = async (codCategoria, codFornecedor, page, pagination) => {
//   const config = localConfig()

//   api.defaults.headers.Acesso = config.acesso

//   const response: AxiosResponse<TypeGetFornecedor> = await api.get(
//     `/fornecedor?codConta=${config.conta}&codCliente=${
//       codCategoria === 2 ? '' : config.codCliente
//     }&codCategoria=${codCategoria}&cod=${codFornecedor}&pagination=${pagination}&page=${page}`
//   )

//   return response
// }

export const getFornecedorEditar = async (codCategoria, codFornecedor) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetFornecedor> = await api.get(
    `/fornecedor?codConta=${config.conta}&codCliente=${
      codCategoria === 2 ? '' : config.codCliente
    }&codCategoria=${codCategoria}&cod=${codFornecedor}`
  )

  return response
}

export const postFornecedor = async (data, fecharCadastro, setLoadingSalvar?: Function) => {
  const config = localConfig()

  const response: AxiosResponse<TypeGetFornecedor> = await api
    .post(`/fornecedor?codConta=${config.conta}&codCliente=${config.codCliente}`, data)
    .then((): any => {
      destroyAlert()
      successAlert('Fornecedor cadastrado com sucesso!')
      fecharCadastro()
    })
    .catch((error) => {
      console.log(error)
      destroyAlert();
      errorAlert(`${error?.response?.data?.error[0]?.msg?.[0] ?? error?.response?.data?.error }`);
      setLoadingSalvar?.(false)
    })
  return response
}

export const putFornecedor = async (
  codFornecedor,
  data,
  fecharCadastro?: Function,
  setLoadingSalvar?: Function
) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeGetFornecedor> = await api
    .put(
      `/fornecedor/${codFornecedor}?codConta=${config.conta}&codCliente=${config.codCliente}`,
      data
    )

    .then((): any => {
      destroyAlert()
      successAlert('Fornecedor atualizado com sucesso!')
      fecharCadastro()
    })
    .catch((error) => {
      console.log(error)
      destroyAlert()
      errorAlert(`${error?.response?.data?.error[0]?.msg[0]}`)
      setLoadingSalvar?.(false)
    })

  return response
}

export const delFornecedor = async (codFornecedor) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetFornecedor> = await api.delete(
    `/fornecedor/${codFornecedor}?codConta=${config.conta}&codCliente=${config.codCliente}`
  )

  return response
}
