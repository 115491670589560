export const paletaCores = [
  "#FFC312", 
  "#F79F1F", 
  "#EE5A24",
  "#EA2027", 
  "#C4E538",
  "#A3CB38", 
  "#009432", 
  "#006266", 
  "#1289A7", 
  "#0652DD",
  "#1B1464", 
  "#40407a",
  "#2c2c54",
  "#FDA7DF", 
  "#D980FA", 
  "#9980FA", 
  "#5758BB",
  "#ED4C67",
  "#B53471", 
  "#833471",
  "#6F1E51",
  "#0fbcf9",
  "#4bcffa",
  "#00d8d6",
  "#34e7e4",
  "#0be881",
  "#05c46b",
  "#f3a683",
  "#f19066",
  "#ea8685",
  "#e66767",
  "#2bcbba",
  "#0fb9b1",
  "#778ca3",
  "#4b6584"
];