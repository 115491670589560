import React from 'react'
import Input from '../../../CustomUi/input'
import {
  faAtom,
  faBolt,
  faBone,
  faCloudRain,
  faFlask,
  faFlaskVial,
  faLeaf,
  faLightbulb,
  faN,
  faSeedling,
  faSun,
  faVialCircleCheck,
} from '@fortawesome/free-solid-svg-icons'

interface PropsFormElementos {
  nitrogenio: string
  setNitrogenio: React.Dispatch<React.SetStateAction<string>>
  fosforo: string
  setFosforo: React.Dispatch<React.SetStateAction<string>>
  potassio: string
  setPotassio: React.Dispatch<React.SetStateAction<string>>
  calcio: string
  setCalcio: React.Dispatch<React.SetStateAction<string>>
  magnesio: string
  setMagnesio: React.Dispatch<React.SetStateAction<string>>
  enxofre: string
  setEnxofre: React.Dispatch<React.SetStateAction<string>>
  boro: string
  setBoro: React.Dispatch<React.SetStateAction<string>>
  cobre: string
  setCobre: React.Dispatch<React.SetStateAction<string>>
  ferro: string
  setFerro: React.Dispatch<React.SetStateAction<string>>
  manganes: string
  setManganes: React.Dispatch<React.SetStateAction<string>>
  zinco: string
  setZinco: React.Dispatch<React.SetStateAction<string>>
  cobalto: string
  setCobalto: React.Dispatch<React.SetStateAction<string>>
  molibdenio: string
  setMolibdenio: React.Dispatch<React.SetStateAction<string>>
  niquel: string
  setNiquel: React.Dispatch<React.SetStateAction<string>>
  silicio: string
  setSilicio: React.Dispatch<React.SetStateAction<string>>
  selenio: string
  setSelenio: React.Dispatch<React.SetStateAction<string>>
}

export const FormElementos: React.FC<PropsFormElementos> = ({
  nitrogenio,
  setNitrogenio,
  fosforo,
  setFosforo,
  potassio,
  setPotassio,
  calcio,
  setCalcio,
  magnesio,
  setMagnesio,
  enxofre,
  setEnxofre,
  boro,
  setBoro,
  cobre,
  setCobre,
  ferro,
  setFerro,
  manganes,
  setManganes,
  zinco,
  setZinco,
  cobalto,
  setCobalto,
  molibdenio,
  setMolibdenio,
  niquel,
  setNiquel,
  silicio,
  setSilicio,
  selenio,
  setSelenio
}) => {

  function mascaraDecimal(data, elemento) {
    let area = data.replace(/\D/g, "")
    area = area.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
    area = (area / 100).toFixed(2).replace('.', ',')

    if (elemento === 'n') {
      setNitrogenio(area)
    }

    if (elemento === 'p') {
      setFosforo(area)
    }

    if (elemento === 'k') {
      setPotassio(area)
    }

    if (elemento === 'ca') {
      setCalcio(area)
    }

    if (elemento === 'mg') {
      setMagnesio(area)
    }

    if (elemento === 's') {
      setEnxofre(area)
    }

    if (elemento === 'b') {
      setBoro(area)
    }

    if (elemento === 'cu') {
      setCobre(area)
    }

    if (elemento === 'fe') {
      setFerro(area)
    }

    if (elemento === 'mn') {
      setManganes(area)
    }

    if (elemento === 'zn') {
      setZinco(area)
    }

    if (elemento === 'co') {
      setCobalto(area)
    }

    if (elemento === 'mo') {
      setMolibdenio(area)
    }

    if (elemento === 'ni') {
      setNiquel(area)
    }

    if (elemento === 'si') {
      setSilicio(area)
    }

    if (elemento === 'se') {
      setSelenio(area)
    }
  }

  return (
    <>
      <div className='campo__dois__inputs'>
        <Input
          label='N (Nitrogênio)'
          icon={faVialCircleCheck}
          placeholder='Digite o valor do nitrogênio'
          value={nitrogenio}
          onChange={(e) => mascaraDecimal(e, 'n')}
        />
        <Input
          label='P (Fósforo)'
          icon={faVialCircleCheck}
          placeholder='Digite o valor do fósforo'
          value={fosforo}
          onChange={(e) => mascaraDecimal(e, 'p')}
        />
      </div>
      <div className='campo__dois__inputs'>
        <Input
          label='K (Potássio)'
          icon={faVialCircleCheck}
          placeholder='Digite o valor do potássio'
          value={potassio}
          onChange={(e) => mascaraDecimal(e, 'k')}
        />
        <Input
          label='Ca (Cálcio)'
          icon={faVialCircleCheck}
          placeholder='Digite o valor do cálcio'
          value={calcio}
          onChange={(e) => mascaraDecimal(e, 'ca')}
        />
      </div>
      <div className='campo__dois__inputs'>
        <Input
          label='Mg (Magnésio)'
          icon={faVialCircleCheck}
          placeholder='Digite o valor do magnésio'
          value={magnesio}
          onChange={(e) => mascaraDecimal(e, 'mg')}
        />
        <Input
          label='S (Enxofre)'
          icon={faVialCircleCheck}
          placeholder='Digite o valor do enxofre'
          value={enxofre}
          onChange={(e) => mascaraDecimal(e, 's')}
        />
      </div>
      <div className='campo__dois__inputs'>
        <Input
          label='B (Boro)'
          icon={faVialCircleCheck}
          placeholder='Digite o valor do boro'
          value={boro}
          onChange={(e) => mascaraDecimal(e, 'b')}
        />
        <Input
          label='Cu (Cobre)'
          icon={faVialCircleCheck}
          placeholder='Digite o valor do cobre'
          value={cobre}
          onChange={(e) => mascaraDecimal(e, 'cu')}
        />
      </div>
      <div className='campo__dois__inputs'>
        <Input
          label='Fe (Ferro)'
          icon={faVialCircleCheck}
          placeholder='Digite o valor de ferro'
          value={ferro}
          onChange={(e) => mascaraDecimal(e, 'fe')}
        />
        <Input
          label='Mn (Manganês)'
          icon={faVialCircleCheck}
          placeholder='Digite o valor manganês'
          value={manganes}
          onChange={(e) => mascaraDecimal(e, 'mn')}
        />
      </div>
      <div className='campo__dois__inputs'>
        <Input
          label='Zn (Zinco)'
          icon={faVialCircleCheck}
          placeholder='Digite o valor do zinco'
          value={zinco}
          onChange={(e) => mascaraDecimal(e, 'zn')}
        />
        <Input
          label='Co (Cobalto)'
          icon={faVialCircleCheck}
          placeholder='Digite o valor do cobalto'
          value={cobalto}
          onChange={(e) => mascaraDecimal(e, 'co')}
        />
      </div>
      <div className='campo__dois__inputs'>
        <Input
          label='Mo (Molibdênio)'
          icon={faVialCircleCheck}
          placeholder='Digite o valor do molibdênio'
          value={molibdenio}
          onChange={(e) => mascaraDecimal(e, 'mo')}
        />
        <Input
          label='Ni (Níquel)'
          icon={faVialCircleCheck}
          placeholder='Digite o valor do níquel'
          value={niquel}
          onChange={(e) => mascaraDecimal(e, 'ni')}
        />
      </div>
      <div className='campo__dois__inputs'>
      <Input
        label='Si (Silício)'
        icon={faVialCircleCheck}
        placeholder='Digite o valor do silício'
        value={silicio}
        onChange={(e) => mascaraDecimal(e, 'si')}
      />
      <Input
        label='Se (Selênio )'
        icon={faVialCircleCheck}
        placeholder='Digite o valor do selênio '
        value={selenio}
        onChange={(e) => mascaraDecimal(e, 'se')}
      />
      </div>
    </>
  )
}
