import { useQuery } from 'react-query'
import { api } from '../../api'
import { localConfig } from '../../../Configs/localConfig'
import { errorAlert } from '../../../utils/alert'

export type ItensFertilizantes = {
  cod: 1
  nome: string
  cod_formulacao: number
  densidade: string
  cod_marca: number
  n: string
  p: string
  k: string
  ca: string
  mg: string
  s: string
  b: string
  cu: string
  fe: string
  mn: string
  zn: string
  co: string
  mo: string
  ni: string
  si: string
  se: string
  aminoacido: string
  indice_salino: string
  extrato_de_alga: string
  observacao: string
  cod_conta: number
  marca_nome: string
  tipo_formulacao_nome: string
  aplicacoes: [
    {
      cod_fertilizante: number
      cod_aplicacao: number
      fertilizante_nome: string
      aplicacao_nome: string
    }
  ]
}
interface Fertilizantes {
  itens: ItensFertilizantes[]
  total?: number | null
  mensagem?: string | null
}
export interface ParamsMarca {
  cod: number
  nome: string
  cod_tipo: number
  outros_tipos: number
  cod_categoria: null
  geral: number
  exibir_marca: number
  teste_bandeja: number
  cod_conta: null
  cod_cliente: null
  categorias: [
    {
      cod: number
      cod_categoria: number
      cod_tipo: number
      categoria_nome: string
      tipo_nome: string
    }
  ]
}

export interface ParamsFormulacao {
  cod: number
  nome: string
  cod_tipo_geral: number
  ordem: number
  tipo_geral_nome: string
}

export interface ParamsAplicacoes {
  cod: number
  nome: string
  cod_tipo_geral: number
  ordem: 1
  tipo_geral_nome: string
}

export const useGetFertilizantes = (pagination = 15, page = 1) => {
  const queryKey: any = [`/fertilizante`]

  const { data, isFetching, refetch, isFetchedAfterMount, isError } = useQuery<Fertilizantes, Error>(
    queryKey,
    async () => {
      return api
        .get(`/fertilizante?codConta=${localConfig()?.conta}&pagination=${pagination}&page=${page}&embed=aplicacoes`)
        .then((response): any => {
          return response.data
        })
        .catch((error) => {
          errorAlert(error?.response?.data?.error ?? 'Falha ao obter lista de fertilizantes!')
        })
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_fertilizante: data,
    carregando_fertilizante: isFetching,
    refetch_data_fertilizante: refetch,
    isFetched: isFetchedAfterMount,
    isError: isError,
  }
}

export const useGetFertilizantesEditar = (cod: number) => {
  const queryKey: any = [`/fertilizante_${cod}`]

  const { data, isFetching, refetch, isFetchedAfterMount, isError } = useQuery<ItensFertilizantes[], Error>(
    queryKey,
    async () => {
      return api
        .get(`/fertilizante?codConta=${localConfig()?.conta}&cod=${cod}&pagination=-1&embed=aplicacoes`)
        .then((response): any => {
          return response.data.itens
        })
        .catch((error) => {
          errorAlert(error?.response?.data?.error ?? 'Falha ao obter lista de fertilizantes!')
        })
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_fertilizante_editar: data ?? [],
    carregando_fertilizante_editar: isFetching,
    refetch_data_fertilizante_editar: refetch,
    isFetched: isFetchedAfterMount,
    isError: isError,
  }
}

export const useGetAplicacoes = (triggerError?: Function) => {
  const queryKey: any = [`/tipos-sistema_${73}`]

  const { conta } = localConfig()

  const { data, isFetching, refetch, isFetchedAfterMount } = useQuery(
    queryKey,
    async () => {
      return api
        .get(`/tipos-sistema?codTipo=${73}&codConta=${conta}&pagination=-1`)
        .then((response): any => {
          triggerError?.(false)
          return response.data.itens ?? []
        })
        .catch(() => {
          errorAlert('Falha ao obter lista de aplicações!')
          triggerError?.(true)
        })
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_aplicacoes: data,
    carregando_aplicacoes: isFetching,
    refetch_data_aplicacoes: refetch,
    isFetched_aplicacoes: isFetchedAfterMount,
  }
}

export const useGetFormulacao = (triggerError?: Function) => {
  const queryKey: any = [`/tipos-sistema_${78}`]

  const { conta } = localConfig()

  const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<ParamsFormulacao[]>(
    queryKey,
    async () => {
      return api
        .get(`/tipos-sistema?codTipo=${78}&codConta=${conta}&pagination=-1`)
        .then((response): any => {
          triggerError?.(false)
          return response.data.itens ?? []
        })
        .catch(() => {
          errorAlert('Falha ao obter lista de aplicações!')
          triggerError?.(true)
        })
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_formulacao: data,
    carregando_formulacao: isFetching,
    refetch_data_formulacao: refetch,
    isFetched_formulacao: isFetchedAfterMount,
  }
}

export const useGetMarca = (triggerError?: Function) => {
  const queryKey: any = [`/categoria-marca${78}`]

  const { conta } = localConfig()

  const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<ParamsMarca[]>(
    queryKey,
    async () => {
      return api
        .get(`/categoria-marca?codCategoriaExibir=380&codConta=${conta}&pagination=-1`)
        .then((response): any => {
          triggerError?.(false)
          return response.data.itens ?? []
        })
        .catch(() => {
          errorAlert('Falha ao obter lista de aplicações!')
          triggerError?.(true)
        })
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_marca: data,
    carregando_marca: isFetching,
    refetch_data_marca: refetch,
    isFetched_marca: isFetchedAfterMount,
  }
}
