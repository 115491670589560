import ReactDOM from 'react-dom';
import { motion } from 'framer-motion';
import { Carousel, Controls, MediaContainer, Overlay } from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faArrowRotateLeft, faArrowRotateRight, faChevronLeft, faChevronRight, faExpand, faMagnifyingGlassMinus, faMagnifyingGlassPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import ButtonCustom from '../../ButtonCustom';
import { Render } from '../render';
import { useScreenSize } from '../../../utils/tsx';

//PROPS
interface Props {
    slides: { media: string, type?: string, description?: string }[];
    slide_inicial?: number;
    onClose: Function;
}

export const SlidesFullScreen: React.FC<Props> = ({ slides, slide_inicial = 0, onClose }) => {

    //CONST
    const { currentWidth } = useScreenSize({});

    const [slide_atual, setSlide_atual] = useState<number>(slide_inicial);
    const [zoom, setZoom] = useState<number>(100);
    const [rotate, setRotate] = useState<number>(0);
    const [full_screen, setFull_screen] = useState<boolean>(false);
    const [carousel, setCarousel] = useState<boolean>((slides ?? []).length < 2 ? false : true);
    const [zoom_origin, setZoom_origin] = useState('center');

    useEffect(() => {
      if (currentWidth < 521) {
        setZoom(65)
      }
      else {
        setZoom(100)
      }
    }, [currentWidth])
    

    const origins = [
        { origin: 'top left' },
        { origin: 'top center' },
        { origin: 'top right' },
        { origin: 'left' },
        { origin: 'center' },
        { origin: 'right' },
        { origin: 'bottom left' },
        { origin: 'bottom center' },
        { origin: 'bottom right' }
    ];


    function avancarSlide() {
        let qtd_slides = slides?.length;
        if ((slide_atual + 1) === qtd_slides) {
            setSlide_atual(0);
        }
        else {
            setSlide_atual(slide_atual => slide_atual + 1)
        }
    }
    function retrocederSlide() {
        let qtd_slides = slides?.length;
        if (slide_atual === 0) {
            setSlide_atual(qtd_slides - 1);
        }
        else {
            setSlide_atual(slide_atual => slide_atual - 1);
        }
    }

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch((err) => {
                console.error(`Erro ao tentar ativar o modo tela cheia: ${err.message}`);
            });
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    };

    function fecharFullScreen() {
        if (document?.fullscreenElement && document?.exitFullscreen) {
            document?.exitFullscreen?.();
        }
    }

    return ReactDOM.createPortal(
        <Overlay>
            <ButtonCustom
                icon={<FontAwesomeIcon icon={faXmark} />}
                onClick={() => { fecharFullScreen(); onClose?.() }}
                danger
                style={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    transform: 'scale(1.2)',
                    zIndex: 2000
                }}
            />

            <Controls>

                <div className='Controls__buttons'>
                    <motion.button
                        onClick={() => { setZoom(100); setFull_screen(true); toggleFullScreen() }}
                        className='Controls__rotate'
                        whileTap={{ scale: 0.9 }}
                    >
                        <motion.div
                            className='Controls__rotate__icon'
                            whileTap={{ scale: 1.5 }}
                        > <FontAwesomeIcon icon={faExpand} /> </motion.div>
                    </motion.button>

                    <motion.button
                        onClick={() => { setZoom_origin('center'); setRotate(rotate => rotate - 90) }}
                        className='Controls__rotate'
                        whileTap={{ scale: 0.9 }}
                    >
                        <motion.div
                            className='Controls__rotate__icon'
                            whileTap={{ rotate: -90 }}
                        > <FontAwesomeIcon icon={faArrowRotateLeft} /> </motion.div>
                    </motion.button>

                    <motion.button
                        onClick={() => { setZoom_origin('center'); setRotate(rotate => rotate + 90) }}
                        className='Controls__rotate'
                        whileTap={{ scale: 0.9 }}
                    >
                        <motion.div
                            className='Controls__rotate__icon'
                            whileTap={{ rotate: 90 }}
                        > <FontAwesomeIcon icon={faArrowRotateRight} /> </motion.div>
                    </motion.button>
                </div>


                <div className='Controls__range'>
                    <input type='range' className='Controls__range__range' style={{ accentColor: (zoom > 500 || zoom < 0) && 'red' }} min={0} max={500} value={zoom} onChange={(e) => { setZoom(Number(e.currentTarget.value)) }} />
                    <input type='number' className='Controls__range__number' min={0} value={zoom} onChange={(e) => { setZoom(Number(e.currentTarget.value)) }} />
                </div>

                <div className='Controls__buttons' data-zoom={true}>
                    <motion.button
                        onClick={zoom >= 0 ? () => { setZoom(zoom => zoom - 10) } : () => { }}
                        className='Controls__rotate'
                        whileTap={{ scale: 0.9 }}
                        disabled={zoom == 0}
                    >
                        <motion.div
                            className='Controls__rotate__icon'
                            whileTap={{ scale: 0.8 }}
                        > <FontAwesomeIcon icon={faMagnifyingGlassMinus} /> </motion.div>
                    </motion.button>
                    <motion.button
                        onClick={() => { setZoom(zoom => zoom + 10) }}
                        className='Controls__rotate'
                        whileTap={{ scale: 0.9 }}
                    >
                        <motion.div
                            className='Controls__rotate__icon'
                            whileTap={{ scale: 1.3 }}
                        > <FontAwesomeIcon icon={faMagnifyingGlassPlus} /> </motion.div>
                    </motion.button>
                </div>
                

            </Controls>

            <MediaContainer data-single={(slides ?? []).length < 2} data-full={full_screen} data-carousel={carousel}>
                <motion.img
                    alt={`img_${slide_atual}`}
                    className='MediaContainer__img'
                    src={slides?.[slide_atual]?.media}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, scale: (zoom / 100), rotate: rotate, transformOrigin: zoom_origin }}
                />

                <Render condition={!!(slides?.[slide_atual]?.description)}>
                    <div className='MediaContainer__legenda'>
                        {slides?.[slide_atual]?.description}
                    </div>
                </Render>

                <motion.button
                    onClick={() => { retrocederSlide() }}
                    className='MediaContainer__move'
                    whileTap={{ scale: 0.9 }}
                    style={{ display: (slides ?? []).length < 2 && 'none' }}
                >
                    <motion.div
                        className='Controls__rotate__icon'
                        whileTap={{ scale: 0.8 }}
                    > <FontAwesomeIcon icon={faChevronLeft} /> </motion.div>
                </motion.button>
                <motion.button
                    onClick={() => { avancarSlide() }}
                    className='MediaContainer__move'
                    data-position='right'
                    whileTap={{ scale: 0.9 }}
                    style={{ display: (slides ?? []).length < 2 && 'none' }}
                >
                    <motion.div
                        className='Controls__rotate__icon'
                        whileTap={{ scale: 0.8 }}
                    > <FontAwesomeIcon icon={faChevronRight} /> </motion.div>
                </motion.button>

                <div className='MediaContainer__origin' title='definir posição de ancoragem da imagem' style={{ display: full_screen && 'none' }}>
                    {origins.map(({ origin }, index) => {
                        return (
                            <div key={index} className='MediaContainer__origin__item' onClick={() => { setZoom_origin(origin) }}>
                                {origin === zoom_origin && <div className='MediaContainer__origin__item__dot' />}
                            </div>
                        )
                    })}
                </div>
            </MediaContainer>

            <Carousel data-visible={carousel} style={{ display: full_screen && 'none' }}>
                {slides.map(({ media }, index) => {
                    return (
                        <motion.button
                            onClick={() => { setSlide_atual(index); setZoom_origin('center'); setRotate(0); setZoom(100) }}
                            className='Carousel__media'
                            data-select={slide_atual === index}
                            whileTap={{ scale: 0.98 }}
                        >
                            <motion.img
                                src={media}
                                alt=''
                                className='Carousel__media__img'
                                whileHover={{ scale: 1.3 }}
                                whileTap={{ scale: 0.98 }}
                            />
                        </motion.button>
                    )
                })}


                <button className='Carousel__collapse' onClick={() => setCarousel(!carousel)} title='ocultar/exibir galria de imagens'>
                    <FontAwesomeIcon icon={faAngleDown} />
                </button>
            </Carousel>

        </Overlay>,

        document.getElementById('portal')
    );
}