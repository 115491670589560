import React, { useState } from 'react'
import { Button, Empty, Popconfirm } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faEdit, faPlus, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'
import { gerarKey, removerAcentos } from '../../../../../../utils'

//COMPONENTS
import { Box } from '../../../../../../Components/CustomUi/box'
import { Inline } from '../../../../../../Components/CustomUi/inline'
import Input from '../../../../../../Components/CustomUi/input'
import ButtonCustom from '../../../../../../Components/ButtonCustom'
import { HeaderTable } from '../../../../../../Components/CustomUi/table/headerTable'
import { BodyTable } from '../../../../../../Components/CustomUi/table/bodyTable'
import { Render } from '../../../../../../Components/CustomUi/render'
import { RowTable } from '../../../../../../Components/CustomUi/table/rowTable'
import { RowItem } from '../../../../../../Components/CustomUi/table/rowItem'
import { mascaraOdom } from '../../../../../../services/Afins'
import { DeleteButton } from '../../../../../../Components/CustomUi/deleteButton'
import Carregando from '../../../../../../Components/CustomUi/loading'

//PROPS
interface Props {
  loading: boolean
  list_calcarios: any[]
  deletar: Function
  abrir: Function
  editar: Function
  setPagination?: React.Dispatch<React.SetStateAction<number>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  total: number
  confirmar_exclusao: boolean
  setConfirmar_exclusao: React.Dispatch<React.SetStateAction<boolean>>
  id_item_atual: number
  setId_item_atual: React.Dispatch<React.SetStateAction<number>>
  nome_item_atual: string
  setNome_item_atual: React.Dispatch<React.SetStateAction<string>>
  fake_loading: boolean
  setFake_loading: React.Dispatch<React.SetStateAction<boolean>>
}

export const TabelaCalcarios: React.FC<Props> = ({
  list_calcarios,
  loading,
  deletar,
  abrir,
  setPagination,
  editar,
  page,
  setPage,
  total,
  confirmar_exclusao,
  setConfirmar_exclusao,
  id_item_atual,
  setId_item_atual,
  nome_item_atual,
  setNome_item_atual,
  fake_loading,
  setFake_loading,
}) => {
  //STATES
  const [buscarTabela, setBuscarTabela] = useState('')

  function buscarPatrimonios(buscar_nome: string) {
    const palavraSemAcentos = removerAcentos(buscar_nome)
    return function (item) {
      const nomeSemAcentos = removerAcentos(String(item?.nome))
      return nomeSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) || !buscar_nome
    }
  }

  function removerUltimosDigitos(value) {
    if (!value) return ''
    // Remove qualquer caractere que não seja número
    const numericValue = value.replace(/\D/g, '')
    // Retorna o valor sem os dois últimos dígitos
    return numericValue.slice(0, -2)
  }

  // function deletarPatrimonioTemp(cod: number) {
  //   setFake_loading(true)
  //   deletarPatrimonio(cod)

  //   setTimeout(() => {
  //     setFake_loading(false)
  //   }, 3000)
  // }

  return (
    <Box style={{ height: 'calc(100vh - 170px)', border: 'none' }} padding={0} borderless>
      {/* <TitleH2>Lista de patrimônios</TitleH2> */}

      <Inline alignItems='center'>
        <Input placeholder='Buscar calcário' value={buscarTabela} onChange={setBuscarTabela} />
        <ButtonCustom icon={<FontAwesomeIcon icon={faPlus} />} onClick={abrir}>
          Novo
        </ButtonCustom>
      </Inline>

      <HeaderTable
        itens={[
          { valor: 'Nome', width: 'calc(100% - 625px)' },
          { valor: 'Nome do fornecedor', width: '400px' },
          { valor: 'Ações', width: '225px' },
        ]}
      />

      <BodyTable
        paginate={total}
        onChangeItensPage={setPagination}
        onChangePage={setPage}
        current_page={page}
        setCurrent_page={setPage}
        style={{
          height: 'calc(100vh - 170px)',
          // height: (list_calcarios ?? []).length < 8 && 'calc(100vh - 170px)',
          position: 'relative',
          overflowX: 'hidden',
          overflowY: 'scroll',
          marginTop: -10,
        }}
      >
        <Render condition={!loading}>
          {(list_calcarios ?? [])
            .filter(buscarPatrimonios(buscarTabela))
            .map(({ cod, nome, fonecedor_nome }, index) => {
              return (
                <RowTable key={cod} onDoubleClick={() => editar(cod, false)}>
                  <RowItem fontSize={13} width={'calc(100% - 620px)'}>
                    {nome}
                  </RowItem>
                  <RowItem fontSize={13} width={'400px'}>
                    {fonecedor_nome}
                  </RowItem>
                  <RowItem width={'220px'} gap={10}>
                    <DeleteButton
                      open={confirmar_exclusao && id_item_atual === cod}
                      trigger={setConfirmar_exclusao}
                      onTrigger={() => {
                        setId_item_atual(cod)
                        setNome_item_atual(nome)
                      }}
                      loading={fake_loading}
                      message={`o item ${nome} será excluido!`}
                      deleteds={[nome]}
                      onDelete={() => {
                        deletar(cod)
                      }}
                      disabled={fake_loading}
                      textButtonTrigger='Excluir'
                      typeButtonTrigger='secondary'
                    />
                    <ButtonCustom
                      icon={<FontAwesomeIcon icon={faEdit} />}
                      onClick={() => {
                        editar(cod, false)
                      }}
                    >
                      Editar
                    </ButtonCustom>
                  </RowItem>
                </RowTable>
              )
            })}
        </Render>

        <Render condition={list_calcarios.length === 0 && !loading}>
          {buscarTabela === '' ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<> Nenhum registro disponível!</>}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  {' '}
                  <b>'{buscarTabela}'</b> não encontrado!
                </>
              }
            />
          )}
        </Render>

        <Render condition={loading}>
          <Carregando
            animation
            legenda='Carregando calcarios...'
            justifyContent='center'
            height={400}
            size='default'
          />
        </Render>
      </BodyTable>
    </Box>
  )
}
