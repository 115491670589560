import dayjs from "dayjs";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Empty, Tag, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//STYLES
import { ContainerDadosResumo } from "./style"

//APP
import { Inline } from "../../../../../../Components/CustomUi/inline";
import { useAccess } from "../../../../../../context/useAccess";
import { gerarEstrturaPDF } from "./meta/estrutura_pdf";
import { ordenarDadosResumo, processarDatasMonitoramento } from "./meta";
import { gerarEstruturaExcel, pintarCelulaPlanilha } from "./meta/estrutura_excel";
import { apenasNumeros, mascaraData, removerAcentos } from "../../../../../../utils";
import { useGetResumoMonitoramento, useGetTalhoesPropriedade } from "../../../../../../services/monitoramento/resumo/get";

//COMPONENTS
import Input from "../../../../../../Components/CustomUi/input";
import Carregando from "../../../../../../Components/CustomUi/loading";
import ButtonCustom from "../../../../../../Components/ButtonCustom";
import GeneratePdfButton from "../../../../../../Components/relatorio-component";
import { Render } from "../../../../../../Components/CustomUi/render";
import { RowItem } from "../../../../../../Components/CustomUi/table/rowItem";
import { CircleNC } from "./components/circle";
import { RowTable } from "../../../../../../Components/CustomUi/table/rowTable";
import { BodyTable } from "../../../../../../Components/CustomUi/table/bodyTable";
import { GerarExcel } from "../../../../../../Components/GerarExcel";
import { HeaderTable } from "../../../../../../Components/CustomUi/table/headerTable";
import { ModalPopOver } from "../../../../../../Components/CustomUi/modalPopOver";
import { ListagemPragas } from "./components/modalPragas";

//PROPS
interface Props {
    parent_open: boolean;
    cod_propriedade: number;
    nome_propriedade: string;
}

export const GavetaResumo: React.FC<Props> = ({ parent_open, cod_propriedade, nome_propriedade }) => {

    //CONSTs
    const { nameCliente } = useAccess()

    //STATES
    const [buscar_data, setBuscar_data] = useState<string>('');
    const [open_modal, setOpen_modal] = useState<boolean>(false);
    const [data_monitoramento_selecionada, setData_monitoramento_selecionada] = useState<string>('');
    const [dados_monitoramento, setDados_monitoramento] = useState([]);
    const [cache_loading, setCache_loading] = useState<boolean>(true);

    //API
    const { data_resumo, carregando_resumo } = useGetResumoMonitoramento(cod_propriedade);
    const { data_talhoes } = useGetTalhoesPropriedade(cod_propriedade);

    //FUNCTIONS
    function filtrarData(buscar_nome: string) {
        const ativoSemAcentos = removerAcentos(buscar_nome)
        return function (item) {
            const data_formatada = dayjs(String(item?.['data_monitoramento'])).format('DD/MM/YYYY');
            return data_formatada?.includes(ativoSemAcentos) || !buscar_nome
        }
    }
    function dataMaisRecente() {
        return processarDatasMonitoramento(data_resumo?.itens ??[])?.[0]?.data_monitoramento;
    }

    useEffect(() => {
        setTimeout(() => {
            setCache_loading(false);
        }, 500);
    }, [])
    

    return (
        <ContainerDadosResumo>
            <Inline style={{ marginTop: -8 }}>
                {/* <Tabs
                    width={270}
                    tabs={[
                        { descricao: 'Por talhão', icone: faMountain, task: ()=>{} },
                        { descricao: 'Todos', icone: faLayerGroup, task: ()=>{}},
                    ]}
                    onChange={setCurrent_tab}
                    selecionado={current_tab}
                    trackWidth={127.5}
                    indexInicial={1}
                /> */}

                <Input
                    width={170}
                    value={mascaraData(apenasNumeros(buscar_data))}
                    onChange={setBuscar_data}
                    placeholder={'__/__/____'}
                />
            </Inline>
            
            <Render condition={carregando_resumo || cache_loading}>
                <Carregando 
                    justifyContent="center"
                    height={350}
                    legenda="Carregando monitoramentos..."
                    animation
                />
            </Render>
            
            <Render condition={(processarDatasMonitoramento(data_resumo?.itens ??[])?.filter(filtrarData(buscar_data)))?.length === 0 && (data_resumo?.itens ?? []).length > 0 && !carregando_resumo && !cache_loading}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<> data <b>'{buscar_data}'</b> não encontrada!</>} />
            </Render>
            
            <Render condition={(processarDatasMonitoramento(data_resumo?.itens ??[])?.filter(filtrarData(buscar_data)))?.length === 0 && (data_resumo?.itens ?? []).length === 0 && !carregando_resumo && !cache_loading}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<> Nenhum registro de monitoramento foi encontrado!</>} />
            </Render>
            
            <Render condition={processarDatasMonitoramento(data_resumo?.itens ??[]).filter(filtrarData(buscar_data)).length > 0 && !cache_loading && !carregando_resumo}>
                <HeaderTable 
                    itens={[
                        {valor: 'Datas de monitoramento', width: 306},
                        {valor: 'Resumo NCs', width: 142.5},
                        {valor: 'Ações', width: 290},
                    ]}
                />
                <BodyTable style={{marginTop: -15, overflowX: 'hidden', height: 'calc(100vh - 225px)', overflowY: 'visible'}}>
                    {processarDatasMonitoramento(data_resumo?.itens ??[]).filter(filtrarData(buscar_data)).map((item, index)=> {
                        let { data_monitoramento, qtd_green, qtd_yellow, qtd_orange, qtd_red, dados } = item;
                        let data_moni_format = dayjs(data_monitoramento).format('DD/MM/YYYY');                        
                        let dados_ordenados = ordenarDadosResumo(dados);
                        return (
                            <RowTable key={index} onDoubleClick={()=>{setData_monitoramento_selecionada(data_moni_format);setDados_monitoramento(dados_ordenados);setOpen_modal(true)}}>
                                <RowItem width={305} style={{fontWeight: data_monitoramento === dataMaisRecente() ? '700' : undefined, alignItems:'center'}} gap={10}>
                                    {data_moni_format}
                                    {data_monitoramento === dataMaisRecente() && <Tag color="blue" style={{fontWeight:500}}>mais recente</Tag>}
                                </RowItem>
                                <RowItem width={143} gap={5}>
                                    <Tooltip title={`${qtd_green} registros sem ocorrência`}>
                                        <samp>
                                        <CircleNC 
                                            value={qtd_green}
                                            color="green"
                                        />
                                        </samp>
                                    </Tooltip>

                                    <Tooltip title={`${qtd_yellow} ocorrências abaixo do NC!`}>
                                        <samp>
                                        <CircleNC 
                                            value={qtd_yellow}
                                            color="yellow"
                                        />
                                        </samp>
                                    </Tooltip>

                                    <Tooltip title={`${qtd_orange} ocorrências no NC!`}>
                                        <samp>
                                        <CircleNC 
                                            value={qtd_orange}
                                            color="orange"
                                        />
                                        </samp>
                                    </Tooltip>

                                    <Tooltip title={`${qtd_red} ocorrências acima do NC!`}>
                                        <samp>
                                        <CircleNC 
                                            value={qtd_red}
                                            color="red"
                                        />
                                        </samp>
                                    </Tooltip>
                                                         
                                </RowItem>
                                <RowItem width={290} gap={10}>
                                    {localStorage.getItem('tipo-acesso') === 'conta' ? (
                                        <Tooltip title="Imprimir ou gerar pdf do resumo" ><samp>
                                            <GeneratePdfButton
                                                dados={gerarEstrturaPDF(dados_ordenados)}
                                                title={`Resumo do monitoramento - ${data_moni_format}`}
                                                totais={[]}
                                                hiddeTextBtn
                                                loading_time={dados_ordenados.length}
                                            /></samp>
                                        </Tooltip>
                                    ) : null}

                                    <Tooltip title='Gerar planilha do resumo'><span>
                                        <GerarExcel 
                                            titulo={`Resumo do monitoramento - ${data_moni_format}`}
                                            nome_do_arquivo={`Resumo_do_monitoramento_${String(nameCliente)?.replace(/ /g, "_")}_${String(nome_propriedade)?.replace(/ /g, "_")?.replace(/\./g, "")}_${data_moni_format}`}
                                            usuario={(JSON.parse(localStorage.getItem('Itens')))?.[0]?.email?.toLowerCase()}
                                            tabela={gerarEstruturaExcel(dados_ordenados)}
                                            typeBtn="secondary"
                                            posicao_logo={4}
                                            estilizar_celulas={pintarCelulaPlanilha(dados_ordenados)}
                                            width_coluna={23}
                                        />
                                    </span></Tooltip>
                                    
                                    <ButtonCustom
                                        onClick={()=>{setData_monitoramento_selecionada(data_moni_format);setDados_monitoramento(dados_ordenados);setOpen_modal(true)}}
                                        icon={<FontAwesomeIcon icon={faEye} />}
                                    >
                                        Ver
                                    </ButtonCustom>
                                </RowItem>
                            </RowTable>
                        )
                    })}
                </BodyTable>
            </Render>
            
            

            <ModalPopOver 
                width={750}
                top={50}
                right={10}
                open={!parent_open ? false : open_modal}
                onClose={()=>{setOpen_modal(false)}}
                title={`${nome_propriedade} - ${data_monitoramento_selecionada}`}
                height={'calc(100vh - 60px)'}
            >
                <ListagemPragas 
                    dados_monitoramento_selecionado={dados_monitoramento}
                    list_talhoes={data_talhoes?.itens ?? []}
                />
            </ModalPopOver>

        </ContainerDadosResumo>
    )
}