import { useState } from "react";

//STYLES
import { ListPragas } from "./style";

//APP
import { removerAcentos } from "../../../../../../../../utils";
import { obterCoordenadasTalhao } from "./meta";
import { Item__Resumo, Item__Talhoes } from "../../../../../../../../services/monitoramento/resumo/get";
import { listarDadosTalhao, processarMonitoramentoSelecionado } from "../../meta";

//COMPONENTS
import Svg from "../../../../../../../../Components/talhasvg";
import Input from "../../../../../../../../Components/CustomUi/input";
import { Inline } from "../../../../../../../../Components/CustomUi/inline"
import { RowItem } from "../../../../../../../../Components/CustomUi/table/rowItem";
import { Switcher } from "../../../../../../../../Components/CustomUi/Switcher";
import { RowTable } from "../../../../../../../../Components/CustomUi/table/rowTable";
import { Accordion } from "../../../../../../../../Components/CustomUi/accordion";
import { BodyTable } from "../../../../../../../../Components/CustomUi/table/bodyTable";
import { HeaderTable } from "../../../../../../../../Components/CustomUi/table/headerTable";
import { SmallCircleNC } from "./components/SmallCircleNC";
import { LoadAccordion } from "./components/loadAccordion";
import { esconderAccordion } from "../../../../../../../../Components/monitoramento/components/painel/meta";
import { Render } from "../../../../../../../../Components/CustomUi/render";
import { Empty } from "antd";
import { localConfig } from "../../../../../../../../Configs/localConfig";

//PROPS
interface Props {
    dados_monitoramento_selecionado: Item__Resumo[];
    list_talhoes: Item__Talhoes[]
}

export const ListagemPragas: React.FC<Props> = ({ dados_monitoramento_selecionado, list_talhoes }) => {

    //CONST
    let { nomeSafra } = localConfig();

    //STATES
    const [agrupar_variacoes, setAgrupar_variacoes] = useState<boolean>(false);
    const [buscar_praga, setBuscar_praga] = useState<string>('');
    const [cod_talhao_selecionado, setCod_talhao_selecionado] = useState<number>(null);
    const [talhao_selecionado, setTalhao_selecionado] = useState<string>(null);

    //FUNCTIONS
    function encontrarPraga(buscar_nome: string) {
        const praga_digitada = removerAcentos(buscar_nome);
        return function(item) {
            const especie = removerAcentos(String(item?.['especie']));
            const variacao = removerAcentos(String(item?.['variacao']));
            return especie?.toLowerCase()?.includes(praga_digitada.toLowerCase()) ||
                  variacao?.toLowerCase()?.includes(praga_digitada.toLowerCase()) || !buscar_nome;
        }
    }
    console.log("dados_monitoramento_selecionado: ", dados_monitoramento_selecionado)
    return (
        <ListPragas>
            <Inline>
                <Switcher 
                    isChecked={agrupar_variacoes}
                    trigger={setAgrupar_variacoes}
                    label={'Agrupar variações das espécies'}
                />
                <Input 
                    width={438}
                    placeholder="Buscar Praga ou variação"
                    value={buscar_praga}
                    onChange={setBuscar_praga}
                    desabilitado={talhao_selecionado===null}
                />
            </Inline>

            {/* <div className="ListPragas__estadios">
                <div className="ListPragas__estadios__title">
                    Estádio(s)
                </div>
                <div className="ListPragas__estadios__listagem">
                    R7.1- Até 50% de maturação fisiológica, R5.5 - Granação de 76 a 100%
                </div>
            </div> */}

            <div className="ListPragas__talhoes">
                {processarMonitoramentoSelecionado(dados_monitoramento_selecionado).map(({ nome_talhao, cod_talhao, dados_talhao }, index, self)=> {
                    return (
                        <Accordion
                            label={nome_talhao}
                            open={talhao_selecionado===nome_talhao}
                            trigger={()=>{setCod_talhao_selecionado(talhao_selecionado===nome_talhao ? null : cod_talhao);setTalhao_selecionado(talhao_selecionado===nome_talhao ? '' : nome_talhao)}}
                            extra={ cod_talhao_selecionado === null ?
                                <Svg
                                    fill={'#70CE9F'}
                                    //@ts-ignore
                                    coordinates={obterCoordenadasTalhao(list_talhoes, cod_talhao)}
                                />
                                : undefined
                            }
                            isFirst={index===0}
                            isLast={self.length === (index + 1) }
                            single={cod_talhao_selecionado === cod_talhao}
                            style={{ display: esconderAccordion(cod_talhao_selecionado, cod_talhao) }}
                        >
                            <LoadAccordion qtd_registros={(dados_talhao ?? []).length}>
                                
                                <HeaderTable 
                                    itens={[
                                        {valor: 'Praga', width: 270},
                                        {valor: 'Pontos', width: 80},
                                        {valor: 'Métrica', width: 94},
                                        {valor: 'Min', width: 80},
                                        {valor: 'Méd', width: 80},
                                        {valor: 'Max', width: 80}
                                    ]}
                                />
                                <BodyTable style={{height: 'calc(100vh - 342px)', overflowY: 'scroll', overflowX: 'hidden'}}>
                                    {(listarDadosTalhao(dados_talhao, agrupar_variacoes) ?? []).filter(encontrarPraga(buscar_praga)).map(({ especie, variacao, colmo, pontos, tipo_metrica_nome, minimo, media, maximo, inverter_escala, nivel_controle }, index)=> {
                                        let is_safra_milho = String(nomeSafra).toLowerCase().includes('milho');
                                        return (
                                            <RowTable style={{fontSize: 14}} key={index}>
                                                <RowItem width={270} style={{flexDirection:'column',alignItems: 'flex-start', justifyContent:'center'}} >
                                                    <span style={{fontWeight: 600}}>{especie}</span>
                                                    {variacao!==null && <span style={{color: 'var(--text-color-light)', fontWeight: 500}}>{variacao}</span> }
                                                    {is_safra_milho  && <small style={{fontWeight:600, color: 'var(--text-color-light)'}}>colmo: {colmo}</small>}
                                                </RowItem>
                                                <RowItem width={80}>
                                                    {pontos}
                                                </RowItem>
                                                <RowItem width={94} style={{fontSize: tipo_metrica_nome==='Porcentagem' && 11}}>
                                                    {tipo_metrica_nome}
                                                </RowItem>
                                                <RowItem width={80} gap={5}>
                                                    {Number(minimo).toFixed(2)} <SmallCircleNC valor={Number(minimo)} nivel_controle={Number(nivel_controle)} is_invertido={Boolean(inverter_escala)} />
                                                </RowItem>
                                                <RowItem width={80} gap={5}>
                                                    {Number(media).toFixed(2)} <SmallCircleNC valor={Number(media)} nivel_controle={Number(nivel_controle)} is_invertido={Boolean(inverter_escala)} />
                                                </RowItem>
                                                <RowItem width={80} gap={5}>
                                                    {Number(maximo).toFixed(2)} <SmallCircleNC valor={Number(maximo)} nivel_controle={Number(nivel_controle)} is_invertido={Boolean(inverter_escala)} />
                                                </RowItem>
                                            </RowTable>
                                        )
                                    })}
                                    
                                    <Render condition={(dados_talhao ?? []).filter(encontrarPraga(buscar_praga))?.length === 0}>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<> <b>'{buscar_praga}'</b> não encontrada!</>} />
                                    </Render>
                                    
                                </BodyTable>

                            </LoadAccordion>
                        </Accordion>
                    )
                })}
            </div>
            
        </ListPragas>
    )
};