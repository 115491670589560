import styled from "styled-components";

export const MediaContainer = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    gap: 10px;
    overflow-x: auto;
    box-sizing: border-box;
    padding-bottom: 5px;

    div.MediaContainer__media {
        min-width: 95px;
        width: 95px;
        height: 100%;
        background: rgba(93, 165, 125, 0.5);
        border-radius: 7px;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        :is([data-selected = true]) {
            border: 2px solid var(--primary-color);
        }
        
        img.MediaContainer__media__img {
            width: 100%;
            transition: .4s;
            transform: scale(1.5);

            :hover {
                transform: scale(2);
            }
        }
    }
`;

export const MediaDescription = styled.p`
    color: var(--text-color);
    margin: 0px;
`;