import { motion, AnimatePresence } from 'framer-motion';
import ReactDOM from 'react-dom';

//ASSETS
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//STYLES
import { ContainerModalPO as StyledContainerModalPO } from './style';

//APP


//COMPONENTS


//CONST
const ContainerModalPO = motion(StyledContainerModalPO);

//PROPS
interface Props {
    open: boolean;
    onClose: Function;
    title: string;
    children?: React.ReactNode;
    width?: number;
    height?: number | string;
    top: number;
    right: number;
}

export const ModalPopOver: React.FC<Props> = ({ open, onClose, title, children, width, top, right, height }) => {

    return ReactDOM.createPortal(
        <AnimatePresence>

            {open &&(
                <ContainerModalPO 
                    style={{width: width, height: height, top: top, right: right}}
                    initial={{ opacity: 0.5, y: 40 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 80 }}
                    transition={{ duration: 0.8, ease: [0, 0.71, 0.2, 1.01], exit: { duration: 0.1, ease: [0, 1.21, 0.77, 0.91] } }}
                >
                    <div className='ContainerModalPO__header'>
                        {title}

                        <button className='ContainerModalPO__header__close' onClick={()=>onClose()}>
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </div>

                    <div className='ContainerModalPO__body'>
                        {children}
                    </div>
                    
                    
                </ContainerModalPO>
            )}
            
        </AnimatePresence>,

        document.getElementById('portal')
    );
}