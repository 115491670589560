import React, { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { GoogleMap, Polygon, Marker } from '@react-google-maps/api';
import 'leaflet/dist/leaflet.css';

//ASSETS
import pin from '../../../../../../assets/svg/marker.svg';

//APP
import { getBoundsNoArray } from '../../../../../../services/Afins'

//PROPS
export interface Props {
    coord: google.maps.LatLng[] | google.maps.LatLngLiteral[]
    customHeight?: number | string;
    keyProp: number | string;
    list_pontos: {lat: number, lng: number, ponto: string}[]
}

const MapTalhao: React.FC<Props> = ({ coord, customHeight, keyProp, list_pontos }) => {
    
    //STATES
    const [map, setMap] = useState<google.maps.Map>()
    const [polygon, setPolygon] = useState<google.maps.Polygon>()

    //CONST
    const optionsPolygon: google.maps.PolygonOptions = { fillColor: 'aqua', strokeColor: 'aqua' };
    const optionsMap: google.maps.MapOptions = {
        mapTypeId: 'hybrid',
        zoomControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        scrollwheel: false,
        draggable: false
    }
    const brazilCoords = { lat: -14.235, lng: -51.9253 }

    //USEEFFECTS
    useEffect(() => {
        if (map && coord && polygon) {
            map.fitBounds(getBoundsNoArray(coord))
            map?.setZoom(14)
            polygon.setPath(coord)
        }
    });

    return (
        // @ts-ignore
        <GoogleMap
            onLoad={setMap}
            mapContainerStyle={{ minWidth: '100%', maxWidth: '500px', height: customHeight ?? '50vh' }}
            center={brazilCoords}
            options={optionsMap}
            key={keyProp}
        >
            <Polygon onLoad={setPolygon} options={optionsPolygon} />

            {(list_pontos??[]).map(({ lat, lng, ponto })=> {
                return (
                    <Marker
                        key={uniqueId()}
                        clickable
                        icon={{ url: pin, scaledSize: new window.google.maps.Size(30, 40) }}
                        visible={true}
                        position={{ lat: Number(lat), lng: Number(lng) }}
                        onClick={() => {}}
                        label={{
                            className: 'MapTalhao__label',
                            text: ponto,
                            fontSize: '11px',
                            fontWeight: 'bold',
                        }}
                    />
                )
            })}
            

        </GoogleMap>
    )
}

export default MapTalhao
