import { useQuery } from 'react-query';

//APP
import { api } from '../../../api';
import { localConfig } from '../../../../Configs/localConfig';
import { errorAlert } from '../../../../utils/alert';
import { removerEspacos } from '../../../../utils';

//=================================================================================>
// BUSCAR LISTA DE PROPRIEDADES E SEUS TALHÕES
//<=================================================================================

//TYPES
type Item = {
    cod: number | null,
    nome: string | null,
    status: number | null,
    cod_tipo: number | null,
    cod_conta: number | null,
    cod_cliente: number | null,
    arquivo: string | null,
    area: string | null,
    matricula: string | null,
    cod_cidade: number | null,
    ccir: string | null,
    itr: string | null,
    nirf: string | null,
    inscricao_estadual: string | null,
    area_matricula: string | null,
    cod_calcario: number | null,
    cod_consultor: number | null,
    conta_nome: string | null,
    cliente_nome: string | null,
    cliente: {cod: number, nome: string}[];
    cidade: any[];
    talhao: any[],
    imagens_monitoramento: number
}

//INTERFACE
export interface UsuarioAssisTecnico {
    itens: Item[]
}

export const useGetPropriedadesComFotosTalhoes = () => {
    let { conta, codCliente, codSafra } = localConfig();

    const queryKey: any = [`propriedade_imagem_${conta}_${codCliente}_${codSafra}`];

    const { data, isFetching, refetch, isFetchedAfterMount, isError } = useQuery<UsuarioAssisTecnico, Error>(queryKey, async () => {
        return api.get(`propriedade?codConta=${conta}&codCliente=${codCliente}&codSafra=${codSafra}&embed=imagem`)
            .then((response):any => {
                return response.data
            })
            .catch((error) => {
                errorAlert(error?.response?.data?.error ?? 'Falha ao obter lista de propriedades!');
            })
    },
        {
            enabled: true,
            refetchOnWindowFocus: false,
            staleTime: 300000
        }
    );

    return { 
        data_propriedades: data, 
        carregando_propriedades: isFetching, 
        refetch_data_propriedades: refetch,
        isFetched: isFetchedAfterMount,
        isError: isError
    };
};


//=================================================================================>
// BUSCAR LISTA DE DATAS DE RESUMO
//<=================================================================================

//TYPES
export type ImagemBookMonitoramento__Itens = {
    cod: number | null,
    cod_monitoramento:  number | null,
    arquivo:  string | null,
    descricao: null,
    hora: string | null,
    cod_talhao:  number | null,
    url_arquivo: string | null,
    talhao: {
                cod:  number | null,
                nome:string | null,
                kml: { nome: "", lookAt: { latitude:  number, longitude: number }, coordenadas: { latitude: number, longitude: number }[] },
                propriedade_nome: "ETN. PEROBA ROSA; FAZ. TRÊS IRMÃOS.",
                cod_cliente:  number | null,
            },
    monitoramento: {
        cod:  number | null,
        data_inicio: string | null,
        data_final: string | null,
        ponto:  number | null,
        longitude: string | null,
        latitude: string | null,
    }
}

//INTERFACE
export interface ImagemBookMonitoramento {
    itens: ImagemBookMonitoramento__Itens[],
    total?: number | null,
    mensagem?: string | null,
}

export const useGetRelatorioFotoMonitoramento = (codPropriedade: number, triggerError?: Function) => {

    const { conta, codCliente, codSafra } = localConfig();
    
    const queryKey: any = [`book-monitoramento-imagem_${conta}_${codCliente}_${codSafra}_${codPropriedade}`];


    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<ImagemBookMonitoramento, Error>(queryKey, async () => {
        
        return api.get(`monitoramento-imagem?codConta=${conta}&codCliente=${codCliente}&codSafra=${codSafra}&codPropriedade=${codPropriedade}&embed=monitoramento,talhao&pagination=-1`)
            .then((response):any => {
                triggerError?.(false);
                return response.data
            })
            .catch(() => {
                errorAlert('Falha ao obter imagens do monitormanto!');
                triggerError?.(true);
            })
    },
        {
            enabled: true,
            refetchOnWindowFocus: false,
            staleTime: 300000
        }
    );

    return { 
        data_relatorio: data?.itens ?? [], 
        carregando_relatorio: isFetching, 
        refetch_imagens_book: refetch,
        isFetched_imagens_book: isFetchedAfterMount
    };
};
