import Icon from '@ant-design/icons'
import { Button, Drawer, Form, Select, Table } from 'antd'
import { Excel } from 'antd-table-saveas-excel'
import { IExcelColumn } from 'antd-table-saveas-excel/app'
import { RefSelectProps } from 'antd/lib/select'
import dayjs, { Dayjs } from 'dayjs'
import html2canvas from 'html2canvas'
import { jsPDF as JsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import { orderBy, uniq } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ViewPage from '../../../../Components/ViewPage'

import { SvgExcel, SvgPdf } from '../../../../assets/svg'
import { ReportFooter, ReportHeader } from '../../../../Components/CabecalhoRodape'
import DatePicker from '../../../../Components/DatePiker'
import { pagination } from '../../../../Components/Default'
import { useLogic } from '../../../../context/useLogic'
import { EstadioItens, getEstadios } from '../../../../services/estadio'
import { getMonitoramentoDatas } from '../../../../services/monitoramento'
import {
  getResumoMonitoramento,
  ItensMonitoramentoResumoTypes,
} from '../../../../services/monitoramento/resumo'
import { getPropriedade, PropriedadeItens } from '../../../../services/Propriedades'
import { getTalhaoCultivavel, TalhaoCultivaveisItens } from '../../../../services/TalhaoSafra'
import { Container } from './styles'
import { TitleH1 } from '../../../../Components/CustomUi/titleH1'
import { TabelaResumo } from './components/tabela'

interface TypeFinish {
  codPropriedade: number
  codTalhao: number
  dataInicioMonitoramento: string
}



const ResumoMonitoramento: React.FC = () => {
  const { checkSafra } = useLogic()

  const [dataPropriedade, setDataPropriedade] = useState<PropriedadeItens[]>([])
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const [dataTalhao, setDataTalhao] = useState<TalhaoCultivaveisItens[]>([])
  const [dataMonitoramento, setDataMonitoramento] = useState<ItensMonitoramentoResumoTypes[]>([])
  const [fromValues, setFromValues] = useState<TypeFinish>({} as TypeFinish)
  const [dataEstadios, setDataEstadios] = useState<EstadioItens[]>([])
  const [datasMonitoradas, setDatasMonitoradas] = useState<string[]>([])
  const [estadios, setEstadios] = useState<string>()
  const codSafra = localStorage.getItem('codSafra')

  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const refSelectPropriedade = useRef<RefSelectProps>()

  const [form] = Form.useForm<TypeFinish>()

  const listPropriedade = async () => {
    const resp = await getPropriedade('', '')
    const respFilter = resp.data.itens.filter((item) => item.status === 1)
   
    setDataPropriedade(respFilter)
  }

  const listEstadios = async () => {
    const resp = await getEstadios({ pagination: -1 })

    setDataEstadios(resp.data.itens)
  }

  const handleChangePropriedade = async (cod) => {
    form.resetFields(['dataInicioMonitoramento', 'codTalhao'])
    const resp = await getTalhaoCultivavel({ codPropriedade: cod })
    setDataTalhao(resp.data.itens)
  }

  const handleChangeTalhao = async (codTalhao) => {
    const codPropriedade = form.getFieldValue('codPropriedade')

    const resp = await getMonitoramentoDatas({ codPropriedade, codTalhao })

    setDatasMonitoradas(resp.data.itens.map((rec) => rec.data_inicio))
  }

  const handleDisableDate = (date: Dayjs) => {
    const resp = datasMonitoradas.filter(
      (rec) => dayjs(rec).format('DD/MM/YYYY') === date.format('DD/MM/YYYY')
    )
    return resp.length <= 0
  }

  const onFinish = async (values: TypeFinish) => {
    setLoading(true)
    values.dataInicioMonitoramento = dayjs(values.dataInicioMonitoramento).format('YYYY-MM-DD')
    setFromValues(values)
    setDataMonitoramento([])
    const resp = await getResumoMonitoramento({
      ...values,
      tipo: 2,
      embed: 'estadios_nc',
    })
    const dados = orderBy(resp.data.itens, 'pontos_acima', 'desc')
    setDataMonitoramento(dados)
    // setVisibleDrawer(true)
    setLoading(false)
  }


  useEffect(() => {
    setEstadios(
      `Estádio(s): ${uniq(
        dataMonitoramento.map(
          (rec) => dataEstadios.find((e) => e.cod === rec.estadios_nc[0].cod_estadio).nome
        )
      ).join(', ')}`
    )
  }, [dataMonitoramento])

  useEffect(() => {
    listPropriedade()
    listEstadios()
    refSelectPropriedade?.current?.focus?.()

    const idDaSafra = checkSafra('/monitoramento/resumo')

    if (!idDaSafra) {
      history.push('/home')
    }
  }, [])

  useEffect(() => {
    listPropriedade()
    listEstadios()
  }, [codSafra])

  return (
    <Container>
      <ViewPage
        newHide
        btnClick={null}
        title='Resumo monitoramento'
        content={<></>}
        hiddeTitle
        cardBodyStyle={{padding: 15}}
        cardMainStyle={{height: '87.5vh'}}
      >
        <TitleH1>Resumo do monitoramento</TitleH1>

        <TabelaResumo />

      </ViewPage>


      <div style={{ width: '900px' }} id='tempDiv' />
    </Container>
  )
}

export default ResumoMonitoramento
