import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom'
import { RefSelectProps } from 'antd/lib/select'

//STYLES
import { Container } from './styles'

//APP
import { useLogic } from '../../../../context/useLogic'

//COMPONENTS
import ViewPage from '../../../../Components/ViewPage';
import { TitleH1 } from '../../../../Components/CustomUi/titleH1';
import { TabelaRelatorioFotografico } from './components/tabela';

const RelatorioFotografico: React.FC = () => {

  //REFs
  const refSelectPropriedade = useRef<RefSelectProps>();

  //CONST
  const history = useHistory();
  const { checkSafra } = useLogic();

  useEffect(() => {
    refSelectPropriedade?.current?.focus?.()

    const idDaSafra = checkSafra('/monitoramento/relatorio-fotografico');

    if (!idDaSafra) {
      history.push('/home')
    }
  }, []);

  return (
    <Container>
      <ViewPage
        newHide
        btnClick={null}
        title='Relatório fotográfico'
        content={<></>}
        hiddeTitle
        cardBodyStyle={{padding: 15}}
        cardMainStyle={{height: '87.5vh'}}
      >
        <TitleH1>Relatório fotográfico</TitleH1>

        <TabelaRelatorioFotografico />

      </ViewPage>


      <div style={{ width: '900px' }} id='tempDiv' />
    </Container>
  )
}

export default RelatorioFotografico;
