import React, { useEffect, useState } from 'react'
import { ContainerVaridade } from './styles'
import ViewPage from '../../../../Components/ViewPage'
import { TitleH1 } from '../../../../Components/CustomUi/titleH1'
import { TabelaVariedadeHibrida } from './components/tabela'
import {
  useGetClassificacao,
  useGetCultura,
  useGetEspecieVariacao,
  useGetNivelInvestimento,
  useGetOpcaoClassificacao,
  useGetTecnologias,
  useGetTipo,
  useGetVariedadeHibrido,
  useGetVariedadeHibridoEditar,
} from '../../../../services/variedadeHibrido/GET'
import { Drawer } from 'antd'
import ButtonCustom from '../../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk, faXmark } from '@fortawesome/free-solid-svg-icons'
import { NavegacaoDrawer } from '../novoCompatibilidadeAtivo/components/gavetaCadastrar/styled'
import { FormVariedadeHibrido } from '../../../../Components/formVariedadeHibrido'
import {
  usePostVariedadeHibrido,
  usePutVariedadeHibrido,
} from '../../../../services/variedadeHibrido/POST'
import { v4 as uid } from 'uuid'
import { useDeleteVariedade } from '../../../../services/variedadeHibrido/DELETE'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'

const VariedadeHibrida: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)

  //TABELA
  const [update, setUpdate] = useState<number>(null)
  const [updateDuplicar, setUpdateDuplicar] = useState<number>(null)
  const [buscarTabela, setBuscarTabela] = useState<string>('')
  const [pagination, setPagination] = useState<number>(15)
  const [page, setPage] = useState<number>(1)
  const [confirmar_exclusao, setConfirmar_exclusao] = useState<boolean>(false)
  const [id_item_atual, setId_item_atual] = useState<number>(null)
  const [nome_item_atual, setNome_item_atual] = useState<string>('')
  const [fake_loading, setFake_loading] = useState<boolean>(false)
  const [permissions, setPermissions] = useState<ItensPerUserLogged>()

  //FORMULARIO
  const [nome, setNome] = useState<string>('')
  const [empresa, setEmpresa] = useState<string>('')
  const [cultura, setCultura] = useState<string>('')
  const [culturaId, setCulturaId] = useState<number>(null)
  const [culturaIdDuplicado, setCulturaIdDuplicado] = useState<number>(null)
  const [tecnologias_selecionadas, setTecnologias_selecionadas] = useState<
    { descr: string; id: number; key?: number }[]
  >([])
  const [nivel_investimento, setNivel_investimento] = useState<string>('')
  const [nivel_investimentoId, setNivel_investimentoId] = useState<number>(null)
  const [classificacao, setClassificacao] = useState<string>('')
  const [classificacaoId, setClassificacaoId] = useState<number>(null)
  const [opcao_classificacao, setOpcao_classificacao] = useState<string>('')
  const [opcao_classificacaoId, setOpcao_classificacaoId] = useState<number>(null)
  const [valor_classificacao, setValor_classificacao] = useState<string>('')
  const [especie, setEspecie] = useState<string>('')
  const [especieId, setEspecieId] = useState<number>(null)
  const [variacao_especie, setVariacao_especie] = useState<string>('')
  const [variacao_especieId, setVariacao_especieId] = useState<number>(null)
  const [tipo, setTipo] = useState<string>('')
  const [tipoId, setTipoId] = useState<number>(null)
  const [tolerancias, setTolerancias] = useState<
    {
      cod?: number | string
      cod_especie: number
      cod_variacao_especie?: number
      cod_tipo: number
    }[]
  >([])
  const [tolerancias_remover, setTolerancias_remover] = useState<
    {
      cod?: number | string
      cod_especie: number
      cod_variacao_especie?: number
      cod_tipo: number
    }[]
  >([])
  const [confirmar_exclusao_tolerancia, setConfirmar_exclusao_tolerancia] = useState<boolean>(false)
  const [id_item_atual_tolerancia, setId_item_atual_tolerancia] = useState<number>(null)
  const [nome_item_atual_tolerancia, setNome_item_atual_tolerancia] = useState<string>('')
  const [fake_loading_tolerancia, setFake_loading_tolerancia] = useState<boolean>(false)
  const [editar, setEditar] = useState<boolean>(false)
  const [cod_tolerancia, setCod_tolerancia] = useState<number>(null)

  const { data_variedade, carregando_variedade, refetch_data_variedade } = useGetVariedadeHibrido(
    pagination,
    page
  )
  const { data_variedade_editar, carregando_variedade_editar, refetch_data_variedade_editar } =
    useGetVariedadeHibridoEditar(update ?? updateDuplicar)
  const { data_tecnologias, refetch_data_tecnologias } = useGetTecnologias(culturaId)
  const { data_cultura, refetch_data_cultura } = useGetCultura()
  const { data_nivel_investimento, refetch_data_nivel_investimento } = useGetNivelInvestimento()
  const { data_classificacao, refetch_data_classificacao } = useGetClassificacao()
  const { data_opcao_classificacao, refetch_data_opcao_classificacao } =
    useGetOpcaoClassificacao(classificacaoId)
  const { data_especieVariacao, refetch_data_especieVariacao } = useGetEspecieVariacao()
  const { data_tipo, refetch_data_tipo } = useGetTipo()
  const { mutate_variedade_hibrido, carregando_variedade_hibrido } = usePostVariedadeHibrido()
  const { mutate_edit_variedade, carregando_edit_variedade } = usePutVariedadeHibrido(update)
  const { mudateDeletarEngrenagem } = useDeleteVariedade()

  const gerarIdsUnicos = () => {
    const gerarIds = new Set()
    let id
    while (!id || gerarIds.has(id)) {
      id = Math.floor(Math.random() * 1000000) // Número de 6 dígitos
    }
    gerarIds.add(id)
    return id
  }

  function abrirGaveta() {
    setOpen(true)
  }

  function editarGaveta(cod) {
    setOpen(true)
    setUpdate(cod)
  }

  function duplicarDados(cod) {
    setOpen(true)
    setUpdateDuplicar(cod)
  }

  function editarCadastro() {
    refetch_data_variedade_editar()
  }

  function deletarVariedade(cod) {
    setFake_loading(true)
    mudateDeletarEngrenagem({
      cod_variedade: cod,
      refetch_deletar: () => {
        fecharGaveta()
        refetch_data_variedade()
        refetch_data_cultura()
        refetch_data_nivel_investimento()
        refetch_data_classificacao()
        refetch_data_especieVariacao()
        refetch_data_tipo()
      },
    })

    setTimeout(() => {
      setFake_loading(false)
    }, 800)
  }

  function fecharGaveta() {
    setOpen(false)
    setUpdate(null)
    setUpdateDuplicar(null)
    setCod_tolerancia(null)
    setNome('')
    setEmpresa('')
    setCultura('')
    setCulturaId(null)
    setCulturaIdDuplicado(null)
    setTecnologias_selecionadas([])
    setNivel_investimento('')
    setNivel_investimentoId(null)
    setClassificacao('')
    setClassificacaoId(null)
    setOpcao_classificacao('')
    setOpcao_classificacaoId(null)
    setValor_classificacao('')
    setEspecie('')
    setEspecieId(null)
    setVariacao_especie('')
    setVariacao_especieId(null)
    setTipo('')
    setTipoId(null)
    setTolerancias([])
    setTolerancias_remover([])
    setEditar(false)
  }

  function salvarVariedadeHibrido() {
    if (update > 0) {
      mutate_edit_variedade({
        nome: nome,
        empresa_obtentora: empresa,
        cod_cultura: culturaId,
        tecnologias: tecnologias_selecionadas?.map((item) => item.id),
        cod_nivel_investimento: nivel_investimentoId,
        cod_classificacao: classificacaoId,
        cod_opcao_classificacao: opcao_classificacaoId,
        valor_classificacao: valor_classificacao,
        tolerancias: tolerancias,
        tolerancias_remover: tolerancias_remover,
        refetch: () => {
          fecharGaveta()
          refetch_data_variedade()
          refetch_data_cultura()
          refetch_data_nivel_investimento()
          refetch_data_classificacao()
          refetch_data_especieVariacao()
          refetch_data_tipo()
        },
      })
    }

    if (update <= 0 || updateDuplicar > 0) {
      mutate_variedade_hibrido({
        nome: nome,
        empresa_obtentora: empresa,
        cod_cultura: culturaId,
        tecnologias: tecnologias_selecionadas?.map((item) => item.id),
        cod_nivel_investimento: nivel_investimentoId,
        cod_classificacao: classificacaoId,
        cod_opcao_classificacao: opcao_classificacaoId,
        valor_classificacao: valor_classificacao,
        tolerancias: tolerancias,
        refetch: () => {
          fecharGaveta()
          refetch_data_variedade()
          refetch_data_cultura()
          refetch_data_nivel_investimento()
          refetch_data_classificacao()
          refetch_data_especieVariacao()
          refetch_data_tipo()
        },
      })
    }
  }

  function editarTolerecnia(cod, cod_especie, cod_tipo) {
    setEditar(true)
    setCod_tolerancia(cod)
    const filterEspecie = data_especieVariacao.filter((item) => item.cod === cod_especie)
    setEspecieId(cod_especie)
    setEspecie(filterEspecie[0]?.nome)
    const filterTipo = data_tipo.filter((item) => item.cod === cod_tipo)
    setTipoId(cod_tipo)
    setTipo(filterTipo[0]?.nome)
  }

  function adicionarTolerancia(cod) {
    if (cod === null) {
      if (especieId !== null && tipoId !== null) {
        setTolerancias([
          ...tolerancias,
          {
            cod: uid(),
            cod_especie: especieId,
            cod_tipo: tipoId,
          },
        ])
      }
    } else {
      setTolerancias((prevTolerancia) =>
        prevTolerancia.map((item) =>
          item.cod === cod
            ? {
                ...item,
                cod_especie: especieId,
                cod_variacao_especie: variacao_especieId,
                cod_tipo: tipoId,
              }
            : item
        )
      )
      setEditar(false)
      setCod_tolerancia(null)
    }

    setEspecie('')
    setEspecieId(null)
    setTipo('')
    setTipoId(null)
    setCod_tolerancia(null)
  }
  function deletarTolerancia(cod) {
    if (typeof cod === 'string') {
      setTolerancias((prevTolerancia) => prevTolerancia.filter((item) => item.cod !== cod))
    } else {
      setTolerancias((prevDados) => {
        const removidos = prevDados.find((item) => item.cod === cod)
        if (removidos) {
          setTolerancias_remover((prevRemovidos) => [...prevRemovidos, removidos])
        }
        return prevDados.filter((item) => item.cod !== cod)
      })
    }
  }

  useEffect(() => {
    if (data_variedade_editar.length > 0) {
      setClassificacao(data_variedade_editar[0]?.tipo_classificacao)
      setClassificacaoId(data_variedade_editar[0]?.cod_classificacao)
      setOpcao_classificacao(data_variedade_editar[0]?.tipo_opcao_classificacao)
      setOpcao_classificacaoId(data_variedade_editar[0]?.cod_opcao_classificacao)
      setNome(data_variedade_editar[0]?.nome)
      setEmpresa(data_variedade_editar[0]?.empresa_obtentora)
      setValor_classificacao(String(data_variedade_editar[0]?.valor_classificacao))
      const filterCultura = data_cultura.filter(
        (item) => item.cod === data_variedade_editar[0]?.cod_cultura
      )
      setCultura(filterCultura[0]?.nome)
      setCulturaId(data_variedade_editar[0]?.cod_cultura)
      const editTecnologia = data_variedade_editar[0]?.tecnologias.map((item, index) => {
        return { descr: item?.tecnologia_nome, id: item?.cod_tecnologia, key: item?.cod_tecnologia }
      })
      setCulturaIdDuplicado(data_variedade_editar[0]?.cod_cultura)
      setTecnologias_selecionadas(editTecnologia)
      setNivel_investimento(data_variedade_editar[0]?.nivel_investimento)
      setNivel_investimentoId(data_variedade_editar[0]?.cod_nivel_investimento)
      
      const editTolerancia = data_variedade_editar[0]?.tolerancias.map((item) => {
        if (item?.cod_especie) {
          return {
            cod: gerarIdsUnicos(),
            cod_variacao_especie: null,
            cod_especie: item?.cod_especie,
            cod_tipo: item?.cod_tipo,
          }
        } else {
          return {
            cod: gerarIdsUnicos(),
            cod_especie: null,
            cod_variacao_especie: item?.cod_variacao_especie,
            cod_tipo: item?.cod_tipo,
          }
        }
      })
      setTolerancias(editTolerancia)
    }
  }, [data_variedade_editar])

  useEffect(() => {
    refetch_data_variedade()
    refetch_data_cultura()
    refetch_data_nivel_investimento()
    refetch_data_classificacao()
    refetch_data_especieVariacao()
    refetch_data_tipo()
  }, [])

  useEffect(() => {
    refetch_data_tecnologias()
  }, [culturaId])

  useEffect(() => {
    if (classificacaoId === 51) {
      refetch_data_opcao_classificacao()
    }
  }, [classificacaoId])

  useEffect(() => {
    if (update > 0) {
      editarCadastro()
    }
  }, [update])
  useEffect(() => {
    if (updateDuplicar > 0) {
      editarCadastro()
    }
  }, [updateDuplicar])

  useEffect(() => {
    refetch_data_variedade()
  }, [pagination])

  useEffect(() => {
    refetch_data_variedade()
  }, [page])

  // useEffect(() => {
  //   const variacoesDaEspecie = data_especieVariacao.filter((item) => item.cod === especieId)
  //   console.log(variacoesDaEspecie)
  // }, [especieId])

  useEffect(() => {
    if (culturaIdDuplicado !== culturaId) {
      setTecnologias_selecionadas([])
    }
  }, [data_tecnologias])

  return (
    <ContainerVaridade>
      <ViewPage
        rotina={86}
        onPermission={setPermissions}
        title='Variedade/Híbrido'
        btnClick={null}
        hiddeTitle
        cardBodyStyle={{ padding: 15 }}
        cardMainStyle={{ height: '87.5vh' }}
      >
        <div style={{ marginBottom: '10px' }}>
          <TitleH1>Variedade/Híbrido</TitleH1>
        </div>

        <TabelaVariedadeHibrida
          loading={carregando_variedade}
          setPagination={setPagination}
          page={page}
          setPage={setPage}
          total={data_variedade?.total}
          listar_variedades={data_variedade?.itens}
          setOpen={setOpen}
          abrirGaveta={abrirGaveta}
          editarCadastro={editarGaveta}
          duplicarDados={duplicarDados}
          deletar={deletarVariedade}
          permissao={permissions}
          confirmar_exclusao={confirmar_exclusao}
          setConfirmar_exclusao={setConfirmar_exclusao}
          id_item_atual={id_item_atual}
          setId_item_atual={setId_item_atual}
          nome_item_atual={nome_item_atual}
          setNome_item_atual={setNome_item_atual}
          fake_loading={fake_loading}
          setFake_loading={setFake_loading}
          buscarTabela={buscarTabela}
          setBuscarTabela={setBuscarTabela}
          data_cultura={data_cultura}
        />
      </ViewPage>
      <Drawer
        width={650}
        open={open}
        onClose={() => fecharGaveta()}
        closable={false}
        bodyStyle={{ padding: 0 }}
        headerStyle={{ padding: '10px 10px' }}
        destroyOnClose
        footer={
          <div className='footer'>
            <ButtonCustom
              icon={<FontAwesomeIcon icon={faFloppyDisk} />}
              type='primary'
              onClick={salvarVariedadeHibrido}
              loading={carregando_variedade_hibrido ?? carregando_edit_variedade}
              disabled={carregando_variedade_hibrido ?? carregando_edit_variedade}
            >
              Salvar
            </ButtonCustom>
          </div>
        }
        title={
          <NavegacaoDrawer>
            <ButtonCustom
              danger
              icon={<FontAwesomeIcon icon={faXmark} />}
              type='tertiary'
              onClick={() => fecharGaveta()}
              style={{ marginLeft: -10 }}
            >
              Fechar
            </ButtonCustom>
          </NavegacaoDrawer>
        }
      >
        <FormVariedadeHibrido
          update={update}
          loadingForm={carregando_variedade_editar}
          nome={nome}
          setNome={setNome}
          empresa={empresa}
          setEmpresa={setEmpresa}
          cultura={cultura}
          setCultura={setCultura}
          culturaId={culturaId}
          setCulturaId={setCulturaId}
          tecnologias_selecionadas={tecnologias_selecionadas}
          setTecnologias_selecionadas={setTecnologias_selecionadas}
          nivel_investimento={nivel_investimento}
          setNivel_investimento={setNivel_investimento}
          nivel_investimentoId={nivel_investimentoId}
          setNivel_investimentoId={setNivel_investimentoId}
          classificacao={classificacao}
          setClassificacao={setClassificacao}
          classificacaoId={classificacaoId}
          setClassificacaoId={setClassificacaoId}
          opcao_classificacao={opcao_classificacao}
          setOpcao_classificacao={setOpcao_classificacao}
          opcao_classificacaoId={opcao_classificacaoId}
          setOpcao_classificacaoId={setOpcao_classificacaoId}
          valor_classificacao={valor_classificacao}
          setValor_classificacao={setValor_classificacao}
          especie={especie}
          setEspecie={setEspecie}
          especieId={especieId}
          setEspecieId={setEspecieId}
          variacao_especie={variacao_especie}
          setVariacao_especie={setVariacao_especie}
          variacao_especieId={variacao_especieId}
          setVariacao_especieId={setVariacao_especieId}
          tipo={tipo}
          setTipo={setTipo}
          tipoId={tipoId}
          setTipoId={setTipoId}
          confirmar_exclusao={confirmar_exclusao_tolerancia}
          setConfirmar_exclusao={setConfirmar_exclusao_tolerancia}
          id_item_atual={id_item_atual_tolerancia}
          setId_item_atual={setId_item_atual_tolerancia}
          nome_item_atual={nome_item_atual_tolerancia}
          setNome_item_atual={setNome_item_atual_tolerancia}
          fake_loading={fake_loading_tolerancia}
          setFake_loading={setFake_loading_tolerancia}
          data_cultura={data_cultura}
          data_tecnologias={data_tecnologias}
          data_nivel_investimentos={data_nivel_investimento}
          data_classificacao={data_classificacao}
          data_opcao_classificacao={data_opcao_classificacao}
          data_especieVariacao={data_especieVariacao}
          data_tipo={data_tipo}
          tolerancias={tolerancias}
          adicionarTolerancia={adicionarTolerancia}
          editarTolerecnia={editarTolerecnia}
          editar={editar}
          deletarTolerancia={deletarTolerancia}
          cod_tolerancia={cod_tolerancia}
        />
      </Drawer>
    </ContainerVaridade>
  )
}

export default VariedadeHibrida
