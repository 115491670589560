import { useMutation, UseMutationResult } from 'react-query'
import { destroyAlert, errorAlert, loadingAlert, successAlert } from '../../../utils/alert'
import { localConfig } from '../../../Configs/localConfig'
import { api } from '../../api'

type Tolerancias = {
  cod_especie: number
  cod_variacao_especie?: number
  cod_tipo: number
}

interface Params {
  nome: string
  empresa_obtentora: string
  cod_cultura: number
  tecnologias: number[]
  cod_nivel_investimento: number
  cod_classificacao: number
  cod_opcao_classificacao: number
  valor_classificacao: string
  tolerancias: Tolerancias[]
  tolerancias_remover?: Tolerancias[]
  refetch?: Function
}

export const usePostVariedadeHibrido = (): {
  carregando_variedade_hibrido: boolean
  mutate_variedade_hibrido: (params: Params) => void
  isSuccess_variedade_hibrido: boolean
} => {
  const mutation: UseMutationResult<Response, Error, Params> = useMutation(
    async (params: Params) => {
      const {
        nome,
        empresa_obtentora,
        cod_cultura,
        tecnologias,
        cod_nivel_investimento,
        cod_classificacao,
        cod_opcao_classificacao,
        valor_classificacao,
        tolerancias,

        refetch,
      } = params

      loadingAlert({ msg: 'Salvando...', id: 'loading' })

      return api
        .post(`variedade-hibrido?codConta=${localConfig()?.conta}`, {
          nome: nome,
          empresa_obtentora: empresa_obtentora,
          cod_cultura: cod_cultura,
          tecnologias: tecnologias,
          cod_nivel_investimento: cod_nivel_investimento,
          cod_classificacao: cod_classificacao,
          cod_opcao_classificacao: cod_opcao_classificacao,
          valor_classificacao: valor_classificacao,
          tolerancias: tolerancias,
        })
        .then((response: any) => {
          successAlert('Variedade cadastrada com sucesso!', 5000, 'loading')
          refetch?.()
          return response
        })
        .catch((error: any) => {
          const {data} = error?.response
          
          if (!data.error) {
            data.error.forEach((data) => {
              errorAlert(
                data?.msg[0] ?? 'Falha ao salvar variedade!',
                5000,
                'loading'
              )
            });
          }else{
            errorAlert(
              error?.response?.data?.error ?? 'Falha ao salvar variedade!',
              5000,
              'loading'
            )
          }
          
          
          throw error
        })
    }
  )

  return {
    carregando_variedade_hibrido: mutation.isLoading,
    mutate_variedade_hibrido: mutation.mutate,
    isSuccess_variedade_hibrido: mutation.isSuccess,
  }
}

export const usePutVariedadeHibrido = (
  cod: number
): {
  carregando_edit_variedade: boolean
  mutate_edit_variedade: (params: Params) => void
  isSuccess_edit_variedade: boolean
} => {
  const mutation: UseMutationResult<Response, Error, Params> = useMutation(
    async (params: Params) => {
      const {
        nome,
        empresa_obtentora,
        cod_cultura,
        tecnologias,
        cod_nivel_investimento,
        cod_classificacao,
        cod_opcao_classificacao,
        valor_classificacao,
        tolerancias,
        tolerancias_remover,
        refetch,
      } = params

      loadingAlert({ msg: 'Salvando...', id: 'loading' })

      return api
        .post(`variedade-hibrido/${cod}?codConta=${localConfig()?.conta}`, {
          nome: nome,
          empresa_obtentora: empresa_obtentora,
          cod_cultura: cod_cultura,
          tecnologias: tecnologias,
          cod_nivel_investimento: cod_nivel_investimento,
          cod_classificacao: cod_classificacao,
          cod_opcao_classificacao: cod_opcao_classificacao,
          valor_classificacao: valor_classificacao,
          tolerancias: tolerancias,
          tolerancias_remover: tolerancias_remover,
        })
        .then((response: any) => {
          successAlert('Variedade atualizado com sucesso!', 5000, 'loading')
          refetch?.()
          return response
        })
        .catch((error: any) => {
          errorAlert(
            error?.response?.data?.error ?? 'Falha ao editar a variedade!',
            5000,
            'loading'
          )
          throw error
        })
    }
  )

  return {
    carregando_edit_variedade: mutation.isLoading,
    mutate_edit_variedade: mutation.mutate,
    isSuccess_edit_variedade: mutation.isSuccess,
  }
}
