import dayjs from "dayjs";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Empty, Tag } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//STYLES
import { ContainerDadosResumo } from "./style"

//APP
import { useScreenSize } from "../../../../../../utils/tsx";
import { agruparPorData, larguraResponsiva } from "./meta";
import { useGetTalhoesPropriedade } from "../../../../../../services/monitoramento/resumo/get";
import { apenasNumeros, leadZero, mascaraData, removerAcentos } from "../../../../../../utils";
import { useGetRelatorioFotoMonitoramento, ImagemBookMonitoramento__Itens } from "../../../../../../services/monitoramento/relatorioFotografico/get";

//COMPONENTS
import Input from "../../../../../../Components/CustomUi/input";
import Carregando from "../../../../../../Components/CustomUi/loading";
import ButtonCustom from "../../../../../../Components/ButtonCustom";
import { Render } from "../../../../../../Components/CustomUi/render";
import { Inline } from "../../../../../../Components/CustomUi/inline";
import { RowItem } from "../../../../../../Components/CustomUi/table/rowItem";
import { RowTable } from "../../../../../../Components/CustomUi/table/rowTable";
import { BodyTable } from "../../../../../../Components/CustomUi/table/bodyTable";
import { HeaderTable } from "../../../../../../Components/CustomUi/table/headerTable";
import { ModalPopOver } from "../../../../../../Components/CustomUi/modalPopOver";
import { ListagemFotos } from "./components/modalFotos";

//PROPS
interface Props {
    parent_open: boolean;
    cod_propriedade: number;
    nome_propriedade: string;
}

export const GavetaFotos: React.FC<Props> = ({ parent_open, cod_propriedade, nome_propriedade }) => {

    //CONST
    const { currentWidth } = useScreenSize({})

    //STATES
    const [buscar_data, setBuscar_data] = useState<string>('');
    const [open_modal, setOpen_modal] = useState<boolean>(false);
    const [data_monitoramento_selecionada, setData_monitoramento_selecionada] = useState<string>('');
    const [lista_fotos_data_selecionada, setLista_fotos_data_selecionada] = useState<ImagemBookMonitoramento__Itens[]>([]);

    //API
    const { data_relatorio, carregando_relatorio } = useGetRelatorioFotoMonitoramento(cod_propriedade);
    const { data_talhoes } = useGetTalhoesPropriedade(cod_propriedade);

    //FUNCTIONS
    function filtrarData(buscar_nome: string) {
        const ativoSemAcentos = removerAcentos(buscar_nome)
        return function (item) {
            const data_formatada = dayjs(String(item?.['data_monitoramento'])).format('DD/MM/YYYY');
            return data_formatada?.includes(ativoSemAcentos) || !buscar_nome
        }
    }
    function dataMaisRecente() {
        return 'false'
    }

    return (
        <ContainerDadosResumo>
            <Inline style={{ marginTop: -8 }}>
                <Input
                    width={170}
                    value={mascaraData(apenasNumeros(buscar_data))}
                    onChange={setBuscar_data}
                    placeholder={'__/__/____'}
                />
            </Inline>
            
            <Render condition={carregando_relatorio}>
                <Carregando 
                    justifyContent="center"
                    height={350}
                    legenda="Carregando monitoramentos..."
                    animation
                />
            </Render>
            
            <Render condition={((data_relatorio ??[])?.filter(filtrarData(buscar_data)))?.length === 0 && (data_relatorio ?? []).length > 0 && !carregando_relatorio}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<> data <b>'{buscar_data}'</b> não encontrada!</>} />
            </Render>
            
            <Render condition={((data_relatorio ??[])?.filter(filtrarData(buscar_data)))?.length === 0 && (data_relatorio ?? []).length === 0 && !carregando_relatorio}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<> Nenhum registro de monitoramento foi encontrado!</>} />
            </Render>
            
            <Render condition={(data_relatorio ??[]).filter(filtrarData(buscar_data)).length > 0 && !carregando_relatorio}>
                <HeaderTable 
                    itens={[
                        {valor: 'Datas de monitoramento', width: larguraResponsiva(currentWidth, 461, 'datas_de_monitoramento')},
                        {valor: 'Talhões com fotos', width: larguraResponsiva(currentWidth, 275, 'talhoes_com_fotos')},
                        {valor: currentWidth > 768 ? 'Quantidade de fotos' : 'Qtd fotos', width: larguraResponsiva(currentWidth, 274.5, 'quantidade_de_fotos')},
                        {valor: 'Ações', width: larguraResponsiva(currentWidth, 100, 'acoes')},
                    ]}
                    textTransform="none"                 
                />
                <BodyTable style={{marginTop: -15, overflowX: 'hidden', height: 'calc(100vh - 225px)', overflowY: 'visible'}}>
                    {agruparPorData(data_relatorio ?? []).filter(filtrarData(buscar_data)).map(({ data_inicio, qtd_talhoes, qtd_fotos, dados }, index)=> {
                        
                        let data_moni_format = dayjs(data_inicio).format('DD/MM/YYYY');    
                        return (
                            <RowTable key={index} onDoubleClick={()=>{setLista_fotos_data_selecionada(dados);setData_monitoramento_selecionada(data_moni_format);setOpen_modal(true)}}>
                                <RowItem width={larguraResponsiva(currentWidth, 460, 'datas_de_monitoramento')} style={{fontWeight: data_inicio === dataMaisRecente() ? '700' : undefined, alignItems:'center'}} gap={10}>
                                    {data_moni_format}
                                    {data_inicio === dataMaisRecente() && <Tag color="blue" style={{fontWeight:500}}>mais recente</Tag>}
                                </RowItem>
                                <RowItem width={larguraResponsiva(currentWidth, 275, 'talhoes_com_fotos')} gap={5}>
                                    {leadZero(qtd_talhoes)}
                                </RowItem>
                                <RowItem width={larguraResponsiva(currentWidth, 275, 'quantidade_de_fotos')} gap={5}>
                                    {leadZero(qtd_fotos)}
                                </RowItem>
                                <RowItem width={larguraResponsiva(currentWidth, 100, 'acoes')} gap={10}>
                                    <ButtonCustom
                                        onClick={()=>{setLista_fotos_data_selecionada(dados);setData_monitoramento_selecionada(data_moni_format);setOpen_modal(true)}}
                                        icon={<FontAwesomeIcon icon={faEye} />}
                                    >
                                        Ver
                                    </ButtonCustom>
                                </RowItem>
                            </RowTable>
                        )
                    })}
                </BodyTable>
            </Render>
            
            

            <ModalPopOver 
                width={larguraResponsiva(currentWidth, 1120, 'modal_fotos')}
                top={45}
                right={10}
                open={!parent_open ? false : open_modal}
                onClose={()=>{setOpen_modal(false)}}
                title={`${nome_propriedade} - ${data_monitoramento_selecionada}`}
                height={'calc(100vh - 50px)'}
            >
                <ListagemFotos
                    lista_fotos_data_selecionada={lista_fotos_data_selecionada}
                    list_talhoes={data_talhoes?.itens ?? []}
                    nome_propriedade={nome_propriedade}
                    data_monitoramento={data_monitoramento_selecionada}
                />
            </ModalPopOver>

        </ContainerDadosResumo>
    )
}