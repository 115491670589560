import L from 'leaflet';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import { Button, Descriptions, Drawer, Space } from 'antd';
import { ImageOverlay, Marker as LMarker, Polygon as LPolygon, MapContainer, TileLayer } from 'react-leaflet';

//STYLES
import { Capa, Container, Content, ContentMap, Escala, PdfPrint, RodapeTtivos, SubCapa } from './styled';

//ASSETS
import pin from '../../assets/svg/marker.svg';
import logoTtivos from '../../assets/img/logo-2.png';

//APP
import { useAccess } from '../../context/useAccess';
import { decodeHtml } from '../../services/Afins';
import { getResumoMonitoramento } from '../../services/monitoramento/resumo';
import { MoniNivelItemControleItens } from '../../services/monitoramento/nivelControleItens';

//COMPONENTS
import WaterMarker from '../WaterMarker';
import CanvasInterpolation, { TypesGerarGroupOverlay, TypesOverlayView } from '../CanvasInterpolation';
import { Render } from '../CustomUi/render';
import { localConfig } from '../../Configs/localConfig';
import { obterColmos } from './meta';
import { PaginaFotos } from './components/paginaFotos';
import { Switcher } from '../CustomUi/Switcher';
import { gerarKey } from '../../utils';

interface TypesBookMonitoramento {
  data?: TypesBookData;
  open: boolean;
  codPro?: number;
  mapType: google.maps.MapTypeId;
  resumir: boolean;
  totalizar?: boolean;
  ordenarTalhao?: boolean;
  nivelControleItens: MoniNivelItemControleItens[];
  onClose?: (data: boolean) => void;
  onLoading?: (data: boolean) => void;
  finishBook?: (data: boolean) => void;
  setResumir?: (data: boolean) => void;
  setOpenBook: (data: boolean) => void;
  setOrdenarTalhao?: (data: boolean) => void;
  lista_filtrar_talhoes?: any[]
  ocultar_fotos_prop?: boolean;
  setOcultar_fotos_prop?:React.Dispatch<React.SetStateAction<boolean>>;
  data_selecionada_fotos_moni?: string;
}

export interface TypesBookData {
  nameCliente?: string
  namePropriedade?: string
  nameConsultor?: string
  codTalhao?: number[]
  date?: string
  monitoramento?: TypeBookMonitoramento[]
}

export interface TypeBookMonitoramento {
  especieVariacao: string
  data: {
    key: string
    codTalhao: number
    talhao?: string
    estadios?: string[]
    area: string | number
    min?: string
    med?: string
    max?: string
    talhao_observacao?: string
    polygon?: { lat: number; lng: number }[]
    idw?: [number, number, string, number, string, string][]
    overLayView?: TypesOverlayView[]
  }[]
}

const BookMonitoramento: React.FC<TypesBookMonitoramento> = (props) => {

  //PROPS
  const { data, open, nivelControleItens, onClose, onLoading, finishBook, resumir, 
    ordenarTalhao, totalizar, codPro, setResumir, setOpenBook, lista_filtrar_talhoes, 
    ocultar_fotos_prop, data_selecionada_fotos_moni } = props;

  //CONSTs
  const { consultoria } = useAccess()
  const { monitoramento = [] } = data;

  //REFs
  const refPdfPrint = useRef<HTMLDivElement>()

  //USESTATE
  const [dataBooking, setDataBooking] = useState<TypesBookData>({} as TypesBookData);
  const [geraGroundOverlay, setGeraGroundOverlay] = useState<TypesGerarGroupOverlay[]>([]);
  const [markerOpacitySubCapa, setMarkerOpacitySubCapa] = useState(false);
  const [returnOverlayView, setReturnOverlayView] = useState<TypesOverlayView[]>([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [totalizarBook, setTotalizarBook] = useState(false);
  const [logoBook, setLogoBook] = useState('');
  const [infoLocal, setInfoLocal] = useState([]);
  const [licenciado, setLicenciado] = useState('');
  const [dataResumo, setDataResumo] = useState([]);
  const [ocultar_fotos, setOcultar_fotos] = useState<boolean>(false);


  useEffect(() => {
    setOcultar_fotos(ocultar_fotos_prop)
  }, [ocultar_fotos_prop])
  

  //LOCAL STORAGE
  const tipoAcesso = localStorage.getItem('tipo-acesso')
  const nomeBookApp = JSON.parse(localStorage.getItem('bookApp'))
  const nomeClienteLocal = localStorage.getItem('nome-cliente')

  const filtrarDadosLocal = () => {
    if (nomeBookApp == 1) {
      setLogoBook(localStorage.getItem('logo'))
      setLicenciado(localStorage.getItem('nome-cliente'))
    } else {
      const cod =
        tipoAcesso === 'conta'
          ? JSON.parse(localStorage.getItem('conta'))
          : JSON.parse(localStorage.getItem('cod-cliente'))

      const licenciadoPara = tipoAcesso === 'cliente' ? nomeClienteLocal : consultoria.linha1
      setLicenciado(licenciadoPara)
      const dadosLocal = JSON.parse(localStorage.getItem('Itens'))[0]
      const acessoCliente = dadosLocal['acessoConta']
      const clientes = tipoAcesso === 'conta' ? acessoCliente['contas'] : acessoCliente['clientes']
      setInfoLocal(clientes)
      const dadosLogoLocal = infoLocal.find((codId) => codId.cod === cod)
      if (dadosLogoLocal) {
        const logos = dadosLogoLocal['logo']
        setLogoBook(logos)
      }
    }
  }

  const listResumo = async () => {
    const resp = await getResumoMonitoramento({
      dataInicioMonitoramento: data.date,
      codPropriedade: codPro,
      tipo: 3,
      embed: 'estadios_nc',
    })

    setDataResumo(resp.data.itens)
  }

  useEffect(() => {
    if (data) {
      onLoading?.(true)
      setTotalizarBook(totalizar)
      let temp: TypesGerarGroupOverlay[] = []
      monitoramento.forEach((rec) => {
        const values = rec.especieVariacao
          .split(', ')
          .map((rec) => (rec === 'null' ? null : parseInt(rec)))

        const nivel = nivelControleItens.find((rec) =>
          isNaN(values[2]) || values[2] == null || values[2] == 1
            ? rec.cod_especie === values[1]
            : rec.cod_variacao_especie === values[2]
        )

        if (rec.data.length > 0) {
          rec.data.forEach((item) => {
            temp = [
              ...temp,
              {
                key: item.key,
                nivelVari: rec.especieVariacao,
                polyCoord: item.polygon.map((p) => [p.lat, p.lng]),
                praga: item.idw.map((idw) => [idw[0], idw[1], idw[2]]),
                inverterEscala: nivel?.inverter_escala === 1,
                talhao_observacao: item?.talhao_observacao,
              },
            ]
          })
        }
      })

      setGeraGroundOverlay(temp)
    }
    if (localStorage.getItem('Itens') !== null) {
      filtrarDadosLocal()
    }
  }, [data])

  useEffect(() => {
    setDataBooking(data)
    onLoading?.(false)
    setOpenBook(true)
  }, [returnOverlayView])

  useEffect(() => {
    if (geraGroundOverlay.length > 0) {
      onLoading?.(false)
      finishBook?.(false)
    }
  }, [geraGroundOverlay])

  useEffect(() => {
    if (nomeBookApp === 1) {
      setMarkerOpacitySubCapa(true)
    }else{
      setMarkerOpacitySubCapa(false)
    }
  }, [open])

  return (
    <Container>
      <Drawer
        forceRender={true}
        open={open}
        onClose={() => {
          onClose?.(false)
          setOpenDrawer(false)
          setResumir?.(false)
          setOpenBook(false)
          setTotalizarBook(false)
        }}
        width='311mm'
        footer={
          <Space>
            <ReactToPrint
              documentTitle={`${dayjs(data.date, 'DD/MM/YYYY').format('YYYYMMDD')}_${
                data.namePropriedade
              } - ttivos`}
              trigger={() => (
                <Button type='primary' title='Imprimir' icon={<PrinterOutlined />}>
                  Imprimir
                </Button>
              )}
              content={() => refPdfPrint.current}
            />
            {/* <Switch
              onChange={(e) => setMarkerOpacitySubCapa(e)}
              checkedChildren='Sim'
              unCheckedChildren='Não'
            />
            <span>Ocultar marcadores na subcapa</span> */}
            
            <Switcher 
              isChecked={markerOpacitySubCapa}
              trigger={setMarkerOpacitySubCapa}
              label='Ocultar marcadores na subcapa'
            />

            {/* <Switch
              onChange={(e) => setOcultar_fotos(e)}
              checkedChildren='Sim'
              unCheckedChildren='Não'
            />
            <span>Ocultar fotos do monitoramento</span> */}
            <Switcher 
              isChecked={ocultar_fotos}
              trigger={setOcultar_fotos}
              label='Ocultar fotos do monitoramento'
            />
          </Space>
        }
      >
        <PdfPrint id='printMonitoramento' ref={refPdfPrint}>
          <title>
            {data.date}_{data.namePropriedade} - ttivos
          </title>
          <div id='docPrintPdf' className='break'>
            
              <title>
                {data.date}_{data.namePropriedade} - ttivos
              </title>
              {nomeBookApp === 1 ? (
                <Capa style={{ height: '190mm' }}>
                  <span>Monitoramento</span>
                  <h2 style={{ marginBottom: 0 }}>{dataBooking?.namePropriedade?.toLowerCase()}</h2>
                  <h3 style={{ color: '#a0a0a0', fontWeight: 700 }}>
                    {tipoAcesso === 'conta'
                      ? dataBooking?.nameCliente?.toLowerCase()
                      : nomeClienteLocal}
                  </h3>
                  <h3>
                    <b>{dataBooking?.date}</b>
                  </h3>

                  {tipoAcesso === 'conta' && dataBooking?.nameConsultor !== '' ? (
                    <>
                      <h3>Consultor</h3>
                      <h3>{dataBooking?.nameConsultor?.toLowerCase()}</h3>
                    </>
                  ) : null}

                  {logoBook === '' ? null : (
                    <div style={{ width: '30%', marginTop: '20px' }}>
                      <img style={{ width: '90%' }} src={logoBook} alt='Agromarques' />
                    </div>
                  )}

                  <RodapeTtivos>
                    <div style={{ marginBottom: '10px' }}>
                      <img src={logoTtivos} alt='' />
                    </div>

                    <small>Licenciado para: {licenciado}</small>
                  </RodapeTtivos>
                </Capa>
              ) : (
                <Capa>
                  <span>Monitoramento</span>
                  <h2 style={{ marginBottom: 0 }}>{dataBooking?.namePropriedade?.toLowerCase()}</h2>
                  <h3 style={{ color: '#a0a0a0', fontWeight: 700 }}>
                    {tipoAcesso === 'conta'
                      ? dataBooking?.nameCliente?.toLowerCase()
                      : nomeClienteLocal}
                  </h3>
                  <h3>
                    <b>{dataBooking?.date}</b>
                  </h3>

                  {tipoAcesso === 'conta' && dataBooking?.nameConsultor !== '' ? (
                    <>
                      <h3>Consultor</h3>
                      <h3>{dataBooking?.nameConsultor?.toLowerCase()}</h3>
                    </>
                  ) : null}

                  {logoBook === '' ? null : (
                    <div style={{ width: '30%', marginTop: '20px' }}>
                      <img style={{ width: '90%' }} src={logoBook} alt='Agromarques' />
                    </div>
                  )}

                  <RodapeTtivos>
                    <div style={{ marginBottom: '10px' }}>
                      <img src={logoTtivos} alt='' />
                    </div>

                    <small>Licenciado para: {licenciado}</small>
                  </RodapeTtivos>
                </Capa>
              )}
            

              {dataBooking?.monitoramento?.map((item, i) => {

                const values = item.especieVariacao.split(', ').map((rec) => (rec === 'null' ? null : parseInt(rec)))

                const nivel = nivelControleItens.find((rec) =>
                  values[2] == null || isNaN(values[2]) || values[2] == 1
                    ? rec.cod_especie === values[1]
                    : rec.cod_especie === values[1] && rec.cod_variacao_especie === values[2]
                )

                const nomesTotalizados = item.especieVariacao.split(', ');

                let is_safra_milho = (localConfig().nomeSafra).toLowerCase()?.includes('milho');
             
                let estadios = (item.data ?? []).map(it_esta=> it_esta.estadios)
                let mesclar_estadios = [].concat(...estadios.map(sub => sub[0]));
                let unir_estadio = (mesclar_estadios??[]).reduce(function(acc, curr) {
                    if (acc.indexOf(curr) === -1) {
                        acc.push(curr);
                    }
                    return acc;
                }, []);

                //@ts-ignore
                let colmos = (item.data ?? []).map(it_col=> it_col?.colmos);

                let achatar = function(arr) {
                    return arr.reduce(function(acc, val) {
                        return acc.concat(val);
                    }, []);
                };
                
                let valoresUnicos = function(arr) {
                    var set = {};
                    return arr.filter(function(val) {
                        if (!set[val]) {
                            set[val] = true;
                            return true;
                        }
                        return false;
                    });
                };
                
                var mesclar_colmos = valoresUnicos(achatar(colmos)).map(function(valor) {
                    return valor;
                });

                let tempTalhaoes = []
                dataBooking.codTalhao?.forEach(
                  (d) =>
                    (tempTalhaoes = [
                      ...tempTalhaoes,
                      ...item.data.filter((rec) => d === rec.codTalhao),
                    ])
                )
                

                const dadosGroundOverlay: TypeBookMonitoramento['data'] =
                dataBooking?.codTalhao === undefined || dataBooking?.codTalhao?.length === 0 ? item.data : tempTalhaoes

                //gerar subcapa com mapa todo
                const overlayAll = returnOverlayView?.filter(
                  (f) => f.praga === item.especieVariacao
                )
                const allPolygon = overlayAll.map((m) => m.polygon)
                const polygon = new L.Polygon(allPolygon, { weight: 0, fillOpacity: 1 })

                const fitBounds = polygon.getBounds();


                function isOcultarMapaInvertido() {
                  if (nivel?.inverter_escala === 0) {
                    return false;
                  }

                  let verificar_pontos = overlayAll.map((item_overall)=> {
                    let lista_markers = dadosGroundOverlay.find((rec) => rec.key === item_overall.key);
                    let list_idw = lista_markers.idw
                    return{
                      list_idw: list_idw.map(item_lidw=> item_lidw?.[4])
                    }
                  })
                  
  
                  let juntar_pontos = verificar_pontos.reduce((acc, item) => acc.concat(item.list_idw), []);
                  let somar_todos_pontos = juntar_pontos.reduce((acc:number, item_zerados: any)=> acc + item_zerados, 0) === 0;

                  return somar_todos_pontos;

                }

                return (
                  <>
                    {ordenarTalhao == true ? null : (
                      <Render condition={isOcultarMapaInvertido()===false}>
                        <SubCapa className='break'>
                          <div className='sub-capa-title'>
                            <span>Mapa de pragas</span>
                            
                            {totalizarBook === true && values[3] === 1 ? (
                              <>
                                <h2>{nomesTotalizados[0]?.toLocaleLowerCase()}</h2>{' '}
                                <h3>
                                  {nomesTotalizados[2] === '1'
                                    ? null
                                    : nomesTotalizados[2].toLowerCase()}
                                </h3>
                                <h4 style={{color: '#498564', maxWidth: '100%'}}>({String(unir_estadio)})</h4>
                                {is_safra_milho &&
                                <> 
                                  {mesclar_colmos.length > 1 
                                  ?  <h5 style={{color: '#498564'}}>Colmo: mínimo {obterColmos(mesclar_colmos).min} | média {obterColmos(mesclar_colmos).med} | máximo {obterColmos(mesclar_colmos).max}</h5>
                                  :  <h5 style={{color: '#498564'}}>Colmo: {String(mesclar_colmos)}</h5>
                                  }
                                </>
                                }
                                
                              </>
                            ) : (
                              <>
                                <h2>{decodeHtml(nivel?.especie_nome?.toLocaleLowerCase() ?? '')}</h2>{' '}
                                <h3>{decodeHtml(nivel?.variacao_especie_nome?.toLowerCase() ?? '')}</h3>
                                <h4 style={{color: '#498564', maxWidth: '100%'}}>({String(unir_estadio)})</h4>
                                {is_safra_milho &&
                                <> 
                                  {mesclar_colmos.length > 1 
                                  ?  <h5 style={{color: '#498564'}}>Colmo: mínimo {obterColmos(mesclar_colmos).min} | média {obterColmos(mesclar_colmos).med} | máximo {obterColmos(mesclar_colmos).max}</h5>
                                  :  <h5 style={{color: '#498564'}}>Colmo: {String(mesclar_colmos)} mm</h5>
                                  }
                                </>
                                }
                              </>
                            )}
                          </div>

                          <div style={{ width: '831px', height: '555px' }}>
                            <MapContainer
                              scrollWheelZoom={false}
                              dragging={false}
                              style={{ height: '555px' }}
                              id={`map${i}`}
                              bounds={fitBounds}
                              zoomControl={false}
                            >
                              <TileLayer
                                url='http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                                maxZoom={20}
                                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                              />

                              {overlayAll.map((ov) => {
                                const markers = dadosGroundOverlay.find((rec) => rec.key === ov.key)

                                if (ov.url !== null) {
                                  return (
                                    <>
                                      <ImageOverlay url={ov.url} bounds={ov.boundsL} />
                                      {markers?.idw?.map((rec, index_marker) => {
                                        let is_metrica_porcentagem = String(rec?.[5]) === 'Porcentagem';
                                        return (
                                          // @ts-ignore
                                          <LMarker
                                            key={gerarKey(index_marker)}
                                            opacity={markerOpacitySubCapa ? 0 : 1}
                                            ref={(data) =>
                                              data?.bindTooltip((Number(rec[4]).toFixed(1) + `${is_metrica_porcentagem?'%':''}`), {
                                                permanent: true,
                                                sticky: false,
                                                opacity: markerOpacitySubCapa ? 0 : 1,
                                                direction: 'top',
                                                className: `leaflet-tooltip ${is_metrica_porcentagem?'porcentagem':''}`,
                                              })
                                            }
                                            icon={
                                              new L.Icon({
                                                iconUrl: pin,
                                                iconSize: [40, 50],
                                                iconAnchor: [20, 50],
                                                tooltipAnchor: [0, -16],
                                              })
                                            }
                                            position={{ lat: rec[0], lng: rec[1] }}
                                          />
                                        )
                                      })}
                                    </>
                                  )
                                } else {
                                  return (
                                    <>
                                      <LPolygon
                                        positions={ov.polygon}
                                        pathOptions={{
                                          opacity: 0,
                                          fillOpacity: 1,
                                          fillColor: ov.cor,
                                        }}
                                      />
                                      {markers?.idw?.map((rec, index_marker) => {
                                        let is_metrica_porcentagem = String(rec?.[5]) === 'Porcentagem';
                                        return (
                                          // @ts-ignore
                                          <LMarker
                                            key={gerarKey(index_marker)}
                                            ref={(data) =>
                                              data?.bindTooltip((Number(rec[4]).toFixed(1) + `${is_metrica_porcentagem?'%':''}`), {
                                                permanent: true,
                                                sticky: false,
                                                opacity: markerOpacitySubCapa ? 0 : 1,
                                                direction: 'top',
                                                className: `leaflet-tooltip ${is_metrica_porcentagem?'porcentagem':''}`,
                                              })
                                            }
                                            opacity={markerOpacitySubCapa ? 0 : 1}
                                            icon={
                                              new L.Icon({
                                                iconUrl: pin,
                                                iconSize: [40, 50],
                                                iconAnchor: [20, 50],
                                                tooltipAnchor: [0, -16],
                                              })
                                            }
                                            position={{ lat: rec[0], lng: rec[1] }}
                                          />
                                        )
                                      })}
                                    </>
                                  )
                                }
                              })}

                              <LPolygon
                                fillOpacity={0}
                                pathOptions={{ color: '#ffffff' }}
                                positions={allPolygon}
                              />
                            </MapContainer>
                          </div>
                        </SubCapa>
                      </Render>
                      
                    )}
                    {resumir == true
                      ? null
                      : dadosGroundOverlay?.map((rec, index) => {
                        
                          // const fitBounds = getBoundsNoArray(rec.polygon)
                          const menorValorEstadios = rec.estadios.reduce(
                            (menor, atual) => (atual < menor ? atual : menor),
                            rec.estadios[0]
                          )
                          const maiorValorEstadios = rec.estadios.reduce(
                            (maior, atual) => (atual > maior ? atual : maior),
                            rec.estadios[0]
                          )

                          const estadiosFormatados = `${menorValorEstadios} a ${maiorValorEstadios}`

                          const overlay = returnOverlayView.filter((re) => re.key === rec.key)

                          const polygonFit = new L.Polygon(rec.polygon, {})
                          const fitBounds = polygonFit.getBounds()

                    
                          function isOcultarMapaInvertido(): boolean {
                            if (nivel?.inverter_escala === 0) {
                              return false;
                            }

                            let valores_dos_pontos = (rec?.idw ?? []).map(item_idw=> Number(item_idw?.[2]));
                            /**Se o `valorApurado` for `0 (zero)` em todos os itens, o mapa deve ser ocultado
                             * 
                             * A regra é que se for invertido e o valorApurado sempre for zero, assumi-se que o ponto foi adicionado erroneamente
                             * causando a exibição do mapa com cor errada, por isso deve ser ocultado
                             */
                            let isZerado = (valores_dos_pontos.reduce((acumulador:number,item_acumalar:number) => acumulador + item_acumalar, 0)) === 0;

                            return isZerado;
                          }

                          //@ts-ignore
                          let colmos = (item.data ?? []).map(it_col=> it_col?.colmos);

                          var achatar = function(arr) {
                              return arr.reduce(function(acc, val) {
                                  return acc.concat(val);
                              }, []);
                          };
                          
                          var valoresUnicos = function(arr) {
                              var set = {};
                              return arr.filter(function(val) {
                                  if (!set[val]) {
                                      set[val] = true;
                                      return true;
                                  }
                                  return false;
                              });
                          };
                          
                          var mesclar_colmos = valoresUnicos(achatar(colmos)).map(function(valor) {
                              return valor;
                          });

                          let is_safra_milho = (localConfig().nomeSafra).toLowerCase()?.includes('milho');

                          //alterar aqui
                          if (isOcultarMapaInvertido()===false) {
                            return (
                                <Content zIndex={index + 1} className='break' key={gerarKey(index)}>
                                  <div
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      overflow: 'hidden',
                                      position: 'relative',
                                    }}
                                  >
                                    <MapContainer
                                      scrollWheelZoom={false}
                                      dragging={false}
                                      zoomControl={false}
                                      bounds={fitBounds}
                                      boundsOptions={{ paddingTopLeft: [0, 250] }}
                                      style={{
                                        width: '297mm',
                                        height: '210mm',
                                      }}
                                    >
                                      <TileLayer
                                        url='http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                                        maxZoom={20}
                                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                        zIndex={30}
                                      />
                                      <LPolygon
                                        pathOptions={{ color: '#ffffff', fillOpacity: 0 }}
                                        positions={rec.polygon}
                                      />
                                      {overlay?.map((ov, index_ov_2) => {
                                        if (ov.url !== null) {
                                          return <ImageOverlay url={ov.url} bounds={ov.boundsL} />
                                        } else {
                                          return (
                                            <LPolygon
                                              key={gerarKey(index_ov_2)}
                                              positions={ov.polygon}
                                              pathOptions={{
                                                fillOpacity: 1,
                                                fillColor: ov.cor,
                                                color: '#ffffff',
                                              }}
                                            />
                                          )
                                        }
                                      })}
                                      {rec.idw.map((rec, index_rec_idw) => {
                                        let is_metrica_porcentagem = String(rec?.[5]) === 'Porcentagem';

                                        return (
                                          // @ts-ignore
                                          <LMarker
                                            key={gerarKey(index_rec_idw)}
                                            opacity={markerOpacitySubCapa ? 0 : 1}
                                            ref={(data) =>
                                              data?.bindTooltip((Number(rec[4]).toFixed(1) + `${is_metrica_porcentagem?'%':''}`), {
                                                permanent: true,
                                                sticky: false,
                                                direction: 'top',
                                                opacity: markerOpacitySubCapa ? 0 : 1,
                                                className: `leaflet-tooltip ${is_metrica_porcentagem?'porcentagem':''}`,
                                              })
                                            }
                                            icon={
                                              new L.Icon({
                                                iconUrl: pin,
                                                iconSize: [40, 50],
                                                iconAnchor: [20, 50],
                                                tooltipAnchor: [0, -10],
                                              })
                                            }
                                            position={{ lat: rec[0], lng: rec[1] }}
                                          />
                                        )
                                      })}
                                      <ContentMap>
                                        <Descriptions
                                          layout='vertical'
                                          bordered
                                          style={{ width: '100%', padding: '0px' }}
                                          size='small'
                                          column={8}
                                          labelStyle={{
                                            fontWeight: 'bold',
                                            display: 'flex',
                                            justifyContent: 'center',
                                          }}
                                          contentStyle={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <Descriptions.Item
                                            label='Talhão'
                                            contentStyle={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                            }}
                                          >
                                            {decodeHtml(rec?.talhao ??'')}
                                            <small style={{ opacity: 0.8 }}>Área: {rec.area}ha</small>
                                          </Descriptions.Item>
                                          <Descriptions.Item
                                            label='Espécie variação'
                                            contentStyle={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                            }}
                                          >
                                            {decodeHtml(nivel?.especie_nome ?? '')}
                                            {totalizarBook === true ? (
                                              <small style={{ opacity: 0.8 }}>
                                                {nomesTotalizados[2] === '1'
                                                  ? null
                                                  : nomesTotalizados[2]}
                                              </small>
                                            ) : (
                                              <small style={{ opacity: 0.8 }}>
                                                {decodeHtml(nivel?.variacao_especie_nome ?? '')}
                                              </small>
                                            )}
                                          </Descriptions.Item>
                                          <Descriptions.Item label='Min'>{rec.min}</Descriptions.Item>
                                          <Descriptions.Item label='Méd'>{rec.med}</Descriptions.Item>
                                          <Descriptions.Item label='Máx'>{rec.max}</Descriptions.Item>
                                          <Descriptions.Item label='Pontos'>
                                            {rec.idw.length}
                                          </Descriptions.Item>
                                          <Descriptions.Item label='Estadios'>
                                            <div style={{display:'flex',flexDirection:'column',color:'var(--text-color)'}}>
                                              {estadiosFormatados}
                                              {is_safra_milho &&
                                                <> 
                                                  {mesclar_colmos.length > 1 
                                                  ?  <small><b>Colmo: ( mín: ${obterColmos(mesclar_colmos).min} | méd: ${obterColmos(mesclar_colmos).med} | máx: ${obterColmos(mesclar_colmos).max} )</b></small>
                                                  :  <small><b>Colmo: {String(mesclar_colmos)} mm</b></small>
                                                  }
                                                </>
                                              }
                                            </div>  
                                          </Descriptions.Item>
                                          <Descriptions.Item label='Inverter escala'>
                                            {nivel?.inverter_escala === 1 ? 'Sim' : 'Não'}
                                          </Descriptions.Item>
                                          <Descriptions.Item
                                            className='label-vazia'
                                            labelStyle={{ padding: 0 }}
                                          >
                                            <Escala>
                                              {nivel?.inverter_escala === 1 ? (
                                                <>
                                                  <div>
                                                    <div style={{ backgroundColor: '#FCFF02' }} />
                                                    <div style={{ backgroundColor: '#BAFF04' }} />
                                                    <div style={{ backgroundColor: '#01FF00' }} />
                                                  </div>
                                                  <div style={{ height: '20px' }}>
                                                    <div>Pior caso</div>
                                                    <div>Melhor caso</div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div>
                                                    <div style={{ backgroundColor: '#01FF00' }} />
                                                    {/* <div style={{ backgroundColor: '#BAFF04' }} /> */}
                                                    <div style={{ backgroundColor: '#FCFF02' }} />
                                                    <div style={{ backgroundColor: '#FCB700' }} />
                                                    <div style={{ backgroundColor: '#FB0303' }} />
                                                  </div>
                                                  <div className='legend-scale'>
                                                    <div>Abaixo do NC</div>
                                                    <div>Nível de controle</div>
                                                    <div>Acima do NC</div>
                                                  </div>
                                                  {rec.talhao_observacao && (
                                                    <div>
                                                      <strong>Observação:</strong> <br />
                                                      {rec.talhao_observacao}
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                            </Escala>
                                          </Descriptions.Item>
                                        </Descriptions>
                                      </ContentMap>
                                      <WaterMarker placement='bottomRight' />
                                    </MapContainer>
                                  </div>
                                </Content>
                            )
                          }
                          
                        })}
                  </>
                )
              })}

                
              <PaginaFotos
                licenca={licenciado}
                coordsKml={dataBooking?.monitoramento?.[0]?.data?.[0]?.polygon}
                dataMonitoramento={String(dataBooking?.date)?.split('/')?.reverse()?.join('-')}
                codPropriedade={codPro}
                lista_filtrar_talhoes={lista_filtrar_talhoes}
                ocultar_fotos={ocultar_fotos}
                nome_propriedade={data?.namePropriedade ?? dataBooking?.namePropriedade}
                print={false}
                data_selecionada_fotos_moni={data_selecionada_fotos_moni}
              />
              <PaginaFotos
                licenca={licenciado}
                coordsKml={dataBooking?.monitoramento?.[0]?.data?.[0]?.polygon}
                dataMonitoramento={String(dataBooking?.date)?.split('/')?.reverse()?.join('-')}
                codPropriedade={codPro}
                lista_filtrar_talhoes={lista_filtrar_talhoes}
                ocultar_fotos={ocultar_fotos}
                nome_propriedade={data?.namePropriedade ?? dataBooking?.namePropriedade}
                print={true}
                data_selecionada_fotos_moni={data_selecionada_fotos_moni}
              />
            
           
          </div>
        </PdfPrint>
      </Drawer>
      <CanvasInterpolation
        dataGroundOverlay={geraGroundOverlay}
        onOverlayView={setReturnOverlayView}
      />
    </Container>
  )
}
export default BookMonitoramento