import dayjs from "dayjs";
import { useEffect } from "react";

//STYLES
import { Page } from "./style"

//ASSETS
import logoTtivos from '../../../../assets/img/logo-2.png';

//APP
import { gerarKey } from "../../../../utils";
import { useAccess } from "../../../../context/useAccess";
import { obterTalhoes } from "./meta";
import { useGetImagensBookMonitoramento } from "../../../../services/monitoramento/imagemMonitoramento/get";

//COMPONENTS
import MapTalhao from "./components/mapa";

//PROPS
interface Props {
    licenca: string;
    coordsKml: { lat: number, lng: number }[]
    dataMonitoramento: string;
    codPropriedade: number;
    lista_filtrar_talhoes: any[];
    ocultar_fotos: boolean;
    nome_propriedade: string;
    print?: boolean;
    data_selecionada_fotos_moni?: string;
}

export const PaginaFotos: React.FC<Props> = ({ licenca, nome_propriedade, dataMonitoramento, codPropriedade, lista_filtrar_talhoes, ocultar_fotos, print, data_selecionada_fotos_moni }) => {

    //CONST
    let { nameCliente } = useAccess();

    //API
    const { data_imagens_book, refetch_imagens_book } = useGetImagensBookMonitoramento(data_selecionada_fotos_moni, codPropriedade);

    useEffect(() => {
        if (String(data_selecionada_fotos_moni)!== '' && String(data_selecionada_fotos_moni).length === 10 && Number(codPropriedade) > 0) {
            refetch_imagens_book();
        }
    }, [dataMonitoramento, codPropriedade])
    
    if (obterTalhoes(data_imagens_book??[], lista_filtrar_talhoes??[])?.length === 0 || ocultar_fotos) {
        return null;
    }
    
    return (
        <Page className="break" data-print={print}>
            <h2 className="Page__title">
                Fotos do monitoramento
            </h2>

            {obterTalhoes(data_imagens_book??[], lista_filtrar_talhoes??[]).map(({ nome_talhao, coordenadas, coordendas_pontos, pontos }, index)=> {

                return (
                    <div className="Page__table" key={gerarKey(index)} style={{pageBreakInside: index > 0 ? 'avoid' : undefined}}>
                        <div className="Page__table__header">
                            <div className="Page__table__header__title">
                                Cliente:
                            </div>
                            <div className="Page__table__header__value">
                                {String(nameCliente).toLowerCase()}
                            </div>
                        </div>
                        <div className="Page__table__header">
                            <div className="Page__table__header__title">
                                Propriedade:
                            </div>
                            <div className="Page__table__header__value">
                                {String(nome_propriedade).toLowerCase()}
                            </div>
                        </div>
                        <div className="Page__table__header">
                            <div className="Page__table__header__title">
                                Data:
                            </div>
                            <div className="Page__table__header__value">
                                {dayjs(dataMonitoramento).format("DD/MM/YYYY")}
                            </div>
                        </div>
                        
                        <div className="Page__table__header">
                            <div className="Page__table__header__title bolder">
                                Talhão:
                            </div>
                            <div className="Page__table__header__value bolder">
                                {nome_talhao}
                            </div>
                        </div>
    
                        <div className="Page__table__content">
                            <div className="Page__table__contorno">

                                <MapTalhao 
                                    coord={coordenadas} 
                                    customHeight={279} 
                                    keyProp={gerarKey(index)}
                                    list_pontos={coordendas_pontos ?? []}
                                />

                            </div>
                            <div className="Page__table__midias">
                                {pontos.map(({ url_arquivo, monitoramento, descricao }, index_pontos)=> {
                                    return (
                                        <div className="Page__table__midias__container" key={gerarKey(index_pontos)}>
                                            <p className="Page__table__midias__container__title">Ponto {monitoramento?.ponto} <span style={{color:'var(--text-color-light)'}}>({nome_talhao})</span></p>
                                            <img alt={`foto monitoramento ${monitoramento?.ponto}`} src={url_arquivo} className="Page__table__midias__container__img" />
                                            <p className="Page__table__midias__container__desc">
                                                {descricao}
                                            </p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            })}

            <div className="Page__radape">
                <img src={logoTtivos} className="Page__radape__logo" alt="ttivos Gestão Rural"/>
                licenciado para: {licenca}
            </div>
        </Page>
    )
}