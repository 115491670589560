import { groupBy, orderBy } from "lodash";
import { ImagemBookMonitoramento__Itens } from "../../../../../services/monitoramento/imagemMonitoramento/get";

export function obterTalhoes(lista_imagem_monitoramento: ImagemBookMonitoramento__Itens[], filtrar_talhoes: number[]) {

    let lista_filtrada = filtrar_talhoes?.length === 0 ? lista_imagem_monitoramento : lista_imagem_monitoramento.filter(item => filtrar_talhoes.includes(item.cod_talhao));
    
    let agrupar_talhoes = groupBy(lista_filtrada, 'cod_talhao');

    let lista_talhoes = (Object.entries(agrupar_talhoes).map(([cod_talhao, dados])=> {

        let coordendas_pontos = dados.map((item)=> {
            return {
                lat: Number(item?.monitoramento?.latitude),
                lng: Number(item?.monitoramento?.longitude),
                ponto: String(item?.monitoramento?.ponto),
            }
        });

        let remover_repetidos = coordendas_pontos.filter((item, index, self)=> index === self.findIndex(sub => sub.ponto === item.ponto) );

        let dados_com_ponto = dados.map((item)=> {
            return {
                ...item,
                ponto: item?.monitoramento?.ponto
            }
        })

        let ordenar_dados_ponto = orderBy(dados_com_ponto, 'ponto', ['asc'])
        
        return {
            cod_talhao: cod_talhao,
            nome_talhao: dados?.[0]?.talhao?.nome,
            coordenadas: (dados?.[0]?.talhao?.kml?.coordenadas??[]).map((item)=> {
                return {
                    lat: item.latitude,
                    lng: item.longitude,
                }
            }),
            coordendas_pontos: remover_repetidos,
            pontos: ordenar_dados_ponto
        }
    }))

    return lista_talhoes ?? []
};

export function obterPontos(pontos: ImagemBookMonitoramento__Itens[]) {

    let agrupar_pontos = groupBy(pontos, 'cod_monitoramento');

    let list_pontos = (Object.entries(agrupar_pontos)).map(([cod_monitoramento, dados])=> {
        return {
            cod_monitoramento: cod_monitoramento,
            ponto: dados?.[0]?.monitoramento?.ponto,
            fotos: dados.map(({ url_arquivo, descricao })=> {
                return {
                    arquivo: url_arquivo,
                    descricao: descricao
                }
            })
        }
    })
    
    return orderBy(list_pontos, 'ponto', ['asc']) ?? []
};