import { useEffect, useState } from "react";
import Carregando from "../../../../../../../../../../Components/CustomUi/loading";

//PROPS
interface Props {
    children: React.ReactNode;
    qtd_registros: number;
}

export const LoadAccordion: React.FC<Props> = ({ children, qtd_registros }) => {

    //STATES
    const [loading, setLoading] = useState<boolean>(true);

    //USEEFFECTS
    useEffect(() => {
        let time = qtd_registros < 1 ? 100 : qtd_registros*25
        setTimeout(() => {
            setLoading(false);
        }, time);
    }, [])
    

    if (!loading) {
        return (
            <samp>{children}</samp>
        );
    }

    return (
        <Carregando
            justifyContent="center"
            height={'calc(100vh - 342px)'}
            legenda="Processando monitoramentos..."
            animation
        />
    )
}