import { processarMonitoramentoSelecionado } from ".";
import { localConfig } from "../../../../../../../Configs/localConfig";

export function typeColor(valor: number, nivel_controle: number, is_invertido: boolean) {
    const porcentagem = (valor * 100) / nivel_controle;

    // if (valor===0 && nivel_controle ===0) {
    //     console.warn('valor e apurado e NC não podem ser zero!')
    //     return '#01FF00';
    // }

    if (is_invertido) {
        if (porcentagem === 0) {
            return '#FCFF02';
        }
        if (porcentagem > 0 && porcentagem < 100) {
            return '#63FF08';
        }
        if (porcentagem >= 100 && porcentagem < 300) {
            return '#24e92b';
        }
        if (porcentagem >= 300) {
            return '#01FF00';
        }
        else {
            return '#01FF00'
        }
    }
    if (porcentagem === 0) {
        return '#01FF00';
    }
    if (porcentagem > 0 && porcentagem < 100) {
        return '#FCFF02';
    }
    if (porcentagem >= 100 && porcentagem < 300) {
        return '#FCB700';
    }
    if (porcentagem >= 300) {
        return '#FB0303';
    }
    else {
        return '#01FF00'
    }
}

export function gerarEstrturaPDF(dados_monitoramento: any[]): any {

    let { nomeSafra } = localConfig();

    let fonst_size_row = 10;
    let is_safra_milho = String(nomeSafra).toLowerCase().includes('milho');

    let estrutura_pdf = processarMonitoramentoSelecionado(dados_monitoramento).map(({ nome_talhao, dados_talhao }) => {

        let lista_dados_praga = dados_talhao.map(({ especie, variacao, colmo, pontos, tipo_metrica_nome, minimo, media, maximo, nivel_controle, inverter_escala }) => {
            
            return [
                { text: `${especie} ${variacao ? `(${variacao})` : ''} ${is_safra_milho ? `[colmo: ${colmo}]` : ''}`, fontSize: fonst_size_row, margin: [0, 5, 5, 5] },
                { text: String(pontos), fontSize: fonst_size_row, margin: [0, 5, 5, 5] },
                { text: tipo_metrica_nome, fontSize: fonst_size_row, margin: [0, 5, 5, 5] },
                {
                    columns: [
                        { text: String(Number(minimo)?.toFixed(2)), fontSize: fonst_size_row, margin: [0, 5, 5, 5] }, // Texto com margem à direita
                        {
                            canvas: [
                                {
                                    type: 'ellipse', // Desenha um círculo
                                    x: -5, y: 5, // Posição relativa ao container
                                    r1: 5, r2: 5, // Raio do círculo (largura e altura)
                                    color: typeColor(Number(minimo), Number(nivel_controle), Boolean(inverter_escala)) // Cor do círculo
                                }
                            ],
                            width: 5, // Largura do canvas para alinhar com o texto
                            margin: [0, 5, 0, 5]
                        }
                    ],
                    alignment: 'left'
                },
                {
                    columns: [
                        { text: String(Number(media)?.toFixed(2)), fontSize: fonst_size_row, margin: [0, 5, 5, 5] },
                        {
                            canvas: [
                                {
                                    type: 'ellipse',
                                    x: -5, y: 5,
                                    r1: 5, r2: 5,
                                    color: typeColor(Number(media), Number(nivel_controle), Boolean(inverter_escala))
                                }
                            ],
                            width: 5,
                            margin: [0, 5, 0, 5]
                        }
                    ],
                    alignment: 'left'
                },
                {
                    columns: [
                        { text: String(Number(maximo)?.toFixed(2)), fontSize: fonst_size_row, margin: [0, 5, 5, 5] },
                        {
                            canvas: [
                                {
                                    type: 'ellipse',
                                    x: -5, y: 5,
                                    r1: 5, r2: 5,
                                    color: typeColor(Number(maximo), Number(nivel_controle), Boolean(inverter_escala))
                                }
                            ],
                            width: 5, // Largura do canvas para alinhar com o texto
                            margin: [0, 5, 0, 5]
                        }
                    ],
                    alignment: 'left'
                }
            ]
        });
        return [
            {
                table: {
                    headerRows: [1, 2],
                    widths: [
                        200,
                        50,
                        70,
                        50,
                        50,
                        50
                    ],
                    body: [
                        [{ text: `Talhão: ${nome_talhao}`, style: 'tableHeader', fontSize: 12, colSpan: 6, alignment: 'center' }, {}, {}, {}, {}, {}],
                        [
                            { text: 'Praga', style: 'tableHeader', colSpan: 1, alignment: 'left' },
                            { text: 'Pontos', style: 'tableHeader', colSpan: 1, alignment: 'left' },
                            { text: 'Métrica', style: 'tableHeader', colSpan: 1, alignment: 'left' },
                            { text: 'Minimo', style: 'tableHeader', colSpan: 1, alignment: 'left' },
                            { text: 'Média', style: 'tableHeader', colSpan: 1, alignment: 'left' },
                            { text: 'Maximo', style: 'tableHeader', colSpan: 1, alignment: 'left' },
                        ],
                        ...lista_dados_praga
                    ]

                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex % 2 === 0) ? '#fafafa' : null;
                    }
                }

            },
            {
                style: 'tableExample',
                table: {
                    headerRows: 1,
                    body: [
                        [''],
                        [''],
                        [''],
                    ]
                },
                layout: 'noBorders'
            },
        ]
    })

    return estrutura_pdf

}


export function totalizarRegistros(): any {
    return [{
        table: {
            headerRow: 1,
            width: [
                "*",
                "*",
                "*",
                "*",
                "*"
            ],
            body: [
                [
                    {
                        text: "Total Safras",
                        style: "tableHeader",
                        fontSize: 9,
                        alignment: "center"
                    },
                    {
                        text: "Total Talhões",
                        style: "tableHeader",
                        fontSize: 9,
                        alignment: "center"
                    },
                    {
                        text: "Total de Área",
                        style: "tableHeader",
                        fontSize: 9,
                        alignment: "center"
                    },
                    {
                        text: "Total Subáreas",
                        style: "tableHeader",
                        fontSize: 9,
                        alignment: "center"
                    },
                    {
                        text: "Total área subáreas",
                        style: "tableHeader",
                        fontSize: 9,
                        alignment: "center"
                    }
                ],
                [
                    {
                        text: 2,
                        fontSize: 7,
                        margin: [
                            0,
                            2,
                            0,
                            2
                        ],
                        alignment: "right"
                    },
                    {
                        text: 22,
                        fontSize: 7,
                        margin: [
                            0,
                            2,
                            0,
                            2
                        ],
                        alignment: "right"
                    },
                    {
                        text: "1.540,74 ha",
                        fontSize: 7,
                        margin: [
                            0,
                            2,
                            0,
                            2
                        ],
                        alignment: "right"
                    },
                    {
                        text: 1,
                        fontSize: 7,
                        margin: [
                            0,
                            2,
                            0,
                            2
                        ],
                        alignment: "right"
                    },
                    {
                        text: "19,48 ha",
                        fontSize: 7,
                        margin: [
                            0,
                            2,
                            0,
                            2
                        ],
                        alignment: "right"
                    }
                ]
            ],
            margin: [
                0,
                50,
                0,
                0
            ]
        },
        layout: {}
    }]
}