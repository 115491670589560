import React, { useEffect, useState } from 'react'
import { ContainerGrupo } from './styles'
import ViewPage from '../../../../Components/ViewPage'
import { TitleH1 } from '../../../../Components/CustomUi/titleH1'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import { TabelaGrupoAnalises } from './components/tabela'
import {
  delGrupoServico,
  getGrupoServico,
  postGrupoServico,
  putGrupoServico,
} from '../../../../services/GrupoServicos'
import { Drawer } from 'antd'
import ButtonCustom from '../../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk, faXmark } from '@fortawesome/free-solid-svg-icons'
import { NavegacaoDrawer } from '../../recomendacao/novoCompatibilidadeAtivo/components/gavetaCadastrar/styled'
import { FormGrupoAnalises } from '../../../../Components/grupoServicoNovo'
import { paletaCores } from './meta'
import { destroyAlert, errorAlert, loadingAlert, successAlert } from '../../../../utils/alert'

const GrupoServicos: React.FC = () => {
  const [permissions, setPermissions] = useState<ItensPerUserLogged>()
  const [pagination, setPagination] = useState<number>(15)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState(null)
  const [open, setOpen] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [update, setUpdate] = useState<number>(null)
  const [dataServicoSer, setDataServicoSer] = useState([])
  const [dataCores, setDataCores] = useState([])
  const [dataCoresOriginal, setDataCoresOriginal] = useState([])

  //GAVETA
  const [loadingForm, setLoadingForm] = useState(false)
  const [nome, setNome] = useState('')
  const [cor, setCor] = useState('')
  const [profundidade, setProfundidade] = useState('')
  const [profundidadeId, setProfundidadeId] = useState(null)
  const [selectedColor, setSelectedColor] = useState<string | null>(null)
  const [analises_selecionadas, setAnalises_selecionadas] = useState<
    { descr: string; id: number; key?: number }[]
  >([])

  //EXCLUSÃO
  const [confirmar_exclusao, setConfirmar_exclusao] = useState<boolean>(false)
  const [id_item_atual, setId_item_atual] = useState<number>(null)
  const [nome_item_atual, setNome_item_atual] = useState<string>('')
  const [fake_loading, setFake_loading] = useState<boolean>(false)

  const listarServico = async () => {
    try {
      setLoadingTable(true)
      const response = await getGrupoServico({ page: page, pagination: pagination })
      setTotal(response?.data?.total)
      const coresOriginal = response.data.itens.map((item) => item.cor)

      setDataCoresOriginal(coresOriginal)
      setDataCores(paletaCores)
      if (response.status === 200) {
        setDataServicoSer(response.data.itens)
        setLoadingTable(false)
      }
    } catch (error) {
      return error
    }
  }

  const handleChangeServico = async (data) => {
    setLoadingForm(true)
    const response = await getGrupoServico({ cod: data === undefined ? '' : data })

    const dados = response.data.itens[0]

    const editGrupoAnalise = dados?.analises?.analises?.map((rec, index) => {
      return { descr: rec?.nome, id: rec?.cod, key: rec?.cod }
    })

    setNome(dados?.nome)
    setSelectedColor(dados?.cor)
    setProfundidade(dados?.profundidade_nome)
    setProfundidadeId(dados?.cod_profundidade)
    setAnalises_selecionadas(editGrupoAnalise)
    setLoadingForm(false)
  }

  function abrirGaveta() {
    setOpen(true)
  }

  function editarGaveta(cod) {
    setUpdate(cod)
    setOpen(true)
  }

  function fecharGaveta() {
    setOpen(false)
    setUpdate(null)
    setSelectedColor(null)
    setNome('')
    setProfundidade('')
    setProfundidadeId(null)
    setAnalises_selecionadas([])
  }

  async function salvarGrupoDeAnalises() {
    const data = {
      nome: nome,
      cor: selectedColor,
      cod_profundidade: profundidadeId,
      cod_analises: analises_selecionadas.map((item) => item?.id),
      status: 1,
    }
    try {
      if (update <= 0) {
        loadingAlert({ msg: 'Salvando...', id: 'cadastro_grupo' })
        const response = await postGrupoServico(data)

        if (response.status === 201) {
          successAlert('Grupo de servico cadastrado com sucesso!', 5000, 'cadastro_grupo')
          fecharGaveta()
          listarServico()
        }
      } else {
        loadingAlert({ msg: 'Salvando...', id: 'editar_grupo' })
        const response = await putGrupoServico(update, data)

        if (response.status === 200) {
          successAlert('Grupo de servico atualizado com sucesso!', 5000, 'editar_grupo')
          listarServico()
          handleChangeServico(update)
        }
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          errorAlert(data?.error ?? '')
          destroyAlert('cadastro_grupo')
          destroyAlert('editar_grupo')
        } else {
          data.error.forEach((data) => {
            errorAlert(data?.msg?.[0] ?? '')
            destroyAlert('cadastro_grupo')
            destroyAlert('editar_grupo')
          })
        }
      } else {
        destroyAlert()
      }
    }
  }

  const handleTrashServico = async (data) => {
    setFake_loading(true)

    try {
      const responseDel = await delGrupoServico(data)

      if (responseDel.status === 200) {
        listarServico()
        successAlert('Grupo de análises excluída com sucesso!', 5000)
        setTimeout(() => {
          setFake_loading(false)
        }, 3000)
      }
    } catch (error) {
      errorAlert(`${error ?? 'Erro ao excluir grupo de análises!'}`, 7000)
    }
  }

  useEffect(() => {
    if (update > 0) {
      handleChangeServico(update)
    }
  }, [update])

  useEffect(() => {
    listarServico()
  }, [])

  useEffect(() => {
    listarServico()
  }, [page])

  return (
    <ContainerGrupo>
      <ViewPage
        rotina={34}
        onPermission={setPermissions}
        title='Grupo de Serviço'
        btnClick={null}
        hiddeTitle
        cardBodyStyle={{ padding: 15 }}
        cardMainStyle={{ height: '87.5vh' }}
      >
        <div style={{ marginBottom: '10px' }}>
          <TitleH1>Grupo de análises </TitleH1>
        </div>
        <TabelaGrupoAnalises
          setOpen={setOpen}
          abrirGaveta={abrirGaveta}
          listar_analises={dataServicoSer}
          loading={loadingTable}
          setPagination={setPagination}
          page={page}
          setPage={setPage}
          total={total}
          permissao={permissions}
          editarCadastro={editarGaveta}
          deletar={handleTrashServico}
          confirmar_exclusao={confirmar_exclusao}
          setConfirmar_exclusao={setConfirmar_exclusao}
          id_item_atual={id_item_atual}
          setId_item_atual={setId_item_atual}
          nome_item_atual={nome_item_atual}
          setNome_item_atual={setNome_item_atual}
          fake_loading={fake_loading}
          setFake_loading={setFake_loading}
        />

        <Drawer
          width={500}
          open={open}
          onClose={() => fecharGaveta()}
          closable={false}
          bodyStyle={{ padding: 0 }}
          headerStyle={{ padding: '10px 10px' }}
          destroyOnClose
          footer={
            <div className='footer'>
              <ButtonCustom
                icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                type='primary'
                onClick={salvarGrupoDeAnalises}
              >
                Salvar
              </ButtonCustom>
            </div>
          }
          title={
            <NavegacaoDrawer>
              <ButtonCustom
                danger
                icon={<FontAwesomeIcon icon={faXmark} />}
                type='tertiary'
                onClick={() => fecharGaveta()}
                style={{ marginLeft: -10 }}
              >
                Fechar
              </ButtonCustom>
            </NavegacaoDrawer>
          }
        >
          <FormGrupoAnalises
            loadingForm={loadingForm}
            update={update}
            nome={nome}
            setNome={setNome}
            profundidade={profundidade}
            setProfundidade={setProfundidade}
            setProfundidadeId={setProfundidadeId}
            analises_selecionadas={analises_selecionadas}
            setAnalises_selecionadas={setAnalises_selecionadas}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            colorsUsadas={dataCores}
            colorsOriginal={dataCoresOriginal}
          />
        </Drawer>
      </ViewPage>
    </ContainerGrupo>
  )
}

export default GrupoServicos
