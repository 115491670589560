import React, { useEffect, useState } from 'react'
import { ContainerPropri } from './styles'
import ViewPage from '../../../Components/ViewPage'
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado'
import { TitleH1 } from '../../../Components/CustomUi/titleH1'
import { TabelaPropriedades } from './components/tabela'
import { formatarValor } from '../../../utils'
import { getSafra, SafraItens } from '../../../services/Safra'
import {
  delPropriedade,
  getPropriedade,
  postPropriedade,
  putPropriedade,
} from '../../../services/Propriedades'
import { getTalhao, TalhaoItens } from '../../../services/Talhao'
import {
  destroyAlert,
  errorAlert,
  infoAlert,
  loadingAlert,
  successAlert,
} from '../../../utils/alert'
import { buscaCidade, centerMap, listUf, tryError } from '../../../services/Afins'
import { Drawer, Modal } from 'antd'
import { PainelTodasPropriedades } from '../talhaoSafra/components/painelTodasPropriedades'
import { GoogleContainer, ContainerMap } from '../propriedades/styled'
import { GoogleMap, InfoBox, Polygon } from '@react-google-maps/api'
import { getTalhaoCultivavel, TalhaoCultivaveisItens } from '../../../services/TalhaoSafra'
import { paleta_cores } from '../talhaoSafra/meta'
import { Painel1Propriedade } from '../talhaoSafra/components/painel'
import { useLogic } from '../../../context/useLogic'
import ButtonCustom from '../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk, faXmark } from '@fortawesome/free-solid-svg-icons'
import { NavegacaoDrawer } from '../recomendacao/novoCompatibilidadeAtivo/components/gavetaCadastrar/styled'
import { FormPropriedadeNovo } from '../../../Components/propriedadeNovo'
import {
  getTipoConsultor,
  ItensTipoConsultorTypes,
} from '../../../services/consultoriaAgricola/tipoConsultor'
import { getCalcario, ItensCalcario } from '../../../services/recomendacao/calcario'
import Base64 from '../../../Components/Base64Geral'

interface TypesMapa {
  zoom: number
  center: {
    lat: number
    lng: number
  }
  style?: { [key: string]: string }
}

interface TypesVisuPropri {
  name?: string
  cod?: number
  cor?: string
  areaPropriedade?: number
  talhoes?: TalhaoItens[]
}

const PropriedadesNovo: React.FC = () => {
  const { codCliente } = useLogic()
  const [permissions, setPermissions] = useState<ItensPerUserLogged>({} as ItensPerUserLogged)
  const [dataPropriedades, setDataPropriedades] = useState([])
  const [dataTabelaFiltrar, setDataTabelaFiltrar] = useState([])
  const [dataPropriedadeStatus, setDataPropriedadeStatus] = useState([])
  const [dataSafra, setDataSafra] = useState<SafraItens[]>([])
  const [dadosEstatisticas, setDadosEstatisticas] = useState([])
  const [dadosResultado, setDadosResultado] = useState([])
  const [loadingVisuPropriedades, setLoadingVisuPropriedades] = useState(false)
  const [loadingForm, setLoadingForm] = useState(false)
  const [loadingSalvar, setLoadingSalvar] = useState(false)
  const [propsMap, setPropsMapa] = useState<TypesMapa>({
    zoom: 2,
    center: {
      lat: 0,
      lng: 0,
    },
    style: { width: '100%', height: '78vh' },
  })
  const [dataVisuPropridades, setDataVisuPropridades] = useState<TypesVisuPropri[]>([])
  const infoWindow = new window.google.maps.InfoWindow()
  const [polygon, setPolygon] = useState<google.maps.Polygon>()
  const [map, setMap] = useState<google.maps.Map>()
  const [visualizarPropriedade, setVisualizarPropriedade] = useState(false)
  const [visualizarPropriedadesCliente, setVisualizarPropriedadesCliente] = useState(false)
  const [talhoesCultivaveis, setTalhoesCultivaveis] = useState<TalhaoCultivaveisItens[]>([])
  const [tempPolygon, setTempPolygon] = useState<any>()
  const [dataPolygon, setDataPolygon] = useState([])
  const [inativos, setInativos] = useState(false)
  const [codPropriedade, setCodPropriedade] = useState(0)
  const [safra, setSafra] = useState(0)
  const [ufList, setUfList] = useState([])
  const [cityList, setCityList] = useState([])
  const dataTipo = [
    {
      cod: 1,
      nome: 'Próprio',
    },
    {
      cod: 2,
      nome: 'Arrendado',
    },
  ]
  const [buscarTabela, setBuscarTabela] = useState('')

  //TABELA
  const [loadingTable, setLoadingTable] = useState(false)
  const [pagination, setPagination] = useState<number>(15)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState(null)
  const [open, setOpen] = useState(false)
  const [update, setUpdate] = useState(null)
  const [confirmar_exclusao, setConfirmar_exclusao] = useState<boolean>(false)
  const [id_item_atual, setId_item_atual] = useState<number>(null)
  const [nome_item_atual, setNome_item_atual] = useState<string>('')
  const [fake_loading, setFake_loading] = useState<boolean>(false)

  //FORMULÁRIO
  const [nome, setNome] = useState<string>('')
  const [matricula, setMatricula] = useState<string>('')
  const [ccir, setCcir] = useState<string>('')
  const [itr, setItr] = useState<string>('')
  const [nirf, setNirf] = useState<string>('')
  const [ie, setIe] = useState<string>('')
  const [tipo, setTipo] = useState<string>('')
  const [tipoId, setTipoId] = useState<number>(null)
  const [area, setArea] = useState<string>('')
  const [uf, setUf] = useState<string>('')
  const [ufId, setUfId] = useState<number>(null)
  const [cidade, setCidade] = useState<string>('')
  const [cidadeId, setCidadeId] = useState<number>(null)
  const [calcario, setCalcario] = useState<string>('')
  const [calcarioId, setCalcarioId] = useState<number>(null)
  const [responsavel, setResponsavel] = useState<string>('')
  const [responsavelId, setResponsavelId] = useState<number>(null)
  const [calcario_original, setCalcario_original] = useState<number | null>(null)
  const [calcario_novo, setCalcario_novo] = useState<number | null>(null)
  const [fileOpen, setFileOpen] = useState('')
  const [dataConsultor, setDataConsultor] = useState<ItensTipoConsultorTypes[]>([])
  const [dataCalcario, setDataCalcario] = useState<ItensCalcario[]>([])
  const [selectedFile, setSelectedFile] = useState({})

  //PAINEL
  const [exibir_area_talhao, setExibir_area_talhao] = useState<boolean>(false)
  const [exibir_nome_talhao, setExibir_nome_talhao] = useState<boolean>(false)
  const [exibir_area_talhao_propriedade, setExibir_area_talhao_propriedade] =
    useState<boolean>(false)
  const [exibir_nome_talhao_propriedade, setExibir_nome_talhao_propriedade] =
    useState<boolean>(false)
  const [distinguir_cor, setDistinguir_cor] = useState<boolean>(false)
  const [talhao_selecionado, setTalhao_selecionado] = useState<number | null>(null)

  const listarPropriedades = async (cod, loading = false) => {
    setLoadingTable(true)
    const response = await getPropriedade(cod,'', codCliente, '', '', buscarTabela,page, pagination )
    const dados = response.data.itens
    setTotal(response.data.total)
    const resultado = dados.reduce(
      (soma, item) => {
        //@ts-ignore

        return {
          //@ts-ignore

          area: soma.area + parseFloat(item.area),
        }
      },
      {
        area: 0,
      }
    )

    const resultadoFinal = [
      {
        totalizado_area: formatarValor(resultado?.area),
      },
    ]
    setDataPropriedades(dados)
    setDataTabelaFiltrar(dados)
    gerarTabela(dados)
    gerarTabela2(resultadoFinal, dados.length)

    setDataPropriedadeStatus(dados.filter((rec) => rec.status !== 0))
    setLoadingTable(false)
  }

  function gerarTabela(dados) {
    const data = dados?.map((item) => {
      return [
        { text: item?.nome, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        {
          text: formatarValor(item?.area),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'right',
        },
        {
          text: `${item?.cidade[0]?.nome}/${item?.cidade[0]?.uf_sigla}`,
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: [
            [
              { text: 'Nome', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Área/ha', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Município/UF', style: 'tableHeader', fontSize: 9, alignment: 'center' },
            ],
            ...data,
          ],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosEstatisticas(table)
  }

  function gerarTabela2(dados, length) {
    const data = dados?.map((item) => {
      return [
        { text: length, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        { text: item?.totalizado_area, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 0,
          widths: ['*', '*'], // Definindo larguras das colunas
          body: [
            [
              {
                text: 'Total propriedades',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
              { text: 'Total de Área', style: 'tableHeader', fontSize: 9, alignment: 'center' },
            ],
            ...data,
          ],
          margin: [0, 50, 0, 0],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosResultado(table)
  }

  const onChangeCity = async (data) => {
    setUfId(data)
    const response = await buscaCidade('', data, '')
    setCityList(response)
  }

  const onChangeUf = async () => {
    const response = await listUf()

    setUfList(response)
  }

  const handleGetPropriedade = async (data) => {
    try {
      setLoadingForm(true)

      const response = await getPropriedade(data)

      if (response.status === 200) {
        const dados = response.data.itens[0]
        setNome(dados?.nome)
        setMatricula(dados?.matricula)
        setCcir(dados?.ccir)
        setItr(dados?.itr)
        setNirf(dados?.nirf)
        setIe(dados?.inscricao_estadual)
        setArea(String(dados?.area))
        const filtrarTipo = dataTipo.filter((item) => item.cod === dados?.cod_tipo)
        setTipo(filtrarTipo[0]?.nome)
        setTipoId(filtrarTipo[0]?.cod)
        const filtrarCalcario = dataCalcario.filter((item) => item.cod === dados?.cod_calcario)
        setCalcario(filtrarCalcario[0]?.nome)
        setCalcarioId(filtrarCalcario[0]?.cod)
        setUf(dados?.cidade[0]?.uf_sigla)
        onChangeCity(dados?.cidade[0]?.cod_uf)
        setUfId(dados?.cidade[0]?.cod_uf)
        setCidade(dados?.cidade[0]?.nome)
        setCidadeId(dados?.cidade[0]?.cod)
        setCalcario_original(dados?.cod_calcario)
        const filtrarConsultor = dataConsultor.filter(
          (item) => item.cod_usuario === dados?.cod_consultor
        )
        setResponsavelId(dados.cod_consultor)
        setResponsavel(filtrarConsultor[0]?.usuario_nome)
        setFileOpen(dados.arquivo_base64)

        const codCity = await buscaCidade('', '', dados.cod_cidade)

        const codUf = codCity[0].cod_uf

        onChangeCity(codUf)

        // const areaFild = form.getFieldValue('area').replace('.', ',')
        setLoadingForm(false)
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          errorAlert(data?.error ?? 'Falha ao obter propriedade!', 7000, 'load_propriedade')
        } else {
          data.error.forEach((data) => {
            errorAlert(data?.msg?.[0] ?? 'Falha ao obter propriedade!', 7000, 'load_propriedade')
          })
        }
      } else {
        destroyAlert()
      }
    }
  }

  const salvarPropriedade = async () => {
    let data = {
      nome: nome,
      matricula: matricula,
      ccir: ccir,
      itr: itr,
      nirf: nirf,
      inscricao_estadual: ie,
      cod_tipo: tipoId,
      area: area,
      uf: ufId,
      cod_cidade: cidadeId,
      cod_calcario: calcarioId,
      cod_consultor: responsavelId,
      arquivo: selectedFile,
      status: 1,
      cod_conta: localStorage.getItem('conta'),
      cod_cliente: localStorage.getItem('cod-cliente'),
    }
    try {
      // @ts-ignore
      if (selectedFile.uid) {
        data.arquivo = await Base64(selectedFile)
      } else {
        data.arquivo = ''
      }
      
      setLoadingSalvar(true)
      loadingAlert({ msg: 'Salvando...', id: 'save_propriedade' })

      const codCliente = localStorage.getItem('cod-cliente')
      if (update <= 0) {
        const response = await postPropriedade(data)
        if (response.status === 201) {
          successAlert('Propriedade cadastrada com sucesso!', 5000, 'save_propriedade')
          fecharGaveta()
          listarPropriedades('')
          setLoadingSalvar(false)
        }
      } else {
        const response = await putPropriedade(codCliente, update, data)
        if (response.status === 200) {
          successAlert('Propriedade atualizada com sucesso!', 5000, 'save_propriedade')
          handleGetPropriedade(update)
          listarPropriedades('')
          setLoadingSalvar(false)
        }
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          errorAlert(data?.error ?? 'Falha na operação!', 7000, 'save_propriedade')
          setLoadingSalvar(false)
        } else {
          data.error.forEach((data) => {
            errorAlert(data?.msg?.[0] ?? 'Falha na operação!', 7000, 'save_propriedade')
          })
          setLoadingSalvar(false)
        }
      } else {
        destroyAlert()
        setLoadingSalvar(false)
      }
    }
  }

  const listConsultor = async () => {
    const resp = await getTipoConsultor({ codCliente: null })

    setDataConsultor(resp?.data?.itens)
  }

  const listCalcario = async () => {
    const resp = await getCalcario({})

    setDataCalcario(resp.data.itens)
  }

  function abrirGaveta() {
    setOpen(true)
  }

  function abrirGavetaEdit(cod) {
    setOpen(true)
    setUpdate(cod)
  }

  function fecharGaveta() {
    setOpen(false)
    setUpdate(null)
    setNome('')
    setMatricula('')
    setCcir('')
    setItr('')
    setNirf('')
    setIe('')
    setArea('')
    setTipo('')
    setTipoId(null)
    setUf('')
    setUfId(null)
    setCidade('')
    setCidadeId(null)
    setCalcario('')
    setCalcarioId(null)
    setResponsavel('')
    setResponsavelId(null)
    setSelectedFile({})
  }

  async function deletarPropriedade(data) {
    setFake_loading(true)
    const responseDel = await delPropriedade(data, codCliente, nome_item_atual)

    if (responseDel.status === 200) {
      successAlert('Propriedade excluida com sucesso!')

      listarPropriedades('')
    }

    setTimeout(() => {
      setFake_loading(false)
    }, 3000)
  }

  const handleClickVisualizarTodasPropriedades = async () => {
    let talhoes = []

    setLoadingVisuPropriedades(true)
    for (let i = 0; i < dataPropriedades.length; i += 1) {
      const propri = dataPropriedades[i]

      const resp = await getTalhao(propri.cod, '')

      if (resp?.data?.itens === undefined || resp?.data?.itens === null) {
        errorAlert('Falha ao carregar mapa da propriedade!')
        setLoadingVisuPropriedades(false)
        return null
      }

      if (resp.data.itens.length > 0) {
        resp.data.itens.forEach((data) => {
          data.cor = 'cyan'
        })
        talhoes = [
          ...talhoes,
          {
            cod: propri.cod,
            areaPropriedade: propri.area,
            name: propri.nome,
            talhoes: resp.data.itens,
          },
        ]
      }
    }

    let centeRs: google.maps.LatLngLiteral[] = []
    if (talhoes.length > 0) {
      talhoes[0].talhoes[0].kml.coordenadas.forEach((data) => {
        centeRs = [...centeRs, { lng: data.longitude, lat: data.latitude }]

        return centeRs
      })

      const center: google.maps.LatLngLiteral = centerMap(centeRs).toJSON()
      const tamanho_propriedades: number =
        talhoes.reduce((acumulador, item) => acumulador + Number(item.areaPropriedade), 0) ?? 0

      function zoomBaseadoNaArea() {
        if (tamanho_propriedades > 900) {
          return 12
        }
        if (tamanho_propriedades >= 700 && tamanho_propriedades < 900) {
          return 14
        }
        if (tamanho_propriedades >= 100 && tamanho_propriedades < 700) {
          return 15
        }
        if (tamanho_propriedades < 100) {
          return 16
        }

        return 11
      }

      setPropsMapa({ center, zoom: zoomBaseadoNaArea() })
    } else {
      infoAlert('Cliente não possui talhões')
      setLoadingVisuPropriedades(false)
      return
    }

    setDataVisuPropridades(talhoes)
    // setListaTalhoes(talhoes?.map(item=>item.talhoes));
    setVisualizarPropriedadesCliente(true)
    setLoadingVisuPropriedades(false)
  }

  const handleClickPolygon = (
    e: google.maps.PolyMouseEvent,
    index,
    cliente = null,
    indexT = null
  ) => {
    if (tempPolygon) {
      tempPolygon.close()
    }
    const position = e.latLng.toJSON()
    let content
    if (cliente === null) {
      const talhao = talhoesCultivaveis.find((data, i) => i === index)

      content = `<h5><b>Nome</b>: ${talhao.nome} <br /> <b>Área</b>: ${talhao.area}/ha <br /> `

      const dados = JSON.parse(JSON.stringify(dataPolygon))

      dados.forEach((data, i) => {
        if (i === index) {
          data.cor = 'orange'
        } else {
          data.cor = safra !== 0 ? 'yellow' : 'cyan'
        }
      })

      setDataPolygon(dados)
    } else {
      const listTalhoesVis = [...dataVisuPropridades]

      listTalhoesVis.forEach((info, i) => {
        info.talhoes.forEach((record, ii) => {
          if (indexT === ii && index === i) {
            record.cor = 'orange'
          } else {
            record.cor = 'cyan'
          }
        })
      })
      content = `<h5>
                    <b>Propriedade</b>: ${cliente.name} <br /> 
                    <b>Área da propriedade</b>: ${cliente.areaPropriedade}/ha <br /> 
                    <br />
                    <b>Nome do talhão</b>: ${cliente.nome_talhao} <br />
                    <b>Área do talhão</b>: ${cliente.area}/ha <br />`
      setDataVisuPropridades([...listTalhoesVis])
    }
    // <b>Lat:</b> ${position.lat.toFixed(6)} <b>Lng:</b> ${position.lng.toFixed(6)}</h5>

    infoWindow.setContent(content)
    infoWindow.setPosition(position)
    infoWindow.open({
      anchor: polygon,
      map,
      shouldFocus: true,
    })
    setTempPolygon(infoWindow)
  }

  function corAtualTalhao(cod_talhao: number, cor_inicial: string, index: number): string {
    if (talhao_selecionado === cod_talhao) {
      return 'orange'
    }
    return distinguir_cor ? paleta_cores?.[index] : cor_inicial
  }

  const listSafra = async () => {
    const resp = await getSafra({ pagination: 15, order: 'data_inicio' })

    setDataSafra(resp.data.itens)
  }

  const onLoadPolygon = (e: google.maps.Polygon) => {
    setPolygon(e)
  }

  const onLoadMap = (e: google.maps.Map) => {
    setMap(e)
  }

  const handleChangeSwitchStatus = async (cod, index, status: number) => {
    loadingAlert({ msg: 'Alterando status...', id: 'status' })
    try {
      await putPropriedade(codCliente, cod, {
        status: status === 1 ? 0 : 1,
      })

      listarPropriedades('')
      setTimeout(() => {
        destroyAlert('status')
      }, 600)
    } catch (error) {
      tryError(error)
      setTimeout(() => {
        destroyAlert('status')
      }, 600)
    }
  }

  const handleVisualizarPropri = async (data, info, cod) => {
    if (info.key === '0') {
      setSafra(0)
    } else {
      setSafra(info.value)
    }

    // console.log("data: ", data);
    // console.log("info: ", info);
    // console.log("cod: ", cod);

    loadingAlert({ msg: 'Carregando mapa...', id: 'mapa' })

    // const responsePro = await getPropriedade(data)
    const response =
      info.key === '0'
        ? await getTalhao(cod === '' ? codPropriedade : cod, '')
        : await getTalhaoCultivavel({ codPropriedade, codSafra: info.value })

    const dados = response.data.itens

    setTalhoesCultivaveis(dados)

    if (dados.length > 0) {
      let talhoes = []

      for (let i = 0; i < dados.length; i += 1) {
        const element = dados[i]
        let temp = []
        for (let ii = 0; ii < element.kml.coordenadas.length; ii += 1) {
          const coord = element.kml.coordenadas[ii]

          element.kml.coordenadas[ii].lng = coord.longitude
          element.kml.coordenadas[ii].lat = coord.latitude

          temp = [...temp, { lat: parseFloat(coord.lat), lng: parseFloat(coord.lng) }]
        }
        talhoes = [...talhoes, temp]
      }

      const centro = centerMap(talhoes[0])

      setPropsMapa({
        center: {
          lat: centro.lat(),
          lng: centro.lng(),
        },
        zoom: 11,
      })

      setDataPolygon(talhoes)
      setVisualizarPropriedade(true)
      destroyAlert('mapa')
    } else {
      infoAlert('Propriedade não possui talhões cadastrados', 5000, 'mapa')
      setDataPolygon([])
    }
  }

  function tamanhoPropriedade(): number {
    let soma_area_talhoes: number = talhoesCultivaveis.reduce(
      (acc: number, item: any) => acc + Number(item.area),
      0
    )
    return soma_area_talhoes
  }

  useEffect(() => {
    if (inativos) {
      setLoadingTable(true)
      const filtrar = dataPropriedades.filter((item) => item.status === 1)
      setDataTabelaFiltrar(filtrar)
      setTimeout(() => {
        setLoadingTable(false)
      }, 500)
    } else {
      listarPropriedades('', true)
    }
  }, [inativos])

  useEffect(() => {
    listarPropriedades('', true)
    listSafra()
    listCalcario()
    listConsultor()
    onChangeUf()
    setSafra(0)
  }, [])

  useEffect(() => {
    if (update > 0) {
      handleGetPropriedade(update)
    }
  }, [update])

  useEffect(() => {
    listarPropriedades('')
  }, [page])

  useEffect(() => {
    if (buscarTabela.length > 2) {
      setTimeout(() => {
        listarPropriedades('');
      }, 700); 
    }
    if (buscarTabela === '') {
        listarPropriedades('');
    }
  }, [buscarTabela])

  return (
    <ContainerPropri>
      <ViewPage
        rotina={25}
        onPermission={setPermissions}
        title='Propriedades'
        newHide
        hiddeTitle
        cardBodyStyle={{ padding: 15 }}
        cardMainStyle={{ height: '87.5vh' }}
        // btnsExta={
        //   <>
        //     <Button
        //       icon={<Icon component={() => <FontA icon={solid('map-marked')} />} />}
        //       loading={loadingVisuPropriedades}
        //       onClick={handleClickVisualizarTodasPropriedades}
        //       type='primary'
        //     >
        //       Visualizar todas propriedades
        //     </Button>
        //     {localStorage.getItem('tipo-acesso') === 'conta' ? (
        //       <GeneratePdfButton
        //         dados={dadosEstatisticas}
        //         title={'Relatório propriedade'}
        //         totais={dadosResultado}
        //         disable={dataPropriedade.length <= 0}
        //       />
        //     ) : null}
        //   </>
        // }
        // content={
        //   <FomrPropriedade
        //     onClose={setVisibleForm}
        //     visible={visibleForm}
        //     update={udatePropriedade}
        //     setUpdate={setUpdatePropriedade}
        //     refresh={listarPropriedades}
        //     permissions={permissions}
        //   />
        // }
      >
        <div style={{ marginBottom: '10px' }}>
          <TitleH1>Propriedades</TitleH1>
        </div>

        <TabelaPropriedades
          loading={loadingTable}
          list_propriedades={dataTabelaFiltrar}
          deletar={deletarPropriedade}
          abrir={abrirGaveta}
          page={page}
          setPage={setPage}
          setPagination={setPagination}
          total={total}
          editar={abrirGavetaEdit}
          confirmar_exclusao={confirmar_exclusao}
          setConfirmar_exclusao={setConfirmar_exclusao}
          id_item_atual={id_item_atual}
          setId_item_atual={setId_item_atual}
          setNome_item_atual={setNome_item_atual}
          buscarTabela={buscarTabela}
          setBuscarTabela={setBuscarTabela}
          inativos={inativos}
          setInativos={setInativos}
          setCodPropriedade={setCodPropriedade}
          fake_loading={fake_loading}
          permissao={permissions}
          dadosEstatisticas={dadosEstatisticas}
          dadosResultado={dadosResultado}
          visualizarTodasPropriedades={handleClickVisualizarTodasPropriedades}
          handleAlteraStatus={handleChangeSwitchStatus}
          handleVisualizarPropri={handleVisualizarPropri}
          loadingVisuPropriedades={loadingVisuPropriedades}
        />

        <Drawer
          width={600}
          open={open}
          onClose={fecharGaveta}
          closable={false}
          bodyStyle={{ padding: 0 }}
          headerStyle={{ padding: '10px 10px' }}
          destroyOnClose
          footer={
            <div className='footer'>
              <ButtonCustom
                icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                type='primary'
                onClick={salvarPropriedade}
                loading={loadingSalvar}
                disabled={loadingSalvar }
              >
                Salvar
              </ButtonCustom>
            </div>
          }
          title={
            <NavegacaoDrawer>
              <ButtonCustom
                danger
                icon={<FontAwesomeIcon icon={faXmark} />}
                type='tertiary'
                onClick={fecharGaveta}
                style={{ marginLeft: -10 }}
              >
                Fechar
              </ButtonCustom>
            </NavegacaoDrawer>
          }
        >
          <FormPropriedadeNovo
            loadingForm={loadingForm}
            update={update}
            nome={nome}
            setNome={setNome}
            matricula={matricula}
            setMatricula={setMatricula}
            ccir={ccir}
            setCcir={setCcir}
            itr={itr}
            setItr={setItr}
            nirf={nirf}
            setNirf={setNirf}
            iE={ie}
            setIe={setIe}
            tipo={tipo}
            setTipo={setTipo}
            tipoId={tipoId}
            setTipoId={setTipoId}
            area={area}
            setArea={setArea}
            uf={uf}
            setUf={setUf}
            ufId={ufId}
            onChangeCity={onChangeCity}
            cidade={cidade}
            setCidade={setCidade}
            cidadeId={cidadeId}
            setCidadeId={setCidadeId}
            calcario={calcario}
            setCalcario={setCalcario}
            calcarioId={calcarioId}
            setCalcarioId={setCalcarioId}
            consultorResponsavel={responsavel}
            setConsultorResponsavel={setResponsavel}
            consultorResponsavelId={responsavelId}
            setConsultorResponsavelId={setResponsavelId}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            calcario_original={calcario_original}
            calcario_novo={calcario_novo}
            listUf={ufList}
            listCidade={cityList}
            listCalcario={dataCalcario}
            listConsultorResponsavel={dataConsultor}
            listDataTipo={dataTipo}
            fileOpen={fileOpen}
          />
        </Drawer>

        <Modal
          open={visualizarPropriedade}
          onCancel={() => setVisualizarPropriedade(false)}
          style={{ top: '10px', minWidth: '97%', height: '90vh' }}
          closable={false}
          footer={false}
        >
          <ContainerMap>
            {/* @ts-ignore */}
            <GoogleMap
              onLoad={onLoadMap}
              mapContainerClassName='floating'
              zoom={propsMap.zoom}
              mapContainerStyle={{
                width: '100%',
                height: '93vh',
              }}
              center={propsMap.center}
              options={{
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                mapTypeId: 'hybrid',
              }}
            >
              {dataPolygon.map((data, i) => {
                const color =
                  safra !== 0
                    ? {
                        fillColor: corAtualTalhao(data.cod_talhao, 'yellow', i),
                        strokeColor: corAtualTalhao(data.cod_talhao, 'yellow', i),
                      }
                    : {
                        fillColor: corAtualTalhao(data.cod_talhao, 'cyan', i),
                        strokeColor: corAtualTalhao(data.cod_talhao, 'cyan', i),
                      }

                const talhao = talhoesCultivaveis.find((record, ii) => ii === i)
                const center = centerMap(data)

                return (
                  <>
                    <Polygon
                      paths={data}
                      onClick={(e) => handleClickPolygon(e, i)}
                      onLoad={onLoadPolygon}
                      options={color}
                    />
                    {/* @ts-ignore */}
                    <InfoBox
                      options={{
                        closeBoxURL: '',
                        enableEventPropagation: true,
                      }}
                      position={center}
                    >
                      <div
                        style={{
                          overflow: 'hidden',
                          width: '400px',
                          textShadow: '2px 2px 3px black',
                        }}
                      >
                        <h4>
                          <b
                            hidden={!exibir_nome_talhao_propriedade}
                            style={{ overflow: 'hidden', color: 'white' }}
                          >
                            {talhao?.nome}
                          </b>
                          <br />
                          <b
                            hidden={!exibir_area_talhao_propriedade}
                            style={{ overflow: 'hidden', color: 'white' }}
                          >
                            {` ${talhao?.area} ha`}
                          </b>
                        </h4>
                      </div>
                    </InfoBox>
                  </>
                )
              })}

              <Painel1Propriedade
                exibir_area_talhao={exibir_area_talhao_propriedade}
                setExibir_area_talhao={setExibir_area_talhao_propriedade}
                exibir_nome_talhao={exibir_nome_talhao_propriedade}
                setExibir_nome_talhao={setExibir_nome_talhao_propriedade}
                onClose={() => {
                  setVisualizarPropriedade(false)
                  setTalhao_selecionado(null)
                }}
                dados_propriedades={talhoesCultivaveis}
                distinguir_cor={distinguir_cor}
                setDistinguir_cor={setDistinguir_cor}
                tamanhoPropriedade={tamanhoPropriedade()}
                margem={15}
                list_safra={dataSafra}
                handleVisualizarPropri={handleVisualizarPropri}
                isCadastroPropriedade
              />
            </GoogleMap>
          </ContainerMap>
        </Modal>

        <Modal
          open={visualizarPropriedadesCliente}
          onCancel={() => setVisualizarPropriedadesCliente(false)}
          style={{ top: '10px', minWidth: '97%' }}
          closable={false}
          footer={false}
        >
          <PainelTodasPropriedades
            exibir_area_talhao={exibir_area_talhao}
            setExibir_area_talhao={setExibir_area_talhao}
            exibir_nome_talhao={exibir_nome_talhao}
            setExibir_nome_talhao={setExibir_nome_talhao}
            onClose={() => setVisualizarPropriedadesCliente(false)}
            dados_propriedades={dataVisuPropridades}
            distinguir_cor={distinguir_cor}
            setDistinguir_cor={setDistinguir_cor}
            ocultarSafra
          />
          <GoogleContainer>
            {/* @ts-ignore */}
            <GoogleMap
              mapContainerClassName='floating'
              zoom={propsMap.zoom}
              mapContainerStyle={{
                width: '100%',
                height: '93vh',
              }}
              center={propsMap.center}
              options={{
                zoomControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                mapTypeId: 'hybrid',
              }}
            >
              {dataVisuPropridades.map((data, index) => {
                let poly = []
                for (let i = 0; i < data.talhoes.length; i += 1) {
                  const talhoes = data.talhoes[i]

                  let tempCoord: google.maps.LatLng[] = []
                  for (let j = 0; j < talhoes.kml.coordenadas.length; j += 1) {
                    const coord = talhoes.kml.coordenadas[j]

                    tempCoord = [
                      ...tempCoord,
                      new google.maps.LatLng({
                        lat: coord.latitude,
                        lng: coord.longitude,
                      }),
                    ]
                  }
                  poly = [
                    ...poly,
                    {
                      cod_talhao: talhoes.cod,
                      cor: talhoes.cor,
                      nome_talhao: talhoes.nome,
                      area: talhoes.area,

                      coord: tempCoord,
                    },
                  ]
                }
                return poly.map((info, ii) => {
                  const final = { ...data, ...info }

                  const center = centerMap(info?.coord)

                  return (
                    <>
                      <Polygon
                        onClick={(e) => {
                          handleClickPolygon(e, index, final, ii)
                          setTalhao_selecionado(info.cod_talhao)
                        }}
                        onLoad={onLoadPolygon}
                        options={{
                          // fillColor: distinguir_cor ? paleta_cores?.[index] : 'cyan',
                          // strokeColor: distinguir_cor ? paleta_cores?.[index] : 'cyan'
                          fillColor: corAtualTalhao(info.cod_talhao, 'cyan', index),
                          strokeColor: corAtualTalhao(info.cod_talhao, 'cyan', index),
                        }}
                        paths={info.coord}
                      />
                      {/* @ts-ignore */}
                      <InfoBox
                        options={{
                          closeBoxURL: '',
                          enableEventPropagation: true,
                        }}
                        position={center}
                      >
                        <div
                          style={{
                            overflow: 'hidden',
                            width: '400px',
                            textShadow: '2px 2px 3px black',
                            display: 'inline-flex',
                            zIndex: 0,
                          }}
                        >
                          <h4>
                            <b
                              hidden={!exibir_nome_talhao}
                              style={{ overflow: 'hidden', color: 'white' }}
                            >
                              {info?.nome_talhao}
                            </b>
                            <br />
                            <b
                              hidden={!exibir_area_talhao}
                              style={{ overflow: 'hidden', color: 'white' }}
                            >
                              {` ${info?.area} ha`}
                            </b>
                          </h4>
                        </div>
                      </InfoBox>
                    </>
                  )
                })
              })}
            </GoogleMap>
          </GoogleContainer>
        </Modal>
      </ViewPage>
    </ContainerPropri>
  )
}

export default PropriedadesNovo