import React, { useRef, useState } from 'react'
import { HeaderDrawer } from '../CustomUi/headerGaveta'
import Carregando from '../CustomUi/loading'
import { Render } from '../CustomUi/render'
import { BodyDrawer } from './styles'
import Input from '../CustomUi/input'
import { Select } from '../CustomUi/Select'
import {
  faBank,
  faCity,
  faClipboardList,
  faClipboardUser,
  faEye,
  faFileAlt,
  faFileUpload,
  faHouse,
  faHouseChimney,
  faIdCard,
  faList,
  faMap,
  faMountain,
  faPiggyBank,
  faRuler,
  faRulerVertical,
  faTag,
  faUpload,
} from '@fortawesome/free-solid-svg-icons'
import { UploadChangeParam } from 'antd/lib/upload'
import { Alert, Button, Drawer, Upload } from 'antd'
import { EyeFilled, UploadOutlined } from '@ant-design/icons'
import { Box } from '../CustomUi/box'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonCustom from '../ButtonCustom'

interface PropsFormFornecedores {
  loadingForm: boolean
  update: number
  nome: string
  setNome: React.Dispatch<React.SetStateAction<string>>
  matricula: string
  setMatricula: React.Dispatch<React.SetStateAction<string>>
  ccir: string
  setCcir: React.Dispatch<React.SetStateAction<string>>
  itr: string
  setItr: React.Dispatch<React.SetStateAction<string>>
  nirf: string
  setNirf: React.Dispatch<React.SetStateAction<string>>
  iE: string
  setIe: React.Dispatch<React.SetStateAction<string>>
  tipo: string
  setTipo: React.Dispatch<React.SetStateAction<string>>
  tipoId: number
  setTipoId: React.Dispatch<React.SetStateAction<number>>
  area: string
  setArea: React.Dispatch<React.SetStateAction<string>>
  uf: string
  setUf: React.Dispatch<React.SetStateAction<string>>
  ufId: number
  onChangeCity: React.Dispatch<React.SetStateAction<number | null>>
  cidade: string
  setCidade: React.Dispatch<React.SetStateAction<string>>
  cidadeId: number
  setCidadeId: React.Dispatch<React.SetStateAction<number>>
  calcario: string
  setCalcario: React.Dispatch<React.SetStateAction<string>>
  calcarioId: number
  setCalcarioId: React.Dispatch<React.SetStateAction<number>>
  consultorResponsavel: string
  setConsultorResponsavel: React.Dispatch<React.SetStateAction<string>>
  consultorResponsavelId: number
  setConsultorResponsavelId: React.Dispatch<React.SetStateAction<number>>
  selectedFile: {}
  setSelectedFile: React.Dispatch<React.SetStateAction<{}>>
  calcario_original: number | null
  calcario_novo: number | null
  listUf: any[]
  listCidade: any[]
  listCalcario: any[]
  listConsultorResponsavel: any[]
  listDataTipo: any[]
  fileOpen: string
}

export const FormPropriedadeNovo: React.FC<PropsFormFornecedores> = ({
  loadingForm,
  update,
  nome,
  setNome,
  matricula,
  setMatricula,
  ccir,
  setCcir,
  itr,
  setItr,
  nirf,
  setNirf,
  iE,
  setIe,
  tipo,
  setTipo,
  tipoId,
  setTipoId,
  area,
  setArea,
  uf,
  setUf,
  ufId,
  onChangeCity,
  cidade,
  setCidade,
  cidadeId,
  setCidadeId,
  calcario,
  setCalcario,
  calcarioId,
  setCalcarioId,
  consultorResponsavel,
  setConsultorResponsavel,
  consultorResponsavelId,
  setConsultorResponsavelId,
  selectedFile,
  setSelectedFile,
  calcario_original,
  calcario_novo,
  listUf,
  listCidade,
  listCalcario,
  listConsultorResponsavel,
  listDataTipo,
  fileOpen,
}) => {
  const uploadFile = useRef(null)
  const [openTipo, setOpenTipo] = useState(false)
  const [openUf, setOpenUf] = useState(false)
  const [openCidade, setOpenCidade] = useState(false)
  const [openCalcario, setOpenCalcario] = useState(false)
  const [openResponsavel, setOpenResponsavel] = useState(false)
  const [openDrawerPreviwFile, setOpenDrawerPreviwFile] = useState(false)

  const onChangeUpload = (info: UploadChangeParam) => {
    if (info.file.status === 'done') {
      setSelectedFile(info.file.originFileObj)
    }
  }

  const onRemoveFile = () => {
    setSelectedFile({})
  }

  const handleChangeMask = (data) => {
    let area = data
    area = area.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
    area = (area / 100).toFixed(2).replace('.', ',')
    setArea(area)
  }

  const drawerPreviewFile = () => {
    setOpenDrawerPreviwFile(true)
  }

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  return (
    <>
      <Render condition={loadingForm}>
        <Carregando
          animation
          justifyContent='center'
          height={'100%'}
          legenda='Carregando informações...'
        />
      </Render>
      <Render condition={loadingForm === false}>
        <HeaderDrawer>
          {update > 0 ? 'Atualizar propriedade' : 'Cadastrar propriedade'}
        </HeaderDrawer>
        <BodyDrawer>
          <Input
            label='Nome'
            placeholder={'Digite o nome da propriedade'}
            value={nome}
            onChange={setNome}
            icon={faHouseChimney}
            obrigatorio
          />
          <div className='campo__dois__inputs'>
            <Input
              label='Matrícula'
              placeholder={'Digite a matrícula da propriedade'}
              value={matricula}
              onChange={setMatricula}
              icon={faClipboardList}
              obrigatorio
            />
            <Input
              label='CCIR'
              placeholder={'Digite a CCIR da propriedade'}
              value={ccir}
              icon={faFileAlt}
              onChange={setCcir}
            />
          </div>
          <div className='campo__dois__inputs'>
            <Input
              label='ITR'
              placeholder={'Digite a ITR da propriedade'}
              value={itr}
              icon={faBank}
              onChange={setItr}
            />
            <Input
              label='NIRF'
              placeholder={'Digite a NIRF da propriedade'}
              value={nirf}
              onChange={setNirf}
              icon={faTag}
            />
          </div>
          <Input
            label='Inscrição estadual'
            placeholder={'Digite a inscrição estadual da propriedade'}
            value={iE}
            onChange={setIe}
            icon={faClipboardList}
          />
          <div className='campo__dois__inputs'>
            <Input
              label='Área(ha)'
              placeholder={'Digite a área da propriedade'}
              value={area}
              onChange={handleChangeMask}
              icon={faRulerVertical}
              obrigatorio
            />
            <Select
              label='Tipo'
              placeholder='Selecione o tipo'
              value={tipo}
              onChange={setTipo}
              onChangeID={setTipoId}
              open={openTipo}
              trigger={setOpenTipo}
              icon={faList}
              items={listDataTipo}
              descricaoItem={'nome'}
              idItem={'cod'}
              obrigatorio
            />
          </div>

          <div className='campo__dois__inputs'>
            <Select
              label='UF'
              placeholder='Selecione o estado'
              value={uf}
              onChange={setUf}
              onChangeID={onChangeCity}
              open={openUf}
              trigger={setOpenUf}
              items={listUf}
              descricaoItem={'sigla'}
              idItem={'cod'}
              icon={faMap}
              obrigatorio
            />
            <Select
              label='Cidade'
              placeholder='Selecione a cidade'
              value={cidade}
              onChange={setCidade}
              onChangeID={setCidadeId}
              open={openCidade}
              trigger={setOpenCidade}
              items={listCidade}
              descricaoItem={'nome'}
              idItem={'cod'}
              icon={faCity}
              obrigatorio
            />
          </div>
          <div className='campo__dois__inputs'>
            <Select
              label='Calcário'
              placeholder='Selecione o calcário'
              value={calcario}
              onChange={setCalcario}
              onChangeID={setCalcarioId}
              open={openCalcario}
              trigger={setOpenCalcario}
              items={listCalcario}
              descricaoItem={'nome'}
              icon={faMountain}
              idItem={'cod'}
            />
            <Select
              label='Consultor responsável'
              placeholder='Selecione o consultor responsável'
              value={consultorResponsavel}
              onChange={setConsultorResponsavel}
              onChangeID={setConsultorResponsavelId}
              open={openResponsavel}
              trigger={setOpenResponsavel}
              items={listConsultorResponsavel}
              icon={faClipboardUser}
              descricaoItem={'usuario_nome'}
              idItem={'cod_usuario'}
            />
          </div>

          {calcario_original !== calcarioId && calcarioId !== null && (
            <>
              <Alert
                message='Aviso'
                description='Caso possua resultados de OS já processados, será necessário reprocessá-los!'
                type='warning'
                showIcon
                // closable
              />
              <br />
            </>
          )}

          <div className='campo__upload__arquivo'>
            <label>Documentos da Propriedade</label>
            <div style={{ display: 'flex', gap: '10px' }}>
              {update > 0 && fileOpen !== '' ? (
                <ButtonCustom
                  onClick={drawerPreviewFile}
                  type='primary'
                  icon={<FontAwesomeIcon icon={faEye} />}
                >
           
                </ButtonCustom>
              ) : null}
              <Upload
                ref={uploadFile}
                accept='application/pdf'
                onChange={onChangeUpload}
                onRemove={onRemoveFile}
                maxCount={1}
                multiple={false}
                customRequest={dummyRequest}
              >
                <ButtonCustom
                  onClick={undefined}
                  type='secondary'
                  icon={<FontAwesomeIcon icon={faFileUpload} />}
                >
                  Arquivo
                </ButtonCustom>
              </Upload>
            </div>
          </div>
          <Drawer
            height='100%'
            placement='bottom'
            onClose={() => setOpenDrawerPreviwFile(false)}
            open={openDrawerPreviwFile}
            closeIcon={false}
            footer={
              <Button
                onClick={() => {
                  setOpenDrawerPreviwFile(false)
                }}
                danger
                type='primary'
              >
                Fechar
              </Button>
            }
          >
            <object width='100%' height='100%' data={`data:application/pdf;base64,${fileOpen}`} />
          </Drawer>
        </BodyDrawer>
      </Render>
    </>
  )
}
