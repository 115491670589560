import React from 'react'
import { ItensPerUserLogged } from '../../../../../../services/login/permissaoUsuarioLoado'
import { Box } from '../../../../../../Components/CustomUi/box'
import { Inline } from '../../../../../../Components/CustomUi/inline'
import Input from '../../../../../../Components/CustomUi/input'
import ButtonCustom from '../../../../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'
import { HeaderTable } from '../../../../../../Components/CustomUi/table/headerTable'
import { BodyTable } from '../../../../../../Components/CustomUi/table/bodyTable'
import { Render } from '../../../../../../Components/CustomUi/render'
import { removerAcentos } from '../../../../../../utils'
import { RowTable } from '../../../../../../Components/CustomUi/table/rowTable'
import { RowItem } from '../../../../../../Components/CustomUi/table/rowItem'
import { DeleteButton } from '../../../../../../Components/CustomUi/deleteButton'
import { Empty } from 'antd'
import Carregando from '../../../../../../Components/CustomUi/loading'
import { ItensVariedade, Variedades } from '../../../../../../services/variedadeHibrido/GET'

interface PropsTabelaVariedade {
  loading: boolean
  setPagination: React.Dispatch<React.SetStateAction<number>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  total: number
  listar_variedades: ItensVariedade[]
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  abrirGaveta: Function
  permissao: ItensPerUserLogged
  editarCadastro?: Function
  duplicarDados: Function
  deletar?: Function
  confirmar_exclusao: boolean
  setConfirmar_exclusao: React.Dispatch<React.SetStateAction<boolean>>
  id_item_atual: number
  setId_item_atual: React.Dispatch<React.SetStateAction<number>>
  nome_item_atual: string
  setNome_item_atual: React.Dispatch<React.SetStateAction<string>>
  fake_loading: boolean
  setFake_loading: React.Dispatch<React.SetStateAction<boolean>>
  buscarTabela: string
  setBuscarTabela: React.Dispatch<React.SetStateAction<string>>
  data_cultura: any[]
}

export const TabelaVariedadeHibrida: React.FC<PropsTabelaVariedade> = ({
  loading,
  setPagination,
  page,
  setPage,
  total,
  listar_variedades,
  setOpen,
  abrirGaveta,
  permissao,
  editarCadastro,
  duplicarDados,
  deletar,
  confirmar_exclusao,
  setConfirmar_exclusao,
  id_item_atual,
  setId_item_atual,
  nome_item_atual,
  setNome_item_atual,
  fake_loading,
  setFake_loading,
  buscarTabela,
  setBuscarTabela,
  data_cultura,
}) => {
  function buscarPatrimonios(buscar_nome: string) {
    const palavraSemAcentos = removerAcentos(buscar_nome)
    return function (item) {
      const nomeSemAcentos = removerAcentos(String(item?.nome))
      return nomeSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) || !buscar_nome
    }
  }

  function verificar(cod, valor, tipo_opcao) {
    switch (cod) {
      case 49:
        return valor
      case 50:
        return valor
      case 51:
        return tipo_opcao
    }
  }

  return (
    <Box style={{ height: 'calc(100vh - 160px)' }} gap={10} borderless>
      <Inline alignItems='center'>
        <Input
          placeholder='Buscar variedade/híbrido'
          value={buscarTabela}
          onChange={setBuscarTabela}
        />

        <ButtonCustom icon={<FontAwesomeIcon icon={faPlus} />} onClick={abrirGaveta}>
          Novo
        </ButtonCustom>
      </Inline>
      <HeaderTable
        itens={[
          { valor: 'Descrição', width: 'calc(100% - 826px)' },
          { valor: 'Empresa', width: '150px' },
          { valor: 'DGU/GM/Ciclo', width: '150px' },
          { valor: 'Nível Investimento', width: '160px' },
          { valor: 'Cultura', width: '135px' },
          { valor: 'Ações', width: '230.5px' },
        ]}
      />

      <BodyTable
        paginate={total}
        onChangeItensPage={setPagination}
        onChangePage={setPage}
        current_page={page}
        setCurrent_page={setPage}
        style={{
          height: (listar_variedades ?? []).length < 8 && 'calc(100vh - 170px)',
          position: 'relative',
          overflowX: 'hidden',
          overflowY: 'scroll',
          marginTop: -10,
        }}
      >
        <Render condition={!loading}>
          {(listar_variedades ?? [])
            .filter(buscarPatrimonios(buscarTabela))
            .map(
              ({
                cod,
                nome,
                empresa_obtentora,
                cod_classificacao,
                valor_classificacao,
                tipo_opcao_classificacao,
                nivel_investimento,
                cultura_nome,
              }) => {
                return (
                  <RowTable
                    key={cod}
                    onDoubleClick={() => editarCadastro(cod)}
                    style={{ height: 'auto' }}
                  >
                    <RowItem
                      fontSize={13}
                      style={{ alignItems: 'center' }}
                      width={'calc(100% - 821px)'}
                    >
                      {nome}
                    </RowItem>
                    <RowItem fontSize={13} width={'150px'}>
                      {empresa_obtentora}
                    </RowItem>
                    <RowItem fontSize={13} width={'150px'}>
                      {verificar(cod_classificacao, valor_classificacao, tipo_opcao_classificacao)}
                    </RowItem>
                    <RowItem fontSize={13} width={'160px'}>
                      {nivel_investimento}
                    </RowItem>
                    <RowItem fontSize={13} width={'135px'}>
                      {cultura_nome}
                    </RowItem>

                    <RowItem width={'230px'} gap={10}>
                      <DeleteButton
                        open={confirmar_exclusao && id_item_atual === cod}
                        trigger={setConfirmar_exclusao}
                        onTrigger={() => {
                          setId_item_atual(cod)
                          setNome_item_atual(nome)
                        }}
                        loading={fake_loading}
                        message={`o item ${nome} será excluido!`}
                        deleteds={[nome]}
                        onDelete={() => {
                          deletar(cod)
                        }}
                        disabled={fake_loading}
                        disabledTrigger={fake_loading}
                        typeButtonTrigger='tertiary'
                      />
                      <ButtonCustom
                        type='secondary'
                        icon={<FontAwesomeIcon icon={faCopy} />}
                        onClick={() => duplicarDados(cod)}
                      />
                      <ButtonCustom
                        icon={<FontAwesomeIcon icon={faEdit} />}
                        onClick={() => editarCadastro(cod)}
                      >
                        Editar
                      </ButtonCustom>
                    </RowItem>
                  </RowTable>
                )
              }
            )}
        </Render>

        <Render condition={listar_variedades?.length === 0 && !loading}>
          {buscarTabela === '' ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<> Nenhum registro disponível!</>}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  {' '}
                  <b>'{buscarTabela}'</b> não encontrado!
                </>
              }
            />
          )}
        </Render>

        <Render condition={loading}>
          <Carregando
            animation
            legenda='Carregando variedades...'
            justifyContent='center'
            height={'100%'}
            size='default'
          />
        </Render>
      </BodyTable>
    </Box>
  )
}
