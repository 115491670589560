
import { TypeEstrutura } from "../../painelTodasPropriedades/meta";
import { TalhaoCultivaveisItens } from "../../../../../../services/TalhaoSafra";
import { leadZero } from "../../../../../../utils";


export function gerarEstruturaTabela(dados_propriedades: any[]): TypeEstrutura[] {

    let linha_nome_area = (dados_propriedades??[]).map(({ nome, area }) => [nome, (area + ' ha')]);

    let tabela: TypeEstrutura[] = [{
        planilha: {
            columns: [{name: 'Talhão'}, {name: 'Área'}],
            rows: linha_nome_area
        }
    }];

    return tabela;
}

export function gerarEstruturaTabelaSecundaria(dados_propriedades: TalhaoCultivaveisItens[]): TypeEstrutura[] {

    let nome_propriedade: string = dados_propriedades?.[0]?.propriedade_nome;
    let area_total: number = Number((dados_propriedades??[]).reduce((acc:number, item: any)=> acc + Number(item.area), 0).toFixed(2));
    let qtd_talhoes: any = (dados_propriedades??[]).length;

    let tabela: TypeEstrutura[] = [{
        planilha: {
            columns: [{name: 'Propriedade'}, {name: 'Área total'}, {name: 'Quantidade de talhões'}],
            rows: [[nome_propriedade, (area_total+ ' ha'), leadZero(qtd_talhoes)]]
        }
    }];

    return tabela;
};

export function gerarEstruturaPDF(dados_propriedades: TalhaoCultivaveisItens[]) {

    let rows = (dados_propriedades ?? []).map(({ nome, area })=> {
        return [
            {
                text: nome,
                fontSize: 6,
                margin: [0, 2, 0, 2],
                alignment: "center"
            },
            {
                text: (area + ' ha'),
                fontSize: 6,
                margin: [0, 2, 0, 2],
                alignment: "center"
            },
        ]
    })
    
    let tabela = [{
        table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
                [
                    {
                        text: 'Talhão',
                        style: "tableHeader",
                        fontSize: 8,
                        alignment: "center"
                    },
                    {
                        text: 'Área',
                        style: "tableHeader",
                        fontSize: 8,
                        alignment: "center"
                    },
                ],
                ...rows
            ]
        },
        layuot: {}
    }]

    return tabela ?? [];
}

export function gerarEstruturaTotaisPDF(dados_propriedades: TalhaoCultivaveisItens[]) {

    let nome_propriedade: string = dados_propriedades?.[0]?.propriedade_nome;
    let area_total: number = Number((dados_propriedades??[]).reduce((acc:number, item: any)=> acc + Number(item.area), 0).toFixed(2));
    let qtd_talhoes: any = (dados_propriedades??[]).length;

    let rows = [[
        {
            text: nome_propriedade,
            fontSize: 6,
            margin: [0, 2, 0, 2],
            alignment: "center"
        },
        {
            text: (area_total + ' ha'),
            fontSize: 6,
            margin: [0, 2, 0, 2],
            alignment: "center"
        },
        {
            text: leadZero(qtd_talhoes),
            fontSize: 6,
            margin: [0, 2, 0, 2],
            alignment: "center"
        },
    ]]
    
    let tabela = [{
        table: {
            headerRows: 1,
            widths: ['*', '*', '*'],
            body: [
                [
                    {
                        text: 'Propriedade',
                        style: "tableHeader",
                        fontSize: 8,
                        alignment: "center",
                    },
                    {
                        text: 'Área total',
                        style: "tableHeader",
                        fontSize: 8,
                        alignment: "center"
                    },
                    {
                        text: 'Quantidade de talhões',
                        style: "tableHeader",
                        fontSize: 8,
                        alignment: "center"
                    }
                ],
                ...rows
            ]
        },
        layuot: {}
    }]

    return tabela ?? [];
}