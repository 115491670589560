import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { faLayerGroup, faPrint } from "@fortawesome/free-solid-svg-icons";
import html2pdf from "html2pdf.js";
import ReactToPrint from "react-to-print";

//ASSETS
import logo_tttivos from '../../../../../../../../assets/img/logo-ttivos-png.png';

//STYLES
import { ListFotos, ListFotos__talhoes } from "./style";

//APP
import { gerarKey } from "../../../../../../../../utils";
import { useAccess } from "../../../../../../../../context/useAccess";
import { Item__Talhoes } from "../../../../../../../../services/monitoramento/resumo/get";
import { ImagemBookMonitoramento__Itens } from "../../../../../../../../services/monitoramento/relatorioFotografico/get";
import { adptarArrayCoord, obterPontos, obterPontosMesclados } from "../../meta";
import { agruparFotosPorTalhao, colunaLargura, gerarNomeDoArquivo, listaSlides, obterListaFotos, obterMultiTalhoes } from "./meta";

//COMPONENTS
import Tabs from "../../../../../../../../Components/Tabs";
import MapTalhao from "../../../../../../../../Components/BookMonitoramento/components/paginaFotos/components/mapa";
import ButtonCustom from "../../../../../../../../Components/ButtonCustom";
import MultiPolygonMap from "../../../../../../../../Components/CustomUi/MultiPolygonMapa";
import { Inline } from "../../../../../../../../Components/CustomUi/inline"
import { Render } from "../../../../../../../../Components/CustomUi/render";
import { Select } from "../../../../../../../../Components/CustomUi/Select";
import { TitleH1 } from "../../../../../../../../Components/CustomUi/titleH1";
import { Switcher } from "../../../../../../../../Components/CustomUi/Switcher";
import { SlidesFullScreen } from "../../../../../../../../Components/CustomUi/slidesFullScreen";
import { useScreenSize } from "../../../../../../../../utils/tsx";

//PROPS
interface Props {
    lista_fotos_data_selecionada: ImagemBookMonitoramento__Itens[];
    list_talhoes: Item__Talhoes[];
    nome_propriedade: string;
    data_monitoramento: string;
}

export const ListagemFotos: React.FC<Props> = ({ lista_fotos_data_selecionada, list_talhoes, nome_propriedade, data_monitoramento }) => {

    //CONST
    const contentRef = useRef<HTMLDivElement>(null);
    const { nameCliente } = useAccess();
    const { currentWidth } = useScreenSize({})

    //STATES
    const [open_select_talhao, setOpen_select_talhao] = useState<boolean>(false);
    const [nome_talhao_selecionado, setNome_talhao_selecionado] = useState<string>('');
    const [ocultar_mapa, setOcultar_mapa] = useState<boolean>(false);
    const [agrupar_mapa, setAgrupar_mapa] = useState<boolean>(true);
    const [permitir_cortar_imagem, setPermitir_cortar_imagem] = useState<boolean>(false);
    const [igualar_proporcao, setIgualar_proporcao] = useState<boolean>(false);
    const [tab_selecionada, setTab_selecionada] = useState<'01'|'02'|'03'|'04'>('03');
    const [openSlides, setOpenSlides] = useState<boolean>(false);
    const [slide_selecionado, setSlide_selecionado] = useState<number>(0);
    const [loading_print_button, setLoading_print_button] = useState<boolean>(false);
    

    //FUNCTIONS
    const areImagesLoaded = () => {
        const images = contentRef.current.querySelectorAll("img");
        return Array.from(images).every((img) => img.complete);
    };
    const handleExportPDF = () => {

        contentRef.current.scrollTop = contentRef.current.scrollHeight;

        setTimeout(() => {
            if (!areImagesLoaded()) {
                // Intervalo para verificar se as imagens terminaram de carregar
                const interval = setInterval(() => {
                  if (areImagesLoaded()) {
                    clearInterval(interval);
                    // setLoading(false);
                    gerarPDF();
                  }
                }, 100); // Verifica a cada 100ms se as imagens estão carregadas
              } else {
                  gerarPDF();
              }
        }, 3000);
        
    };
    const gerarPDF = async () => {
        const element = contentRef.current;
        const options = {
            filename: "lista-imagens.pdf",
            margin: 10,
            html2canvas: {
            scale: 2, // Melhora a qualidade da imagem
            useCORS: true,
            },
            jsPDF: {
            unit: "mm",
            // unit: "px",
            // format:  [element.scrollWidth, element.scrollHeight * 3],
            format: "a4",
            orientation: "portrait",
            },
        };
        
        html2pdf().from(element).set(options).save();
    };

    function encontrarIndexSlide(cod_encontrar: number) {
        let index_selecionado = (listaSlides(lista_fotos_data_selecionada, nome_talhao_selecionado)).findIndex((item=> item.cod === cod_encontrar));
        setSlide_selecionado(index_selecionado)
    }

    const imprimirConteudo = () => {

        
        if (contentRef.current) {
            let id_button = document.getElementById('imprimir_conteudo');

            if (contentRef.current.scrollTop > 0) {
                contentRef.current.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
                setTimeout(() => {
                    id_button?.click?.();
                }, 1000);
                return;
            }

            id_button?.click?.();
        }
    };
    
    return (
        <ListFotos>

            <Render condition={currentWidth <= 640}>
                <Select 
                    label={"Filtrar por talhão"}
                    descricaoItem="nome_talhao"
                    idItem="cod_talhao"
                    items={agruparFotosPorTalhao(lista_fotos_data_selecionada)??[]}
                    open={open_select_talhao}
                    trigger={setOpen_select_talhao}
                    value={nome_talhao_selecionado}
                    onChange={setNome_talhao_selecionado}
                    placeholder="Selecionar talhão"
                    icon={faLayerGroup}
                />
            </Render>
            <Render condition={currentWidth <= 480}>
                <Tabs 
                    label="Coluna de fotos"
                    tabs={[{descricao: '01', icone:''}, {descricao: '02', icone:''}, {descricao: '03', icone:''}, {descricao: '04', icone:''}]}
                    onChange={setTab_selecionada}
                    selecionado={tab_selecionada}
                    labelFontSize={12}
                    width={150}
                    trackWidth={35}
                    indexInicial={2}
                    style={{marginTop: -10}}
                />
            </Render>

            <Inline alignItems="center" style={{marginTop: currentWidth <= 480 ? -15 : 0}}>
                <Render condition={currentWidth > 640}>
                    <Select 
                        label={`Filtrar por talhão`}
                        descricaoItem="nome_talhao"
                        idItem="cod_talhao"
                        items={agruparFotosPorTalhao(lista_fotos_data_selecionada)??[]}
                        open={open_select_talhao}
                        trigger={setOpen_select_talhao}
                        value={nome_talhao_selecionado}
                        onChange={setNome_talhao_selecionado}
                        placeholder="Selecionar talhão"
                        icon={faLayerGroup}
                        width={260}
                    />
                </Render>
                
                <Render condition={currentWidth > 480}>
                    <Tabs 
                        label="Coluna de fotos"
                        tabs={[{descricao: '01', icone:''}, {descricao: '02', icone:''}, {descricao: '03', icone:''}, {descricao: '04', icone:''}]}
                        onChange={setTab_selecionada}
                        selecionado={tab_selecionada}
                        labelFontSize={12}
                        width={150}
                        trackWidth={35}
                        indexInicial={2}
                    />
                </Render>
                
                <Switcher 
                    isChecked={ocultar_mapa}
                    trigger={setOcultar_mapa}
                    label="Ocultar mapa"
                    style={{marginTop: 15}}
                />
                <Switcher 
                    isChecked={agrupar_mapa}
                    trigger={setAgrupar_mapa}
                    label="Agrupar mapa"
                    style={{marginTop: 15}}
                    disabled={ocultar_mapa}
                />
                <Switcher 
                    isChecked={igualar_proporcao}
                    trigger={setIgualar_proporcao}
                    label="Igualar proporção"
                    style={{marginTop: 15}}
                />

            </Inline>

            <ListFotos__talhoes  ref={contentRef}>

                <div className="ListFotos__talhoes__header">
                    <img src={logo_tttivos} alt='ttivos' className="ListFotos__talhoes__header__logo" />

                    
                    <TitleH1>Fotos do monitoramento</TitleH1>

                </div>


                <Render condition={!ocultar_mapa && agrupar_mapa}>
                    <div className="ListFotos__talhoes__talhoes_agrupado">
                        <MultiPolygonMap 
                            multi_coord={obterMultiTalhoes(lista_fotos_data_selecionada, nome_talhao_selecionado)}
                            coord={[]}
                            keyProp={Date.now()}
                            list_pontos={obterPontosMesclados(lista_fotos_data_selecionada, nome_talhao_selecionado)}
                            customHeight={279}
                        />
                    </div>
                </Render>
                
                {(agruparFotosPorTalhao(lista_fotos_data_selecionada) ?? []).filter((item)=>item.nome_talhao===nome_talhao_selecionado || nome_talhao_selecionado=='').map(({ nome_talhao, coordenadas, dados_talhao }, index)=> {
                    return (
                        <div className="ListFotos__talhoes__container" data-noBreak={index===0} key={gerarKey(index)}>

                            <div className="ListFotos__talhoes__container__info">
                                <div className="ListFotos__t__c__info__row">
                                    <div className="ListFotos__t__c__info__row__title">
                                        Cliente
                                    </div>
                                    <div className="ListFotos__t__c__info__row__value">
                                        {nameCliente}
                                    </div>
                                </div>
                                <div className="ListFotos__t__c__info__row">
                                    <div className="ListFotos__t__c__info__row__title">
                                        Propriedade
                                    </div>
                                    <div className="ListFotos__t__c__info__row__value">
                                        {nome_propriedade}
                                    </div>
                                </div>
                                <div className="ListFotos__t__c__info__row">
                                    <div className="ListFotos__t__c__info__row__title">
                                        Data
                                    </div>
                                    <div className="ListFotos__t__c__info__row__value">
                                        {data_monitoramento}
                                    </div>
                                </div>
                                <div className="ListFotos__t__c__info__row" data-bold={true}>
                                    <div className="ListFotos__t__c__info__row__title">
                                        Talhão
                                    </div>
                                    <div className="ListFotos__t__c__info__row__value">
                                        {nome_talhao}
                                    </div>
                                </div>
                            </div>

                            <div className="ListFotos__talhoes__container__content">

                                <Render condition={!ocultar_mapa && !agrupar_mapa}>
                                    <div className="ListFotos__talhoes__container__content__mapa">
                                        <MapTalhao
                                            coord={adptarArrayCoord(coordenadas ?? [])} 
                                            customHeight={279} 
                                            keyProp={gerarKey(1)}
                                            list_pontos={obterPontos(dados_talhao)}
                                        />
                                    </div>
                                </Render>   

                                <div className="ListFotos__talhoes__container__content__fotos">
                                    {(dados_talhao ?? []).map(({ monitoramento, descricao, url_arquivo, cod })=> {
                                        return (
                                            <div className="ListFotos__t__c__fotos__card" data-igualar={igualar_proporcao ? tab_selecionada : false} style={{width: colunaLargura(tab_selecionada, currentWidth), pageBreakInside: permitir_cortar_imagem ? 'auto' : undefined}} >
                                                <div className="ListFotos__t__c__fotos__card__header">
                                                    Ponto {monitoramento?.ponto}
                                                    <span>{nome_talhao}</span>
                                                </div>

                                                <div className="ListFotos__t__c__fotos__card__img" onClick={()=>{encontrarIndexSlide(cod);setOpenSlides(true)}}>
                                                    <img src={url_arquivo} alt={`img_${monitoramento?.ponto}`} style={{pageBreakInside: permitir_cortar_imagem ? 'initial' : undefined}} />
                                                </div>

                                                <p className="ListFotos__t__c__fotos__card__descr">
                                                    {descricao}
                                                </p>

                                            </div>
                                        )
                                    })}
                                    
                                </div>
                            </div>
                        </div>
                    )
                })}
                
            
            </ListFotos__talhoes>


            <div className="ListFotos__footer">
                <span style={{transform: 'scale(0)', opacity: 1, position: 'absolute', bottom: -1000}}>
                    <ReactToPrint
                        trigger={() => <button id='imprimir_conteudo' style={{width:111, background:'transparent', border:'none'}}>
                            <ButtonCustom 
                                onClick={()=>{}}
                                icon={<FontAwesomeIcon icon={faPrint} />}
                            >
                                Imprimir
                            </ButtonCustom>
                        </button>}
                        content={() => contentRef.current}
                        onBeforePrint={() => {setLoading_print_button(false)}}
                        documentTitle={`${gerarNomeDoArquivo(data_monitoramento, nameCliente, nome_propriedade)}`}
                    ></ReactToPrint>
                </span>

                <ButtonCustom 
                    onClick={()=>{ setLoading_print_button(true); imprimirConteudo()}}
                    icon={<FontAwesomeIcon icon={faPrint} />}
                    loading={loading_print_button}
                    disabled={loading_print_button}
                >
                    Imprimir
                </ButtonCustom>

                {/* <ButtonCustom 
                    onClick={handleExportPDF}
                    icon={<FontAwesomeIcon icon={faFilePdf} />}
                    type="secondary"
                >
                    Gerar PDF
                </ButtonCustom> */}
            </div>

            <Render condition={openSlides}>
                <SlidesFullScreen 
                    slides={obterListaFotos(lista_fotos_data_selecionada, nome_talhao_selecionado)}
                    onClose={()=>setOpenSlides(false)}
                    slide_inicial={slide_selecionado ?? 0}
                />
            </Render>
            
        </ListFotos>
    )
};