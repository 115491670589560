import styled from 'styled-components'

export const BodyDrawer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .campo__dois__inputs {
    display: flex;
    gap: 10px;
  }

  .campo__upload__arquivo {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    label {
      font-size: 13px;
      font-weight: 500;
      color: var(--text-color);
      display: inline-flex;
      gap: 5px;
    }
  }

  .header {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    background-color: rgb(224, 224, 224);
  }
`
