
import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'

export interface TypeGetGrupoServico {
  itens: [GrupoServicoItens]
  total: number
}

export interface GrupoServicoItens {
  cod?: number
  cod_c?: number
  cod_profundidade?: number
  nome?: string
  cor?: string
  profundidade_nome?: string
  valor?: string
  analises?: {
    profundidade?: {
      cod?: number
      nome?: string
    }
    analises?: [
      {
        cod?: number
        nome?: string
      }
    ]
  }
}

interface TypeParamGet {
  cod?: number | string
  codProfundidade?: number | string
  codAnalise?: number
  pagination?: number | -1
  page?: number | 15
}

export const getGrupoServico = async (params: TypeParamGet) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetGrupoServico> = await api.get(
    `grupo-analise?codConta=${config.conta}`, { params }
  )

  return response
}

export const postGrupoServico = async (data) => {
  const config = localConfig()

  const response: AxiosResponse<TypeGetGrupoServico> = await api.post(
    `grupo-analise?codConta=${config.conta}`,
    data
  )

  return response
}

export const putGrupoServico = async (cod, data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeGetGrupoServico> = await api.put(
    `grupo-analise/${cod}?codConta=${config.conta}`,
    data
  )

  return response
}

export const delGrupoServico = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetGrupoServico> = await api.delete(
    `grupo-analise/${cod}?codConta=${config.conta}`
  )

  return response
}
