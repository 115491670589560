import React, { useEffect, useState } from 'react'
import { ContainerFertilizante } from './styles'
import ViewPage from '../../../../Components/ViewPage'
import { TitleH1 } from '../../../../Components/CustomUi/titleH1'
import { TabelaFertilizantes } from './components/tabela'
import { Drawer } from 'antd'
import ButtonCustom from '../../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk, faXmark } from '@fortawesome/free-solid-svg-icons'
import { NavegacaoDrawer } from '../novoCompatibilidadeAtivo/components/gavetaCadastrar/styled'
import FormFertilizante from '../../../../Components/fertilizante'
import { usePostFertilizante, usePutFertilizante } from '../../../../services/fertilizante/POST'
import {
  useGetAplicacoes,
  useGetFertilizantes,
  useGetFertilizantesEditar,
  useGetFormulacao,
  useGetMarca,
} from '../../../../services/fertilizante/GET'
import { useDeleteFertilizante } from '../../../../services/fertilizante/DELETE'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'

const Fertilizante: React.FC = () => {
  //TABELA
  const [open, setOpen] = useState<boolean>(false)
  const [update, setUpdate] = useState<number>(null)
  const [updateDuplicar, setUpdateDuplicar] = useState<number>(null)
  const [pagination, setPagination] = useState<number>(15)
  const [page, setPage] = useState<number>(1)
  const [confirmar_exclusao, setConfirmar_exclusao] = useState<boolean>(false)
  const [id_item_atual, setId_item_atual] = useState<number>(null)
  const [nome_item_atual, setNome_item_atual] = useState<string>('')
  const [fake_loading, setFake_loading] = useState<boolean>(false)
  const [permissions, setPermissions] = useState<ItensPerUserLogged>()

  //FORMULARIO
  const [tab_selecionado_atual, setTab_selecionado_atual] = useState<
    'Dados do fertilizante' | 'Elementos'
  >('Dados do fertilizante')
  const [nome, setNome] = useState<string>('')
  const [observacao, setObservacao] = useState<string>('')
  const [densidade, setDensidade] = useState<string>('')
  const [aminoacido, setAminoacido] = useState<string>('')
  const [indice_salino, setIndice_salino] = useState<string>('')
  const [formulacao, setFormulacao] = useState<string>('')
  const [formulacaoId, setFormulacaoId] = useState<number>(null)
  const [marca, setMarca] = useState<string>('')
  const [marcaId, setMarcaId] = useState<number>(null)
  const [extratoAlga, setExtratoAlga] = useState<string>('')
  const [nitrogenio, setNitrogenio] = useState<string>('')
  const [fosforo, setFosforo] = useState<string>('')
  const [potassio, setPotassio] = useState<string>('')
  const [calcio, setCalcio] = useState<string>('')
  const [magnesio, setMagnesio] = useState<string>('')
  const [enxofre, setEnxofre] = useState<string>('')
  const [boro, setBoro] = useState<string>('')
  const [cobre, setCobre] = useState<string>('')
  const [ferro, setFerro] = useState<string>('')
  const [manganes, setManganes] = useState<string>('')
  const [zinco, setZinco] = useState<string>('')
  const [cobalto, setCobalto] = useState<string>('')
  const [molibdenio, setMolibdenio] = useState<string>('')
  const [niquel, setNiquel] = useState<string>('')
  const [silicio, setSilicio] = useState<string>('')
  const [selenio, setSelenio] = useState<string>('')
  const [aplicacoes, setAplicacoes] = useState<{ descr: string; id: number; key?: number }[]>([])

  //APIS
  const { refetch_data_fertilizante, carregando_fertilizante, data_fertilizante } =
    useGetFertilizantes(pagination, page)
  const {
    refetch_data_fertilizante_editar,
    carregando_fertilizante_editar,
    data_fertilizante_editar,
  } = useGetFertilizantesEditar(update ?? updateDuplicar)
  const { refetch_data_aplicacoes, data_aplicacoes } = useGetAplicacoes()
  const { refetch_data_formulacao, data_formulacao } = useGetFormulacao()
  const { refetch_data_marca, data_marca } = useGetMarca()
  const { carregando_salvar_fertilizante, mutate_fertilizante } = usePostFertilizante()
  const { mutate_edit_fertilizante, carregando_edit_fertilizante } = usePutFertilizante(update)
  const { mudateDeletarFertilizante } = useDeleteFertilizante()

  function abrirGaveta() {
    setOpen(true)
  }

  function editarGaveta(cod) {
    setOpen(true)
    setUpdate(cod)
  }

  function duplicar(cod) {
    setOpen(true)
    setUpdateDuplicar(cod)
  }

  function fecharGaveta() {
    setOpen(false)
    setUpdate(null)
    setUpdateDuplicar(null)
    setNome('')
    setTab_selecionado_atual('Dados do fertilizante')
    setObservacao('')
    setDensidade('')
    setAminoacido('')
    setIndice_salino('')
    setAplicacoes([])
    setFormulacao('')
    setFormulacaoId(null)
    setMarca('')
    setMarcaId(null)
    setExtratoAlga('')
    setNitrogenio('')
    setFosforo('')
    setPotassio('')
    setCalcio('')
    setMagnesio('')
    setEnxofre('')
    setBoro('')
    setCobre('')
    setFerro('')
    setManganes('')
    setZinco('')
    setCobalto('')
    setMolibdenio('')
    setNiquel('')
    setSilicio('')
    setSelenio('')
  }

  function salvarFertilizante() {
    if (update <= 0 || updateDuplicar > 0) {
      mutate_fertilizante({
        nome: nome,
        observacao: observacao,
        densidade: densidade,
        aminoacido: aminoacido,
        indice_salino: indice_salino,
        extrato_de_alga: extratoAlga,
        aplicacoes: aplicacoes.map((item) => item.id),
        cod_formulacao: formulacaoId,
        cod_marca: marcaId,
        n: nitrogenio,
        p: fosforo,
        k: potassio,
        ca: calcio,
        mg: magnesio,
        s: enxofre,
        b: boro,
        cu: cobre,
        fe: ferro,
        mn: manganes,
        zn: zinco,
        co: cobalto,
        mo: molibdenio,
        ni: niquel,
        si: silicio,
        se: selenio,
        refetch: () => {
          fecharGaveta()
          refetch_data_fertilizante()
        },
      })
    }

    if (update > 0) {
      mutate_edit_fertilizante({
        nome: nome,
        observacao: observacao,
        densidade: densidade,
        aminoacido: aminoacido,
        indice_salino: indice_salino,
        extrato_de_alga: extratoAlga,
        aplicacoes: aplicacoes.map((item) => item.id),
        cod_formulacao: formulacaoId,
        cod_marca: marcaId,
        n: nitrogenio,
        p: fosforo,
        k: potassio,
        ca: calcio,
        mg: magnesio,
        s: enxofre,
        b: boro,
        cu: cobre,
        fe: ferro,
        mn: manganes,
        zn: zinco,
        co: cobalto,
        mo: molibdenio,
        ni: niquel,
        si: silicio,
        se: selenio,
        refetch: () => {
          fecharGaveta()
          refetch_data_fertilizante()
        },
      })
    }
  }

  function deletarFertilizante(cod) {
    setFake_loading(true)
    mudateDeletarFertilizante({
      cod_fertilizante: cod,
      refetch_deletar: () => refetch_data_fertilizante(),
    })

    setTimeout(() => {
      setFake_loading(false)
    }, 800)
  }

  useEffect(() => {
    refetch_data_fertilizante()
    refetch_data_aplicacoes()
    refetch_data_formulacao()
    refetch_data_marca()
  }, [])

  useEffect(() => {
    if (data_fertilizante_editar.length > 0) {
      setNome(data_fertilizante_editar[0]?.nome)
      setDensidade(data_fertilizante_editar[0]?.densidade?.replace(/\./g, ','))
      setAminoacido(data_fertilizante_editar[0]?.aminoacido?.replace(/\./g, ','))
      setIndice_salino(data_fertilizante_editar[0]?.indice_salino?.replace(/\./g, ','))
      const editAplicacao = data_fertilizante_editar[0]?.aplicacoes.map((item, index) => {
        return { descr: item?.aplicacao_nome, id: item?.cod_aplicacao, key: item?.cod_aplicacao }
      })
      setAplicacoes(editAplicacao)
      setFormulacao(data_fertilizante_editar[0]?.tipo_formulacao_nome)
      setFormulacaoId(data_fertilizante_editar[0]?.cod_formulacao)
      setMarca(data_fertilizante_editar[0]?.marca_nome)
      setMarcaId(data_fertilizante_editar[0]?.cod_marca)
      setObservacao(data_fertilizante_editar[0]?.observacao)
      setExtratoAlga(data_fertilizante_editar[0]?.extrato_de_alga)
      setNitrogenio(data_fertilizante_editar[0]?.n?.replace(/\./g, ','))
      setFosforo(data_fertilizante_editar[0]?.p?.replace(/\./g, ','))
      setPotassio(data_fertilizante_editar[0]?.k?.replace(/\./g, ','))
      setCalcio(data_fertilizante_editar[0]?.ca?.replace(/\./g, ','))
      setMagnesio(data_fertilizante_editar[0]?.mg?.replace(/\./g, ','))
      setEnxofre(data_fertilizante_editar[0]?.s?.replace(/\./g, ','))
      setBoro(data_fertilizante_editar[0]?.b?.replace(/\./g, ','))
      setCobre(data_fertilizante_editar[0]?.cu?.replace(/\./g, ','))
      setFerro(data_fertilizante_editar[0]?.fe?.replace(/\./g, ','))
      setManganes(data_fertilizante_editar[0]?.mn?.replace(/\./g, ','))
      setZinco(data_fertilizante_editar[0]?.zn?.replace(/\./g, ','))
      setCobalto(data_fertilizante_editar[0]?.co?.replace(/\./g, ','))
      setMolibdenio(data_fertilizante_editar[0]?.mo?.replace(/\./g, ','))
      setNiquel(data_fertilizante_editar[0]?.ni?.replace(/\./g, ','))
      setSilicio(data_fertilizante_editar[0]?.si?.replace(/\./g, ','))
      setSelenio(data_fertilizante_editar[0]?.se?.replace(/\./g, ','))
    }
  }, [data_fertilizante_editar])

  useEffect(() => {
    if (update > 0) {
      refetch_data_fertilizante_editar()
    }
  }, [update])

  useEffect(() => {
    if (updateDuplicar > 0) {
      refetch_data_fertilizante_editar()
    }
  }, [updateDuplicar])

  return (
    <ContainerFertilizante>
      <ViewPage
        rotina={88}
        onPermission={setPermissions}
        title='Fertilizantes'
        btnClick={null}
        hiddeTitle
        cardBodyStyle={{ padding: 15 }}
        cardMainStyle={{ height: '87.5vh' }}
      >
        <div style={{ marginBottom: '10px' }}>
          <TitleH1>Fertilizantes</TitleH1>
        </div>

        <TabelaFertilizantes
          abrirGaveta={abrirGaveta}
          listar_fertilizantes={data_fertilizante?.itens}
          loading={carregando_fertilizante}
          setPagination={setPagination}
          page={page}
          setPage={setPage}
          total={data_fertilizante?.total}
          permissao={permissions}
          editarCadastro={editarGaveta}
          duplicar={duplicar}
          deletar={deletarFertilizante}
          confirmar_exclusao={confirmar_exclusao}
          setConfirmar_exclusao={setConfirmar_exclusao}
          id_item_atual={id_item_atual}
          setId_item_atual={setId_item_atual}
          nome_item_atual={nome_item_atual}
          setNome_item_atual={setNome_item_atual}
          fake_loading={fake_loading}
          setFake_loading={setFake_loading}
        />
        <Drawer
          width={550}
          open={open}
          onClose={() => fecharGaveta()}
          closable={false}
          bodyStyle={{ padding: 0 }}
          headerStyle={{ padding: '10px 10px' }}
          destroyOnClose
          footer={
            <div className='footer'>
              <ButtonCustom
                icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                type='primary'
                onClick={salvarFertilizante}
                disabled={carregando_salvar_fertilizante ?? carregando_edit_fertilizante}
                loading={carregando_salvar_fertilizante ?? carregando_edit_fertilizante}
                hidden={permissions?.acesso === 1}
              >
                Salvar
              </ButtonCustom>
            </div>
          }
          title={
            <NavegacaoDrawer>
              <ButtonCustom
                danger
                icon={<FontAwesomeIcon icon={faXmark} />}
                type='tertiary'
                onClick={() => fecharGaveta()}
                style={{ marginLeft: -10 }}
              >
                Fechar
              </ButtonCustom>
            </NavegacaoDrawer>
          }
        >
          <FormFertilizante
            loadingForm={carregando_fertilizante_editar}
            update={update}
            tab_selecionar_atual={tab_selecionado_atual}
            setTab_selecionar_atual={setTab_selecionado_atual}
            nome={nome}
            setNome={setNome}
            observacao={observacao}
            setObservacao={setObservacao}
            extratoAlga={extratoAlga}
            setExtratoAlga={setExtratoAlga}
            densidade={densidade}
            setDensidade={setDensidade}
            aminoacido={aminoacido}
            setAminoacido={setAminoacido}
            indice_salino={indice_salino}
            setIndice_salino={setIndice_salino}
            aplicaçoes={aplicacoes}
            setAplicaçoes={setAplicacoes}
            formulacao={formulacao}
            setFormulacao={setFormulacao}
            setFormulacaoId={setFormulacaoId}
            marca={marca}
            setMarca={setMarca}
            setMarcaId={setMarcaId}
            nitrogenio={nitrogenio}
            setNitrogenio={setNitrogenio}
            fosforo={fosforo}
            setFosforo={setFosforo}
            potassio={potassio}
            setPotassio={setPotassio}
            calcio={calcio}
            setCalcio={setCalcio}
            magnesio={magnesio}
            setMagnesio={setMagnesio}
            enxofre={enxofre}
            setEnxofre={setEnxofre}
            boro={boro}
            setBoro={setBoro}
            cobre={cobre}
            setCobre={setCobre}
            ferro={ferro}
            setFerro={setFerro}
            manganes={manganes}
            setManganes={setManganes}
            zinco={zinco}
            setZinco={setZinco}
            cobalto={cobalto}
            setCobalto={setCobalto}
            molibdenio={molibdenio}
            setMolibdenio={setMolibdenio}
            niquel={niquel}
            setNiquel={setNiquel}
            silicio={silicio}
            setSilicio={setSilicio}
            selenio={selenio}
            setSelenio={setSelenio}
            data_aplicacoes={data_aplicacoes}
            data_formulacao={data_formulacao}
            data_marca={data_marca}
          />
        </Drawer>
      </ViewPage>
    </ContainerFertilizante>
  )
}

export default Fertilizante
