import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  gap: 4px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 8px;

  label {
    font-size: 13px;
    font-weight: 500;
    color: var(--text-color);
    display: inline-flex;
    gap: 5px;
  }

  .button__trigger{
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid var(--border-color);
    padding: 5px;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .button__trigger:hover{
    transform: scale(1.1);
    transition: 0.5s;
  }
`

export const Grid = styled.div`
  width: 315px !important;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

export const ColorButton = styled.button<{ color: string; isSelected: boolean, disable: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid ${({ isSelected }) => (isSelected ? 'black' : 'transparent')};
  background-color: ${({ color }) => color};
  transition: transform 0.2s, border-color 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  ${({ disable }) => disable && 'opacity: 0.5; cursor: not-allowed;'}

  ${({ isSelected }) => isSelected && 'transform: scale(1.15);'}
`
