import { Render } from "../../../../../CustomUi/render";
import { HeaderTable } from "../../../../../CustomUi/table/headerTable";
import { FixedSizeList as List } from 'react-window';
import { obterAlturaListaTodasPragas } from "../../meta";
import { useScreenSize } from "../../../../../../utils/tsx";
import { RowTable } from "../../../../../CustomUi/table/rowTable";
import { listPragas } from "../../../tabelaPragas/meta";
import { RowItem } from "../../../../../CustomUi/table/rowItem";
import { ColorCircle } from "../../../tabelaPragas/styled";
import { leadZero } from "../../../../../../utils";
import ButtonCustom from "../../../../../ButtonCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTableList } from "@fortawesome/free-solid-svg-icons";
import { Empty } from "antd";
import { TabelaDatas } from "../../../tabelaDatas";
import { TypeDetailsMonitoramento } from "../../../../../../features/Monitoramento/Monitoramento.dataDetails";

//PROPS & TYPES
interface Props {
    list_todos_dados: any[];
    setPraga_selecionada: React.Dispatch<React.SetStateAction<listPragas>>;
    setNome_praga_selecionada: React.Dispatch<React.SetStateAction<string>>;
    setNome_variacao_selecionada: React.Dispatch<React.SetStateAction<string>>;
    setOpen_tabela_datas: React.Dispatch<React.SetStateAction<boolean>>;
    search_talhao: string;
    open_tabela_datas: boolean;
    encolher: boolean;
    nome_praga_selecionada: string;
    nome_variacao_selecionada: string;
    setVisibleDrawerPonto: React.Dispatch<React.SetStateAction<boolean>>;
    praga_selecionada: listPragas;
    dataDetails: TypeDetailsMonitoramento;
    visibleChangeBooking: boolean;
    handleAssembleHeatMap: Function;
    handleClickBadge: Function;
    setCalorSelect: Function;
}

export const Todos: React.FC<Props> = ({ list_todos_dados, setPraga_selecionada, setNome_praga_selecionada, 
    setNome_variacao_selecionada, setOpen_tabela_datas, search_talhao, open_tabela_datas, encolher, 
    nome_praga_selecionada, nome_variacao_selecionada, setVisibleDrawerPonto, praga_selecionada, 
    handleAssembleHeatMap, handleClickBadge, setCalorSelect, dataDetails, visibleChangeBooking
}) => {

    //CONST
    const { currentHeight } = useScreenSize({});

    const Row = ({ index, style }) => {//NOSONAR

        let { especie_nome, variacao, cores, key } = list_todos_dados?.[index] ?? {};

        function selecionarPraga(key_selecionada: number) {
            let select = list_todos_dados.filter(item => item.key === key_selecionada);
            setPraga_selecionada(select?.[0]);
        }

        function qtdeDados() {
            let { cor_verde_claro, cor_verde_forte, cor_amarelo_claro, cor_amarelo_forte, cor_vermelho } = cores;
            let soma = cor_verde_claro + cor_verde_forte + cor_amarelo_claro + cor_amarelo_forte + cor_vermelho;
            return soma;
        }

        return (
            <div style={style}>
                <RowTable onDoubleClick={() => {
                    setNome_praga_selecionada(especie_nome);
                    setNome_variacao_selecionada(variacao);
                    selecionarPraga(key);
                    setOpen_tabela_datas(true);
                }}>
                    <RowItem width={240} fontSize={13}>
                        {especie_nome}
                    </RowItem>
                    <RowItem width={116} fontSize={12.5}>
                        {variacao}
                    </RowItem>
                    <RowItem width={155} gap={5} style={{ flexWrap: "wrap", padding: '0px 5px' }} >
                        <Render condition={cores?.cor_verde_claro > 0}>
                            <ColorCircle data-cor={'verde_claro'}>{leadZero(cores?.cor_verde_claro)}</ColorCircle>
                        </Render>
                        <Render condition={cores?.cor_verde_forte > 0}>
                            <ColorCircle data-cor={'verde_forte'}>{leadZero(cores?.cor_verde_forte)}</ColorCircle>
                        </Render>
                        <Render condition={cores?.cor_amarelo_claro > 0}>
                            <ColorCircle data-cor={'amarelo_claro'}>{leadZero(cores?.cor_amarelo_claro)}</ColorCircle>
                        </Render>
                        <Render condition={cores?.cor_amarelo_forte > 0}>
                            <ColorCircle data-cor={'amarelo_forte'}>{leadZero(cores?.cor_amarelo_forte)}</ColorCircle>
                        </Render>
                        <Render condition={cores?.cor_vermelho > 0}>
                            <ColorCircle data-cor={'vermelho'}>{leadZero(cores?.cor_vermelho)}</ColorCircle>
                        </Render>
                    </RowItem>
                    <RowItem width={65}>
                        <ButtonCustom
                            onClick={() => {
                                setNome_praga_selecionada(especie_nome);
                                setNome_variacao_selecionada(variacao);
                                selecionarPraga(key);
                                setOpen_tabela_datas(true);
                            }}
                            type={qtdeDados() !== 1 ? 'secondary' : 'primary'}
                            icon={qtdeDados() !== 1 ? <FontAwesomeIcon icon={faTableList} /> : <FontAwesomeIcon icon={faEye} />}
                        >
                        </ButtonCustom>
                    </RowItem>
                </RowTable>
            </div>
        );
    }

    return (
        <div style={{ height: 'calc(100vh - 320px)', overflowY: 'auto', boxSizing: 'border-box', paddingRight: 7, marginTop: 10, }}>
            <HeaderTable
                itens={[
                    { valor: 'Praga', width: 240 },
                    { valor: 'Variação', width: 116 },
                    { valor: 'Resumo', width: 155.5 },
                    { valor: '', width: 64 },
                ]}
            />
            <div style={{ marginTop: -1 }}>
                <Render condition={list_todos_dados.length > 0}>
                    <List
                        height={obterAlturaListaTodasPragas(currentHeight)}
                        itemCount={list_todos_dados.length ?? 0}
                        itemSize={55}
                        width={'100%'}
                        useIsScrolling
                    >
                        {Row}
                    </List>
                </Render>

                <Render condition={list_todos_dados.length === 0}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<> <b>'{search_talhao}'</b> não encontrado!</>} />
                </Render>
            </div>



            <Render condition={open_tabela_datas && !encolher}>
                <TabelaDatas
                    nome_praga={nome_praga_selecionada}
                    nome_variacao={nome_variacao_selecionada}
                    nome_talhao={''}
                    onClose={() => { setOpen_tabela_datas(false); setVisibleDrawerPonto(false) }}
                    praga_selecionada={praga_selecionada?.cores?.dados_gerais ?? []}
                    list_datas={praga_selecionada?.datas ?? []}
                    handleAssembleHeatMap={handleAssembleHeatMap}
                    handleClickBadge={handleClickBadge}
                    setCalorSelect={setCalorSelect}
                    dataDetails={dataDetails}
                    origem={'Todos'}
                    encolher={encolher}
                    setVisibleDrawerPonto={setVisibleDrawerPonto}
                    visibleChangeBooking={visibleChangeBooking}
                />
            </Render>
        </div>
    )
}