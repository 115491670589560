export const analises = [
  {
    cod: 1,
    nome: 'MACRONUTRIENTES',
    key: 1,
  },
  {
    cod: 2,
    nome: 'MICRONUTRIENTES',
    key: 2,
  },
  {
    cod: 3,
    nome: 'ENXOFRE',
    key: 3,
  },
  {
    cod: 4,
    nome: 'POTÁSSIO TOTAL',
    key: 4,
  },
  {
    cod: 5,
    nome: 'GRANULOMETRIA',
    key: 5,
  },
  {
    cod: 6,
    nome: 'BORO',
    key: 6,
  },
  {
    cod: 7,
    nome: 'MATÉRIA ORGÂNICA',
    key: 7,
  },
]

export const profundidades = [
  {
    cod: 1,
    nome: '00 - 20'
  },
  {
    cod: 2,
    nome: '20 - 40'
  },
  {
    cod: 3,
    nome: '40 - 60'
  },
  {
    cod: 4,
    nome: '00 - 50'
  },
]

// MICRONUTRIENTES

// ENXOFRE

// POTÁSSIO TOTAL

// GRANULOMETRIA

// BORO

// MATÉRIA ORGÂNICA
