import React, { useState } from 'react'
import { BodyDrawer } from './styles'
import { HeaderDrawer } from '../CustomUi/headerGaveta'
import Input from '../CustomUi/input'
import { Select } from '../CustomUi/Select'
import { SelectMulti } from '../CustomUi/SelectMulti'
import { Box } from '../CustomUi/box'
import { TitleH3 } from '../CustomUi/titleH3'
import ButtonCustom from '../ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBug,
  faBugs,
  faBuilding,
  faChartColumn,
  faChartLine,
  faCheck,
  faCogs,
  faEdit,
  faList,
  faListNumeric,
  faPen,
  faPlus,
  faSeedling,
  faSignal,
  faWheatAlt,
  faWrench,
} from '@fortawesome/free-solid-svg-icons'
import { HeaderTable } from '../CustomUi/table/headerTable'
import { BodyTable } from '../CustomUi/table/bodyTable'
import { Render } from '../CustomUi/render'
import { RowTable } from '../CustomUi/table/rowTable'
import { RowItem } from '../CustomUi/table/rowItem'
import { Empty } from 'antd'
import Carregando from '../CustomUi/loading'
import {
  ItensCultura,
  ItensEspecie,
  ItensTecnologias,
  ItensTipoSistema,
} from '../../services/variedadeHibrido/GET'
import { DeleteButton } from '../CustomUi/deleteButton'

interface PropsFormVariedadeHibrido {
  update: number
  loadingForm: boolean
  nome: string
  setNome: React.Dispatch<React.SetStateAction<string>>
  empresa: string
  setEmpresa: React.Dispatch<React.SetStateAction<string>>
  cultura: string
  setCultura: React.Dispatch<React.SetStateAction<string>>
  culturaId: number
  setCulturaId: React.Dispatch<React.SetStateAction<number>>
  tecnologias_selecionadas: { descr: string; id: number; key?: number }[]
  setTecnologias_selecionadas: React.Dispatch<
    React.SetStateAction<{ descr: string; id: number; key?: number }[]>
  >
  nivel_investimento: string
  setNivel_investimento: React.Dispatch<React.SetStateAction<string>>
  nivel_investimentoId: number
  setNivel_investimentoId: React.Dispatch<React.SetStateAction<number>>
  classificacao: string
  setClassificacao: React.Dispatch<React.SetStateAction<string>>
  classificacaoId: number
  setClassificacaoId: React.Dispatch<React.SetStateAction<number>>
  opcao_classificacao: string
  setOpcao_classificacao: React.Dispatch<React.SetStateAction<string>>
  opcao_classificacaoId: number
  setOpcao_classificacaoId: React.Dispatch<React.SetStateAction<number>>
  valor_classificacao: string
  setValor_classificacao: React.Dispatch<React.SetStateAction<string>>
  especie: string
  setEspecie: React.Dispatch<React.SetStateAction<string>>
  especieId: number
  setEspecieId: React.Dispatch<React.SetStateAction<number>>
  variacao_especie: string
  setVariacao_especie: React.Dispatch<React.SetStateAction<string>>
  variacao_especieId: number
  setVariacao_especieId: React.Dispatch<React.SetStateAction<number>>
  tipo: string
  setTipo: React.Dispatch<React.SetStateAction<string>>
  tipoId: number
  setTipoId: React.Dispatch<React.SetStateAction<number>>
  confirmar_exclusao: boolean
  setConfirmar_exclusao: React.Dispatch<React.SetStateAction<boolean>>
  id_item_atual: number
  setId_item_atual: React.Dispatch<React.SetStateAction<number | string>>
  nome_item_atual: string
  setNome_item_atual: React.Dispatch<React.SetStateAction<string>>
  fake_loading: boolean
  setFake_loading: React.Dispatch<React.SetStateAction<boolean>>
  editar: boolean
  cod_tolerancia: number
  data_cultura: any[]
  data_tecnologias: ItensTecnologias[]
  data_nivel_investimentos: ItensTipoSistema[]
  data_classificacao: ItensTipoSistema[]
  data_opcao_classificacao: ItensTipoSistema[]
  data_especieVariacao: ItensEspecie[]
  data_tipo: any[]
  tolerancias: {
    cod?: number | string
    cod_especie: number
    cod_variacao_especie?: number
    cod_tipo: number
  }[]
  adicionarTolerancia: Function
  editarTolerecnia: Function
  deletarTolerancia: Function
}

export const FormVariedadeHibrido: React.FC<PropsFormVariedadeHibrido> = ({
  update,
  loadingForm,
  nome,
  setNome,
  empresa,
  setEmpresa,
  cultura,
  setCultura,
  culturaId,
  setCulturaId,
  tecnologias_selecionadas,
  setTecnologias_selecionadas,
  nivel_investimento,
  setNivel_investimento,
  nivel_investimentoId,
  setNivel_investimentoId,
  classificacao,
  setClassificacao,
  classificacaoId,
  setClassificacaoId,
  opcao_classificacao,
  setOpcao_classificacao,
  opcao_classificacaoId,
  setOpcao_classificacaoId,
  valor_classificacao,
  setValor_classificacao,
  especie,
  setEspecie,
  especieId,
  setEspecieId,
  variacao_especie,
  setVariacao_especie,
  variacao_especieId,
  setVariacao_especieId,
  tipo,
  setTipo,
  tipoId,
  setTipoId,
  confirmar_exclusao,
  setConfirmar_exclusao,
  id_item_atual,
  setId_item_atual,
  nome_item_atual,
  setNome_item_atual,
  fake_loading,
  setFake_loading,
  editar,
  data_cultura,
  data_tecnologias,
  data_nivel_investimentos,
  data_classificacao,
  data_opcao_classificacao,
  data_especieVariacao,
  data_tipo,
  tolerancias,
  adicionarTolerancia,
  editarTolerecnia,
  deletarTolerancia,
  cod_tolerancia,
}) => {
  const [openCultura, setOpenCultura] = useState<boolean>(false)
  const [openTeclogias, setOpenTecnologias] = useState<boolean>(false)
  const [openNivel, setOpenNivel] = useState<boolean>(false)
  const [openClassificacao, setOpenClassificacao] = useState<boolean>(false)
  const [openOpcao, setOpenOpcao] = useState<boolean>(false)
  const [openEspecie, setOpenEspecie] = useState<boolean>(false)
  // const [openVariacao, setOpenVariacao] = useState<boolean>(false)
  const [openTipo, setOpenTipo] = useState<boolean>(false)

  function nomeEspecie(cod) {
    const filtrarEspecie = data_especieVariacao.filter((item) => item.cod === cod)
    return filtrarEspecie[0]?.nome
  }

  function nomeTipo(codTipo) {
    const filtrarTipo = data_tipo.filter((item) => item.cod === codTipo)

    return filtrarTipo[0]?.nome
  }

  const handleChangeMask = (data) => {
    let area = data
    area = area.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
    area = (area / 100).toFixed(2).replace('.', ',')
    setValor_classificacao(area)
  }

  return (
    <>
      <HeaderDrawer>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {update > 0 ? 'Atualizar variedade/hibrido' : 'Cadastrar variedade/hibrido'}

          {/* <small style={{ opacity: '0.5', fontSize: '9px' }}>{criadoPorAtualizadoPor}</small> */}
        </div>
      </HeaderDrawer>
      <BodyDrawer>
        <Render condition={loadingForm === false}>
          <Input
            label='Nome'
            placeholder='Digite o nome da variedade/hibrido'
            icon={faSeedling}
            value={nome}
            onChange={setNome}
          />
          <div className='campo__dois__inputs'>
            <Input
              label='Empresa'
              placeholder='Digite o nome da empresa'
              icon={faBuilding}
              value={empresa}
              onChange={setEmpresa}
            />
            <Select
              label='Cultura'
              placeholder='Selecione a cultura'
              icon={faWheatAlt}
              value={cultura}
              onChange={setCultura}
              onChangeID={setCulturaId}
              open={openCultura}
              disabled={update > 0}
              trigger={setOpenCultura}
              items={data_cultura}
              descricaoItem={'nome'}
              idItem={'cod'}
              allowClear={update > 0 ? false : true}
            />
          </div>
          <SelectMulti
            label='Tecnologias'
            placeholder='Selecione as tecnologias'
            icon={faCogs}
            open={openTeclogias}
            trigger={setOpenTecnologias}
            items={data_tecnologias}
            disabled={data_tecnologias.length <= 0}
            descricaoItem={'nome'}
            idItem={'cod'}
            selecteds={tecnologias_selecionadas}
            onChange={setTecnologias_selecionadas}
          />
          <Select
            label='Nível investimento'
            placeholder='Selecione o nível investimento'
            icon={faSignal}
            value={nivel_investimento}
            onChange={setNivel_investimento}
            onChangeID={setNivel_investimentoId}
            open={openNivel}
            trigger={setOpenNivel}
            items={data_nivel_investimentos}
            descricaoItem={'nome'}
            idItem={'cod'}
          />
          <div className='campo__dois__inputs'>
            <Select
              label='Classificação'
              placeholder='Selecione a classificação'
              icon={faChartColumn}
              value={classificacao}
              onChange={setClassificacao}
              onChangeID={setClassificacaoId}
              open={openClassificacao}
              trigger={setOpenClassificacao}
              items={data_classificacao}
              descricaoItem={'nome'}
              idItem={'cod'}
            />
            <Render condition={classificacaoId === 51}>
              <Select
                label='Opção classificação'
                placeholder='Selecione a opção da classificação'
                icon={faChartLine}
                value={opcao_classificacao}
                onChange={setOpcao_classificacao}
                onChangeID={setOpcao_classificacaoId}
                open={openOpcao}
                trigger={setOpenOpcao}
                items={data_opcao_classificacao}
                descricaoItem={'nome'}
                idItem={'cod'}
              />
            </Render>
          </div>
          <Render condition={classificacaoId === 49 || classificacaoId === 50}>
            <Input
              label='Valor classificação'
              placeholder='Digite o valor classificação'
              icon={faListNumeric}
              value={valor_classificacao}
              onChange={(e) => handleChangeMask(e)}
            />
          </Render>
          <Box gap={10}>
            <TitleH3>Tolerâncias</TitleH3>
            <div className='campo__dois__inputs'>
              <Select
                label='Espécie'
                placeholder='Selecione a espécie'
                icon={faBug}
                value={especie}
                onChange={setEspecie}
                onChangeID={setEspecieId}
                open={openEspecie}
                trigger={setOpenEspecie}
                items={data_especieVariacao}
                disabled={editar}
                descricaoItem={'nome'}
                idItem={'cod'}
              />
              {/* <Select
                label='Variação espécie'
                placeholder='Selecione a variação da espécie'
                icon={faBugs}
                value={variacao_especie}
                onChange={setVariacao_especie}
                onChangeID={setVariacao_especieId}
                open={openVariacao}
                trigger={setOpenVariacao}
                items={[]}
                descricaoItem={''}
                idItem={''}
                
                disabled={true}
              /> */}
              <Select
                label='Tipo'
                placeholder='Selecione o tipo'
                icon={faList}
                value={tipo}
                onChange={setTipo}
                onChangeID={setTipoId}
                open={openTipo}
                trigger={setOpenTipo}
                items={data_tipo}
                descricaoItem={'nome'}
                idItem={'cod'}
              />
            </div>

            <div>
              <ButtonCustom
                onClick={() => adicionarTolerancia(cod_tolerancia)}
                icon={<FontAwesomeIcon icon={editar ? faCheck : faPlus} />}
              >
                {editar ? 'Atualizar' : 'Inserir'}
              </ButtonCustom>
            </div>
            <div style={{ marginTop: '10px' }}>
              <HeaderTable
                itens={[
                  { valor: 'Espécie', width: 'calc(100% - 245px)' },
                  { valor: 'Tipo', width: '120px' },
                  { valor: 'Ações', width: '120px' },
                ]}
              />

              <BodyTable
                style={{
                  height: 'calc(100% - 100px )',
                  position: 'relative',
                  overflowX: 'hidden',
                  overflowY: 'scroll',
                  marginTop: -10,
                }}
              >
                <Render condition={true}>
                  {(tolerancias ?? []).map(({ cod, cod_especie, cod_tipo }) => {
                    return (
                      <RowTable key={cod} style={{ height: '80px' }}>
                        <RowItem
                          fontSize={12}
                          style={{ alignItems: 'center' }}
                          width={'calc(100% - 240px)'}
                        >
                          {nomeEspecie(cod_especie)}
                        </RowItem>

                        <RowItem
                          fontSize={12}
                          width={'120px'}
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            alignContent: 'center',
                          }}
                        >
                          {nomeTipo(cod_tipo)}
                        </RowItem>

                        <RowItem width={'120px'} gap={10}>
                          <DeleteButton
                            open={confirmar_exclusao && id_item_atual === cod}
                            trigger={setConfirmar_exclusao}
                            onTrigger={() => {
                              setId_item_atual(cod)
                              setNome_item_atual(nomeEspecie(cod_especie))
                            }}
                            loading={fake_loading}
                            message={`o item ${nomeEspecie(cod_especie)} será excluido!`}
                            deleteds={[nomeEspecie(cod_especie)]}
                            onDelete={() => deletarTolerancia(cod)}
                            disabled={fake_loading}
                            typeButtonTrigger='secondary'
                          />
                          <ButtonCustom
                            icon={<FontAwesomeIcon icon={faEdit} />}
                            onClick={() => editarTolerecnia(cod, cod_especie, cod_tipo)}
                          />
                        </RowItem>
                      </RowTable>
                    )
                  })}
                </Render>

                <Render condition={tolerancias.length <= 0}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<> Nenhuma tolerância cadastrada!</>}
                  />
                </Render>
              </BodyTable>
            </div>
          </Box>
        </Render>
        <Render condition={loadingForm}>
          <Carregando
            animation
            legenda='Carregando informações...'
            justifyContent='center'
            height={'300px'}
            size='default'
          />
        </Render>
      </BodyDrawer>
    </>
  )
}
