import { useEffect } from 'react'
import styled from 'styled-components'
import Svg from '../../../../talhasvg'

//STYLES
const Item = styled.button`
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  border: none;
  text-align: left;
  /* outline: none; */

  :hover {
    background-color: var(--hover-color);
  }
  :focus {
    background-color: var(--hover-color);
  }
  :is([data-focused='true']) {
    background-color: var(--hover-color);
  }
  :is([data-current='true']) {
    background-color: #d8e6dc;
    font-weight: 600;
    border-radius: 7px;
  }
  :is([data-disabled='true']) {
    background-color: var(--disabled-color);
    color: var(--disabled-text-color);
    cursor: not-allowed;
  }

  div.Item__img_placeholder {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

//PROPS
interface Props {
  children?: React.ReactNode
  index?: number
  focused: boolean
  onClick: Function
  current?: string
  disabled?: boolean
  iconSVGSize?: number
  iconSVG?: [string | number, string | number]
  node?: React.ReactNode
}

export const ItemList: React.FC<Props> = ({
  children,
  focused,
  index,
  onClick,
  current,
  disabled,
  iconSVG,
  iconSVGSize,
  node,
}) => {
  useEffect(() => {
    if (focused) {
      let elemento = document.getElementById(`item_${index}`)
      elemento.focus()
    }
  }, [focused])

  return (
    <Item
      data-focused={focused}
      disabled={disabled}
      data-disabled={disabled}
      data-current={current === children}
      id={`item_${index}`}
      onClick={() => onClick()}
    >
      {!!iconSVG && (
        <div className='Item__img_placeholder' style={{ width: iconSVGSize, height: iconSVGSize }}>
          {/* {icon} */}
          <Svg fill='green' coordinates={iconSVG} />
        </div>
      )}

      {children}

      {node && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          {/*@ts-ignore */}
          <span dangerouslySetInnerHTML={{ __html: node }} />
        </div>
      )}
    </Item>
  )
}
