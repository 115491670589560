import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Empty } from "antd";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//STYLE
import { Tabela } from "./style";

//APP
import { Render } from "../../../../../../Components/CustomUi/render";
import { useScreenSize } from "../../../../../../utils/tsx";
import { larguraResponsiva } from "./meta";
import { leadZero, removerAcentos } from "../../../../../../utils";
import { useGetPropriedadesComFotosTalhoes } from "../../../../../../services/monitoramento/relatorioFotografico/get";

//COMPONENTS
import Input from "../../../../../../Components/CustomUi/input";
import Carregando from "../../../../../../Components/CustomUi/loading";
import ButtonCustom from "../../../../../../Components/ButtonCustom";
import { RowItem } from "../../../../../../Components/CustomUi/table/rowItem";
import { RowTable } from "../../../../../../Components/CustomUi/table/rowTable";
import { BodyTable } from "../../../../../../Components/CustomUi/table/bodyTable";
import { HeaderTable } from "../../../../../../Components/CustomUi/table/headerTable";
import { GavetaFotos } from "../gavetaFotos";
import { DrawerCustom } from "../../../../../../Components/CustomUi/Drawer";

export const TabelaRelatorioFotografico: React.FC = () => {

    //CONSTs
    const { currentWidth } = useScreenSize({})

    //STATES
    const [buscar_propriedade, setBuscar_propriedade] = useState<string>('');
    const [open_drawer, setOpen_drawer] = useState<boolean>(false);
    //STATES - Info propriedade
    const [cod_propriedade, setCod_propriedade] = useState<number>(null);
    const [nome_propriedade, setNome_propriedade] = useState<string>('');
    
    //API
    const { data_propriedades, carregando_propriedades } = useGetPropriedadesComFotosTalhoes();

    //FUNCTIONS
    function encontrarAtivo(buscar_nome: string) {
        const ativoSemAcentos = removerAcentos(buscar_nome)
        return function (item) {
            const nomeSemAcentos = removerAcentos(String(item?.['nome']))
            return nomeSemAcentos.toLowerCase().includes(ativoSemAcentos.toLowerCase()) || !buscar_nome
        }
    }
    
    return (
        <Tabela>
            
            <Input 
                placeholder="Buscar propriedade"
                value={buscar_propriedade}
                onChange={setBuscar_propriedade}
            />

            <HeaderTable 
                itens={[
                    {valor: 'Nome da propriedade', width: currentWidth > 800 ? 'calc(100% - 376px)' : larguraResponsiva(currentWidth, 250, 'nome_da_propriedade')},
                    {valor: currentWidth > 768 ? 'Quantidade de fotos' : 'Qtd fotos', width: larguraResponsiva(currentWidth, 250, 'quantidade_de_fotos')},
                    {valor: 'Ações', width: 100},
                ]}
                textTransform="none"
            />

            <Render condition={!carregando_propriedades}>
                <BodyTable style={{marginTop: -10}}>
                    {(data_propriedades?.itens ?? []).filter(encontrarAtivo(buscar_propriedade)).map(({nome, cod, talhao, imagens_monitoramento}, index)=> {
                        return (
                            <RowTable key={index} onDoubleClick={()=>{setCod_propriedade(cod);setNome_propriedade(nome);setOpen_drawer(true)}}>
                                <RowItem width={currentWidth > 800 ? 'calc(100% - 375px)' : larguraResponsiva(currentWidth, 250, 'nome_da_propriedade')}>
                                    {nome}
                                </RowItem>
                                <RowItem width={larguraResponsiva(currentWidth, 250, 'quantidade_de_fotos')}>
                                    {leadZero(imagens_monitoramento)}
                                </RowItem>
                                <RowItem width={100}>
                                    <ButtonCustom 
                                        icon={<FontAwesomeIcon icon={faEye} /> }
                                        onClick={()=>{setCod_propriedade(cod);setNome_propriedade(nome);setOpen_drawer(true)}}
                                    >
                                        Ver
                                    </ButtonCustom>
                                </RowItem>
                            </RowTable>
                        )
                    })}
                </BodyTable>
            </Render>
            
            <Render condition={carregando_propriedades}>
                <Carregando 
                    animation
                    height={500}
                    justifyContent="center"
                    legenda="Carregando propriedades...."
                />
            </Render>
            
            <Render condition={!carregando_propriedades && ((data_propriedades?.itens ?? []).filter(encontrarAtivo(buscar_propriedade))).length === 0 && (data_propriedades?.itens ?? []).length > 0}>
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<> {' '} <b>'{buscar_propriedade}'</b> não encontrado! </> }
                />
            </Render>
            
            <Render condition={!carregando_propriedades && (data_propriedades?.itens ?? []).length === 0 && ((data_propriedades?.itens ?? []).filter(encontrarAtivo(buscar_propriedade))).length === 0}>
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<>Nenhum registro encontrado!</> }
                />
            </Render>

            <DrawerCustom
                open={open_drawer}
                onClose={()=>{setOpen_drawer(false)}}
                destroyOnClose
                width={1140}
                title={nome_propriedade}
            >
                <GavetaFotos 
                    parent_open={open_drawer} 
                    cod_propriedade={cod_propriedade}
                    nome_propriedade={nome_propriedade}
                />
            </DrawerCustom>

        </Tabela>
    );
};