import { Tooltip } from "antd/lib";
import styled from "styled-components";

//APP
import { typeColor } from "../../../../meta/estrutura_pdf";

//STYLES
const Circle = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 50%;
`;

//PROPS
interface Props {
    valor: number;
    nivel_controle: number;
    is_invertido: boolean;
}

export const SmallCircleNC: React.FC<Props> = ({ valor, nivel_controle, is_invertido }) => {

    function typeColor2() {
        return (valor * 100) / nivel_controle;
    }

    function typeTooltip() {
        if (valor === 0) {
            return 'Não possui ocorrência';
        }
        if (valor > 0 && valor < nivel_controle) {
            return 'Abaixo do NC';
        }
        if (valor === nivel_controle) {
            return 'Está no NC';
        }
        if (valor > nivel_controle) {
            return 'Acima do NC';
        }
    }

    function newTypeTooltip(cor: string) {
        switch (cor) {
            case '#01FF00':
                return 'Não possui ocorrência';
            case '#FCFF02':
                return 'Abaixo do NC';
            case '#FCB700':
                return 'Está no NC';
            case '#FB0303':
                return 'Acima do NC';
            default:
                break;
        }
    }

    return (
        <Tooltip title={`(NC: ${nivel_controle}) | ${newTypeTooltip(typeColor(valor, nivel_controle, is_invertido))}`} zIndex={9999}>
            <samp data-valor={typeColor2()}>
                <Circle style={{background: typeColor(valor, nivel_controle, is_invertido)}} />
            </samp>
        </Tooltip>
    )
}
