import styled from "styled-components";

export const ListFotos = styled.div`
    height: calc(100vh - 135px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: hidden;

    div.ListFotos__estadios {
        width: 100%;
        min-height: 38px;
        display: flex;
        border: thin solid var(--border-color);
        border-radius: 5px;
        overflow: hidden;

        div.ListFotos__estadios__title {
            border-right: thin solid var(--border-color);
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding: 10px;
            font-weight: 600;
            background: #FAFAFA;
            color: var(--text-color);
            font-size: 14px;
        }

        div.ListFotos__estadios__listagem {
            box-sizing: border-box;
            padding: 0px 10px;
            font-size: 13px;
            display: flex;
            align-items: center;
            font-weight: 500;
        }
    }


    div.ListFotos__footer {
        width: 100%;
        height: 35px;
        bottom: 5px;
        background: #fff;

        @media (max-width: 415px) {
            margin-bottom: 80px;
        }
    }
`;

export const ListFotos__talhoes = styled.div`
        width: 100%;
        box-sizing: border-box;
        padding-right: 5px;
        overflow-y: auto;

        @media print {
            padding: 20px;
        }

        div.ListFotos__talhoes__header {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            position: relative;
            display: none;

            img.ListFotos__talhoes__header__logo {
                width: 120px;
            }

            @media print {
                display: flex;
            }

        }

        div.ListFotos__talhoes__talhoes_agrupado {
            width: 100%;
            height: 279px;
            border-radius: 5px;
            overflow: hidden;
            margin-top: 15px;
        }

        div.ListFotos__talhoes__container {
            width: 100%;
            border-radius: 5px;
            border: thin solid var(--border-color);
            margin-top: 10px;
            overflow: hidden;
            page-break-inside: avoid;

            :is([data-noBreak = true]) {
                page-break-inside: auto !important;
            }

            div.ListFotos__talhoes__container__info {
                width: 100%;
                display: flex;
                flex-direction: column;

                div.ListFotos__t__c__info__row {
                    width: 100%;
                    height: 30px;
                    display: flex;
                    border-bottom: thin solid var(--border-color);

                    :is([data-bold = true]) {
                        div.ListFotos__t__c__info__row__title {
                            font-weight: 700 !important;
                        }
                        div.ListFotos__t__c__info__row__value {
                            font-weight: 600 !important;
                        }
                        
                    }

                    div.ListFotos__t__c__info__row__title {
                        height: 29px;
                        width: 120px;
                        background: #fafafa;
                        border-right: thin solid var(--border-color);
                        display: flex;
                        align-items: center;
                        font-size: 13px;
                        font-weight: 500;
                        box-sizing: border-box;
                        padding: 10px;
                        color: var(--text-color);

                        @media (max-width: 481px) {
                            width: 105px;
                        }
                    }
                    div.ListFotos__t__c__info__row__value {
                        width: calc(100% - 150px);
                        display: flex;
                        align-items: center;
                        font-size: 13px;
                        font-weight: 400;
                        box-sizing: border-box;
                        padding: 10px;
                        color: var(--text-color);

                        @media (max-width: 992px) {
                            font-size: 11px;
                        }
                        @media (max-width: 481px) {
                            width: calc(100% - 105px);
                            font-size: 9px;
                            font-weight: 500;
                        }
                    }
                }
            }

            div.ListFotos__talhoes__container__content {
                width: 100%;
                box-sizing: border-box;
                padding: 10px;

                div.ListFotos__talhoes__container__content__mapa {
                    width: 100%;
                    height: 275px;
                    border-radius: 5px;
                    overflow: hidden;
                    page-break-inside: avoid;
                }

                div.ListFotos__talhoes__container__content__fotos {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 15px;
                    margin-top: 15px;

                    div.ListFotos__t__c__fotos__card {
                        width: 32.2%;
                        border: thin solid var(--border-color);
                        border-radius: 5px;
                        box-sizing: border-box;
                        padding: 10px;
                        transition: .4s;
                        page-break-inside: avoid;

                        div.ListFotos__t__c__fotos__card__header {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            font-size: 13px;
                            font-weight: 700;
                            color: var(--text-color);

                            span {
                                color: var(--text-color-light);
                            }

                            @media (max-width: 992px) {
                                font-size: 11px;
                                gap: 5px;
                            }
                        }

                        div.ListFotos__t__c__fotos__card__img {
                            width: 100%;
                            /* min-height: 150px; */
                            background: var(--hover-color);
                            margin-top: 7px;
                            border-radius: 7px;
                            overflow: hidden;

                            img {
                                width: 100%;
                                cursor: pointer;
                                transition: .4s;

                                :hover {
                                    transform: scale(1.1);
                                }
                            }
                        }

                        :is([data-igualar = '02']) {
                            div.ListFotos__t__c__fotos__card__img {
                                width: 100%;
                                height: 255px;
                                display: flex;
                                align-items: flex-start;
                                justify-content: center;

                                img {
                                    width: 100%;
                                    height: 100%; 
                                    object-fit: contain;
                                    border-radius: 7px !important;
                                }
                            }
                        }
                        :is([data-igualar = '03']) {
                            div.ListFotos__t__c__fotos__card__img {
                                width: 100%;
                                height: 170px;
                                display: flex;
                                align-items: flex-start;
                                justify-content: center;

                                img {
                                    width: 100%;
                                    height: 100%; 
                                    object-fit: contain;
                                    border-radius: 7px !important;
                                }
                            }
                        }
                        :is([data-igualar = '04']) {
                            div.ListFotos__t__c__fotos__card__img {
                                width: 100%;
                                height: 220px;
                                display: flex;
                                align-items: flex-start;
                                justify-content: center;

                                img {
                                    width: 100%;
                                    height: 100%; 
                                    object-fit: contain;
                                    border-radius: 7px !important;
                                }
                            }
                        }

                        p.ListFotos__t__c__fotos__card__descr {
                            margin-top: 10px;
                            font-size: 13px;
                            font-weight: 400px;
                            color: var(--text-color);

                            @media (max-width: 415px) {
                                font-size: 11px;
                            }
                        }

                    }
                }
            }

            
        }
`;