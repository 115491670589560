import { groupBy } from "lodash";
import { Item__Talhoes } from "../../../../../../../../../services/monitoramento/resumo/get";
import { ImagemBookMonitoramento__Itens } from "../../../../../../../../../services/monitoramento/imagemMonitoramento/get";
import { useAccess } from "../../../../../../../../../context/useAccess";

export function obterCoordenadasTalhao(list_talhoes: Item__Talhoes[], cod_talhao: number) {
    let talhao = (list_talhoes ?? []).find(item=> item?.cod === cod_talhao);
    return talhao?.kml?.coordenadas ?? []
}

export function colunaResponsiva(
    current_width: number,
    coluna: string
  ): string {
    
    function getResponsiveWidth(ranges: Array<{ min: number, max: number, value: string }>, defaultValue: string): string {
      for (const range of ranges) {
        if (current_width <= range.max && current_width > range.min) {
          return range.value;
        }
      }
      return defaultValue;
    }
  
    const ranges: { [key: string]: Array<{ min: number, max: number, value: string }> } = {
        '04': [
        { min: 991, max: 1024, value: '23.2%' },
        { min: 800, max: 991, value: '23.2%' },
        { min: 768, max: 800, value: '23%' },
        { min: 720, max: 768, value: '23%' },
        { min: 640, max: 720, value: '23%' },
        { min: 600, max: 640, value: '23%' },
        { min: 520, max: 600, value: '22%' },
        { min: 480, max: 520, value: '22%' },
        { min: 412, max: 480, value: '22%' },
        { min: 375, max: 425, value: '21%' },
        { min: 0, max: 375, value: '20%' },
      ],
      '03': [
        { min: 800, max: 991, value: '31%' },
        { min: 768, max: 800, value: '31%' },
        { min: 720, max: 768, value: '31%' },
        { min: 640, max: 720, value: '31%' },
        { min: 600, max: 640, value: '31%' },
        { min: 520, max: 600, value: '31%' },
        { min: 480, max: 520, value: '31%' },
        { min: 412, max: 480, value: '30%' },
        { min: 375, max: 425, value: '29%' },
        { min: 0, max: 375, value: '28%' },
      ],
      '02': [
        { min: 991, max: 1024, value: '48.5%' },
        { min: 800, max: 991, value: '48%' },
        { min: 768, max: 800, value: '48%' },
        { min: 720, max: 768, value: '48%' },
        { min: 640, max: 720, value: '48%' },
        { min: 600, max: 640, value: '48%' },
        { min: 520, max: 600, value: '48%' },
        { min: 480, max: 520, value: '48%' },
        { min: 412, max: 480, value: '48%' },
        { min: 375, max: 425, value: '45%' },
        { min: 0, max: 375, value: '44%' },
      ]
    };
  
    const defaultValues = {
      '04': '23.5%',
      '03': '31%',
      '02': '49%',
    };
    
    return getResponsiveWidth(ranges[coluna] || [], defaultValues[coluna]);
  }

export function colunaLargura(colunas: '01' | '02' | '03' | '04', current_width: number) {
    switch (colunas) {
        case '01':
            return '100%';
        case '02':
            return colunaResponsiva(current_width, '02');
        case '03':
            return colunaResponsiva(current_width, '03');
        case '04':
            return colunaResponsiva(current_width, '04');
        default:
            return '32.2%';
    }
};

export function agruparFotosPorTalhao(lista_fotos: ImagemBookMonitoramento__Itens[]): { nome_talhao: string; cod_talhao: number, coordenadas: {latitude:number,longitude:number}[], dados_talhao: ImagemBookMonitoramento__Itens[] }[] {
    
    let agrupar = groupBy(lista_fotos, 'talhao[nome]');
    let lista_agrupada = Object.entries(agrupar).map(([nome_talhao, dados])=> {
        return {
            cod_talhao: dados?.[0]?.cod_talhao,
            nome_talhao: nome_talhao,
            coordenadas: dados?.[0]?.talhao?.kml?.coordenadas,
            dados_talhao: dados
        }
    })

    return lista_agrupada ?? [];
}


export function obterMultiTalhoes(dados: ImagemBookMonitoramento__Itens[], nome_filtrar_talhao: string | null) {

    let agrupar = groupBy(dados, 'talhao[nome]');
    let lista_agrupada = Object.entries(agrupar).map(([nome_talhao, dados])=> {
        let rename = (dados?.[0]?.talhao?.kml?.coordenadas).map((item)=> {
            return {
                lat: item.latitude,
                lng: item.longitude,
            }
        })
        return {
            nome_talhao: nome_talhao,
            coordenadas: rename
        }
    })

    if (nome_filtrar_talhao!= null && nome_filtrar_talhao !== '') {
        let filtrar = lista_agrupada.filter((item=> item.nome_talhao === nome_filtrar_talhao));

        return filtrar;
    }

    return lista_agrupada ?? [];
}

export function obterListaFotos(lista_fotos: ImagemBookMonitoramento__Itens[], nome_filtrar_talhao: string | null) {

    if (nome_filtrar_talhao!= null && nome_filtrar_talhao !== '') {
        let filtrar = lista_fotos.filter((item=> item.talhao.nome === nome_filtrar_talhao)); 

        let estruturar = filtrar.map(({ url_arquivo, descricao })=> {
            return {
                media: url_arquivo,
                description: descricao
            }
        })

        return estruturar ?? []
    }

    let estruturar = lista_fotos.map(({ url_arquivo, descricao })=> {
        return {
            media: url_arquivo,
            description: descricao
        }
    })

    return estruturar ?? []
}


export function listaSlides(lista_fotos: ImagemBookMonitoramento__Itens[], nome_filtrar_talhao: string | null) {

    if (nome_filtrar_talhao!= null && nome_filtrar_talhao !== '') {
        let filtrar = lista_fotos.filter((item=> item.talhao.nome === nome_filtrar_talhao)); 

        let estruturar = filtrar.map(({ url_arquivo, descricao, cod })=> {
            return {
                cod: cod,
                media: url_arquivo,
                description: descricao
            }
        })

        return estruturar ?? []
    }

    let estruturar = lista_fotos.map(({ url_arquivo, descricao, cod })=> {
        return {
            cod: cod,
            media: url_arquivo,
            description: descricao
        }
    })

    return estruturar ?? []
}

export function gerarNomeDoArquivo(data_monitoramento: string, nome_cliente: string, nome_propriedade: string): string {

    let data = data_monitoramento?.split('/')?.reverse()?.join('-')
    
    return `${data}_relatório_fotográfico__cliente__${nome_cliente}__pripriedade__${nome_propriedade}`
}