import { SetStateAction, useState } from 'react'
import { HeaderDrawer } from '../CustomUi/headerGaveta'
import Input from '../CustomUi/input'
import Carregando from '../CustomUi/loading'
import { Render } from '../CustomUi/render'
import { Select } from '../CustomUi/Select'
import { BodyDrawer } from './styles'
import { SelectMulti } from '../CustomUi/SelectMulti'
import Tabs from '../Tabs'
import { faFlask, faSeedling } from '@fortawesome/free-solid-svg-icons'
import { FormDadosFertilizante } from './components/formDadosFertilizante'
import { FormElementos } from './components/formElementos'
import { ParamsFormulacao, ParamsMarca } from '../../services/fertilizante/GET'

interface PropsFormFertilizante {
  loadingForm: boolean
  update: number
  tab_selecionar_atual: string
  setTab_selecionar_atual: React.Dispatch<React.SetStateAction<string>>
  nome: string
  setNome: React.Dispatch<React.SetStateAction<string>>
  observacao: string
  setObservacao: React.Dispatch<React.SetStateAction<string>>
  extratoAlga: string
  setExtratoAlga: React.Dispatch<React.SetStateAction<string>>
  densidade: string
  setDensidade: React.Dispatch<React.SetStateAction<string>>
  aminoacido: string
  setAminoacido: React.Dispatch<React.SetStateAction<string>>
  indice_salino: string
  setIndice_salino: React.Dispatch<React.SetStateAction<string>>
  aplicaçoes: { descr: string; id: number; key?: number }[]
  setAplicaçoes: React.Dispatch<React.SetStateAction<{ descr: string; id: number; key?: number }[]>>
  formulacao: string
  setFormulacao: React.Dispatch<React.SetStateAction<string>>
  setFormulacaoId: React.Dispatch<React.SetStateAction<number>>
  marca: string
  setMarca: React.Dispatch<React.SetStateAction<string>>
  setMarcaId: React.Dispatch<React.SetStateAction<number>>
  nitrogenio: string
  setNitrogenio: React.Dispatch<React.SetStateAction<string>>
  fosforo: string
  setFosforo: React.Dispatch<React.SetStateAction<string>>
  potassio: string
  setPotassio: React.Dispatch<React.SetStateAction<string>>
  calcio: string
  setCalcio: React.Dispatch<React.SetStateAction<string>>
  magnesio: string
  setMagnesio: React.Dispatch<React.SetStateAction<string>>
  enxofre: string
  setEnxofre: React.Dispatch<React.SetStateAction<string>>
  boro: string
  setBoro: React.Dispatch<React.SetStateAction<string>>
  cobre: string
  setCobre: React.Dispatch<React.SetStateAction<string>>
  ferro: string
  setFerro: React.Dispatch<React.SetStateAction<string>>
  manganes: string
  setManganes: React.Dispatch<React.SetStateAction<string>>
  zinco: string
  setZinco: React.Dispatch<React.SetStateAction<string>>
  cobalto: string
  setCobalto: React.Dispatch<React.SetStateAction<string>>
  molibdenio: string
  setMolibdenio: React.Dispatch<React.SetStateAction<string>>
  niquel: string
  setNiquel: React.Dispatch<React.SetStateAction<string>>
  silicio: string
  setSilicio: React.Dispatch<React.SetStateAction<string>>
  selenio: string
    setSelenio: React.Dispatch<React.SetStateAction<string>>
  data_aplicacoes: any[]
  data_formulacao: ParamsFormulacao[]
  data_marca: ParamsMarca[]
}

const FormFertilizante: React.FC<PropsFormFertilizante> = ({
  loadingForm,
  update,
  tab_selecionar_atual,
  setTab_selecionar_atual,
  nome,
  setNome,
  observacao,
  setObservacao,
  extratoAlga,
  setExtratoAlga,
  densidade,
  setDensidade,
  aminoacido,
  setAminoacido,
  indice_salino,
  setIndice_salino,
  aplicaçoes,
  setAplicaçoes,
  formulacao,
  setFormulacao,
  setFormulacaoId,
  marca,
  setMarca,
  setMarcaId,
  nitrogenio,
  setNitrogenio,
  fosforo,
  setFosforo,
  potassio,
  setPotassio,
  calcio,
  setCalcio,
  magnesio,
  setMagnesio,
  enxofre,
  setEnxofre,
  boro,
  setBoro,
  cobre,
  setCobre,
  ferro,
  setFerro,
  manganes,
  setManganes,
  zinco,
  setZinco,
  cobalto,
  setCobalto,
  molibdenio,
  setMolibdenio,
  niquel,
  setNiquel,
  silicio,
  setSilicio,
  selenio,
  setSelenio,
  data_aplicacoes,
  data_formulacao,
  data_marca
}) => {
  return (
    <>
      <HeaderDrawer>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {update > 0 ? 'Atualizar fertilizante' : 'Cadastrar fertilizante'}

          {/* <small style={{ opacity: '0.5', fontSize: '9px' }}>{criadoPorAtualizadoPor}</small> */}
        </div>
      </HeaderDrawer>
      <BodyDrawer>
        <Render condition={loadingForm}>
          <Carregando
            animation
            justifyContent='center'
            height={'500px'}
            legenda='Carregando informações...'
          />
        </Render>
        <Render condition={loadingForm === false}>
          <Tabs
            tabs={[
              { descricao: 'Dados do fertilizante', icone: faSeedling },
              { descricao: 'Elementos', icone: faFlask },
            ]}
            selecionado={tab_selecionar_atual}
            trackWidth={250}
            onChange={setTab_selecionar_atual}
          />
          {tab_selecionar_atual === 'Dados do fertilizante' && (
            <FormDadosFertilizante
              nome={nome}
              setNome={setNome}
              observacao={observacao}
              setObservacao={setObservacao}
              extratoAlga={extratoAlga}
              setExtratoAlga={setExtratoAlga}
              densidade={densidade}
              setDensidade={setDensidade}
              aminoacido={aminoacido}
              setAminoacido={setAminoacido}
              indice_salino={indice_salino}
              setIndice_salino={setIndice_salino}
              aplicaçoes={aplicaçoes}
              setAplicaçoes={setAplicaçoes}
              formulacao={formulacao}
              setFormulacao={setFormulacao}
              setFormulacaoId={setFormulacaoId}
              marca={marca}
              setMarca={setMarca}
              setMarcaId={setMarcaId}
              data_aplicacoes={data_aplicacoes}
              data_formulacao={data_formulacao}
              data_marca={data_marca}
            />
          )}

          {tab_selecionar_atual === 'Elementos' && (
            <FormElementos
              nitrogenio={nitrogenio}
              setNitrogenio={setNitrogenio}
              fosforo={fosforo}
              setFosforo={setFosforo}
              potassio={potassio}
              setPotassio={setPotassio}
              calcio={calcio}
              setCalcio={setCalcio}
              magnesio={magnesio}
              setMagnesio={setMagnesio}
              enxofre={enxofre}
              setEnxofre={setEnxofre}
              boro={boro}
              setBoro={setBoro}
              cobre={cobre}
              setCobre={setCobre}
              ferro={ferro}
              setFerro={setFerro}
              manganes={manganes}
              setManganes={setManganes}
              zinco={zinco}
              setZinco={setZinco}
              cobalto={cobalto}
              setCobalto={setCobalto}
              molibdenio={molibdenio}
              setMolibdenio={setMolibdenio}
              niquel={niquel}
              setNiquel={setNiquel}
              silicio={silicio}
              setSilicio={setSilicio}
              selenio={selenio}
              setSelenio={setSelenio}
            />
          )}
        </Render>
      </BodyDrawer>
    </>
  )
}

export default FormFertilizante
