import { api } from '../../../api';
import { useQuery } from 'react-query';
import { localConfig } from '../../../../Configs/localConfig';
import { errorAlert } from '../../../../utils/alert';
import { removerEspacos } from '../../../../utils';

//=================================================================================>
// BUSCAR LISTA DE ATIVOS CADASTRADOS
//<=================================================================================

//TYPES
type ImagemMonitoramento__Itens = {
    cod: number | null;
    cod_monitoramento: number | null;
    arquivo: string | null;
    descricao: string | null;
    create_time: string | null;
    update_time: string | null;
    delete_time: string | null;
    create_user: string | null;
    update_user: string | null;
    delete_user: string | null;
    url_arquivo: string | null;
}

//INTERFACE
export interface ImagemMonitoramento {
    itens: ImagemMonitoramento__Itens[],
    total?: number | null,
    mensagem?: string | null,
}

export const useGetImagemPontoMonitoramento = (codMonitoramento: number, triggerError?: Function) => {
    const queryKey: any = [`monitoramento-imagem_${codMonitoramento}`];

    const { conta, codCliente, codSafra } = localConfig();

    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<ImagemMonitoramento, Error>(queryKey, async () => {
        
        return api.get(`monitoramento-imagem?codConta=${conta}&codCliente=${codCliente}&codSafra=${codSafra}&codMonitoramento=${codMonitoramento}&pagination=-1`)
            .then((response):any => {
                triggerError?.(false);
                return response.data
            })
            .catch(() => {
                errorAlert('Falha ao obter lista de ativos!');
                triggerError?.(true);
            })
    },
        {
            enabled: true,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_imagem: data?.itens ?? [], 
        carregando_imagem: isFetching, 
        refetch_imagem: refetch,
        isFetched_aimgem: isFetchedAfterMount
    };
};


//=================================================================================>
// BUSCAR LISTA DE ATIVOS CADASTRADOS
//<=================================================================================

//TYPES
export type ImagemBookMonitoramento__Itens = {
    cod: number | null,
    cod_monitoramento:  number | null,
    arquivo:  string | null,
    descricao: null,
    hora: string | null,
    cod_talhao:  number | null,
    url_arquivo: string | null,
    talhao: {
                cod:  number | null,
                nome:string | null,
                kml: { nome: "", lookAt: { latitude:  number, longitude: number }, coordenadas: { latitude: number, longitude: number }[] },
                propriedade_nome: "ETN. PEROBA ROSA; FAZ. TRÊS IRMÃOS.",
                cod_cliente:  number | null,
            },
    monitoramento: {
        cod:  number | null,
        data_inicio: string | null,
        data_final: string | null,
        ponto:  number | null,
        longitude: string | null,
        latitude: string | null,
    }
}

//INTERFACE
export interface ImagemBookMonitoramento {
    itens: ImagemBookMonitoramento__Itens[],
    total?: number | null,
    mensagem?: string | null,
}

export const useGetImagensBookMonitoramento = (dataMonitoramento: string, codPropriedade: number, triggerError?: Function) => {

    const { conta, codCliente, codSafra } = localConfig();
    
    const queryKey: any = [`book-monitoramento-imagem_${codCliente}_${removerEspacos(dataMonitoramento)}`];


    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<ImagemBookMonitoramento, Error>(queryKey, async () => {
        
        return api.get(`monitoramento-imagem?codConta=${conta}&codCliente=${codCliente}
            &dataInicio=${dataMonitoramento}&codSafra=${codSafra}&codPropriedade=${codPropriedade}&embed=monitoramento,talhao&pagination=-1`)
            .then((response):any => {
                triggerError?.(false);
                return response.data
            })
            .catch(() => {
                errorAlert('Falha ao obter imagens do monitormanto!');
                triggerError?.(true);
            })
    },
        {
            enabled: false,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_imagens_book: data?.itens ?? [], 
        carregando_imagens_book: isFetching, 
        refetch_imagens_book: refetch,
        isFetched_imagens_book: isFetchedAfterMount
    };
};
