import { orderBy } from "lodash";
import { Item__Resumo } from "../../../../../../../services/monitoramento/resumo/get";
import { TypeEstrutura } from "../../../../../talhaoSafra/components/painelTodasPropriedades/meta";
import { localConfig } from "../../../../../../../Configs/localConfig";

export function gerarEstruturaExcel(dados_monitoramento: Item__Resumo[]): TypeEstrutura[] {
    
    let ordenar_por_talhao = orderBy(dados_monitoramento, ['cod_talhao'], ['asc']);
    let { nomeSafra } = localConfig();
    let is_safra_milho = String(nomeSafra).toLowerCase().includes('milho');

    if (is_safra_milho) {
        let linha_dados: any = (ordenar_por_talhao??[]).map(({ talhao, especie, variacao, colmo, pontos, tipo_metrica_nome, minimo, media, maximo }) => [
            talhao, especie, variacao, colmo, pontos, tipo_metrica_nome, (Number(minimo).toFixed(2)), (Number(media).toFixed(2)), (Number(maximo).toFixed(2))
        ]);
    
        let tabela: TypeEstrutura[] = [
            {
                planilha: {
                    columns: [{name: 'Talhão'}, {name: 'Praga'}, {name: 'Variação'}, {name: 'colmo'}, {name: 'Pontos'}, {name: 'Métrica'}, {name: 'Mínimo'}, {name: 'Média'}, {name: 'Máximo'}],
                    rows: linha_dados ?? []
                }
            }
        ];
    
        return tabela;
    }

    let linha_dados: any = (ordenar_por_talhao??[]).map(({ talhao, especie, variacao, pontos, tipo_metrica_nome, minimo, media, maximo }) => [
        talhao, especie, variacao, pontos, tipo_metrica_nome, (Number(minimo).toFixed(2)), (Number(media).toFixed(2)), (Number(maximo).toFixed(2))
    ]);

    let tabela: TypeEstrutura[] = [
        {
            planilha: {
                columns: [{name: 'Talhão'}, {name: 'Praga'}, {name: 'Variação'}, {name: 'Pontos'}, {name: 'Métrica'}, {name: 'Mínimo'}, {name: 'Média'}, {name: 'Máximo'}],
                rows: linha_dados ?? []
            }
        }
    ];

    return tabela;
}

function typeColor(valor: number, nivel_controle: number, is_invertido: boolean) {

    const porcentagem = (valor * 100) / nivel_controle;
    
    if (is_invertido) {
        if (porcentagem === 0) {
            return {bg: 'FCFF02', color: 'af5400'};
        }
        if (porcentagem > 0 && porcentagem < 100) {
            return {bg: '63FF08', color: '004d25'};
        }
        if (porcentagem >= 100 && porcentagem < 300) {
            return {bg: '24e92b', color: '004d25'};
        }
        if (porcentagem >= 300) {
            return {bg: '01FF00', color: '004d25'};
        }
        else {
            return {bg: '01FF00', color: 'FFFFFF'};
        }
    }
    if (porcentagem === 0) {
        // return {bg: 'FFFFFF', color: '000000'};
        return {bg: '01FF00', color: '004d25'};
    }
    // if (valor > 0 && valor < nivel_controle) {
    if (porcentagem > 0 && porcentagem < 100) {
        return {bg: 'FCFF02', color: 'af5400'};
    }
    if (porcentagem >= 100 && porcentagem < 300) {
        return {bg: 'FCB700', color: '8c3100'};
    }
    if (porcentagem >= 300) {
        return {bg: 'FB0303', color: 'FFFFFF'};
    }
    else {
        return {bg: '01FF00', color: '004d25'};
    }
}

export function pintarCelulaPlanilha(dados_monitoramento: Item__Resumo[]) {
    
    let linha_inicio = 6;

    let ordenar_por_talhao = orderBy(dados_monitoramento, ['cod_talhao'], ['asc']);

    let pintar_coluna_minimo = (ordenar_por_talhao??[]).map(({minimo, nivel_controle, inverter_escala}, index)=> {
        
        let { nomeSafra } = localConfig();
        let is_safra_milho = String(nomeSafra).toLowerCase().includes('milho');
        let posicao_celula = is_safra_milho ? 'G' : 'F'
        
        return {
            posicao: posicao_celula+(linha_inicio + index),
            background: typeColor(Number(minimo), Number(nivel_controle), Boolean(inverter_escala)).bg,
            color: typeColor(Number(minimo), Number(nivel_controle), Boolean(inverter_escala)).color
        }
    })
    let pintar_coluna_media = (ordenar_por_talhao??[]).map(({media, nivel_controle, inverter_escala}, index)=> {
        
        let { nomeSafra } = localConfig();
        let is_safra_milho = String(nomeSafra).toLowerCase().includes('milho');
        let posicao_celula = is_safra_milho ? 'H' : 'G'
        
        return {
            posicao: posicao_celula+(linha_inicio + index),
            background: typeColor(Number(media), Number(nivel_controle), Boolean(inverter_escala)).bg,
            color: typeColor(Number(media), Number(nivel_controle), Boolean(inverter_escala)).color
        }
    })
    let pintar_coluna_maximo = (ordenar_por_talhao??[]).map(({maximo, nivel_controle, inverter_escala}, index)=> {
        
        let { nomeSafra } = localConfig();
        let is_safra_milho = String(nomeSafra).toLowerCase().includes('milho');
        let posicao_celula = is_safra_milho ? 'I' : 'H'
        
        return {
            posicao: posicao_celula+(linha_inicio + index),
            background: typeColor(Number(maximo), Number(nivel_controle), Boolean(inverter_escala)).bg,
            color: typeColor(Number(maximo), Number(nivel_controle), Boolean(inverter_escala)).color
        }
    })


    return [...pintar_coluna_minimo, ...pintar_coluna_media, ...pintar_coluna_maximo]

}