import { groupBy, orderBy } from "lodash";
import { Item__Resumo } from "../../../../../../../services/monitoramento/resumo/get";



function obterCorResumo(valor: number, nivel_controle: number, is_invertido: boolean) {

    const porcentagem = (valor * 100) / nivel_controle;

        if (is_invertido) {
            if (porcentagem === 0) {
                return 'yellow';
            }
            if (porcentagem > 0 && porcentagem < 100) {
                return 'green';
            }
            if (porcentagem >= 100 &&  porcentagem < 300) {
                return 'green';
            }
            if (porcentagem >= 300) {
                return 'green';
            }
            else {
                return 'green';
            }
        }
        if (porcentagem === 0) {
            return 'green';
        }
        if (porcentagem > 0 && porcentagem < 100) {
            return 'yellow';
        }
        if (porcentagem >= 100 &&  porcentagem < 300) {
            return 'orange';
        }
        if (porcentagem >= 300) {
            return 'red';
        }
        else {
            return 'green';
        }

}

function pontuarCorResumo(valor: number, nivel_controle: number, is_invertido: boolean) {

    const porcentagem = (valor * 100) / nivel_controle;

        if (is_invertido) {
            if (porcentagem === 0) {
                return 3;
            }
            if (porcentagem > 0 && porcentagem < 100) {
                return 0;
            }
            if (porcentagem >= 100 &&  porcentagem < 300) {
                return 0;
            }
            if (porcentagem >= 300) {
                return 0;
            }
            else {
                return 0;
            }
        }
        if (porcentagem === 0) {
            return 0;
        }
        if (porcentagem > 0 && porcentagem < 100) {
            return 1;
        }
        if (porcentagem >= 100 &&  porcentagem < 300) {
            return 3;
        }
        if (porcentagem >= 300) {
            return 20;
        }
        else {
            return 0;
        }

}

function obterQtdCores(cor:string, dados: any[]) {
    if (cor==='green') {
        let qtd_minimo = (dados.filter(cor => cor?.cor_minimo==='green')).length;
        let qtd_media = (dados.filter(cor => cor?.cor_media==='green')).length;
        let qtd_maximo = (dados.filter(cor => cor?.cor_maximo==='green')).length;
        
        return Number(qtd_minimo) + Number(qtd_media) + Number(qtd_maximo);
    }
    if (cor==='yellow') {
        let qtd_minimo = (dados.filter(cor => cor?.cor_minimo==='yellow')).length;
        let qtd_media = (dados.filter(cor => cor?.cor_media==='yellow')).length;
        let qtd_maximo = (dados.filter(cor => cor?.cor_maximo==='yellow')).length;
        
        return Number(qtd_minimo) + Number(qtd_media) + Number(qtd_maximo);
    }
    if (cor==='orange') {
        let qtd_minimo = (dados.filter(cor => cor?.cor_minimo==='orange')).length;
        let qtd_media = (dados.filter(cor => cor?.cor_media==='orange')).length;
        let qtd_maximo = (dados.filter(cor => cor?.cor_maximo==='orange')).length;
        
        return Number(qtd_minimo) + Number(qtd_media) + Number(qtd_maximo);
    }
    if (cor==='red') {
        let qtd_minimo = (dados.filter(cor => cor?.cor_minimo==='red')).length;
        let qtd_media = (dados.filter(cor => cor?.cor_media==='red')).length;
        let qtd_maximo = (dados.filter(cor => cor?.cor_maximo==='red')).length;
        
        return Number(qtd_minimo) + Number(qtd_media) + Number(qtd_maximo);
    }
}

export function processarDatasMonitoramento(dados_monitoramento: Item__Resumo[]) {

    //1° etapa - add ao array a cor de cada valor apurado
    let obter_cores = (dados_monitoramento ?? []).map((item)=> {
        return {
            ...item,
            minimo: Number(item.minimo),
            media: Number(item.media),
            maximo: Number(item.maximo),
            cor_minimo: obterCorResumo(Number(item.minimo), Number(item.nivel_controle), Boolean(item.inverter_escala)),
            cor_media: obterCorResumo(Number(item.media), Number(item.nivel_controle), Boolean(item.inverter_escala)),
            cor_maximo: obterCorResumo(Number(item.maximo), Number(item.nivel_controle), Boolean(item.inverter_escala)),
        }
    });
   
    //2° etapa - Agrupa os dados por data de monitoramento
    let agrupar_por_data = groupBy(obter_cores, 'data_inicio');

    //3° etapa - Transforma o array agrupado em um novo array de objetos para facilitar a manipulação
    let listar_dados_por_data = (Object.entries(agrupar_por_data) ?? []).map(([data, dados])=> {
        return {
            data_monitoramento: data,
            dados: dados
        }
    })

    //4° etapa - conta a quantidade de cores
    let quantificar_cores = (listar_dados_por_data ?? []).map(({data_monitoramento, dados})=> {
        return {
            data_monitoramento: data_monitoramento,
            dados: dados,
            qtd_green: obterQtdCores('green', dados),
            qtd_yellow: obterQtdCores('yellow', dados),
            qtd_orange: obterQtdCores('orange', dados),
            qtd_red: obterQtdCores('red', dados),
        }
    })

    return orderBy(quantificar_cores, ['data_monitoramento'],  ['desc']) ?? [];
}


export function processarMonitoramentoSelecionado(dados_monitoramento: Item__Resumo[]) {
    
    let agrupar_por_talhao = groupBy(dados_monitoramento, 'talhao');
    let listar_talhoes = (Object.entries(agrupar_por_talhao) ?? []).map(([talhao, dados_moni_talhao])=> {
        return {
            nome_talhao: talhao,
            cod_talhao: dados_moni_talhao?.[0]?.cod_talhao,
            dados_talhao: dados_moni_talhao
        }
    })
    
    return listar_talhoes ?? []

}


//TYPES
type TypeDadosTalhao = {
    especie: string,
    qtd_variacoes: number,
    pontos: number,
    tipo_metrica_nome: string,
    minimo: number,
    media: number,
    maximo: number,
    nivel_controle: number,
    inverter_escala: 0 | 1,
    lista_variacoes: Item__Resumo[],
    variacao?: string,
    colmo?: string,
}

export function listarDadosTalhao(dados_talhao: Item__Resumo[], is_agrupado_por_variacao: boolean): TypeDadosTalhao[] {

    if ((dados_talhao??[]).length > 0 && is_agrupado_por_variacao) {

        let agrupar = groupBy(dados_talhao, 'especie');

        let montar_lista_agrupada = (Object?.entries(agrupar) ?? []).map(([nome_especie, dados_agrupados])=> {
            return {
                especie: nome_especie,
                variacao: undefined,
                qtd_variacoes: dados_agrupados.length,
                pontos: dados_agrupados.reduce((acumulador: number, item: any)=> acumulador + item.pontos, 0),
                tipo_metrica_nome: dados_agrupados?.[0]?.tipo_metrica_nome,
                minimo: Math.min(...dados_agrupados.map(item => Number(item.minimo))),
                media: (dados_agrupados.reduce((acumulador: number, item: any)=> acumulador + item.media, 0)) / dados_agrupados.length,
                maximo: Math.max(...dados_agrupados.map(item => Number(item.maximo))),
                nivel_controle: dados_agrupados.reduce((acumulador: number, item: any)=> acumulador + Number(item.nivel_controle), 0),
                inverter_escala: dados_agrupados.reduce((acumulador: boolean, item: any)=> acumulador + item.inverter_escala, false),
                lista_variacoes: dados_agrupados,
            }
        })

        return montar_lista_agrupada;
    }

    let montar_lista_normal = (dados_talhao??[]).map((dado)=> {
        return {
            especie: dado?.especie,
            variacao: dado?.variacao,
            qtd_variacoes: 0,
            pontos: dado?.pontos,
            tipo_metrica_nome: dado?.tipo_metrica_nome,
            minimo: Number(dado?.minimo),
            media: Number(dado?.media),
            maximo: Number(dado?.maximo),
            nivel_controle: Number(dado?.nivel_controle),
            inverter_escala: dado?.inverter_escala,
            lista_variacoes: [],
            colmo: dado?.colmo
        }
    })

    return montar_lista_normal;
}

export function ordenarDadosResumo(dados) {

    let pontuar = dados.map((item)=> {

        let pontuar_min = pontuarCorResumo(Number(item.minimo), Number(item.nivel_controle), Boolean(item?.inverter_escala));
        let pontuar_med = pontuarCorResumo(Number(item.media), Number(item.nivel_controle), Boolean(item?.inverter_escala));
        let pontuar_max = pontuarCorResumo(Number(item.maximo), Number(item.nivel_controle), Boolean(item?.inverter_escala));
        
        let pontuacao_geral = Number(pontuar_min??0) + Number(pontuar_med??0) + Number(pontuar_max??0);

        return {
            ...item,
            pontuacao_geral: pontuacao_geral
        }
    })

    let ordenar = orderBy(pontuar, ['pontuacao_geral', 'media'], ['desc','desc'])

    return ordenar;
}