import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'
import { TalhaoItens } from '../Talhao'
import { TalhaoCultivaveisItens } from '../TalhaoSafra'
import { message } from 'antd'
import { useMutation, UseMutationResult, useQuery } from 'react-query'

export interface TypeGetPropriedade {
  itens: [PropriedadeItens]
  total: number
}

interface GetParamsPontosObs {
  codConta?: number | string
  codCliente?: number | string
  codPropriedade?: number | string
  codTalhao?: number | string
  cod?: number | string
  codSafra?: number | string
  embed?: string
}

interface GetPontosObservacao {
  itens: [PropriedadePontosItens]
}

interface PropriedadePontosItens {
  cod: number
  cod_propriedade: number
  cod_talhao: number
  longitude: string
  latitude: string
  observacoes: [ItensObservacao]
  tipo: number
  titulo: string
  create_user: string
  update_user: null
  create_time: string
  update_time: null
  delete_time: null
  tipo_nome: string
}

export interface ItensObservacao {
  cod: number
  arquivo: string
  cod_ponto_obs: number
  mensagem: string
  url_arquivo: string
}

export interface PropriedadeItens {
  cod?: number
  nome: string
  status: number
  // 1: proprio / 2:Arendado
  cod_tipo?: 1 | 2
  cod_conta: number
  cod_cliente: number
  cod_calcario?: number
  arquivo?: string
  cliente_nome?: string
  area?: number
  area_total_talhao_safra?: number
  cidade?: {
    cod: number
    cod_uf: number
    codigo: number
    nome: string
    status: 1 | 0
    uf: string
    uf_nome: string
    uf_sigla: string
  }
  matricula?: string
  ccir?: string
  itr?:string
  nirf?: string
  inscricao_estadual?: string
  
  cod_cidade?: number
  cod_consultor?: number
  arquivo_url?: string
  arquivo_base64?: string
  talhao: TalhaoItens[]
  nameCity?: string
}
export interface PropriedadeItensMoni {
  cod?: number
  nome: string
  status: number
  // 1: proprio / 2:Arendado
  cod_tipo?: 1 | 2
  cod_conta: number
  cod_cliente: number
  arquivo?: string
  cliente_nome?: string
  area?: number
  cidade?: {
    cod: number
    cod_uf: number
    codigo: number
    nome: string
    status: 1 | 0
    uf: string
    uf_nome: string
    uf_sigla: string
  }
  matricula?: string
  cod_cidade?: number
  arquivo_url?: string
  arquivo_base64?: string
  talhao: TalhaoCultivaveisItens[]
  nameCity?: string
}

interface ItensCadastrarObs {
  arquivo: string
  mensagem: string
  data_hora: string
}

interface ParamsCadastrarObs {
  cod: number
  observacoes: ItensCadastrarObs[]
  arquivos: any[]
  limparCamposObs: Function
}

export const getPropriedade = async (
  codPropriedade,
  embed = '',
  codCliente = null,
  codSafra = null,
  areaTotalTalhaoNaSafra = null,
  nome?: string,
  page?: number,
  pagination?: number
) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso ? config.acesso : 'conta'

  let url = `/propriedade?codConta=${config.conta}&codCliente=${
    codCliente === null ? config.codCliente : codCliente
  }&cod=${codPropriedade}&embed=${embed}&pagination=${
    pagination !== undefined ? pagination : -1
  }&page=${page}`

  if (areaTotalTalhaoNaSafra !== null) {
    url += `&areaTotalTalhaoNaSafra=${codSafra === null ? config.codSafra : codSafra}`
  }

  if (nome !== undefined) {
    url += `&nome=${nome}`
  }

  if (codSafra !== null) {
    url += `&comTalhaoSafra=${codSafra === null ? config.codSafra : codSafra}`
  } else {
    url += `&comTalhaoSafra=${' '}`
  }

  const response: AxiosResponse<TypeGetPropriedade> = await api.get(url)

  return response
}


export const useGetPontosObs = (params: GetParamsPontosObs, triggerError?: Function) => {
  const { codCliente, codConta, codPropriedade, codSafra} = params
  const queryKey: any = [`ponto-observacao_${codConta}_${codCliente}_${codPropriedade}_${codSafra}`]

  const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<GetPontosObservacao, Error>(
    queryKey,
    async () => {
      return api
        .get('ponto-observacao', { params })
        .then((response): any => {
          triggerError?.(false)
          return response.data
        })
        .catch(() => {
          message.error('Falha ao obter pontos de observação!')
          triggerError?.(true)
        })
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_pontos_obs: data?.itens ?? [],
    carregando_pontos_obs: isFetching,
    refetch_pontos_obs: refetch,
    isFetched_pontos_obs: isFetchedAfterMount,
  }
}

export const getPropriedadeLiberada = async (
  codUsuarioPropriedade = null,
  codCliente = null,
  codClientePropriedade = null
) => {
  const config = localConfig()

  const { conta } = config
  const codLocal = config.codCliente

  if (codLocal === '') {
    codCliente = ''
  } else {
    codClientePropriedade = null
    codCliente = codLocal
  }

  api.defaults.headers.Acesso = config.acesso

  const resp: AxiosResponse<TypeGetPropriedade> = await api.get('/propriedade-liberada-usuario', {
    params: {
      codConta: conta,
      codCliente,
      codClientePropriedade,
      pagination: -1,
      codUsuarioPropriedade,
    },
  })

  return resp
}

export const postPropriedade = async (data) => {
  const config = localConfig()

  const response: AxiosResponse<TypeGetPropriedade> = await api.post(
    `/propriedade?codConta=${config.conta}&codCliente=${config.codCliente}`,
    data
  )

  return response
}

export const useCadastrarObs = (): {
  carregando_cadastrar_obs: boolean
  error_cadastrar_obs: any
  mudateCadastrarObs: (params: ParamsCadastrarObs) => void
} => {
  const mutation: UseMutationResult<Response, Error, ParamsCadastrarObs> = useMutation(
    async (params: ParamsCadastrarObs) => {
      const { cod, arquivos, observacoes, limparCamposObs } = params

      const config = localConfig()

      // message.loading({ content: 'Excluindo...', key: 'loading' });

      return api
        .post(
          `/ponto-observacao/${cod}?codConta=${config.conta}&codCliente=${config.codCliente}`,
          {
            observacoes,
            arquivo: arquivos[0]?.originFileObj,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then((response: any) => {
          message.success({ content: 'Observação cadastrada com sucesso!', key: 'loading' })
          limparCamposObs()
          return response
        })
        .catch((error: Error) => {
          //@ts-ignore
          let error_response = error?.response?.data
          // message.destroy('loading');
          message.error({
            content: error_response?.error ?? 'Falha ao editar engrenagem',
            key: 'loading',
          })

          throw error
        })
    }
  )

  return {
    carregando_cadastrar_obs: mutation.isLoading,
    //@ts-ignore
    error_editar_engrenagem: mutation.error?.response?.data,
    mudateCadastrarObs: mutation.mutate,
  }
}

export const putPropriedade = async (codCliente, cod, data, pagination = -1) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeGetPropriedade> = await api.put(
    `/propriedade/${cod}?codConta=${config.conta}&codCliente=${codCliente}&pagination=${pagination}`,
    data
  )

  return response
}

export const getDocPropriedade = async (cod, file) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse = await api.get(
    `/propriedade/${cod}/visualizar/arquivo/${file}?codConta=${config.conta}&codCliente=${config.codCliente}`
  )

  return response
}
export const delPropriedade = async (cod, codCliente, itempro) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetPropriedade> = await api.delete(
    `/propriedade/${cod}?codConta=${config.conta}&codCliente=${codCliente}`
  )

  return response
}