import { useMutation, UseMutationResult } from 'react-query'
import { errorAlert, loadingAlert, successAlert } from '../../../utils/alert'
import { localConfig } from '../../../Configs/localConfig'
import { api } from '../../api'

interface PropsPostFertilizantes {
  nome: string
  observacao: string
  densidade: number | string
  aminoacido: number | string
  indice_salino: number | string
  extrato_de_alga: number | string
  aplicacoes: number[]
  cod_formulacao: number
  cod_marca: number
  n: number | string
  p: number | string
  k: number | string
  ca: number | string
  mg: number | string
  s: number | string
  b: number | string
  cu: number | string
  fe: number | string
  mn: number | string
  zn: number | string
  co: number | string
  mo: number | string
  ni: number | string
  si: number | string
  se: number | string
  refetch: Function
}

export const usePostFertilizante = (): {
  carregando_salvar_fertilizante: boolean
  mutate_fertilizante: (params: PropsPostFertilizantes) => void
  isSuccess_fertilizante: boolean
} => {
  const mutation: UseMutationResult<Response, Error, PropsPostFertilizantes> = useMutation(
    async (params: PropsPostFertilizantes) => {
      const {
        nome,
        observacao,
        densidade,
        aminoacido,
        indice_salino,
        extrato_de_alga,
        aplicacoes,
        cod_formulacao,
        cod_marca,
        n,
        p,
        k,
        ca,
        mg,
        s,
        b,
        cu,
        fe,
        mn,
        zn,
        co,
        mo,
        ni,
        si,
        se,
        refetch,
      } = params

      loadingAlert({ msg: 'Salvando...', id: 'loading' })

      return api
        .post(`/fertilizante?codConta=${localConfig()?.conta}`, {
          nome: nome,
          observacao: observacao,
          densidade: densidade,
          aminoacido: aminoacido,
          indice_salino: indice_salino,
          extrato_de_alga: extrato_de_alga,
          aplicacoes: aplicacoes,
          cod_formulacao: cod_formulacao,
          cod_marca: cod_marca,
          n: n,
          p: p,
          k: k,
          ca: ca,
          mg: mg,
          s: s,
          b: b,
          cu: cu,
          fe: fe,
          mn: mn,
          zn: zn,
          co: co,
          mo: mo,
          ni: ni,
          si: si,
          se: se,
        })
        .then((response: any) => {
          successAlert('Fertilizante cadastrado com sucesso!', 5000, 'loading')
          refetch?.()
          return response
        })
        .catch((error: any) => {
          if (error?.response?.data?.error[0]?.msg) {
            console.log('IF')
            error.response.data.error.forEach((data) => {
              errorAlert(data?.msg[0] ?? 'Falha ao salvar fertilizante!', 5000, 'loading')
            })
          } else {
            console.log('ELSE')
            errorAlert(
              error.response.data.error ?? 'Falha ao salvar fertilizante!',
              5000,
              'loading'
            )
          }

          throw error
        })
    }
  )

  return {
    carregando_salvar_fertilizante: mutation.isLoading,
    mutate_fertilizante: mutation.mutate,
    isSuccess_fertilizante: mutation.isSuccess,
  }
}

export const usePutFertilizante = (
  cod: number
): {
  carregando_edit_fertilizante: boolean
  mutate_edit_fertilizante: (params: PropsPostFertilizantes) => void
  isSuccess_edit_fertilizante: boolean
} => {
  const mutation: UseMutationResult<Response, Error, PropsPostFertilizantes> = useMutation(
    async (params: PropsPostFertilizantes) => {
      const {
        nome,
        observacao,
        densidade,
        aminoacido,
        indice_salino,
        extrato_de_alga,
        aplicacoes,
        cod_formulacao,
        cod_marca,
        n,
        p,
        k,
        ca,
        mg,
        s,
        b,
        cu,
        fe,
        mn,
        zn,
        co,
        mo,
        ni,
        si,
        se,
        refetch,
      } = params

      loadingAlert({ msg: 'Salvando...', id: 'loading' })

      return api
        .post(`/fertilizante/${cod}?codConta=${localConfig()?.conta}`, {
          nome: nome,
          observacao: observacao,
          densidade: densidade,
          aminoacido: aminoacido,
          indice_salino: indice_salino,
          extrato_de_alga: extrato_de_alga,
          aplicacoes: aplicacoes,
          cod_formulacao: cod_formulacao,
          cod_marca: cod_marca,
          n: n,
          p: p,
          k: k,
          ca: ca,
          mg: mg,
          s: s,
          b: b,
          cu: cu,
          fe: fe,
          mn: mn,
          zn: zn,
          co: co,
          mo: mo,
          ni: ni,
          si: si,
          se: se,
        })
        .then((response: any) => {
          successAlert('Fertilizante atualizado com sucesso!', 5000, 'loading')
          refetch?.()
          return response
        })
        .catch((error: any) => {
          errorAlert(
            error?.response?.data?.error ?? 'Falha ao editar a fertilizante!',
            5000,
            'loading'
          )
          throw error
        })
    }
  )

  return {
    carregando_edit_fertilizante: mutation.isLoading,
    mutate_edit_fertilizante: mutation.mutate,
    isSuccess_edit_fertilizante: mutation.isSuccess,
  }
}
