
export function larguraResponsiva(
    current_width: number, 
    default_width: number, 
    field: string
  ): number {
    
    function getResponsiveWidth(ranges: Array<{ min: number, max: number, value: number }>, defaultValue: number): number {
      for (const range of ranges) {
        if (current_width <= range.max && current_width > range.min) {
          return range.value;
        }
      }
      return defaultValue;
    }
  
    const ranges: { [key: string]: Array<{ min: number, max: number, value: number }> } = {
        nome_da_propriedade: [
        { min: 768, max: 800, value: 240 },
        { min: 720, max: 768, value: 200 },
        { min: 640, max: 720, value: 380 },
        { min: 600, max: 640, value: 305 },
        { min: 520, max: 600, value: 290 },
        { min: 480, max: 520, value: 240 },
        { min: 412, max: 480, value: 205 },
        { min: 375, max: 412, value: 130 },
        { min: 0, max: 375, value: 140 },
      ],
      quantidade_de_fotos: [
        { min: 768, max: 800, value: 150 },
        { min: 720, max: 768, value: 150 },
        { min: 640, max: 720, value: 165 },
        { min: 600, max: 640, value: 150 },
        { min: 520, max: 600, value: 140 },
        { min: 480, max: 520, value: 100 },
        { min: 412, max: 480, value: 100 },
        { min: 375, max: 412, value: 60 },
        { min: 0, max: 375, value: 62 },
      ]
    };
  
    const defaultValues = {
      datas_de_monitoramento: 460,
      talhoes_com_fotos: 275,
      quantidade_de_fotos: 275,
      acoes: 100,
    };
  
    if (field === "acoes") {
      return defaultValues.acoes;
    }
  
    return getResponsiveWidth(ranges[field] || [], defaultValues[field]);
  }