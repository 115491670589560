import React, { useEffect, useState } from 'react'
import { ContainerLab } from './styles'
import ViewPage from '../../../../Components/ViewPage'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import { TitleH1 } from '../../../../Components/CustomUi/titleH1'
import { TabelaLaboratorio } from './components/tabela'
import { getFornecedor, getFornecedorEditar } from '../../../../services/Fornecedores'
import { delLaboratorio, postLaboratorio, putLaboratorio } from '../../../../services/Laboratorio'
import FormLaboratorioNovo from '../../../../Components/laboratorioNovo'
import { buscaCidade, listUf } from '../../../../services/Afins'
import { getGrupoServico } from '../../../../services/GrupoServicos'
import { Drawer } from 'antd'
import { NavegacaoDrawer } from '../../recomendacao/novoCompatibilidadeAtivo/components/gavetaCadastrar/styled'
import ButtonCustom from '../../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk, faXmark } from '@fortawesome/free-solid-svg-icons'
import { delLabAnalise, getLabAnalise, postLabAnalise } from '../../../../services/LabAnalise'
import dayjs from 'dayjs'

const LaboratorioNovo: React.FC = () => {
  //TABELA
  const [permissions, setPermissions] = useState<ItensPerUserLogged>()
  const [permissionsExtra, setPermissionsExtra] = useState<ItensPerUserLogged[]>([])
  const [pagination, setPagination] = useState<number>(15)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState(null)
  const [open, setOpen] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)

  //GAVETA
  const [loadingEditar, setEditarLoading] = useState(false)
  const [loadingAnalises, setLoadingAnalises] = useState(false)
  const [dataUf, setDataUf] = useState([])
  const [dataCity, setDataCity] = useState([])
  const [dataService, setDataService] = useState([])
  const [dataFornecedorServi, setDataFornecedorServi] = useState([])
  const [timeRenderizar, setTimeRenderizar] = useState(false)
  const [loadingSalvar, setLoadingSalvar] = useState(false)
  const [loadingAddAnalise, setLoadingAddAnalise] = useState(false)
  const [inputDisableCidade, setInputDisableCidade] = useState(true)
  const [criadoPorAtualizadoPor, setCriadoPorAtualizadoPor] = useState('')

  //EXCLUSÃO
  const [confirmar_exclusao, setConfirmar_exclusao] = useState<boolean>(false)
  const [id_item_atual, setId_item_atual] = useState<number>(null)
  const [nome_item_atual, setNome_item_atual] = useState<string>('')
  const [fake_loading, setFake_loading] = useState<boolean>(false)

  //EXCLUSÃO ANALISE
  const [confirmar_exclusao_analise, setConfirmar_exclusao_analise] = useState<boolean>(false)
  const [id_item_atual_analise, setId_item_atual_analise] = useState<number>(null)
  const [nome_item_atual_analise, setNome_item_atual_analise] = useState<string>('')
  const [fake_loading_analise, setFake_loading_analise] = useState<boolean>(false)

  //EDITAR
  const [nomeLaboratorio, setNomeLaboratorio] = useState('')
  const [dataFornecedores, setDataForncedores] = useState([])
  const [updateLab, setUpdateLab] = useState<number>(0)
  const [indexInicial, setIndexInicial] = useState(null)

  //FORMULÁRIO
  const [tab_selecionar_atual, setTab_selecionar_atual] = useState<
    'Pessoa jurídica' | 'Pessoa física'
  >('Pessoa jurídica')
  const [dataAnalise, setDataAnalise] = useState(null)
  const [tipoAnalise, setTipoAnalise] = useState('')
  const [tipoAnaliseId, setTipoAnaliseId] = useState(null)
  const [valor, setValor] = useState('')

  //FORMULARIO PESSOA FÍSICA
  const [nome, setNome] = useState('')
  const [cpf, setCpf] = useState('')
  const [celular, setCelular] = useState('')
  const [foneFixo, setFoneFixo] = useState('')
  const [email, setEmail] = useState('')
  const [uf, setUf] = useState('')
  const [ufId, setUfId] = useState<number>(null)
  const [cidade, setCidade] = useState('')
  const [cidadeId, setCidadeId] = useState<number>(null)
  const [cep, setCep] = useState('')
  const [endereco, setEndereco] = useState('')
  const [numero, setNumero] = useState('')
  const [bairro, setBairro] = useState('')
  const [complemento, setComplemento] = useState('')

  //FORMULARIO PESSOA JURÍDICA
  const [nomeFantasia, setNomeFantasia] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [razao_social, setRazao_social] = useState('')
  const [ie, setIe] = useState('')
  const [celularJuridico, setCelularJuridico] = useState('')
  const [foneFixoJuridico, setFoneFixoJuridico] = useState('')
  const [emailJuridico, setEmailJuridico] = useState('')
  const [ufJuridico, setUfJuridico] = useState('')
  const [ufIdJuridico, setUfIdJuridico] = useState<number>(null)
  const [cidadeJuridico, setCidadeJuridico] = useState('')
  const [cidadeIdJuridico, setCidadeIdJuridico] = useState<number>(null)
  const [cepJuridico, setCepJuridico] = useState('')
  const [enderecoJuridico, setEnderecoJuridico] = useState('')
  const [numeroJuridico, setNumeroJuridico] = useState('')
  const [bairroJuridico, setBairroJuridico] = useState('')
  const [complementoJuridico, setComplementoJuridico] = useState('')

  const listarFornecedor = async () => {
    setLoadingTable(true)
    const response = await getFornecedor({
      codConta: localStorage.getItem('conta'),
      codCategoria: 2,
      page: page,
      pagination: pagination,
    })
    const nomePro = response.data?.itens[0]?.nome
    setNomeLaboratorio(nomePro)

    setLoadingTable(false)
    setDataForncedores(response.data.itens)
  }

  const handleClickVisuFornecedor = async (data) => {
    setUpdateLab(data)
    setOpen(true)
  }

  function fecharGaveta() {
    setOpen(false)
    setTimeRenderizar(false)
    setUpdateLab(0)
    setNome('')
    setCpf('')
    setCnpj('')
    setCelular('')
    setFoneFixo('')
    setRazao_social('')
    setIe('')
    setEmail('')
    setCep('')
    setUf('')
    setUfId(null)
    setCidade('')
    setCidadeId(null)
    setBairro('')
    setEndereco('')
    setNumero('')
    setComplemento('')
    setNomeFantasia('')
    setCelularJuridico('')
    setFoneFixoJuridico('')
    setEmailJuridico('')
    setCepJuridico('')
    setUfJuridico('')
    setUfIdJuridico(null)
    setCidadeJuridico('')
    setCidadeIdJuridico(null)
    setBairroJuridico('')
    setEnderecoJuridico('')
    setNumeroJuridico('')
    setComplementoJuridico('')
    setTab_selecionar_atual('Pessoa jurídica')
    setIndexInicial(0)
    listarFornecedor()
    setCriadoPorAtualizadoPor('')
  }

  async function listarUfs() {
    const resUF = await listUf()
    setDataUf(resUF)
  }

  const listServico = async () => {
    const response = await getGrupoServico({})
    const novoArray = response.data.itens.map(({ cod, nome, profundidade_nome }) => ({
      cod,
      nome: `${profundidade_nome} | ${nome}`,
    }))
    setDataService(novoArray)
  }

  async function deletarLaboratorio(cod) {
    setFake_loading(true)
    const responseDel = await delLaboratorio(cod)
    setTimeout(() => {
      setFake_loading(false)
    }, 3000)
    listarFornecedor()
  }

  async function buscarAnalisesLab(data) {
    setLoadingAnalises(true)
    const respServico = await getLabAnalise('', data, '', '')
    setDataFornecedorServi(respServico.data.itens)
    setLoadingAnalises(false)
  }

  const handleChangeCidadeUf = async (cod) => {
    if (cod) {
      const resCity = await buscaCidade('', cod, '')
      setDataCity(resCity)
    }
  }

  const verificarSeFoiAtualizado = (create_user, create_time, update_user, update_time) => {
    if (dayjs(update_time).isAfter(dayjs(create_time))) {
      setCriadoPorAtualizadoPor(
        `Atualizado por: ${update_user} em ${dayjs(update_time).format('DD/MM/YYYY HH:mm')}`
      )
    } else {
      setCriadoPorAtualizadoPor(
        `Criado por: ${create_user} em ${dayjs(create_time).format('DD/MM/YYYY HH:mm')}`
      )
    }
  }

  async function editarLab(data) {
    setEditarLoading(true)
    const response = await getFornecedorEditar(2, data)
    buscarAnalisesLab(data)
    setIndexInicial(response.data.itens[0]?.tipo === 'F' ? 1 : 0)
    setTab_selecionar_atual(
      response.data.itens[0]?.tipo === 'F' ? 'Pessoa física' : 'Pessoa jurídica'
    )
    if (response.data.itens[0]?.tipo === 'F') {
      setNome(response.data.itens[0]?.nome)
      setCpf(response.data.itens[0]?.cpf_cnpj)
      setCelular(response.data.itens[0]?.fone)
      setFoneFixo(response.data.itens[0]?.fone2)
      setEmail(response.data.itens[0]?.email)
      setEndereco(response.data.itens[0]?.endereco)
      setComplemento(response.data.itens[0]?.complemento)
      setCep(response.data.itens[0]?.cep?.toString())
      setNumero(response.data.itens[0]?.endereco_numero)
      setBairro(response.data.itens[0]?.bairro)
      setUf(response.data.itens[0]?.cidade[0]?.uf_sigla)
      handleChangeCidadeUf(response.data.itens[0]?.cidade[0]?.cod_uf)
      setUfId(response.data.itens[0]?.cidade[0]?.cod_uf)
      setCidade(response.data.itens[0]?.cidade[0]?.nome)
      setCidadeId(response.data.itens[0]?.cidade[0]?.cod)
    }

    if (response.data.itens[0]?.tipo === 'J') {
      setNomeFantasia(response.data.itens[0]?.nome)
      setCnpj(response.data.itens[0]?.cpf_cnpj)
      setRazao_social(response.data.itens[0]?.razao_social)
      setIe(response?.data?.itens[0]?.inscricao_estadual?.toString())
      setCelularJuridico(response.data.itens[0]?.fone)
      setFoneFixoJuridico(response.data.itens[0]?.fone2)
      setEmailJuridico(response.data.itens[0]?.email)
      setEnderecoJuridico(response.data.itens[0]?.endereco)
      setComplementoJuridico(response.data.itens[0]?.complemento)
      setCepJuridico(response.data.itens[0]?.cep?.toString())
      setNumeroJuridico(response.data.itens[0]?.endereco_numero)
      setBairroJuridico(response.data.itens[0]?.bairro)
      setUfJuridico(response.data.itens[0]?.cidade[0]?.uf_sigla)
      handleChangeCidadeUf(response.data.itens[0]?.cidade[0]?.cod_uf)
      setUfIdJuridico(response.data.itens[0]?.cidade[0]?.cod_uf)
      setCidadeJuridico(response.data.itens[0]?.cidade[0]?.nome)
      setCidadeIdJuridico(response.data.itens[0]?.cidade[0]?.cod)
    }

    verificarSeFoiAtualizado(
      response.data.itens[0]?.create_user,
      response.data.itens[0]?.create_time,
      response.data.itens[0]?.update_user,
      response.data.itens[0]?.update_time
    )

    setInputDisableCidade(false)
    setEditarLoading(false)
  }

  async function salvarLaboratorioJuridica() {
    setLoadingSalvar(true)
    const data = {
      tipo: tab_selecionar_atual === 'Pessoa jurídica' && 'J',
      nome: nomeFantasia,
      cpf_cnpj: cnpj,
      fone: celularJuridico,
      fone2: foneFixoJuridico,
      email: emailJuridico,
      cidade_cod_uf: ufIdJuridico,
      cod_cidade: cidadeIdJuridico,
      cep: cepJuridico,
      endereco: enderecoJuridico,
      endereco_numero: numeroJuridico,
      bairro: bairroJuridico,
      complemento: complementoJuridico,
      inscricao_estadual: ie,
      razao_social: razao_social,
      status: 1,
      cod_categoria: 2,
    }

    if (updateLab <= 0) {
      const response = await postLaboratorio(data, fecharGaveta, setLoadingSalvar)
    } else {
      const response = await putLaboratorio(updateLab, data, fecharGaveta, setLoadingSalvar)
    }
  }

  async function salvarLaboratorioFisica() {
    setLoadingSalvar(true)
    const data = {
      tipo: tab_selecionar_atual === 'Pessoa física' && 'F',
      nome: nome,
      cpf_cnpj: cpf,
      fone: celular,
      fone2: foneFixo,
      email: email,
      cidade_cod_uf: ufId,
      cod_cidade: cidadeId,
      cep: cep,
      endereco: endereco,
      endereco_numero: numero,
      bairro: bairro,
      complemento: complemento,
      inscricao_estadual: ie,
      razao_social: razao_social,
      status: 1,
      cod_categoria: 2,
    }

    if (updateLab <= 0) {
      const response = await postLaboratorio(data, fecharGaveta, setLoadingSalvar)
    } else {
      const response = await putLaboratorio(updateLab, data, fecharGaveta, setLoadingSalvar)
    }
  }

  async function adicionarAnalise() {
    setLoadingAddAnalise(true)
    const data = {
      cod_laboratorio: updateLab,
      status: 1,
      data: dayjs(dataAnalise).format('DD/MM/YYYY'),
      cod_analise: tipoAnaliseId,
      valor: valor,
    }
    const response = await postLabAnalise(data, setLoadingAddAnalise)
    setDataAnalise(null)
    setTipoAnaliseId(null)
    setTipoAnalise('')
    setValor('')
    buscarAnalisesLab(updateLab)
  }

  function abrirGaveta() {
    if (permissionsExtra[0].cod_rotina === 33 && permissionsExtra[0].inclusao === 1) {
      setOpen(true)
    }
    setUpdateLab(0)
  }

  const handleTrashFornServ = async (data) => {
    setFake_loading_analise(true)
    const responseDel = await delLabAnalise(data)
    setTimeout(() => {
      setFake_loading_analise(false)
    }, 2000)
    buscarAnalisesLab(updateLab)
  }

  useEffect(() => {
    listarFornecedor()
    listarUfs()
    listServico()
  }, [])

  useEffect(() => {
    listarFornecedor()
  }, [page])

  useEffect(() => {
    if (updateLab > 0) {
      editarLab(updateLab)
      setOpen(true)
    }
  }, [updateLab])

  return (
    <ContainerLab>
      <ViewPage
        title='Laboratório'
        btnClick={null}
        newHide
        rotina={53}
        rotinaExtra={[33]}
        onPermission={setPermissions}
        onPermissionsExtra={setPermissionsExtra}
        hiddeTitle
        cardBodyStyle={{ padding: 15 }}
        cardMainStyle={{ height: '87.5vh' }}
      >
        <div style={{ marginBottom: '10px' }}>
          <TitleH1>Laboratório</TitleH1>
        </div>

        <TabelaLaboratorio
          setOpen={setOpen}
          abrirGaveta={abrirGaveta}
          listar_laboratorio={dataFornecedores}
          loading={loadingTable}
          setPagination={setPagination}
          page={page}
          setPage={setPage}
          total={total}
          permissao={permissions}
          permissionsExtra={permissionsExtra}
          editarCadastro={handleClickVisuFornecedor}
          deletar={deletarLaboratorio}
          confirmar_exclusao={confirmar_exclusao}
          setConfirmar_exclusao={setConfirmar_exclusao}
          id_item_atual={id_item_atual}
          setId_item_atual={setId_item_atual}
          nome_item_atual={nome_item_atual}
          setNome_item_atual={setNome_item_atual}
          fake_loading={fake_loading}
          setFake_loading={setFake_loading}
        />
        <Drawer
          title={
            <NavegacaoDrawer>
              <ButtonCustom
                danger
                icon={<FontAwesomeIcon icon={faXmark} />}
                type='tertiary'
                onClick={() => fecharGaveta()}
                style={{ marginLeft: -10 }}
              >
                Fechar
              </ButtonCustom>
            </NavegacaoDrawer>
          }
          open={open}
          width={750}
          onClose={() => fecharGaveta()}
          closable={false}
          bodyStyle={{ padding: 0 }}
          headerStyle={{ padding: '10px 10px' }}
          footer={
            <div className='footer'>
              <ButtonCustom
                icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                type='primary'
                onClick={tab_selecionar_atual === 'Pessoa física' ? salvarLaboratorioFisica : salvarLaboratorioJuridica}
                disabled={loadingSalvar}
                loading={loadingSalvar}
              >
                Salvar
              </ButtonCustom>
            </div>
          }
          destroyOnClose
        >
          <FormLaboratorioNovo
            tab_selecionar_atual={tab_selecionar_atual}
            setTab_selecionar_atual={setTab_selecionar_atual}
            nome={nome}
            setNome={setNome}
            cpf={cpf}
            setCpf={setCpf}
            celular={celular}
            setCelular={setCelular}
            foneFixo={foneFixo}
            setFoneFixo={setFoneFixo}
            email={email}
            setEmail={setEmail}
            uf={uf}
            setUf={setUf}
            ufId={ufId}
            setUfId={setUfId}
            cidade={cidade}
            setCidade={setCidade}
            cidadeId={cidadeId}
            setCidadeId={setCidadeId}
            cep={cep}
            setCep={setCep}
            endereco={endereco}
            setEndereco={setEndereco}
            numero={numero}
            setNumero={setNumero}
            bairro={bairro}
            setBairro={setBairro}
            complemento={complemento}
            setComplemento={setComplemento}
            nomeFantasia={nomeFantasia}
            setNomeFantasia={setNomeFantasia}
            razao_social={razao_social}
            setRazao_social={setRazao_social}
            cnpj={cnpj}
            setCnpj={setCnpj}
            iE={ie}
            setIe={setIe}
            celularJuridico={celularJuridico}
            setCelularJuridico={setCelularJuridico}
            foneFixoJuridico={foneFixoJuridico}
            setFoneFixoJuridico={setFoneFixoJuridico}
            emailJuridico={emailJuridico}
            setEmailJuridico={setEmailJuridico}
            cepJuridico={cepJuridico}
            setCepJuridico={setCepJuridico}
            ufJuridico={ufJuridico}
            setUfJuridico={setUfJuridico}
            ufIdJuridico={ufIdJuridico}
            setUfIdJuridico={setUfIdJuridico}
            cidadeJuridico={cidadeJuridico}
            setCidadeJuridico={setCidadeJuridico}
            cidadeIdJuridico={cidadeIdJuridico}
            setCidadeIdJuridico={setCidadeIdJuridico}
            bairroJuridico={bairroJuridico}
            setBairroJuridico={setBairroJuridico}
            enderecoJuridico={enderecoJuridico}
            setEnderecoJuridico={setEnderecoJuridico}
            numeroJuridico={numeroJuridico}
            setNumeroJuridico={setNumeroJuridico}
            complementoJuridico={complementoJuridico}
            setComplementoJuridico={setComplementoJuridico}
            dataAnalise={dataAnalise}
            setDataAnalise={setDataAnalise}
            tipoAnalise={tipoAnalise}
            setTipoAnalise={setTipoAnalise}
            tipoAnaliseId={tipoAnaliseId}
            setTipoAnaliseId={setTipoAnaliseId}
            valor={valor}
            setValor={setValor}
            indexInicial={indexInicial}
            setIndexInicial={setIndexInicial}
            loadingEditar={loadingEditar}
            loadingAnalises={loadingAnalises}
            setEditarLoading={setEditarLoading}
            timeRenderizar={timeRenderizar}
            setTimeRenderizar={setTimeRenderizar}
            confirmar_exclusao={confirmar_exclusao_analise}
            setConfirmar_exclusao={setConfirmar_exclusao_analise}
            fake_loading={fake_loading_analise}
            setFake_loading={setFake_loading_analise}
            id_item_atual={id_item_atual_analise}
            setId_item_atual={setId_item_atual_analise}
            nome_item_atual={nome_item_atual_analise}
            setNome_item_atual={setNome_item_atual_analise}
            deletarAnalise={handleTrashFornServ}
            inputDisableCidade={inputDisableCidade}
            setInputDisableCidade={setInputDisableCidade}
            loadingAddAnalise={loadingAddAnalise}
            criadoPorAtualizadoPor={criadoPorAtualizadoPor}
            update={updateLab}
            dataUf={dataUf}
            dataCity={dataCity}
            setDataCity={setDataCity}
            dataService={dataService}
            dataFornecedorServi={dataFornecedorServi}
            permissions={permissions}
            acessoPermissoes={permissionsExtra}
            adicionarAnalise={adicionarAnalise}
          />
        </Drawer>
      </ViewPage>
    </ContainerLab>
  )
}

export default LaboratorioNovo
