

export function obterColmos(mesclar_colmos: string[]) {

    console.log("mesclar_colmos ", mesclar_colmos)

    let converter = mesclar_colmos.map(item=>Number(item));

    return {
        min: `${(Math.min(...converter))?.toFixed(2)} mm`,
        max: `${(Math.max(...converter))?.toFixed(2)} mm`,
        med: `${(converter.reduce((acc, val) => acc + val, 0) / converter.length)?.toFixed(2)} mm`
    }
}