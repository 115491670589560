import { useQuery } from 'react-query'
import { api } from '../../api'
import { localConfig } from '../../../Configs/localConfig'
import { errorAlert } from '../../../utils/alert'

export type ItensVariedade = {
  cod: number
  cod_conta: number
  cod_cultura: number
  cod_nivel_investimento: number
  nome: string
  empresa_obtentora: string
  observacao: string
  cod_classificacao: number
  valor_classificacao: string | number
  cod_opcao_classificacao: number
  tipo_classificacao: string
  tipo_opcao_classificacao: string
  cultura_nome: string
  nivel_investimento: string
  tecnologias: {
    cod_variedade_hibrido: number
    cod_tecnologia: number
    variedade_hibrido_nome: string
    tecnologia_nome: string
  }[]
  tolerancias: {
    cod_variedade_hibrido: number
    cod_especie: number
    cod_variacao_especie: null
    cod_tipo: number
    variedade_hibrido_nome: string
    especie_nome: string
    variacao_especie_nome: null
    tolerancia_nome: string
  }[]
}

export type ItensVariacao = {
  cod: number
  cod_especie: number
  nome: string
  status: number
  cod_replica: null
  especie_nome: string
  categoria_nome: string
}
export interface ItensTecnologias {
  cod: number
  nome: string
  descricao: string
  sigla: string
}

export interface ItensTipoSistema {
  cod: number
  nome: string
  cod_tipo_geral: number
  ordem: 1
  tipo_geral_nome: string
}
export interface Variedades {
  itens: ItensVariedade[]
  total?: number | null
  mensagem?: string | null
}

export interface ItensCultura {
  cod: number
  nome: string
  nome_cientifico: string
  unidade_colheita: number
  unidade_colheita_nome: string
}

export interface ItensEspecie {
  cod: number
  nome: string
  cod_categoria: number
  cod_conta: number
  cod_cliente: null
  status: number
  nome_cientifico: string
  cod_replica: null
  categoria_nome: string
  variacao: ItensVariacao[]
}

export const useGetVariedadeHibrido = (pagination = 15, page = 1) => {
  const queryKey: any = [`/variedade-hibrido`]

  const { data, isFetching, refetch, isFetchedAfterMount, isError } = useQuery<Variedades, Error>(
    queryKey,
    async () => {
      return api
        .get(`/variedade-hibrido?codConta=${localConfig()?.conta}&pagination=${pagination}&page=${page}`)
        .then((response): any => {
          return response.data
        })
        .catch((error) => {
          errorAlert(error?.response?.data?.error ?? 'Falha ao obter lista de variedade!')
        })
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_variedade: data,
    carregando_variedade: isFetching,
    refetch_data_variedade: refetch,
    isFetched: isFetchedAfterMount,
    isError: isError,
  }
}

export const useGetVariedadeHibridoEditar = (cod) => {
  const queryKey: any = [`/variedade-hibrido_${cod}`]

  const { data, isFetching, refetch, isFetchedAfterMount, isError } = useQuery<
    ItensVariedade[],
    Error
  >(
    queryKey,
    async () => {
      return api
        .get(
          `/variedade-hibrido?codConta=${
            localConfig()?.conta
          }&cod=${cod}&embed=tecnologias,tolerancias&pagination=-1`
        )
        .then((response): any => {
          return response.data.itens
        })
        .catch((error) => {
          errorAlert(error?.response?.data?.error ?? 'Falha ao obter lista de variedade!')
        })
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_variedade_editar: data ?? [],
    carregando_variedade_editar: isFetching,
    refetch_data_variedade_editar: refetch,
    isFetched: isFetchedAfterMount,
    isError: isError,
  }
}

export const useGetCultura = () => {
  const queryKey: any = [`/cultura`]

  const { data, isFetching, refetch, isFetchedAfterMount, isError } = useQuery<
    ItensCultura[],
    Error
  >(
    queryKey,
    async () => {
      return api
        .get(`/cultura?pagination=-1`)
        .then((response): any => {
          return response.data.itens
        })
        .catch((error) => {
          errorAlert(error?.response?.data?.error ?? 'Falha ao obter lista de culturas!')
        })
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_cultura: data,
    carregando_cultura: isFetching,
    refetch_data_cultura: refetch,
    isFetched: isFetchedAfterMount,
    isError: isError,
  }
}

export const useGetTecnologias = (codCultura: number, triggerError?: Function) => {
  const queryKey: any = [`/recomendacao/tecnologia_${codCultura}`]

  const { conta } = localConfig()

  const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<ItensTecnologias[], Error>(
    queryKey,
    async () => {
      return api
        .get(`/recomendacao/tecnologia?codCultura=${codCultura}&codConta=${conta}&pagination=-1`)
        .then((response): any => {
          triggerError?.(false)
          return response.data.itens
        })
        .catch(() => {
          errorAlert('Falha ao obter lista de tecnologias!')
          triggerError?.(true)
        })
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_tecnologias: data ?? [],
    carregando_tecnologias: isFetching,
    refetch_data_tecnologias: refetch,
    isFetched_tecnologias: isFetchedAfterMount,
  }
}

export const useGetNivelInvestimento = (triggerError?: Function) => {
  const queryKey: any = [`/tipos-sistema_${67}`]

  const { conta } = localConfig()

  const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<ItensTipoSistema[], Error>(
    queryKey,
    async () => {
      return api
        .get(`/tipos-sistema?codTipo=${67}&codConta=${conta}&pagination=-1`)
        .then((response): any => {
          triggerError?.(false)
          return response.data.itens
        })
        .catch(() => {
          errorAlert('Falha ao obter lista de nível investimento!')
          triggerError?.(true)
        })
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_nivel_investimento: data,
    carregando_nivel_investimento: isFetching,
    refetch_data_nivel_investimento: refetch,
    isFetched_nivel_investimento: isFetchedAfterMount,
  }
}

export const useGetClassificacao = (triggerError?: Function) => {
  const queryKey: any = [`/tipos-sistema_${48}`]

  const { conta } = localConfig()

  const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<ItensTipoSistema[], Error>(
    queryKey,
    async () => {
      return api
        .get(`/tipos-sistema?codTipo=${48}&codConta=${conta}&pagination=-1`)
        .then((response): any => {
          triggerError?.(false)
          return response.data.itens
        })
        .catch(() => {
          errorAlert('Falha ao obter lista de classificação!')
          triggerError?.(true)
        })
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_classificacao: data,
    carregando_classificacao: isFetching,
    refetch_data_classificacao: refetch,
    isFetched_classificacao: isFetchedAfterMount,
  }
}

export const useGetOpcaoClassificacao = (cod_classificacao?: number, triggerError?: Function) => {
  const queryKey: any = [`/tipos-sistema_${cod_classificacao}`]

  const { conta } = localConfig()

  const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<ItensTipoSistema[], Error>(
    queryKey,
    async () => {
      return api
        .get(`/tipos-sistema?codTipo=${cod_classificacao}&codConta=${conta}&pagination=-1`)
        .then((response): any => {
          triggerError?.(false)
          return response.data.itens
        })
        .catch(() => {
          errorAlert('Falha ao obter lista de opção de classificação!')
          triggerError?.(true)
        })
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_opcao_classificacao: data ?? [],
    carregando_opcao_classificacao: isFetching,
    refetch_data_opcao_classificacao: refetch,
    isFetched_opca_classificacao: isFetchedAfterMount,
  }
}

export const useGetEspecieVariacao = (triggerError?: Function) => {
  const queryKey: any = [`/monitoramento/especie`]

  const { conta } = localConfig()

  const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<ItensEspecie[], Error>(
    queryKey,
    async () => {
      return api
        .get(
          `/monitoramento/especie?codCategoria=3,4&embed=variacao&codConta=${conta}&pagination=-1`
        )
        .then((response): any => {
          triggerError?.(false)
          return response.data.itens
        })
        .catch(() => {
          errorAlert('Falha ao obter lista de espécie e variações!')
          triggerError?.(true)
        })
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_especieVariacao: data ?? [],
    carregando_especieVariacao: isFetching,
    refetch_data_especieVariacao: refetch,
    isFetched_especieVariacao: isFetchedAfterMount,
  }
}

export const useGetTipo = (triggerError?: Function) => {
  const queryKey: any = [`/tipos-sistema_${62}`]

  const { conta } = localConfig()

  const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<ItensTipoSistema[], Error>(
    queryKey,
    async () => {
      return api
        .get(`/tipos-sistema?codTipo=${62}&codConta=${conta}&pagination=-1`)
        .then((response): any => {
          triggerError?.(false)
          return response.data.itens
        })
        .catch(() => {
          errorAlert('Falha ao obter lista de tipos!')
          triggerError?.(true)
        })
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_tipo: data ?? [],
    carregando_tipo: isFetching,
    refetch_data_tipo: refetch,
    isFetched_tipo: isFetchedAfterMount,
  }
}
