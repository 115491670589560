import React, { useEffect, useState } from 'react'
import { BodyDrawer } from './styles'
import { DatePicker, Drawer, Empty } from 'antd'
import ButtonCustom from '../ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAddressCard,
  faBriefcase,
  faCity,
  faClipboardList,
  faEnvelope,
  faFileSignature,
  faFlask,
  faFloppyDisk,
  faHashtag,
  faHouse,
  faHouseUser,
  faIdBadge,
  faLocationDot,
  faMap,
  faMicroscope,
  faMobileScreenButton,
  faMoneyBill,
  faPhone,
  faPlus,
  faUser,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { HeaderDrawer } from '../CustomUi/headerGaveta'
import { NavegacaoDrawer } from '../../Views/app/recomendacao/novoCompatibilidadeAtivo/components/gavetaCadastrar/styled'
import Input from '../CustomUi/input'
import { maskCNPJ } from '../../utils'
import { buscaCidade, cepMask, cpfMask, fixoMask, phoneMask } from '../../services/Afins'
import { Render } from '../CustomUi/render'
import { Select } from '../CustomUi/Select'
import Radio from '../Radio'
import Tabs from '../Tabs'
import { Box } from '../CustomUi/box'
import { TitleH3 } from '../CustomUi/titleH3'
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'
import dayjs, { Dayjs } from 'dayjs'
import { postLaboratorio, putLaboratorio } from '../../services/Laboratorio'
import { getFornecedor, getFornecedorEditar } from '../../services/Fornecedores'
import { destroyAlert, errorAlert, loadingAlert } from '../../utils/alert'
import { useGetCnpj } from '../../services/buscarCNPJ'
import Carregando from '../CustomUi/loading'
import { delLabAnalise, postLabAnalise } from '../../services/LabAnalise'
import { HeaderTable } from '../CustomUi/table/headerTable'
import { BodyTable } from '../CustomUi/table/bodyTable'
import { RowTable } from '../CustomUi/table/rowTable'
import { RowItem } from '../CustomUi/table/rowItem'
import { DeleteButton } from '../CustomUi/deleteButton'
import { FormPessoaFisicaLab } from './components/formPessoaFisica'
import { FormPessoaJuridicaLab } from './components/formPessoaJuridica'

interface PropsFormLab {
  tab_selecionar_atual: string
  setTab_selecionar_atual: React.Dispatch<React.SetStateAction<string>>
  nome: string
  setNome: React.Dispatch<React.SetStateAction<string>>
  cpf: string
  setCpf: React.Dispatch<React.SetStateAction<string>>
  celular: string
  setCelular: React.Dispatch<React.SetStateAction<string>>
  foneFixo: string
  setFoneFixo: React.Dispatch<React.SetStateAction<string>>
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  uf: string
  setUf: React.Dispatch<React.SetStateAction<string>>
  ufId: number
  setUfId: React.Dispatch<React.SetStateAction<number>>
  cidade: string
  setCidade: React.Dispatch<React.SetStateAction<string>>
  cidadeId: number
  setCidadeId: React.Dispatch<React.SetStateAction<number>>
  cep: string
  setCep: React.Dispatch<React.SetStateAction<string>>
  endereco: string
  setEndereco: React.Dispatch<React.SetStateAction<string>>
  numero: string
  setNumero: React.Dispatch<React.SetStateAction<string>>
  bairro: string
  setBairro: React.Dispatch<React.SetStateAction<string>>
  complemento: string
  setComplemento: React.Dispatch<React.SetStateAction<string>>

  //JURIDICO
  nomeFantasia: string
  setNomeFantasia: React.Dispatch<React.SetStateAction<string>>
  razao_social: string
  setRazao_social: React.Dispatch<React.SetStateAction<string>>
  cnpj: string
  setCnpj: React.Dispatch<React.SetStateAction<string>>
  iE: string
  setIe: React.Dispatch<React.SetStateAction<string>>
  celularJuridico: string
  setCelularJuridico: React.Dispatch<React.SetStateAction<string>>
  foneFixoJuridico: string
  setFoneFixoJuridico: React.Dispatch<React.SetStateAction<string>>
  emailJuridico: string
  setEmailJuridico: React.Dispatch<React.SetStateAction<string>>
  ufJuridico: string
  setUfJuridico: React.Dispatch<React.SetStateAction<string>>
  ufIdJuridico: number
  setUfIdJuridico: React.Dispatch<React.SetStateAction<number>>
  cidadeJuridico: string
  setCidadeJuridico: React.Dispatch<React.SetStateAction<string>>
  cidadeIdJuridico: number
  setCidadeIdJuridico: React.Dispatch<React.SetStateAction<number>>
  cepJuridico: string
  setCepJuridico: React.Dispatch<React.SetStateAction<string>>
  enderecoJuridico: string
  setEnderecoJuridico: React.Dispatch<React.SetStateAction<string>>
  numeroJuridico: string
  setNumeroJuridico: React.Dispatch<React.SetStateAction<string>>
  bairroJuridico: string
  setBairroJuridico: React.Dispatch<React.SetStateAction<string>>
  complementoJuridico: string
  setComplementoJuridico: React.Dispatch<React.SetStateAction<string>>

  dataAnalise: Dayjs | null
  setDataAnalise: React.Dispatch<React.SetStateAction<Dayjs | null>>
  tipoAnalise: string
  setTipoAnalise: React.Dispatch<React.SetStateAction<string>>
  tipoAnaliseId: number
  setTipoAnaliseId: React.Dispatch<React.SetStateAction<number>>
  valor: string
  setValor: React.Dispatch<React.SetStateAction<string>>
  indexInicial: number
  setIndexInicial: React.Dispatch<React.SetStateAction<number>>
  loadingEditar: boolean
  loadingAnalises: boolean
  setEditarLoading: React.Dispatch<React.SetStateAction<boolean>>
  timeRenderizar: boolean
  setTimeRenderizar: React.Dispatch<React.SetStateAction<boolean>>
  confirmar_exclusao: boolean
  setConfirmar_exclusao: React.Dispatch<React.SetStateAction<boolean>>
  id_item_atual: number
  setId_item_atual: React.Dispatch<React.SetStateAction<number>>
  nome_item_atual: string
  setNome_item_atual: React.Dispatch<React.SetStateAction<string>>
  fake_loading: boolean
  setFake_loading: React.Dispatch<React.SetStateAction<boolean>>
  inputDisableCidade: boolean
  setInputDisableCidade: React.Dispatch<React.SetStateAction<boolean>>
  dataCity: any[]
  setDataCity: React.Dispatch<React.SetStateAction<any[]>>
  criadoPorAtualizadoPor: string
  loadingAddAnalise: boolean
  update: number
  dataUf: any[]
  dataService: any[]
  dataFornecedorServi: any[]
  permissions: ItensPerUserLogged
  acessoPermissoes: ItensPerUserLogged[]
  adicionarAnalise: Function
  deletarAnalise: Function
}

const FormLaboratorioNovo: React.FC<PropsFormLab> = ({
  tab_selecionar_atual,
  setTab_selecionar_atual,
  nome,
  setNome,
  cpf,
  setCpf,
  celular,
  setCelular,
  foneFixo,
  setFoneFixo,
  email,
  setEmail,
  uf,
  setUf,
  ufId,
  setUfId,
  cidade,
  setCidade,
  cidadeId,
  setCidadeId,
  cep,
  setCep,
  endereco,
  setEndereco,
  numero,
  setNumero,
  bairro,
  setBairro,
  complemento,
  setComplemento,
  nomeFantasia,
  setNomeFantasia,
  razao_social,
  setRazao_social,
  cnpj,
  setCnpj,
  iE,
  setIe,
  celularJuridico,
  setCelularJuridico,
  foneFixoJuridico,
  setFoneFixoJuridico,
  emailJuridico,
  setEmailJuridico,
  cepJuridico,
  setCepJuridico,
  ufJuridico,
  setUfJuridico,
  ufIdJuridico,
  setUfIdJuridico,
  cidadeJuridico,
  setCidadeJuridico,
  cidadeIdJuridico,
  setCidadeIdJuridico,
  bairroJuridico,
  setBairroJuridico,
  enderecoJuridico,
  setEnderecoJuridico,
  numeroJuridico,
  setNumeroJuridico,
  complementoJuridico,
  setComplementoJuridico,
  dataAnalise,
  setDataAnalise,
  tipoAnalise,
  setTipoAnalise,
  tipoAnaliseId,
  setTipoAnaliseId,
  valor,
  setValor,
  indexInicial,
  loadingEditar,
  loadingAnalises,
  timeRenderizar,
  setTimeRenderizar,
  confirmar_exclusao,
  setConfirmar_exclusao,
  fake_loading,
  setFake_loading,
  id_item_atual,
  setId_item_atual,
  nome_item_atual,
  setNome_item_atual,
  loadingAddAnalise,
  inputDisableCidade,
  setInputDisableCidade,
  dataCity,
  setDataCity,
  criadoPorAtualizadoPor,
  update,
  dataUf,
  dataService,
  dataFornecedorServi,
  permissions,
  acessoPermissoes,
  adicionarAnalise,
  deletarAnalise,
}) => {
  const [openUf, setOpenUf] = useState(false)
  const [openCidade, setOpenCidade] = useState(false)
  const [openAnalise, setOpenAnalise] = useState(false)
  const [logUser, setLogUser] = useState(false)

  const [dataCidadeFilter, setDataCidadeFilter] = useState([])
  const { data_cnpj, refetch } = useGetCnpj(cnpj?.replace(/[^\d]/g, ''))

  const haldleChangeCidadeUf = async (cod) => {
    if (cod) {
      setDataCity([])
      const resCity = await buscaCidade('', cod, '')
      setDataCidadeFilter(resCity)

      setInputDisableCidade(false)
    } else {
      setInputDisableCidade(false)
    }
  }

  const handleChangeCidadeUfCep = async (cod, data) => {
    if (cod) {
      setDataCity([])
      const resCity = await buscaCidade('', cod, '')
      setDataCidadeFilter(resCity)
      const cidadeFiltrado = resCity?.filter((item) => item.nome === data.localidade)
      setCidadeId(cidadeFiltrado[0]?.cod)

      setInputDisableCidade(false)
    } else {
      setInputDisableCidade(false)
    }
  }

  const handleChangeCidadeUfCepJuridico = async (cod, data) => {
    if (cod) {
      setDataCity([])
      const resCity = await buscaCidade('', cod, '')
      setDataCidadeFilter(resCity)
      const cidadeFiltrado = resCity?.filter((item) => item.nome === data.localidade)
      setCidadeIdJuridico(cidadeFiltrado[0]?.cod)

      setInputDisableCidade(false)
    } else {
      setInputDisableCidade(false)
    }
  }

  const handleChangeCidadeUfCnpj = async (cod) => {
    if (cod) {
      setDataCity([])
      const resCity = await buscaCidade('', cod, '')
      setDataCidadeFilter(resCity)
      const cidadeSemAcento = resCity.map((item) => {
        return {
          ...item,
          cod: item?.cod,
          nome: item.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        }
      })
      const cidade = cidadeSemAcento.filter(
        (item) => item.nome.toLowerCase() === dadosCNPJ?.municipio.toLowerCase()
      )

      setCidadeJuridico(cidade[0]?.nome)
      setCidadeIdJuridico(cidade[0]?.cod)
      setInputDisableCidade(false)
    } else {
      setInputDisableCidade(false)
    }
  }

  function mascaraValorAnalise(valor) {
    let data = valor
    data = data.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
    data = (data / 100).toFixed(2).replace('.', ',')

    setValor(data)
  }

  function checkCep(cepValue) {
    loadingAlert({ msg: 'Buscando CEP...', id: 'cep' })
    const cep = cepValue.replace(/\D/g, '')
    setTimeout(() => {
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => res.json())
        .then((data) => {
          if (data.bairro !== '' && data.logradouro !== '') {
            setBairro(data.bairro)
            setEndereco(data.logradouro)
            const estadoFiltrado = dataUf?.filter((item) => item.nome === data?.estado)
            setUf(estadoFiltrado[0]?.sigla)
            setUfId(estadoFiltrado[0]?.cod)
            handleChangeCidadeUfCep(estadoFiltrado[0]?.cod, data)

            setCidade(data.localidade)
            setInputDisableCidade(false)
          } else {
            const estadoFiltrado = dataUf?.filter((item) => item.nome === data?.estado)
            setUf(estadoFiltrado[0]?.sigla)
            handleChangeCidadeUfCep(estadoFiltrado[0]?.cod, data)
            setCidade(data.localidade)
            setInputDisableCidade(false)
          }
        })
        .catch(() => {
          errorAlert('Falha ao buscar CEP!', 5000, 'cep')
        })
      destroyAlert('cep')
    }, 300)
  }

  function checkCepJuridico(cepValue) {
    loadingAlert({ msg: 'Buscando CEP...', id: 'cep' })
    const cep = cepValue.replace(/\D/g, '')
    setTimeout(() => {
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => res.json())
        .then((data) => {
          if (data.bairro !== '' && data.logradouro !== '') {
            setBairroJuridico(data.bairro)
            setEnderecoJuridico(data.logradouro)
            const estadoFiltrado = dataUf?.filter((item) => item.nome === data?.estado)
            setUfJuridico(estadoFiltrado[0]?.sigla)
            setUfIdJuridico(estadoFiltrado[0]?.cod)
            handleChangeCidadeUfCepJuridico(estadoFiltrado[0]?.cod, data)

            setCidadeJuridico(data.localidade)
            setInputDisableCidade(false)
          } else {
            const estadoFiltrado = dataUf?.filter((item) => item.nome === data?.estado)
            setUfJuridico(estadoFiltrado[0]?.sigla)
            handleChangeCidadeUfCepJuridico(estadoFiltrado[0]?.cod, data)
            setCidadeJuridico(data.localidade)
            setInputDisableCidade(false)
          }
        })
        .catch(() => {
          errorAlert('Falha ao buscar CEP!', 5000, 'cep')
        })
      destroyAlert('cep')
    }, 300)
  }

  function normalizarObjeto(obj) {
    // Mapeamento dos campos do primeiro objeto
    if (obj?.cnpj) {
      return {
        cnpj: obj.cnpj,
        razao_social: obj.razao_social,
        nome_fantasia: obj.nome_fantasia || '',
        cep: obj.endereco?.cep || '',
        telefone: obj.telefone1 || '',
        email: obj.email || '',
        logradouro: obj.endereco?.logradouro || '',
        numero: obj.endereco?.numero || '',
        complemento: obj.endereco?.complemento || '',
        bairro: obj.endereco?.bairro || '',
        municipio: obj.endereco?.municipio || '',
        uf: obj.endereco?.uf || '',
      }
    }

    if (obj?.data?.['CNPJ']) {
      const ddd = obj?.data?.['DDD'] || ''
      const telefone = obj?.data?.['TELEFONE'] || ''
      // console.log(ddd + telefone)
      return {
        cnpj: obj?.data?.['CNPJ'] || '',
        razao_social: obj?.data?.['RAZAO SOCIAL'] || '',
        nome_fantasia: obj?.data?.['NOME FANTASIA'] || '',
        cep: obj?.data?.['CEP'] || '',
        telefone: ddd + '9' + telefone || '',
        email: obj?.data?.['EMAIL'] || '',
        logradouro: obj?.data?.['LOGRADOURO'] || '',
        numero: obj?.data?.['NUMERO'] || '',
        complemento: obj?.data?.['COMPLEMENTO'] || '',
        bairro: obj?.data?.['BAIRRO'] || '',
        municipio: obj?.data?.['MUNICIPIO'] || '',
        uf: obj?.data?.['UF'] || '',
      }
    }
  }

  const dadosCNPJ = normalizarObjeto(data_cnpj)

  useEffect(() => {
    if (
      data_cnpj !== undefined &&
      dadosCNPJ !== undefined &&
      timeRenderizar &&
      tab_selecionar_atual === 'Pessoa jurídica'
    ) {
      const uf = dataUf.filter((item) => item.sigla === dadosCNPJ?.uf)

      handleChangeCidadeUfCnpj(uf[0]?.cod)
      setUfJuridico(uf[0]?.sigla)
      setRazao_social(dadosCNPJ?.razao_social)
      if (dadosCNPJ?.nome_fantasia) {
        setNomeFantasia(dadosCNPJ?.nome_fantasia)
      }
      setEmailJuridico(dadosCNPJ?.email)
      setCepJuridico(dadosCNPJ?.cep)
      setBairroJuridico(dadosCNPJ?.bairro)
      setEnderecoJuridico(dadosCNPJ?.logradouro)
      setNumeroJuridico(dadosCNPJ?.numero)
      setComplementoJuridico(dadosCNPJ?.complemento)
    }
  }, [data_cnpj])

  // useEffect(() => {
  //   if (dataCidadeFilter.length > 0 && cnpj.replace(/[^\d]/g, '').length === 14 && timeRenderizar) {
  //     console.log('AQUI O B.O')

  //     const cidade = cidadeSemAcento.filter(
  //       (item) => item.nome.toLowerCase() === dadosCNPJ?.municipio.toLowerCase()
  //     )
  //     setCidade(cidade[0]?.nome)

  //   }
  // }, [dataCidadeFilter])

  useEffect(() => {
    if (
      cnpj?.replace(/[^\d]/g, '').length === 14 &&
      tab_selecionar_atual === 'Pessoa jurídica' &&
      timeRenderizar
    ) {
      loadingAlert({ msg: 'Buscando CNPJ...', id: 'cnpj' })
      refetch()
    }
  }, [cnpj])

  useEffect(() => {
    if (cep?.length === 9 && timeRenderizar) {
      checkCep(cep)
    }
  }, [cep])

  useEffect(() => {
    if (cepJuridico?.length === 9 && timeRenderizar) {
      checkCepJuridico(cepJuridico)
    }
  }, [cepJuridico])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTimeRenderizar(true)
    }, 1000)

    return () => {
      clearTimeout(timeoutId)
      setTimeRenderizar(false)
      console.log('Timeout cancelado!')
    }
  }, [])

  return (
    <>
      <HeaderDrawer>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {update ? 'Atualizar laboratório' : 'Cadastrar laboratório'}

          <small style={{ opacity: '0.5', fontSize: '9px' }}>{criadoPorAtualizadoPor}</small>
        </div>
      </HeaderDrawer>

      <BodyDrawer>
        <Render condition={loadingEditar}>
          <Carregando
            animation
            justifyContent='center'
            height={600}
            legenda='Carregando informações...'
          />
        </Render>
        <Render condition={loadingEditar === false}>
          <Tabs
            tabs={[
              { descricao: 'Pessoa jurídica', icone: faIdBadge },
              { descricao: 'Pessoa física', icone: faUser },
            ]}
            selecionado={tab_selecionar_atual}
            trackWidth={348}
            indexInicial={indexInicial}
            onChange={setTab_selecionar_atual}
          />

          {tab_selecionar_atual === 'Pessoa física' && (
            <FormPessoaFisicaLab
              nome={nome}
              setNome={setNome}
              cpf={cpf}
              setCpf={setCpf}
              celular={celular}
              setCelular={setCelular}
              foneFixo={foneFixo}
              setFoneFixo={setFoneFixo}
              email={email}
              setEmail={setEmail}
              cep={cep}
              setCep={setCep}
              uf={uf}
              setUf={setUf}
              ufId={ufId}
              haldleChangeCidadeUf={haldleChangeCidadeUf}
              cidade={cidade}
              setCidade={setCidade}
              cidadeId={cidadeId}
              setCidadeId={setCidadeId}
              bairro={bairro}
              setBairro={setBairro}
              endereco={endereco}
              setEndereco={setEndereco}
              numero={numero}
              setNumero={setNumero}
              complemento={complemento}
              setComplemento={setComplemento}
              dataCidadeFilter={dataCidadeFilter}
              dataCity={dataCity}
              dataUf={dataUf}
              inputDisableCidade={inputDisableCidade}
              setUfId={setUfId}
            />
          )}

          {tab_selecionar_atual === 'Pessoa jurídica' && (
            <FormPessoaJuridicaLab
              cnpj={cnpj}
              setCnpj={setCnpj}
              razaoSocial={razao_social}
              setRazaoSocial={setRazao_social}
              nomeFantasia={nomeFantasia}
              setNomeFantasia={setNomeFantasia}
              iE={iE}
              setIe={setIe}
              celularJuridico={celularJuridico}
              setCelularJuridico={setCelularJuridico}
              foneFixoJuridico={foneFixoJuridico}
              setFoneFixoJuridico={setFoneFixoJuridico}
              emailJuridico={emailJuridico}
              setEmailJuridico={setEmailJuridico}
              cepJuridico={cepJuridico}
              setCepJuridico={setCepJuridico}
              ufJuridico={ufJuridico}
              setUfJuridico={setUfJuridico}
              ufIdJuridico={ufIdJuridico}
              setUfIdJuridico={setUfIdJuridico}
              cidadeJuridico={cidadeJuridico}
              setCidadeJuridico={setCidadeJuridico}
              cidadeIdJuridico={cidadeIdJuridico}
              setCidadeIdJuridico={setCidadeIdJuridico}
              bairroJuridico={bairroJuridico}
              setBairroJuridico={setBairroJuridico}
              enderecoJuridico={enderecoJuridico}
              setEnderecoJuridico={setEnderecoJuridico}
              numeroJuridico={numeroJuridico}
              setNumeroJuridico={setNumeroJuridico}
              complementoJuridico={complementoJuridico}
              setComplementoJuridico={setComplementoJuridico}
              inputDisableCidade={inputDisableCidade}
              haldleChangeCidadeUf={haldleChangeCidadeUf}
              dataUf={dataUf}
              dataCity={dataCity}
              dataCidadeFilter={dataCidadeFilter}
            />
          )}

          <Render condition={update > 0}>
            <Box>
              <TitleH3>Análises</TitleH3>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  gap: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div className='data__vigencia'>
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <label style={{ fontSize: '13px', fontWeight: '500' }}>Data vigência</label>
                  </div>
                  <DatePicker
                    value={dataAnalise}
                    onChange={setDataAnalise}
                    style={{ height: '40px', borderRadius: '10px' }}
                    placeholder='Selecione a data de inicial'
                    format='DD/MM/YYYY'
                  />
                </div>
                <Select
                  value={tipoAnalise}
                  onChange={setTipoAnalise}
                  onChangeID={setTipoAnaliseId}
                  open={openAnalise}
                  trigger={setOpenAnalise}
                  items={dataService}
                  descricaoItem={'nome'}
                  idItem={'cod'}
                  label='Tipo Análise'
                  placeholder='Selecione a análise'
                  icon={faFlask}
                  width={'37%'}
                  disabled={permissions?.acesso === 1}
                />
                <Input
                  placeholder='0,00'
                  value={valor}
                  onChange={mascaraValorAnalise}
                  label='Valor'
                  icon={faMoneyBill}
                  width={'20%'}
                  desabilitado={permissions?.acesso === 1}
                />
                <ButtonCustom
                  style={{ marginTop: '10px' }}
                  isCircle
                  onClick={adicionarAnalise}
                  disabled={loadingAddAnalise || permissions?.exclusao === 0}
                  loading={loadingAddAnalise}
                  icon={<FontAwesomeIcon icon={faPlus} />}
                />
              </div>

              <HeaderTable
                itens={[
                  { valor: 'Data', width: '90px' },
                  { valor: 'Serviço', width: 'calc(100% - 250px)' },
                  { valor: 'valor', width: '80px' },
                  { valor: 'Ações', width: '80px' },
                ]}
              />

              <BodyTable
                style={{
                  height: (dataFornecedorServi ?? []).length < 8 && 'calc(100vh - 550px)',
                  position: 'relative',
                  overflowX: 'hidden',
                  overflowY: 'scroll',
                  marginTop: -10,
                }}
              >
                <Render condition={!loadingAnalises}>
                  {(dataFornecedorServi ?? []).map(
                    ({ cod, data, profundidade_nome, analise_nome, valor }) => {
                      return (
                        <RowTable key={cod} onDoubleClick={() => {}}>
                          <RowItem fontSize={12} width={'89px'}>
                            {dayjs(data).format('DD/MM/YYYY')}
                          </RowItem>
                          <RowItem fontSize={12} width={'calc(100% - 243px)'}>
                            {`${profundidade_nome} | ${analise_nome}`}
                          </RowItem>
                          <RowItem fontSize={12} width={'80px'}>
                            {valor}
                          </RowItem>

                          <RowItem width={'74px'} gap={10}>
                            <DeleteButton
                              open={confirmar_exclusao && id_item_atual === cod}
                              trigger={setConfirmar_exclusao}
                              onTrigger={() => {
                                setId_item_atual(cod)
                                setNome_item_atual(`${profundidade_nome} | ${analise_nome}`)
                              }}
                              loading={fake_loading}
                              message={`o item ${`${profundidade_nome} | ${analise_nome}`} será excluido!`}
                              deleteds={[`${profundidade_nome} | ${analise_nome}`]}
                              onDelete={() => {
                                deletarAnalise(cod)
                              }}
                              disabled={fake_loading}
                              disabledTrigger={permissions?.exclusao === 0}
                              typeButtonTrigger='secondary'
                            />
                          </RowItem>
                        </RowTable>
                      )
                    }
                  )}
                </Render>

                <Render condition={dataFornecedorServi.length === 0 && !loadingAnalises}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<> Nenhum registro disponível!</>}
                  />
                </Render>

                <Render condition={loadingAnalises}>
                  <Carregando
                    animation
                    legenda='Carregando análises...'
                    justifyContent='center'
                    height={'100%'}
                    size='default'
                  />
                </Render>
              </BodyTable>
            </Box>
          </Render>
        </Render>
      </BodyDrawer>
    </>
  )
}

export default FormLaboratorioNovo
