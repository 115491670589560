import React, { useState } from 'react'
import { Empty, Tag } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowsToEye,
  faClone,
  faEdit,
  faMapLocation,
  faMapLocationDot,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { ItensPerUserLogged } from '../../../../../services/login/permissaoUsuarioLoado'
import { removerAcentos } from '../../../../../utils'
import { Box } from '../../../../../Components/CustomUi/box'
import { Inline } from '../../../../../Components/CustomUi/inline'
import Input from '../../../../../Components/CustomUi/input'
import ButtonCustom from '../../../../../Components/ButtonCustom'
import { HeaderTable } from '../../../../../Components/CustomUi/table/headerTable'
import { BodyTable } from '../../../../../Components/CustomUi/table/bodyTable'
import { Render } from '../../../../../Components/CustomUi/render'
import { RowTable } from '../../../../../Components/CustomUi/table/rowTable'
import { RowItem } from '../../../../../Components/CustomUi/table/rowItem'
import { DeleteButton } from '../../../../../Components/CustomUi/deleteButton'
import Carregando from '../../../../../Components/CustomUi/loading'
import GeneratePdfButton from '../../../../../Components/relatorio-component'
import { Switcher } from '../../../../../Components/CustomUi/Switcher'

//COMPONENTS

//PROPS
interface Props {
  loading: boolean
  list_propriedades: any[]
  deletar: Function
  abrir?: Function
  editar?: Function
  permissao: ItensPerUserLogged
  setPagination?: React.Dispatch<React.SetStateAction<number>>
  page?: number
  setPage?: React.Dispatch<React.SetStateAction<number>>
  total: number
  confirmar_exclusao: boolean
  setConfirmar_exclusao: React.Dispatch<React.SetStateAction<boolean>>
  id_item_atual: number
  setId_item_atual: React.Dispatch<React.SetStateAction<number>>
  setNome_item_atual: React.Dispatch<React.SetStateAction<string>>
  buscarTabela: string
  setBuscarTabela: React.Dispatch<React.SetStateAction<string>>
  inativos: boolean
  setInativos: React.Dispatch<React.SetStateAction<boolean>>
  setCodPropriedade: React.Dispatch<React.SetStateAction<number>>
  fake_loading: boolean
  loadingVisuPropriedades: boolean
  visualizarTodasPropriedades: Function
  handleVisualizarPropri: Function
  handleAlteraStatus: Function
  dadosEstatisticas: any[]
  dadosResultado: any[]
}

export const TabelaPropriedades: React.FC<Props> = ({
  list_propriedades,
  loading,
  deletar,
  abrir,
  setPagination,
  editar,
  page,
  setPage,
  total,
  confirmar_exclusao,
  setConfirmar_exclusao,
  id_item_atual,
  setId_item_atual,
  setNome_item_atual,
  buscarTabela,
  setBuscarTabela,
  inativos,
  setInativos,
  setCodPropriedade,
  fake_loading,
  loadingVisuPropriedades,
  permissao,
  visualizarTodasPropriedades,
  handleVisualizarPropri,
  handleAlteraStatus,
  dadosEstatisticas,
  dadosResultado,
}) => {

  function buscarFornecedores(buscar_nome: string) {
    const palavraSemAcentos = removerAcentos(buscar_nome)
    return function (item) {
      const tagSemAcentos = removerAcentos(String(item?.tags))
      const nomeSemAcentos = removerAcentos(String(item?.nome))

      return (
        nomeSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) ||
        tagSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) ||
        !buscar_nome
      )
    }
  }

  // const fornecedoresFiltrados = list_propriedades.filter((item) =>
  //   item.tags?.some((produto) => produto.toLowerCase().includes(buscarTabela.toLowerCase()))
  // )

  // console.log('fornecedor', fornecedoresFiltrados)

  // function removerUltimosDigitos(value) {
  //   if (!value) return ''
  //   // Remove qualquer caractere que não seja número
  //   const numericValue = value.replace(/\D/g, '')
  //   // Retorna o valor sem os dois últimos dígitos
  //   return numericValue.slice(0, -2)
  // }

  function municipio(cidade) {
    if (cidade[0]?.nome !== undefined && cidade[0]?.uf_sigla !== undefined) {
      return `${cidade[0]?.nome} - ${cidade[0]?.uf_sigla}`
    }
  }

  return (
    <Box style={{ height: 'calc(100vh - 170px)' }}>
      <Inline alignItems='center'>
        <Input placeholder='Buscar propriedades' value={buscarTabela} onChange={setBuscarTabela} />
        <ButtonCustom icon={<FontAwesomeIcon icon={faPlus} />} onClick={abrir}>
          Novo
        </ButtonCustom>
        <ButtonCustom
          icon={<FontAwesomeIcon icon={faArrowsToEye} />}
          onClick={visualizarTodasPropriedades}
          disabled={loadingVisuPropriedades}
          loading={loadingVisuPropriedades}
          type='secondary'
        >
          Visualizar todas propriedades
        </ButtonCustom>
        {localStorage.getItem('tipo-acesso') === 'conta' ? (
          <GeneratePdfButton
            dados={dadosEstatisticas}
            title={'Relatório propriedade'}
            totais={dadosResultado}
            disable={list_propriedades.length <= 0}
          />
        ) : null}
      </Inline>
      <Switcher isChecked={inativos} trigger={setInativos} label='Ocultar propriedades inativos' />
      <HeaderTable
        itens={[
          { valor: 'Ativo', width: '75px' },
          { valor: 'Nome', width: 'calc(100% - 595px)' },
          { valor: 'Área/ha', width: '95px' },
          { valor: 'Município/UF', width: '200px' },
          { valor: 'Ações', width: '225px' },
        ]}
      />

      <BodyTable
        paginate={total}
        onChangeItensPage={setPagination}
        onChangePage={setPage}
        current_page={page}
        setCurrent_page={setPage}
        style={{
          height: (list_propriedades ?? []).length < 8 && 'calc(100vh - 170px)',
          position: 'relative',
          overflowX: 'hidden',
          overflowY: 'scroll',
          marginTop: -10,
        }}
      >
        <Render condition={!loading}>
          {(list_propriedades ?? [])
            .filter(buscarFornecedores(buscarTabela))
            .map(({ cod, index, status, nome, area, cidade }) => {
              return (
                <RowTable
                  key={cod}
                  onDoubleClick={() => editar(cod, false)}
                  style={{ height: 'auto' }}
                >
                  <RowItem width={'75px'}>
                    <Switcher
                      isChecked={status}
                      trigger={() => handleAlteraStatus(cod, index, status)}
                    />
                  </RowItem>
                  <RowItem fontSize={13} width={'calc(100% - 590px)'}>
                    {nome}
                  </RowItem>
                  <RowItem fontSize={13} width={'95px'}>
                    {area} ha
                  </RowItem>
                  <RowItem fontSize={13} width={'200px'}>
                    {municipio(cidade)}
                  </RowItem>
                  <RowItem width={'220px'} gap={10}>
                    <DeleteButton
                      open={confirmar_exclusao && id_item_atual === cod}
                      trigger={setConfirmar_exclusao}
                      onTrigger={() => {
                        setId_item_atual(cod)
                        setNome_item_atual(nome)
                      }}
                      loading={fake_loading}
                      message={`o item ${nome} será excluido!`}
                      deleteds={[nome]}
                      onDelete={() => {
                        deletar(cod)
                      }}
                      disabled={fake_loading}
                      disabledTrigger={permissao?.exclusao === 0}
                      typeButtonTrigger='tertiary'
                    />
                    <ButtonCustom
                      icon={<FontAwesomeIcon icon={faMapLocationDot} />}
                      onClick={() =>
                      {
                        setCodPropriedade(cod);
                        handleVisualizarPropri(
                          nome,
                          {
                            key: '0',
                            value: nome,
                            children: 'Talhões da propriedade',
                          },
                          cod
                        )
                      }
                      }
                      disabled={permissao?.alteracao === 0 && permissao?.acesso === 0}
                      type='secondary'
                    ></ButtonCustom>
                    <ButtonCustom
                      icon={<FontAwesomeIcon icon={faEdit} />}
                      onClick={() => {
                        editar(cod, false)
                      }}
                      disabled={permissao?.alteracao === 0 && permissao?.acesso === 0}
                    >
                      Editar
                    </ButtonCustom>
                  </RowItem>
                </RowTable>
              )
            })}
        </Render>

        <Render condition={list_propriedades.length === 0 && !loading}>
          {buscarTabela === '' ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<> Nenhum registro disponível!</>}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  {' '}
                  <b>'{buscarTabela}'</b> não encontrado!
                </>
              }
            />
          )}
        </Render>

        <Render condition={loading}>
          <Carregando
            animation
            legenda='Carregando propriedades...'
            justifyContent='center'
            height={400}
            size='default'
          />
        </Render>
      </BodyTable>
    </Box>
  )
}