import styled from "styled-components";
import { leadZero } from "../../../../../../../../utils";
import React from "react";

//STATES
const Circle = styled.div`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;

    :is([data-color = 'green']) {
        background: #01FF00;
        color: #004D25;
    }
    :is([data-color = 'yellow']) {
        background: #FCFF02;
        color: #B35600;
    }
    :is([data-color = 'orange']) {
        background: #FCB700;
        color: #8F3200;
    }
    :is([data-color = 'red']) {
        background: #FB0303;
        color: #ffffff;
    }
    :is([data-small = true]) {
        font-size: 11.5px;
    }
`;

//PROPS
interface Props {
    value: string | number;
    color: 'green' | 'yellow' | 'orange' | 'red';
}

export const CircleNC: React.FC<Props> = ({ value, color }) => {
    if (Boolean(value) === false) {
        return null;
    }
    return (
        <Circle data-color={color} data-small={Number(value) > 99}>
            {leadZero(Number(value))}
        </Circle>
    )
}
