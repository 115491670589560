import styled from "styled-components";

export const Page = styled.div`
    position: relative;
    display: flex;
    width: 297mm;
    /* min-height: 210mm;
    height: 210mm; */
    align-items: center;
    flex-direction: column;
    border: 1px solid #bbd9cb;
    box-sizing: border-box;
    padding: 20px;
    page-break-before: always;

    :is([data-print = true]) {
        display: none;
    }


    @media print {
        
        :is([data-print = false]) {
            display: none;
        }
        :is([data-print = true]) {
            display: flex;
        }
    }

    h2.Page__title {
        font-weight: 700;
        color: #498564;
    }

    div.Page__table {
        width: 100%;
        margin-top: 15px;
        border: thin solid var(--border-color);
        border-top-left-radius: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        /* page-break-after: always; */

        div.Page__table__header {
            width: 100%;
            background: #fff;
            border-bottom: thin solid var(--border-color);
            color: var(--text-color);
            display: flex;
            align-items: center;
            box-sizing: border-box;
            overflow: hidden;

            .Page__table__header__title {
                box-sizing: border-box;
                padding: 8px;
                width: 120px;
                background: var(--hover-color);
                border-right: thin solid var(--border-color);
                font-weight: 600;
            }
            .Page__table__header__title.bolder {
                font-weight: 700;
            }
            .Page__table__header__value {
                box-sizing: border-box;
                padding: 8px;
                width: calc(100% - 120px);
                text-transform: capitalize;
                font-weight: 500;
            }
            .Page__table__header__value.bolder {
                font-weight: 600;
            }
        }

        div.Page__table__content {
            width: 100%;
            display: flex;
            flex-direction: column;

            div.Page__table__contorno {
                width: 100%;
                min-height: 250px;
                /* border-right: thin solid var(--border-color); */
                box-sizing: border-box;
                padding: 10px;
                /* page-break-inside: avoid; */

                @media print {
                    display: none;
                }
            }

            div.Page__table__midias {
                width: 100%;
                height: 100%;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-auto-rows: minmax(100px, auto); 
                grid-auto-flow: row;

                /* display: flex;
                flex-wrap: wrap; */

                box-sizing: border-box;
                padding: 10px;
                grid-gap: 15px;

                div.Page__table__midias__container {
                    width: 100%;
                    /* height: min-content; */
                    border: 2px solid var(--border-color);
                    box-sizing: border-box;
                    padding: 10px;
                    border-radius: 8px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    page-break-inside: avoid;

                    p.Page__table__midias__container__title {
                        font-weight: 600;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                    }

                    img.Page__table__midias__container__img {
                        width: 100%;
                        border-radius: 5px;
                        margin-top: -15px;
                    }
                }
            }

        }
    }

    div.Page__radape {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 15px 0px;
        gap: 10px;
        color: var(--primary-color);
        font-size: 10px;
        page-break-inside: avoid;

        img.Page__radape__logo {
            width: 95px;
        }
    }
`;