import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//APP
import { TypeEstrutura } from '../../Views/app/talhaoSafra/components/painelTodasPropriedades/meta';
import { logo_ttivos_base64 } from './meta';

//COMPONENTS
import ButtonCustom from "../ButtonCustom";

const exportToExcel = async (
        posicao_logo: number, 
        titulo: string, 
        nome_do_arquivo: string, 
        nome_tab: string, 
        colunas_tabela_1: {name:string}[],
        linhas_tabela_1: (string[] | number[])[],
        usuario: string,
        width_coluna: number = 20,
        colunas_tabela_2?: {name:string}[],
        linhas_tabela_2?: (string[] | number[])[],
        titulo_secundario?: string,
        colunas_tabela_3?: {name:string}[],
        linhas_tabela_3?: (string[] | number[])[],
        estilizar_celulas?: {posicao: string, background: string, color: string}[]
    ) => {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(nome_tab);

    

    /**retorna as colunas que devem ser mescaladas:
     * 
     * EXEMPLO DE RETORNO: `A2:D2`
     */
    function mergeCells(letra_coluna_inicial: string, numero_linha_inicial: string) {
        let qtd_colunas: number = (colunas_tabela_1?.length ?? 1) - 1;
        let letra_coluna: string[] = ['C', 'C', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        return `${letra_coluna_inicial}${numero_linha_inicial}:${letra_coluna[qtd_colunas]}${numero_linha_inicial}`;
    }

    // ** HEADER **
    worksheet.getRow(1).height = 40; // Linha para a imagem
    worksheet.getRow(2).height = 40; // Linha para o título
    worksheet.getRow(3).height = 30; // Linha para o título secundário
    worksheet.getRow(5).height = 20; // Linha para o header da tabela
    worksheet.getRow(5).alignment = { vertical: 'middle' };

    // LOGO
    const headerImageId = workbook.addImage({
        base64: logo_ttivos_base64, 
        extension: 'png',
    });
    worksheet.addImage(headerImageId, {
        tl: { col: posicao_logo ?? 1.7, row: 0 }, // Top-left corner: célula B1
        ext: { width: 121, height: 50 }, // Dimensão da imagem
        editAs: 'LOGO TTIVOS'
    });
    worksheet.mergeCells(mergeCells('A','1')); // Mesclando células para o texto ocupar mais espaço
    worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };


    // TÍTULO DA PLANILHA
    worksheet.getCell('A2').value = titulo ?? '';
    worksheet.getCell('A2').font = { bold: true, size: 16 };
    // worksheet.mergeCells('A2:D2'); // Mesclando células para o texto ocupar mais espaço
    worksheet.mergeCells(mergeCells('A','2')); // Mesclando células para o texto ocupar mais espaço
    worksheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'center' };

    //TÍTUDO DA TABELA (TITULO SECUNDÁRIO)
    worksheet.getCell('A3').value = titulo_secundario ?? '';
    worksheet.getCell('A3').font = { bold: true, size: 12 };
    worksheet.mergeCells(mergeCells('A','3')); 
    worksheet.getCell('A3').alignment = { vertical: 'middle', horizontal: 'center' };



    // ** TABELA PRINCIPAL **
    worksheet.addTable({
        name: 'TabelaPrincipal',
        ref: 'A5', // Posição da tabela (abaixo do header)
        headerRow: true,
        totalsRow: false,
        style: {
            theme: 'TableStyleMedium2',
            showRowStripes: true,
        },
        columns: colunas_tabela_1 ?? [{name: 'temp'}],
        rows: linhas_tabela_1,
    });
    
    // ** TABELA SECUNDÁRIA **
    if (!!linhas_tabela_2 && linhas_tabela_2.length > 0) {
        worksheet.addTable({
            name: 'TabelaSecundaria',
            ref: `A${(linhas_tabela_1.length) + 5 + 2}`, // Posição da tabela (abaixo do header)
            headerRow: true,
            totalsRow: false,
            style: {
                theme: 'TableStyleMedium2',
                showRowStripes: true,
            },
            columns: colunas_tabela_2 ?? [{name: 'temp'}],
            rows: linhas_tabela_2,
        });
    }
    
    // ** TABELA TERCIÁRIA **
    if (!!linhas_tabela_3 && linhas_tabela_3.length > 0) {
        worksheet.addTable({
            name: 'TabelaSecundaria',
            ref: `A${linhas_tabela_1.length + (linhas_tabela_2.length) + 5 + 4}`, // Posição da tabela (abaixo do header)
            headerRow: true,
            totalsRow: false,
            style: {
                theme: 'TableStyleMedium2',
                showRowStripes: true,
            },
            columns: colunas_tabela_3 ?? [{name: 'temp'}],
            rows: linhas_tabela_3,
        });
    }
    


    let letra_coluna: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    // Estilizando cabeçalho
    for (let i = 0; i < (colunas_tabela_1.length + 1); i++) {
        worksheet.getColumn(i + 1).width = width_coluna; 

        if (colunas_tabela_1.length > i) {
            worksheet.getCell(letra_coluna[i]+'5').fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '5da57d' }, // Cor de fundo (primary-color),
            };
            worksheet.getCell(letra_coluna[i]+'5').font = { bold: true, color: {argb: 'FFFFFF'} };
        }
        
    }

    if (!!linhas_tabela_2 && linhas_tabela_2.length > 0) {
        // Estilizando cabrçalho
        for (let i = 0; i < (colunas_tabela_2.length + 1); i++) {
            worksheet.getColumn(i + 1).width = width_coluna; 

            if (colunas_tabela_2.length > i) {

                worksheet.getRow(linhas_tabela_1.length + 5 + 2).height = 20;
                worksheet.getRow(linhas_tabela_1.length + 5 + 2).alignment = { vertical: 'middle' };

                worksheet.getCell(letra_coluna[i]+`${linhas_tabela_1.length + 5 + 2}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '5da57d' }, // Cor de fundo (primary-color),
                };
                worksheet.getCell(letra_coluna[i]+`${linhas_tabela_1.length + 5 + 2}`).font = { bold: true, color: {argb: 'FFFFFF'} };
            }
        }
    }

    if (!!linhas_tabela_3 && linhas_tabela_3.length > 0) {
        // Estilizando cabrçalho
        for (let i = 0; i < (colunas_tabela_3.length + 1); i++) {
            worksheet.getColumn(i + 1).width = width_coluna; 

            let soma_tamanho_tabelas = (linhas_tabela_1.length) + (linhas_tabela_2.length);

            if (colunas_tabela_3.length > i) {

                worksheet.getRow(soma_tamanho_tabelas + 5 + 4).height = 20;
                worksheet.getRow(soma_tamanho_tabelas + 5 + 4).alignment = { vertical: 'middle' };

                worksheet.getCell(letra_coluna[i]+`${soma_tamanho_tabelas + 5 + 4}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '5da57d' }, // Cor de fundo (primary-color),
                };
                worksheet.getCell(letra_coluna[i]+`${soma_tamanho_tabelas + 5 + 4}`).font = { bold: true, color: {argb: 'FFFFFF'} };
            }
        }
    }
    
    //PINTAR CELULA
    if ((estilizar_celulas ?? []).length > 0) {
        for (const item of estilizar_celulas) {
            worksheet.getCell(item?.posicao).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: item.background },
            };
            worksheet.getCell(item?.posicao).font = { bold: true, color: {argb: item.color} };
            worksheet.getCell(item?.posicao).border = { 
              top: { style: 'thin', color: { argb: 'FF000000' } },
              left: { style: 'thin', color: { argb: 'FF000000' } },
              bottom: { style: 'thin', color: { argb: 'FF000000' } },
              right: { style: 'thin', color: { argb: 'FF000000' } },
            };
            
        }
    }
    
    
    // ** FOOTER **
    const espacoRow = worksheet.addRow([]);
    espacoRow.height = 20; 

    const footerRow = worksheet.addRow([]);
    footerRow.getCell(1).value = `Gerador por: ${usuario} - ${dayjs().format('DD/MM/YYYY HH:mm:ss')}`;
    footerRow.font = { italic: true };
    footerRow.alignment = { horizontal: 'center', vertical: 'middle' };

    for (let i = 0; i < (colunas_tabela_1.length); i++) {
        worksheet.getCell(letra_coluna[i]+`${footerRow.number}`).border = {
            top: { style: 'thin', color: { argb: '5da57d' } }
        };
        
    }

    // Mesclando o footer em várias colunas
    worksheet.mergeCells(mergeCells('A',`${footerRow.number}`));
    footerRow.height = 25; // altura do footer

    // Gerando e salvando o arquivo
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${nome_do_arquivo}.xlsx`);
};



//PROPS
interface Props {
    /**valor/descrição/nome do botão
     * 
     * default: `Gerar excel`
    */
    valor_btn?: string;
    /**nome do arquivo .xlsx */
    nome_do_arquivo: string;
    /**nome da tab inferior da planilha */
    nome_tab?: string;
    /**Título da Planilha 
     * 
     * exemplo: `Relatório de Talhões de Safra`
    */
    titulo: string;
    /**Título da tabela
     * 
     * exemplo: `Cliente: WANDERLEY RODRIGUES MACIEL`
    */
    titulo_secundario?: string;
    /**Usuário que gerou o relatório */
    usuario: string;
    /**largura das colunas da tabela
     * 
     * default: `20`
     */
    width_coluna?: number;
    /**dados do relatório para montar a tabela da planilha */
    tabela: TypeEstrutura[];
    /**dados do relatório para montar a 2° tabela da planilha */
    tabela_secundaria?: TypeEstrutura[];
    /**dados do relatório para montar a 3° tabela da planilha */
    tabela_terciaria?: TypeEstrutura[];
    /** Desabilitar botão de gerar excel */
    disabled?: boolean;
    /**tipo de botão: primary / secondary / tertiary */
    typeBtn?: 'primary' | 'secondary' | 'tertiary',
    /**Usado quando é necessário alinhar a logo o ttivos ao centro da planilha
     * 
     * default: 1.7
     */
    posicao_logo?: number;
    /**usado para mudar cor do fundo e dafonte de uma celula especifica */
    estilizar_celulas?: {posicao: string, background: string, color: string}[]
}

export const GerarExcel: React.FC<Props> = ({ valor_btn = 'Gerar excel', titulo, nome_do_arquivo, typeBtn, posicao_logo = 1.7,
    nome_tab = 'ttivos', tabela, tabela_secundaria, tabela_terciaria, usuario, width_coluna, titulo_secundario, disabled, estilizar_celulas }) => {

    //STATES
    const [fake_loaging, setFake_loaging] = useState<boolean>(false);

    //ESTRUTURA DA TABELA - PRINCIPAL
    const colunas_tabela_1: TypeEstrutura['planilha']['columns'] = (tabela?.[0]?.planilha?.columns ?? []).map(item=> { return {name: item.name} });
    const linhas_tabela_1: TypeEstrutura['planilha']['rows'] = (tabela?.[0]?.planilha?.rows ?? []).filter((item, index)=> {
            return item
    });
    //ESTRUTURA DA TABELA - SECUNDÁRIO
    const colunas_tabela_2: TypeEstrutura['planilha']['columns'] = (tabela_secundaria?.[0]?.planilha?.columns ?? []).map(item=> { return {name: item.name} });
    const linhas_tabela_2: TypeEstrutura['planilha']['rows'] = (tabela_secundaria?.[0]?.planilha?.rows ?? []).filter((item, index)=> {
            return item
    });
    //ESTRUTURA DA TABELA - TERTIARIA
    const colunas_tabela_3: TypeEstrutura['planilha']['columns'] = (tabela_terciaria?.[0]?.planilha?.columns ?? []).map(item=> { return {name: item.name} });
    const linhas_tabela_3: TypeEstrutura['planilha']['rows'] = (tabela_terciaria?.[0]?.planilha?.rows ?? []).filter((item, index)=> {
            return item
    });

    function gerarExcel() {
        setTimeout(() => {
            exportToExcel(
                posicao_logo,
                titulo, 
                nome_do_arquivo, 
                nome_tab, 
                colunas_tabela_1, 
                linhas_tabela_1, 
                usuario, width_coluna, 
                colunas_tabela_2, 
                linhas_tabela_2,
                titulo_secundario,
                colunas_tabela_3,
                linhas_tabela_3,
                estilizar_celulas
            );
            setFake_loaging(false);
        }, 1000);
    }

    return (
        <ButtonCustom
            onClick={() => { setFake_loaging(true); gerarExcel() }}
            icon={<FontAwesomeIcon icon={faFileExcel} />}
            loading={fake_loaging}
            disabled={fake_loaging || disabled}
            type={typeBtn}
        >
            {fake_loaging ? 'gerando...' : valor_btn}
        </ButtonCustom>
    )
}