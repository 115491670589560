
//STYLES
import { MediaContainer, MediaDescription } from "./style"

//APP
import { useGetImagemPontoMonitoramento } from "../../../../services/monitoramento/imagemMonitoramento/get";

//COMPONENTS
import { Box } from "../../../CustomUi/box";
import { TitleH4 } from "../../../CustomUi/titleH4";
import { useState } from "react";
import { Render } from "../../../CustomUi/render";
import Carregando from "../../../CustomUi/loading";
import { Empty } from "antd";
import { SlidesFullScreen } from "../../../CustomUi/slidesFullScreen";

//PROPS
interface Props {
    codMonitoramento: number | null;
}

export const Midias: React.FC<Props> = ({ codMonitoramento }) => {

    //API
    const { data_imagem, carregando_imagem } = useGetImagemPontoMonitoramento(codMonitoramento);

    //STATES
    const [descricao_atual, setDescricao_atual] = useState<number>(0);
    const [openSlides, setOpenSlides] = useState<boolean>(false);

    return (
        <Box padding={15}>
            <TitleH4 style={{color: 'var(--text-color-light)'}}>Mídias</TitleH4>
            <Render condition={!carregando_imagem && data_imagem.length > 0}>
                <MediaContainer>
                    {data_imagem.map(({ cod, url_arquivo }, index)=> {
                        return (
                            <div 
                                role="none" 
                                className='MediaContainer__media' 
                                key={cod} 
                                data-first={index===0} 
                                data-selected={descricao_atual===index}
                                onMouseOver={()=>{setDescricao_atual(index);}}
                                onClick={()=> {
                                    setDescricao_atual(index);
                                    setOpenSlides(true);
                                }}
                            >
                                <img src={url_arquivo} alt='media' className="MediaContainer__media__img" />
                            </div>
                        )
                    })}
                </MediaContainer>
                <MediaDescription>
                    {data_imagem?.[descricao_atual]?.descricao}
                </MediaDescription>
            </Render>
            
            <Render condition={carregando_imagem}>
                <Carregando 
                    animation
                    legenda="Carregando mídias..."
                    justifyContent="center"
                />
            </Render>

            <Render condition={!carregando_imagem && data_imagem.length===0}>
                <div style={{height:100, marginTop: -30}}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Esse ponto não possui mídias'} />
                </div>
            </Render>

            <Render condition={openSlides}>
                <SlidesFullScreen 
                    slides={data_imagem.map(item=> {return { media: item.url_arquivo, type: 'image', description: item.descricao }})}
                    onClose={()=>setOpenSlides(false)}
                    slide_inicial={descricao_atual}
                />
            </Render>
            

        </Box>
    )
}