import React, { useEffect, useState } from 'react'
import { ContainerCalcario } from './styles'
import ViewPage from '../../../../Components/ViewPage'
import { TitleH1 } from '../../../../Components/CustomUi/titleH1'
import { TabelaCalcarios } from './components/tabela'
import {
  delCalcario,
  getCalcario,
  ItensCalcario,
  postCalcario,
  putCalcario,
} from '../../../../services/recomendacao/calcario'
import { FornecedorItens, getFornecedor } from '../../../../services/Fornecedores'
import { Drawer } from 'antd'
import FormCalcarioNovo from '../../../../Components/formCalcarioNovo'
import ButtonCustom from '../../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk, faXmark } from '@fortawesome/free-solid-svg-icons'
import { NavegacaoDrawer } from '../novoCompatibilidadeAtivo/components/gavetaCadastrar/styled'
import { localConfig } from '../../../../Configs/localConfig'
import { successAlert } from '../../../../utils/alert'
import { tryError } from '../../../../services/Afins'
import dayjs from 'dayjs'

const CalcarioNovo: React.FC = () => {
  const config = localConfig()
  const [open, setOpen] = useState(false)
  const [dataCalcario, setDataCalcario] = useState<ItensCalcario[]>([])
  const [dataFornecedor, setDataFornecedor] = useState<FornecedorItens[]>([])
  const [loadingForm, setLoadingForm] = useState(false)
  const [loadingSalvar, setLoadingSalvar] = useState(false)
  const [criadoPorAtualizadoPor, setCriadoPorAtualizadoPor] = useState('')

  //TABELA
  const [update, setUpdate] = useState(0)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState<number>(15)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState(null)
  const [confirmar_exclusao, setConfirmar_exclusao] = useState<boolean>(false)
  const [id_item_atual, setId_item_atual] = useState<number>(null)
  const [nome_item_atual, setNome_item_atual] = useState<string>('')
  const [fake_loading, setFake_loading] = useState<boolean>(false)

  //FORMULARIO
  const [nome, setNome] = useState('')
  const [fornecedor, setFornecedor] = useState<string>('')
  const [fornecedorId, setFornecedorId] = useState<number>(null)
  const [oxidoCalcio, setOxidoCalcio] = useState<string>('')
  const [oxidoMagnesio, setOxidoMagnesio] = useState<string>('')
  const [poderNeutralizacao, setPoderNeutralizacao] = useState<string>('')
  const [poderRelativoNeutralizacaoTotal, setPoderRelativoNeutralizacaoTotal] = useState<string>('')
  const [relatividade, setRelatividade] = useState<string>('')
  const [peneiraFundo, setPeneiraFundo] = useState<string>('')

  const listCalcario = async () => {
    setLoading(true)
    const resp = await getCalcario({ page: page, pagination: pagination })

    setDataCalcario(resp.data.itens)
    setTotal(resp.data.total)

    setLoading(false)
  }

  const listFornecedor = async () => {
    const resp = await getFornecedor({ codCategoria: 1, codConta: config.conta })
    const palavras = ['CAL', 'CALCARIO', 'MINERACAO', 'MINERAIS']
    const filterFornecedorCalcario = resp.data.itens.filter((item) =>
      palavras.some((palavra) => item.nome.toLowerCase().includes(palavra.toLowerCase()))
    )
    setDataFornecedor(filterFornecedorCalcario)
  }

  function abrirGaveta() {
    setOpen(true)
  }

  function fecharGaveta() {
    setOpen(false)
    setUpdate(0)
    setNome('')
    setFornecedor('')
    setFornecedorId(null)
    setOxidoCalcio('')
    setOxidoMagnesio('')
    setPoderNeutralizacao('')
    setPoderRelativoNeutralizacaoTotal('')
    setRelatividade('')
    setPeneiraFundo('')
    setCriadoPorAtualizadoPor('')
  }

  function editarCadastro(cod) {
    setOpen(true)
    setUpdate(cod)
  }

  const salvarCalcario = async () => {
    const data = {
      cod_fornecedor: fornecedorId,
      nome: nome,
      ca_o: Number(oxidoCalcio),
      mg_o: Number(oxidoMagnesio),
      pn: Number(poderNeutralizacao),
      re: Number(relatividade),
      prnt: Number(poderRelativoNeutralizacaoTotal),
      peneira_fundo: Number(peneiraFundo),
      tipo: 1,
      fonecedor_nome: fornecedor,
    }
    setLoadingSalvar(true)
    try {
      if (update <= 0) {
        await postCalcario(data)
        successAlert('Calcário salvo com sucesso!')
        fecharGaveta()
        listCalcario()
        setLoadingSalvar(false)
      } else {
        await putCalcario(update, data)
        successAlert('Calcário atualizado com sucesso!')
        handleUpdateCalcario(update)
        setLoadingSalvar(false)
      }
    } catch (error) {
      tryError(error)
      setLoadingSalvar(false)
    }
  }

  async function handleClickDelCalcario(cod: number) {
    setFake_loading(true)
    try {
      const resp = await delCalcario(cod)

      if (resp.status === 200) {
        successAlert('Calcario removido com sucesso!')
        listCalcario()
        setTimeout(() => {
          setFake_loading(false)
        }, 3000)
      }
    } catch (error) {
      tryError(error)
    }
  }

  const handleUpdateCalcario = async (cod: number) => {
    setLoadingForm(true)
    const resp = await getCalcario({ cod })

    setNome(resp.data.itens[0].nome)
    setFornecedor(resp.data.itens[0].fonecedor_nome)
    setFornecedorId(resp.data.itens[0].cod_fornecedor)
    setOxidoCalcio(resp.data.itens[0].ca_o)
    setOxidoMagnesio(resp.data.itens[0].mg_o)
    setPoderNeutralizacao(resp.data.itens[0].pn)
    setPoderRelativoNeutralizacaoTotal(resp.data.itens[0].prnt)
    setRelatividade(resp.data.itens[0].re)
    setPeneiraFundo(resp.data.itens[0].peneira_fundo)
    setLoadingForm(false)

    verificarSeFoiAtualizado(
      resp.data.itens[0]?.create_user,
      resp.data.itens[0]?.create_time,
      resp.data.itens[0]?.update_user,
      resp.data.itens[0]?.update_time
    )
  }

  const verificarSeFoiAtualizado = (create_user, create_time, update_user, update_time) => {
    if (dayjs(update_time).isAfter(dayjs(create_time))) {
      setCriadoPorAtualizadoPor(
        `Atualizado por: ${update_user} em ${dayjs(update_time).format('DD/MM/YYYY HH:mm')}`
      )
    } else {
      setCriadoPorAtualizadoPor(
        `Criado por: ${create_user} em ${dayjs(create_time).format('DD/MM/YYYY HH:mm')}`
      )
    }
  }

  useEffect(() => {
    if (update > 0) {
      handleUpdateCalcario(update)
    }
  }, [update])

  useEffect(() => {
    listCalcario()
    listFornecedor()
  }, [])

  useEffect(() => {
    listFornecedor()
  }, [page])

  return (
    <ContainerCalcario>
      <ViewPage
        title='Cadastro calcário'
        rotina={91}
        hiddeTitle
        cardBodyStyle={{ padding: 15 }}
        cardMainStyle={{ height: '87.5vh' }}
        newHide
      >
        <div style={{ marginBottom: '10px' }}>
          <TitleH1>Cadastro calcário</TitleH1>
        </div>

        <TabelaCalcarios
          loading={loading}
          list_calcarios={dataCalcario}
          deletar={handleClickDelCalcario}
          total={total}
          abrir={abrirGaveta}
          editar={editarCadastro}
          page={page}
          setPage={setPage}
          setPagination={setPagination}
          confirmar_exclusao={confirmar_exclusao}
          setConfirmar_exclusao={setConfirmar_exclusao}
          id_item_atual={id_item_atual}
          setId_item_atual={setId_item_atual}
          nome_item_atual={nome_item_atual}
          setNome_item_atual={setNome_item_atual}
          fake_loading={fake_loading}
          setFake_loading={setFake_loading}
        />
        <Drawer
          width={500}
          open={open}
          onClose={fecharGaveta}
          closable={false}
          bodyStyle={{ padding: 0 }}
          headerStyle={{ padding: '10px 10px' }}
          destroyOnClose
          footer={
            <div className='footer'>
              <ButtonCustom
                icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                type='primary'
                onClick={salvarCalcario}
                 loading={loadingSalvar}
                 disabled={loadingSalvar}
              >
                Salvar
              </ButtonCustom>
            </div>
          }
          title={
            <NavegacaoDrawer>
              <ButtonCustom
                danger
                icon={<FontAwesomeIcon icon={faXmark} />}
                type='tertiary'
                onClick={fecharGaveta}
                style={{ marginLeft: -10 }}
              >
                Fechar
              </ButtonCustom>
            </NavegacaoDrawer>
          }
        >
          <FormCalcarioNovo
            nome={nome}
            setNome={setNome}
            fornecedor={fornecedor}
            setFornecedor={setFornecedor}
            fornecedorId={fornecedorId}
            setFornecedorId={setFornecedorId}
            oxidoCalcio={oxidoCalcio}
            setOxidoCalcio={setOxidoCalcio}
            oxidoMagnesio={oxidoMagnesio}
            setOxidoMagnesio={setOxidoMagnesio}
            poderNeutralizacao={poderNeutralizacao}
            setPoderNeutralizacao={setPoderNeutralizacao}
            poderRelativoNeutralizacaoTotal={poderRelativoNeutralizacaoTotal}
            setPoderRelativoNeutralizacaoTotal={setPoderRelativoNeutralizacaoTotal}
            relatividade={relatividade}
            setRelatividade={setRelatividade}
            peneiraFundo={peneiraFundo}
            setPeneiraFundo={setPeneiraFundo}
            update={update}
            loadingForm={loadingForm}
            dataFornecedores={dataFornecedor}
            criadoPorAtualizadoPor={criadoPorAtualizadoPor}
          />
        </Drawer>
      </ViewPage>
    </ContainerCalcario>
  )
}

export default CalcarioNovo
