import { useState } from 'react'
import { ItensPerUserLogged } from '../../../../../../services/login/permissaoUsuarioLoado'
import { removerAcentos } from '../../../../../../utils'
import { Box } from '../../../../../../Components/CustomUi/box'
import { Inline } from '../../../../../../Components/CustomUi/inline'
import Input from '../../../../../../Components/CustomUi/input'
import ButtonCustom from '../../../../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'
import { HeaderTable } from '../../../../../../Components/CustomUi/table/headerTable'
import { BodyTable } from '../../../../../../Components/CustomUi/table/bodyTable'
import { Render } from '../../../../../../Components/CustomUi/render'
import { RowTable } from '../../../../../../Components/CustomUi/table/rowTable'
import { RowItem } from '../../../../../../Components/CustomUi/table/rowItem'
import { DeleteButton } from '../../../../../../Components/CustomUi/deleteButton'
import { Empty, Tag } from 'antd'
import Carregando from '../../../../../../Components/CustomUi/loading'

interface Props {
  loading: boolean
  setPagination: React.Dispatch<React.SetStateAction<number>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  total: number
  listar_analises: any[]
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  abrirGaveta: Function
  permissao: ItensPerUserLogged
  editarCadastro?: Function
  deletar?: Function
  confirmar_exclusao: boolean
  setConfirmar_exclusao: React.Dispatch<React.SetStateAction<boolean>>
  id_item_atual: number
  setId_item_atual: React.Dispatch<React.SetStateAction<number>>
  nome_item_atual: string
  setNome_item_atual: React.Dispatch<React.SetStateAction<string>>
  fake_loading: boolean
  setFake_loading: React.Dispatch<React.SetStateAction<boolean>>
}

export const TabelaGrupoAnalises: React.FC<Props> = ({
  loading,
  page,
  setPage,
  setPagination,
  total,
  listar_analises,
  setOpen,
  abrirGaveta,
  permissao,
  editarCadastro,
  deletar,
  confirmar_exclusao,
  setConfirmar_exclusao,
  id_item_atual,
  setId_item_atual,
  nome_item_atual,
  setNome_item_atual,
  fake_loading,
  setFake_loading,
}) => {
  const [buscarTabela, setBuscarTabela] = useState('')
  function buscarPatrimonios(buscar_nome: string) {
    const palavraSemAcentos = removerAcentos(buscar_nome)
    return function (item) {
      const nomeSemAcentos = removerAcentos(String(item?.nome))
      return nomeSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) || !buscar_nome
    }
  }

  // function municipio(cidade) {
  //   if (cidade[0]?.nome !== undefined && cidade[0]?.uf_sigla !== undefined) {
  //     return `${cidade[0]?.nome} - ${cidade[0]?.uf_sigla}`
  //   }
  // }

  return (
    <Box style={{ height: 'calc(100vh - 180px)' }} gap={10}>
      <Inline alignItems='center'>
        <Input placeholder='Buscar grupo de análises' value={buscarTabela} onChange={setBuscarTabela} />

        <ButtonCustom icon={<FontAwesomeIcon icon={faPlus} />} onClick={abrirGaveta}>
          Novo
        </ButtonCustom>
      </Inline>
      <HeaderTable
        itens={[
          { valor: 'Nome', width: 'calc(100% - 840.5px)' },
          { valor: 'Profundidade', width: '120px' },
          { valor: 'Análises', width: '500px' },
          { valor: 'Ações', width: '220.5px' },
        ]}
      />

      <BodyTable
        paginate={total}
        onChangeItensPage={setPagination}
        onChangePage={setPage}
        current_page={page}
        setCurrent_page={setPage}
        style={{
          height: (listar_analises ?? []).length < 8 && 'calc(100vh - 170px)',
          position: 'relative',
          overflowX: 'hidden',
          overflowY: 'scroll',
          marginTop: -10,
        }}
      >
        <Render condition={!loading}>
          {(listar_analises ?? [])
            .filter(buscarPatrimonios(buscarTabela))
            .map(({ cod, cor, nome, profundidade_nome, analises }) => {
              return (
                <RowTable key={cod} onDoubleClick={() => editarCadastro(cod)} style={{ height: 'auto' }}>
                  <RowItem
                    fontSize={13}
                    style={{ alignItems: 'center' }}
                    width={'calc(100% - 835px)'}
                  >
                    <Tag style={{ height: '12px' }} color={cor} key={cod} />
                    {nome}
                  </RowItem>
                  <RowItem fontSize={13} width={'120px'}>
                    {profundidade_nome}
                  </RowItem>

                  <RowItem
                    fontSize={13}
                    width={'500px'}
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'flex-start',
                      alignContent: 'center',
                    }}
                  >
                    {analises?.analises?.map((tag) => {
                      return (
                        <Tag color='green' key={tag.cod} style={{ margin: 5 }}>
                          {tag.nome.toUpperCase()}
                        </Tag>
                      )
                    })}
                  </RowItem>

                  <RowItem width={'215px'} gap={10}>
                    <DeleteButton
                      open={confirmar_exclusao && id_item_atual === cod}
                      trigger={setConfirmar_exclusao}
                      onTrigger={() => {
                        setId_item_atual(cod)
                        setNome_item_atual(nome)
                      }}
                      loading={fake_loading}
                      message={`o item ${nome} será excluido!`}
                      deleteds={[nome]}
                      onDelete={() => {
                        deletar(cod)
                      }}
                      disabled={fake_loading}
                      disabledTrigger={undefined}
                      textButtonTrigger='Excluir'
                      typeButtonTrigger='secondary'
                    />
                    <ButtonCustom
                      icon={<FontAwesomeIcon icon={faEdit} />}
                      onClick={() => editarCadastro(cod)}
                    >
                      Editar
                    </ButtonCustom>
                  </RowItem>
                </RowTable>
              )
            })}
        </Render>

        <Render condition={listar_analises.length === 0 && !loading}>
          {buscarTabela === '' ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<> Nenhum registro disponível!</>}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  {' '}
                  <b>'{buscarTabela}'</b> não encontrado!
                </>
              }
            />
          )}
        </Render>

        <Render condition={loading}>
          <Carregando
            animation
            legenda='Carregando laboratórios...'
            justifyContent='center'
            height={'100%'}
            size='default'
          />
        </Render>
      </BodyTable>
    </Box>
  )
}
