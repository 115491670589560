import { useMutation, UseMutationResult } from "react-query";
import { localConfig } from "../../../Configs/localConfig";
import { errorAlert, loadingAlert, successAlert } from "../../../utils/alert";
import { api } from "../../api";

interface ParamsDeleteVariedade {
    cod_variedade: number,
    refetch_deletar: Function;
}

export const useDeleteVariedade = (): {
  carregando_deletar_variedade: boolean
  mudateDeletarEngrenagem: (params: ParamsDeleteVariedade) => void
} => {
  const mutation: UseMutationResult<Response, Error, ParamsDeleteVariedade> = useMutation(
    async (params: ParamsDeleteVariedade) => {
      const {
        cod_variedade,
       refetch_deletar
      } = params

      const config = localConfig()

      loadingAlert({ msg: 'Excluindo...', id: 'delete_variedade' })

      return api
        .delete(
          `/variedade-hibrido/${cod_variedade}?codConta=${config.conta}`
        )
        .then((response: any) => {
          successAlert('Excluida com sucesso!', 5000, 'delete_variedade')
          refetch_deletar?.()
          return response
        })
        .catch((error: Error) => {
          //@ts-ignore
          let error_response = error?.response?.data
          errorAlert(error_response?.error ?? 'Falha ao excluir variedade', 5000, 'delete_variedade')

          throw error
        })
    }
  )

  return {
    carregando_deletar_variedade: mutation.isLoading,
    mudateDeletarEngrenagem: mutation.mutate,
  }
}
