import styled from "styled-components";

export const ContainerModalPO = styled.div`
    z-index: 9999;
    position: absolute;
    top: 100px;
    right: 14px;
    width: 670px;
    height: calc(100vh - 110px);
    background: white;
    box-shadow: rgba(153, 153, 153, 0.5) 0px 0px 15px;
    border-radius: 10px;

    .ContainerModalPO__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 10px 20px;
        font-weight: 700;
        color: var(--text-color);
        border-bottom: thin solid var(--border-color);

        .ContainerModalPO__header__close {
            width: 40px;
            height: 40px;
            border: none;
            background: transparent;
            border-radius: 10px;
            color: #ff595b;
            cursor: pointer;

            :hover {
                background: #ff595b;
                color: #fff;
            }
        }
    }

    .ContainerModalPO__body {
        width: 100%;
        height: calc(100% - 55px);
        box-sizing: border-box;
        padding: 15px;
        overflow-y: auto;
    }

`;