import React, { useState } from 'react'
import Input from '../../../CustomUi/input'
import { SelectMulti } from '../../../CustomUi/SelectMulti'
import { Select } from '../../../CustomUi/Select'
import {
  faDna,
  faMicroscope,
  faScaleBalanced,
  faSprayCan,
  faTag,
  faVial,
  faWheatAwn,
} from '@fortawesome/free-solid-svg-icons'
import TextArea from '../../../CustomUi/textarea'
import { ParamsFormulacao, ParamsMarca } from '../../../../services/fertilizante/GET'
import { Render } from '../../../CustomUi/render'

interface PropsFormDadosFertilizante {
  nome: string
  setNome: React.Dispatch<React.SetStateAction<string>>
  observacao: string
  setObservacao: React.Dispatch<React.SetStateAction<string>>
  extratoAlga: string
  setExtratoAlga: React.Dispatch<React.SetStateAction<string>>
  densidade: string
  setDensidade: React.Dispatch<React.SetStateAction<string>>
  aminoacido: string
  setAminoacido: React.Dispatch<React.SetStateAction<string>>
  indice_salino: string
  setIndice_salino: React.Dispatch<React.SetStateAction<string>>
  aplicaçoes: { descr: string; id: number; key?: number }[]
  setAplicaçoes: React.Dispatch<React.SetStateAction<{ descr: string; id: number; key?: number }[]>>
  formulacao: string
  setFormulacao: React.Dispatch<React.SetStateAction<string>>
  setFormulacaoId: React.Dispatch<React.SetStateAction<number>>
  marca: string
  setMarca: React.Dispatch<React.SetStateAction<string>>
  setMarcaId: React.Dispatch<React.SetStateAction<number>>
  data_aplicacoes: any[]
  data_formulacao: ParamsFormulacao[]
  data_marca: ParamsMarca[]
}

export const FormDadosFertilizante: React.FC<PropsFormDadosFertilizante> = ({
  nome,
  setNome,
  observacao,
  setObservacao,
  extratoAlga,
  setExtratoAlga,
  densidade,
  setDensidade,
  aminoacido,
  setAminoacido,
  indice_salino,
  setIndice_salino,
  aplicaçoes,
  setAplicaçoes,
  formulacao,
  setFormulacao,
  setFormulacaoId,
  marca,
  setMarca,
  setMarcaId,
  data_aplicacoes,
  data_formulacao,
  data_marca
}) => {
  const [openAplicacao, setOpenAplicacao] = useState<boolean>(false)
  const [openFormulacao, setOpenFormulacao] = useState<boolean>(false)
  const [openMarca, setOpenMarca] = useState<boolean>(false)

  function mascaraDecimal(data, input) {
    let area = data.replace(/\D/g, "")
    area = area.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
    area = (area / 100).toFixed(2).replace('.', ',')

    if (input === 'd') {
      setDensidade(area)
    }

    if (input === 'a') {
      setAminoacido(area)
    }

    if (input === 'i') {
      setIndice_salino(area)
    }
  }

  return (
    <>
      <Input
        label='Nome'
        icon={faWheatAwn}
        placeholder='Digite o nome do fertilizante'
        value={nome}
        onChange={setNome}
      />
  <div className='campo__dois__inputs'>
        <Select
          label='Formulação'
          placeholder='Selecione a formulação'
          icon={faVial}
          value={formulacao}
          onChange={setFormulacao}
          onChangeID={setFormulacaoId}
          open={openFormulacao}
          trigger={setOpenFormulacao}
          items={data_formulacao}
          descricaoItem={'nome'}
          idItem={'cod'}
        />
        <Select
          label='Fabricante/Misturadora'
          placeholder='Selecione o fabricante'
          icon={faTag}
          value={marca}
          onChange={setMarca}
          onChangeID={setMarcaId}
          open={openMarca}
          trigger={setOpenMarca}
          items={data_marca}
          descricaoItem={'nome'}
          idItem={'cod'}
        />
      </div>
      <div className='campo__dois__inputs'>
        <Render condition={formulacao === 'Líquido'}>
        <Input
          label='Densidade (g/L)'
          icon={faScaleBalanced}
          placeholder='Digite o valor da densidade'
          value={densidade}
          onChange={(e) => mascaraDecimal(e, 'd')}
        />
        </Render>
        <Input
          label='Aminoácido (%)'
          icon={faDna}
          placeholder='Digite o valor do aminoácido'
          value={aminoacido}
          onChange={(e) => mascaraDecimal(e, 'a')}
        />
      </div>
      <Input
        label='Índice salino'
        icon={faMicroscope}
        placeholder='Digite o valor do índice salino'
        value={indice_salino}
        onChange={(e) => mascaraDecimal(e, 'i')}
      />
      <TextArea
        placeholder='Digite o extrato de alga'
        label='Extrato de alga'
        value={extratoAlga}
        onChange={setExtratoAlga}
        maxHeight={50}
      />
      <SelectMulti
        label='Aplicações'
        placeholder='Selecione as aplicações'
        icon={faSprayCan}
        open={openAplicacao}
        trigger={setOpenAplicacao}
        items={data_aplicacoes}
        descricaoItem={'nome'}
        idItem={'cod'}
        selecteds={aplicaçoes}
        onChange={setAplicaçoes}
      />
      
      <TextArea
        placeholder='Digite a observação'
        label='Observação'
        value={observacao}
        onChange={setObservacao}
      />
    </>
  )
}
