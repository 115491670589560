import { Drawer } from "antd"
import ButtonCustom from "../../ButtonCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import { BodyDrawer, CloseTopDrawer, HeaderDrawer } from "./style";
import { TitleH1 } from "../titleH1";
import { Render } from "../render";

//interface
interface Props {
    open: boolean;
    onClose: Function;
    children?: React.ReactNode;
    destroyOnClose?: boolean;
    width?: number;
    closeButtonLabel?: 'Fechar' | 'Voltar';
    title?: string;
}

export const DrawerCustom: React.FC<Props> = ({ open, onClose, children, width, closeButtonLabel = 'Fechar', 
    title
 }) => {
    return (
        <Drawer
            open={open}
            onClose={()=>{onClose()}}
            destroyOnClose
            headerStyle={{display:'none'}}
            footer={false}
            width={width ?? 500}
            bodyStyle={{padding: 0}}
        >
            
            <CloseTopDrawer>
                <ButtonCustom 
                    onClick={()=>{onClose()}}
                    type="tertiary"
                    icon={<FontAwesomeIcon  icon={closeButtonLabel==='Fechar' ? faXmark : faAngleLeft} />}
                    danger
                >
                    Fechar
                </ButtonCustom>
            </CloseTopDrawer>

            <Render condition={!!title}>
                <HeaderDrawer>
                    <TitleH1>{title}</TitleH1>
                </HeaderDrawer>
            </Render>
            
            <BodyDrawer>
                {children}
            </BodyDrawer>
            
        </Drawer>
    )
}