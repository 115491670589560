import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration);

/**Remove os espaços entre as palavras */
export function removerEspacos(str: string): string {
  return String(str).replace(/\s+/g, '');
}

/**retorna a ultima posição de um array
* 
* (opcional) pode passar um segundo argumento `posicao` para retornar a posicao do array
* 
* (padrão) pode passar um segundo argumento `valor` para retornar o valor da posicao 
*/
export function ultimaPosicao(array: any[], retornar?: 'posicao' | 'valor') {
  if (retornar==='posicao') {
      return array?.slice(-1);
  }
  return array?.slice(-1)?.[0];
}


/**Remove os acentos de uma string */
export function removerAcentos(str: string): string {
  return String(str).normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}


export function leadZero(numero: string | number) {
return String(numero)?.padStart(2, '0');
}

/**retorna o próprio index fornecido, apenas para eviar warning do SonarQube */
export function gerarKey(index: number): number {
return index
}


/**Deixa que apenas números sejam digitados */
export const apenasNumeros = value => {
    return String(value)?.replace(/\D/g, '') ?? value;
}


/**Converte um array de `strings` para um array de `números` */
export function parseArrayNumeros(array: string[] | number[]): number[] {
    return (array??[])?.map(item=> Number(item));
}

/**Converte um array de `números` para um array de `strings` */
export function parseArrayStrings(array: number[] | string[]) {
    return (array??[])?.map(item=> String(item));
}



export const mascaraData = (value: string) => {
    return String(value)
        .replace(/\D/g, '') // substitui qualquer caractere que não seja número por nada
        .slice(0, 8) // limita a 8 caracteres numéricos (ddMMyyyy)
        .replace(/(\d{2})(\d)/, '$1/$2') // adiciona `/` após 2 números digitados
        .replace(/(\d{2})(\d)/, '$1/$2'); // adiciona `/` após 2 números digitados na sequência
};
/**Arredonda um numero `decimal` para `inteiro` 
 * 
 * tipos: 
 * 
 * `auto (padrão):` Arredonda o número para o decimal mais perto
 * 
 * exemplo: `5.1 = 5`, `5.7 = 6`
 * 
 * 
 * `up:` Arredonda o número pra cima
 * 
 * exemplo: `5.1 = 6`, `5.7 = 6`
 * 
 * `down:` Arredonda o número pra baixo
 * 
 * exemplo: `5.1 = 5`, `5.7 = 5`
 * 
*/
export function arredondarNumero(value: number, type?: 'auto' | 'up' | 'down') {
    if (type==='up') {
        return Math.ceil(value);
    }
    if (type==='down') {
        return Math.floor(value);
    }

    return Math.round(value);
}


export  const formatarValor = (valor: number): string => {
  const valorArredondado = Number(valor).toFixed(2); 
  const partes = valorArredondado.split('.');
  const inteiro = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, "."); 
  const decimal = partes[1] ? ',' + partes[1] : ''; 
  return inteiro + decimal + ' ha'; 
}


/**remove `amp;` e subistitui por `""` (aspas) */
export function substituirAsc(str:string): string {
        
    let remove = str?.replace(/quot;|&amp;/g, '"');
    let fraseCorrigida = remove.replace(/amp;+/g, "");

    
    return fraseCorrigida ?? str;
}

export function maskCNPJ(cnpj) {
  if (!cnpj) return ""
  cnpj = cnpj.replace(/\D/g, "");

  cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2"); 
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); 
  cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
  cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2"); 

  return cnpj;
}


export const convertSegundosParaHora = (segundos: number) => {
  return dayjs.duration(segundos, 'seconds').format('HH:mm:ss')
}