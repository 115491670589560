import { GoogleMap } from '@react-google-maps/api'
import { Form, Input, Space, Switch, Tabs } from 'antd'
import exifr from 'exifr'
import { useCallback, useState } from 'react'

function ImageMetadataExtractor() {
  const [form] = Form.useForm()
  const [map, setMap] = useState<google.maps.Map>()

  const [filtro, setFiltro] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const usuarios = [
    { id: 1, nome: "João Silva", tipo: "cliente" },
    { id: 2, nome: "Maria Oliveira", tipo: "cliente" },
    { id: 3, nome: "Carlos Souza", tipo: "adm" },
    { id: 4, nome: "Ana Lima", tipo: "adm" },
  ];

  const filtrados = usuarios.filter((user) =>
    user.nome.toLowerCase().includes(filtro.toLowerCase())
  );

  const handleFileChange = useCallback(async (event) => {
    const file = event.target.files?.[0]

    if (file) {
      try {
        const metadata = await exifr.parse(file)
        const latLng = await exifr.gps(file)

        if (metadata && metadata.GPSLatitude && metadata.GPSLongitude) {
          getLocationInfo(latLng.latitude, latLng.longitude)
        } else {
          console.log('Location data not available')
        }
      } catch (error) {
        console.error('Error reading metadata:', error)
      }
    }
  }, [])

  const getLocationInfo = async (latitude, longitude) => {
    const geocoder = new window.google.maps.Geocoder()
    const latlng = new window.google.maps.LatLng(latitude, longitude)

    try {
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const position = results[0].geometry.location.toJSON()

            console.log('map não existe')
            new google.maps.Marker({
              position,
              map,
            })
         
            map.fitBounds(results[0].geometry?.bounds.toJSON())
          } else {
            console.log('No results found')
          }
        } else {
          console.error('Geocoder failed with status:', status)
        }
      })
    } catch (error) {
      console.error('Error fetching location data:', error)
    }
  }

  return (
    <div>
      <input type='file' onChange={handleFileChange} accept='.jpg, .jpeg' />
      <GoogleMap
        zoom={3}
        center={{ lat: 0.0, lng: 0.0 }}
        options={{ mapTypeId: 'hybrid' }}
        onLoad={setMap}
        mapContainerStyle={{ width: 450, height: 450 }}
      />
      <div>
        <Tabs
          type='card'
          size='small'
          tabBarStyle={{ borderRadius: 5 }}>
          {[
            { label: '00-20', value: '00-20' },
            { label: '20-40', value: '20-40' },
            { label: '00-50', value: '00-50' },
            { label: 'micro', value: 'micro' },
            { label: 'indicadores', value: 'indicadores' },
          ].map((rec) => (
            <Tabs.TabPane key={rec.value} tabKey={rec.value} tab={rec.label}>
              <Form
              form={form}
                style={{ minWidth: '500px', borderTop: '1px solid #ccc' }}
                layout='vertical'
              >
                <Space size={25}>
                  <Form.Item
                    style={{ marginTop: 15 }}
                    name='exibe'
                    label='Visível'
                    initialValue={true}
                  >
                    <Switch
                      unCheckedChildren='Não'
                      checkedChildren='Sim'
                    />
                  </Form.Item>
                </Space>
                <Form.Item
                  name='obs'
                  initialValue=''
                  label='Observação do elemento'
                  shouldUpdate={true}
                >
                  <Input.TextArea
                    // onFocus={(data) =>
                    //   (data.currentTarget.selectionStart =
                    //     data.currentTarget.selectionEnd =
                    //       data.currentTarget.value.length)
                    // }
                    // autoFocus
                    maxLength={350}
                    rows={5}
                  />
                </Form.Item>
                
                <Form.Item name='index' hidden />
                <Form.Item name='disInt' hidden />
              </Form>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  )
}

export default ImageMetadataExtractor
