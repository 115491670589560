import React, { useState } from 'react'
import { BodyDrawer } from './styles'
import Input from '../CustomUi/input'
import { HeaderDrawer } from '../CustomUi/headerGaveta'
import { Render } from '../CustomUi/render'
import Carregando from '../CustomUi/loading'
import { Select } from '../CustomUi/Select'
import {
  faBorderAll,
  faBox,
  faFlaskVial,
  faMountain,
  faSeedling,
  faVial,
  faWheatAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FornecedorItens } from '../../services/Fornecedores'
import { apenasNumeros } from '../../utils'

interface PropsFormCalcarioNovo {
  nome: string
  setNome: React.Dispatch<React.SetStateAction<string>>
  fornecedor: string
  setFornecedor: React.Dispatch<React.SetStateAction<string>>
  fornecedorId: number
  setFornecedorId: React.Dispatch<React.SetStateAction<number>>
  oxidoCalcio: string
  setOxidoCalcio: React.Dispatch<React.SetStateAction<string>>
  oxidoMagnesio: string
  setOxidoMagnesio: React.Dispatch<React.SetStateAction<string>>
  poderNeutralizacao: string
  setPoderNeutralizacao: React.Dispatch<React.SetStateAction<string>>
  poderRelativoNeutralizacaoTotal: string
  setPoderRelativoNeutralizacaoTotal: React.Dispatch<React.SetStateAction<string>>
  relatividade: string
  setRelatividade: React.Dispatch<React.SetStateAction<string>>
  peneiraFundo: string
  setPeneiraFundo: React.Dispatch<React.SetStateAction<string>>
  update: number
  loadingForm: boolean
  dataFornecedores: FornecedorItens[]
  criadoPorAtualizadoPor: string
}

const FormCalcarioNovo: React.FC<PropsFormCalcarioNovo> = ({
  nome,
  setNome,
  fornecedor,
  setFornecedor,
  fornecedorId,
  setFornecedorId,
  oxidoCalcio,
  setOxidoCalcio,
  oxidoMagnesio,
  setOxidoMagnesio,
  poderNeutralizacao,
  setPoderNeutralizacao,
  poderRelativoNeutralizacaoTotal,
  setPoderRelativoNeutralizacaoTotal,
  relatividade,
  setRelatividade,
  peneiraFundo,
  setPeneiraFundo,
  update,
  loadingForm,
  dataFornecedores,
  criadoPorAtualizadoPor,
}) => {
  const [openFornecedor, setOpenFornecedor] = useState(false)

  return (
    <>
      <HeaderDrawer>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {update > 0 ? 'Atualizar calcário' : 'Cadastrar calcário'}

          <small style={{ opacity: '0.5', fontSize: '9px' }}>{criadoPorAtualizadoPor}</small>
        </div>
      </HeaderDrawer>
      <BodyDrawer>
        <Render condition={loadingForm}>
          <Carregando
            animation
            justifyContent='center'
            height={'100%'}
            legenda='Carregando informações...'
          />
        </Render>
        <Render condition={loadingForm === false}>
          <Input
            icon={faMountain}
            label='Nome'
            placeholder='Digite o nome'
            value={nome}
            onChange={setNome}
          />
          <Select
            icon={faBox}
            label='Fornecedor'
            placeholder='Selecione o fornecedor'
            value={fornecedor}
            onChange={setFornecedor}
            onChangeID={setFornecedorId}
            open={openFornecedor}
            trigger={setOpenFornecedor}
            items={dataFornecedores}
            descricaoItem={'nome'}
            idItem={'cod'}
          />
          <Input
            icon={faVial}
            label='Óxido de Calcio'
            placeholder='Digite o nome'
            value={apenasNumeros(oxidoCalcio)}
            onChange={setOxidoCalcio}
          />
          <Input
            icon={faVial}
            label='Óxido de Magnésio'
            placeholder='Digite o nome'
            value={apenasNumeros(oxidoMagnesio)}
            onChange={setOxidoMagnesio}
          />
          <Input
            icon={faSeedling}
            label='Poder de Neutralização'
            placeholder='Digite o nome'
            value={apenasNumeros(poderNeutralizacao)}
            onChange={setPoderNeutralizacao}
          />
          <Input
            icon={faWheatAlt}
            label='Poder Relativo de Neutralização Total'
            placeholder='Digite o nome'
            value={apenasNumeros(poderRelativoNeutralizacaoTotal)}
            onChange={setPoderRelativoNeutralizacaoTotal}
          />
          <Input
            icon={faFlaskVial}
            label='Reatividade'
            placeholder='Digite o nome'
            value={apenasNumeros(relatividade)}
            onChange={setRelatividade}
          />
          <Input
            icon={faBorderAll}
            label='Peneira de fundo'
            placeholder='Digite o nome'
            value={apenasNumeros(peneiraFundo)}
            onChange={setPeneiraFundo}
          />
        </Render>
      </BodyDrawer>
    </>
  )
}

export default FormCalcarioNovo
