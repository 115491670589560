import React, { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { GoogleMap, Polygon, Marker } from '@react-google-maps/api';
import 'leaflet/dist/leaflet.css';

// ASSETS
import pin from '../../../assets/svg/marker.svg';

// APP
import { getBoundsNoArray } from '../../../services/Afins';

// PROPS
export interface Props {
  multi_coord?: {
    nome_talhao?: string;
    coordenadas: { lat: number; lng: number }[];
  }[];
  coord: google.maps.LatLng[] | google.maps.LatLngLiteral[];
  customHeight?: number | string;
  keyProp: number | string;
  list_pontos: { lat: number; lng: number; ponto: string }[];
}

interface TypesMapa {
  zoom: number;
  center: {
    lat: number;
    lng: number;
  };
  style?: { [key: string]: string };
}

const MultiPolygonMapa: React.FC<Props> = ({ coord, multi_coord = [], customHeight, keyProp, list_pontos }) => {
  // STATES
  const [map, setMap] = useState<google.maps.Map>();
  const [polygon, setPolygon] = useState<google.maps.Polygon>();
  const [propsMap, setPropsMapa] = useState<TypesMapa>({
    zoom: 5,
    center: {
      lat: -14.235,
      lng: -51.9253, // Centro aproximado do Brasil
    },
    style: { width: '100%', height: '78vh' },
  });

  const optionsPolygon: google.maps.PolygonOptions = { fillColor: 'aqua', strokeColor: 'aqua' };
  const optionsMap: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    scrollwheel: false,
    draggable: false,
  };

  useEffect(() => {
    if (map && multi_coord.length > 0) {
      //@ts-ignore
      const allCoords = multi_coord.flatMap(item => item.coordenadas);
      if (allCoords.length > 0) {
        map.fitBounds(getBoundsNoArray(allCoords));
        map.setZoom(14);
      }
    }
  }, [map, multi_coord]);

  const onLoadMap = (mapInstance: google.maps.Map) => {
    setMap(mapInstance);
  };

  const onLoadPolygon = (polygonInstance: google.maps.Polygon) => {
    setPolygon(polygonInstance);
  };

  return (
    <GoogleMap
      onLoad={onLoadMap}
      mapContainerStyle={{ minWidth: '100%', maxWidth: '500px', height: customHeight ?? '50vh' }}
      center={propsMap.center}
      options={optionsMap}
      key={keyProp}
    >
      
      {multi_coord?.length > 0 &&
        multi_coord.map(({ coordenadas }, index) => (
          <Polygon
            key={`polygon-${index}`}
            paths={coordenadas}
            onLoad={onLoadPolygon}
            options={optionsPolygon}
          />
        ))}

      {(list_pontos ?? []).map(({ lat, lng, ponto }) => (
        <Marker
          key={uniqueId()}
          clickable
          icon={{ url: pin, scaledSize: new window.google.maps.Size(30, 40) }}
          position={{ lat: Number(lat), lng: Number(lng) }}
          label={{
            className: 'MapTalhao__label',
            text: ponto,
            fontSize: '11px',
            fontWeight: 'bold',
          }}
        />
      ))}
    </GoogleMap>
  );
};

export default MultiPolygonMapa;
