
import { PropriedadeItens } from "../../../../../../services/Propriedades";
import { Accordion } from "../../../../../CustomUi/accordion";
import { MonitoramentoMapCalorType } from "../../../../novo-index";
import { esconderAccordion, obterTalhoes } from "../../meta";
import Svg from "../../../../../talhasvg";
import { TabelaPraga } from "../../../tabelaPragas";
import { MoniNivelItemControleItens } from "../../../../../../services/monitoramento/nivelControleItens";
import { TypeDetailsMonitoramento } from "../../../../../../features/Monitoramento/Monitoramento.dataDetails";

//PROPS
interface Props {
    buscarTalhao: Function;
    search_talhao: string;
    main_data: { [key: string]: MonitoramentoMapCalorType[] };
    propriedade: PropriedadeItens;
    pragas_talhao: any;
    listDates: any;
    nivelControle: MoniNivelItemControleItens[];
    groupTotalizar: boolean;
    dataDetails: TypeDetailsMonitoramento;
    encolher: boolean;
    setVisibleDrawerPonto: React.Dispatch<React.SetStateAction<boolean>>;
    open_accordion: number;
    setOpen_accordion: React.Dispatch<React.SetStateAction<number>>;
    visibleChangeBooking: boolean;
    handleAssembleHeatMap: Function;
    handleClickBadge: Function;
    setCalorSelect: Function;
    handleChangeCollapse: Function;
    obterDadosTalhaoSelecionado: Function;
}

export const PorTalhao: React.FC<Props> = ({ buscarTalhao, search_talhao, main_data, propriedade, handleChangeCollapse, 
    obterDadosTalhaoSelecionado, pragas_talhao, listDates, nivelControle, groupTotalizar, handleAssembleHeatMap, 
    handleClickBadge, setCalorSelect, dataDetails, encolher, setVisibleDrawerPonto, visibleChangeBooking, 
    open_accordion, setOpen_accordion
}) => {

    return (
        <div style={{ height: 'calc(100vh - 320px)', overflowY: 'auto', boxSizing: 'border-box', paddingRight: 7, marginTop: 10, }}>
            {(obterTalhoes(main_data) ?? []).filter(buscarTalhao(search_talhao)).map(({ nome_talhao, cod_talhao }, index) => {
                const talhao = propriedade?.talhao.find((rec) => rec.cod === cod_talhao);
                return (
                    <Accordion
                        key={cod_talhao}
                        label={nome_talhao}
                        open={open_accordion === cod_talhao}
                        isFirst={index === 0}
                        isLast={(index + 1) === (obterTalhoes(main_data).length)}
                        single={open_accordion === cod_talhao}
                        style={{ display: esconderAccordion(open_accordion, cod_talhao) }}
                        extra={
                            <Svg
                                fill={talhao?.isSubArea ? '#FFA500' : '#70CE9F'}
                                // @ts-ignore
                                coordinates={talhao?.kml?.coordenadas}
                            />
                        }
                        trigger={open_accordion === null
                            ? () => {
                                handleChangeCollapse?.(cod_talhao);
                                setOpen_accordion(cod_talhao);
                                obterDadosTalhaoSelecionado(cod_talhao)
                            }
                            : () => { handleChangeCollapse?.(-1); setOpen_accordion(null) }
                        }
                    >
                        <TabelaPraga
                            nome_talhao={nome_talhao}
                            cod_talhao={cod_talhao}
                            list_pragas={pragas_talhao}
                            listDates={listDates}
                            nivelControle={nivelControle}
                            groupTotalizar={groupTotalizar}
                            handleAssembleHeatMap={handleAssembleHeatMap}
                            handleClickBadge={handleClickBadge}
                            setCalorSelect={setCalorSelect}
                            dataDetails={dataDetails}
                            encolher={encolher}
                            setVisibleDrawerPonto={setVisibleDrawerPonto}
                            visibleChangeBooking={visibleChangeBooking}
                        />
                    </Accordion>
                )
            })}
        </div>
    )
}