import { groupBy } from "lodash";
import { ImagemBookMonitoramento__Itens } from "../../../../../../../services/monitoramento/imagemMonitoramento/get";


export function agruparPorData(lista_fotos: ImagemBookMonitoramento__Itens[]) {
    
    let agrupar_por_data = groupBy(lista_fotos, 'monitoramento[data_inicio]');
    let agrupar_por_talhao = groupBy(lista_fotos, 'talhao[cod]');
    let qtd_talhoes = Object.entries(agrupar_por_talhao).map(([cod_talhao])=> cod_talhao);

    let listagem_por_data = Object.entries(agrupar_por_data).map(([data_inicio, dados])=> {
        return {
            data_inicio: data_inicio,
            qtd_talhoes: qtd_talhoes?.length,
            qtd_fotos: dados?.length,
            dados: dados
        }
    })

    return listagem_por_data ?? []
}

export function adptarArrayCoord(coordenadas: {latitude: number, longitude: number}[]) {
    
    let tipar_coordenadas: google.maps.LatLng[] = coordenadas.map(({ latitude, longitude }) => {
        return new google.maps.LatLng(latitude, longitude);
    });

    return tipar_coordenadas ?? [];
};

export function obterPontos(lista_fotos: ImagemBookMonitoramento__Itens[]) {
    
    let listar_pontos = lista_fotos?.map((item)=> {
        return {
            lat: Number(item?.monitoramento?.latitude),
            lng: Number(item?.monitoramento?.longitude),
            ponto: String(item?.monitoramento?.ponto)
        }
    })

    return listar_pontos ?? [];
}

export function obterPontosMesclados(lista_fotos: ImagemBookMonitoramento__Itens[], nome_filtrar_talhao: string | null) {
    
    if (nome_filtrar_talhao!== null && nome_filtrar_talhao !== '') {
        let filtrar = lista_fotos.filter((item=> item.talhao.nome === nome_filtrar_talhao)); 

        let listar_pontos = filtrar?.map((item)=> {
            return {
                lat: Number(item?.monitoramento?.latitude),
                lng: Number(item?.monitoramento?.longitude),
                ponto: String(item?.monitoramento?.ponto)
            }
        })
    
        //@ts-ignore
        let mesclados = listar_pontos?.flat()
    
        return mesclados ?? [];

    }

    let listar_pontos = lista_fotos?.map((item)=> {
        return {
            lat: Number(item?.monitoramento?.latitude),
            lng: Number(item?.monitoramento?.longitude),
            ponto: String(item?.monitoramento?.ponto)
        }
    })

    //@ts-ignore
    let mesclados = listar_pontos?.flat()

    return mesclados ?? [];
}

export function larguraResponsiva(
    current_width: number, 
    default_width: number, 
    field: string
  ): number {
    
    function getResponsiveWidth(ranges: Array<{ min: number, max: number, value: number }>, defaultValue: number): number {
      for (const range of ranges) {
        if (current_width <= range.max && current_width > range.min) {
          return range.value;
        }
      }
      return defaultValue;
    }
  
    const ranges: { [key: string]: Array<{ min: number, max: number, value: number }> } = {
      datas_de_monitoramento: [
        { min: 991, max: 1024, value: 373 },
        { min: 800, max: 991, value: 320 },
        { min: 768, max: 800, value: 288 },
        { min: 720, max: 768, value: 280 },
        { min: 640, max: 720, value: 240 },
        { min: 600, max: 640, value: 210 },
        { min: 520, max: 600, value: 210 },
        { min: 480, max: 520, value: 188 },
        { min: 412, max: 480, value: 150 },
        { min: 375, max: 412, value: 125 },
        { min: 0, max: 375, value: 130 },
      ],
      talhoes_com_fotos: [
        { min: 991, max: 1024, value: 260 },
        { min: 800, max: 991, value: 270 },
        { min: 768, max: 800, value: 190 },
        { min: 720, max: 768, value: 180 },
        { min: 640, max: 720, value: 170 },
        { min: 600, max: 640, value: 160 },
        { min: 520, max: 600, value: 160 },
        { min: 480, max: 520, value: 100 },
        { min: 412, max: 480, value: 100 },
        { min: 375, max: 412, value: 80 },
        { min: 0, max: 375, value: 62 },
      ],
      quantidade_de_fotos: [
        { min: 991, max: 1024, value: 260 },
        { min: 800, max: 991, value: 270 },
        { min: 768, max: 800, value: 190 },
        { min: 720, max: 768, value: 180 },
        { min: 640, max: 720, value: 140 },
        { min: 600, max: 640, value: 100 },
        { min: 520, max: 600, value: 100 },
        { min: 480, max: 520, value: 100 },
        { min: 412, max: 480, value: 100 },
        { min: 375, max: 412, value: 62 },
        { min: 0, max: 375, value: 62 },
      ],
      modal_fotos: [
        { min: 991, max: 1024, value: 1024 },
        { min: 800, max: 991, value: 991 },
        { min: 768, max: 800, value: 800 },
        { min: 720, max: 768, value: 768 },
        { min: 640, max: 720, value: 720 },
        { min: 600, max: 640, value: 640 },
        { min: 520, max: 600, value: 600 },
        { min: 480, max: 520, value: 520 },
        { min: 412, max: 480, value: 480 },
        { min: 375, max: 412, value: 375 },
        { min: 360, max: 375, value: 360 },
        { min: 0, max: 360, value: 360 },
      ],
    };
  
    const defaultValues = {
      datas_de_monitoramento: 460,
      talhoes_com_fotos: 275,
      quantidade_de_fotos: 275,
      acoes: 100,
      modal_fotos: 1120,
    };
  
    if (field === "acoes") {
      return defaultValues.acoes;
    }
  
    return getResponsiveWidth(ranges[field] || [], defaultValues[field]);
  }