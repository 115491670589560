import { useMutation, UseMutationResult } from "react-query";
import { localConfig } from "../../../Configs/localConfig";
import { errorAlert, loadingAlert, successAlert } from "../../../utils/alert";
import { api } from "../../api";

interface ParamsDeleteVariedade {
  cod_fertilizante: number,
  refetch_deletar: Function;
}

export const useDeleteFertilizante = (): {
carregando_deletar_fertilizante: boolean
mudateDeletarFertilizante: (params: ParamsDeleteVariedade) => void
} => {
const mutation: UseMutationResult<Response, Error, ParamsDeleteVariedade> = useMutation(
  async (params: ParamsDeleteVariedade) => {
    const {
      cod_fertilizante,
     refetch_deletar
    } = params

    const config = localConfig()

    loadingAlert({ msg: 'Excluindo...', id: 'delete_fertilizante' })

    return api
      .delete(
        `/fertilizante/${cod_fertilizante}?codConta=${config.conta}`
      )
      .then((response: any) => {
        successAlert('Excluído com sucesso!', 5000, 'delete_fertilizante')
        refetch_deletar?.()
        return response
      })
      .catch((error: Error) => {
        //@ts-ignore
        let error_response = error?.response?.data
        errorAlert(error_response?.error ?? 'Falha ao excluir fertilizante', 5000, 'delete_fertilizante')

        throw error
      })
  }
)

return {
  carregando_deletar_fertilizante: mutation.isLoading,
  mudateDeletarFertilizante: mutation.mutate,
}
}