import styled from "styled-components";

export const ListPragas = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: hidden;

    div.ListPragas__estadios {
        width: 100%;
        min-height: 38px;
        display: flex;
        border: thin solid var(--border-color);
        border-radius: 5px;
        overflow: hidden;

        div.ListPragas__estadios__title {
            border-right: thin solid var(--border-color);
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding: 10px;
            font-weight: 600;
            background: #FAFAFA;
            color: var(--text-color);
            font-size: 14px;
        }

        div.ListPragas__estadios__listagem {
            box-sizing: border-box;
            padding: 0px 10px;
            font-size: 13px;
            display: flex;
            align-items: center;
            font-weight: 500;
        }
    }

    div.ListPragas__talhoes {
        width: 100%;
        height: calc(100vh - 220px);
        box-sizing: border-box;
        padding-right: 5px;
        overflow-y: auto;
    }
`;