import React, { useEffect, useRef, useState } from 'react'
// @ts-ignore
import shp from 'shpjs'

import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  InputNumber,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Tabs,
  Upload,
} from 'antd'

import Icon, { PrinterOutlined, UploadOutlined } from '@ant-design/icons'

import { UploadChangeParam } from 'antd/lib/upload'
import dayjs from 'dayjs'
import ReactToPrint from 'react-to-print'
import { v4 as uuid } from 'uuid'
import Print from './printOS'

import { ClienteItensTypes, getCliente, getConsultoria } from '../../services/Cliente'
import { getEquipeConta } from '../../services/Equipe'
import { getFornecedor } from '../../services/Fornecedores'
import {
  getPedLaboratorio,
  OsItensTypes,
  postPedLaboratorio,
  putPedlaboratorio,
} from '../../services/Laboratorio'
import { getPropriedade, PropriedadeItens } from '../../services/Propriedades'
import { getTalhao, TalhaoItens } from '../../services/Talhao'

import { geraKmlTrakmaker, geralKmlLab } from '../gerarKml'

import { localConfig } from '../../Configs/localConfig'

import { RangePickerProps } from 'antd/es/date-picker'
import { useLogic } from '../../context/useLogic'
import { buscaCidade, dummyRequest, tryError } from '../../services/Afins'
import { getLabAnalise } from '../../services/LabAnalises'
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'
import { getRepresentanteLegal } from '../../services/RepresentanteLegal'
import { getSafraConsultoria } from '../../services/Safra'
import GoogleMaps from '../googleMaps'
import { IconAlert } from '../iconsAlert'
import { Notification } from '../notification'
import Svg from '../talhasvg'
import VisualizarPontosMap from '../visualizarPontosMap'
import AnalisesOrder from './analisesOrder'
import DataClienteOrder from './dataClienteOrder'
import PrintOC from './printOC'
import { CardBtn, Container } from './styled'
import DiasDesdeData from '../DiasDesdeData'
import { Render } from '../CustomUi/render'
import { gerarKey } from '../../utils'
import { destroyAlert, errorAlert, infoAlert, loadingAlert, successAlert } from '../../utils/alert'

interface TypesLaboratorio {
  update: number
  clone: number
  visible: boolean
  setOnClose: (data: boolean) => void
  setUpdate: (data: number) => void
  permissions: ItensPerUserLogged
}

interface TypesDataFinish {
  cod_laboratorio: number
  cod_propriedade: number
  cod_talhao: number
  cod_responsaveis?: []
  cod_area_amostra: number
  analises?: [
    {
      cod_analises?: [
        {
          cod?: number
          nome?: string
          valor?: string
        }
      ]
      cod_profundidade?: number
      id?: string
      pontos?: [
        {
          COMENTARIO: string
          INDICE: number
          NOME: string
          LONGITUDE: string | number
          LATITUDE: string | number
        }
      ]
      profundidade?: string
      title?: string
    }
  ]
  malha_de_pontos?: []
  responsaveis?: [number | string]
}

interface TypesProfundidades {
  id?: number
  nome?: string
}

interface TypesTipoAnalise {
  data?: string
  valorAnalises?: {
    cod?: number
    valor?: number
  }
  valorPonto: number
}

interface TypesDadosPrint {
  talhaoNome: string
  codCidade: number
  propriedadeNome: string
  representantes: {
    cidade_nome?: string
    cod?: number
    cpf?: string
    participacao?: string
    representante?: number
    solicitante?: string
    uf_nome?: string
    uf_sigla?: string
    usuario_nome?: string
  }
  areaAmostra: string
}

interface TypesDataPrint {
  propriedadeNome: string
  talhaoNome: string
  cityUf: string
  representante: {
    cidade_nome?: string
    cod?: number
    cpf?: string
    participacao?: string
    representante?: number
    solicitante?: string
    endereco: string
    endereco_cep: string
    endereco_complemento: string
    uf_nome?: string
    uf_sigla?: string
    usuario_nome?: string
  }
  outrosSolicitantes: string
  cpf: string
  dataColeta: string
  areaAmostra: string
}

const { Option } = Select

function ordernar(data) {
  data.sort((a, b) => {
    if (a.NOME < b.NOME) {
      return -1
    }
    return true
  })

  return data
}

const Laboratorio: React.FC<TypesLaboratorio> = (props) => {
  const { visible, setOnClose, setUpdate, clone, permissions } = props
  const { update } = props

  const { responsive } = useLogic()

  const [form] = Form.useForm()

  const printRef = useRef<HTMLDivElement>(null)
  const url = useRef(null)
  const valorAnalise = useRef(null)
  const inputRepresentante = useRef(null)
  const uploadShape = useRef(null)

  const [listPropriedade, setListPropriedade] = useState([])
  const [listTalhao, setListTalhao] = useState([])
  const [isVisibleFileShape, setIsVisibleFileShape] = useState(true)
  const [listFornecedor, setListFornecedor] = useState([])
  const [listEquipe, setListEquipe] = useState([])
  const [listFornServico, setListFornServico] = useState([])
  const [malha, setMalha] = useState<any>([])
  const [shape, setShape] = useState([])
  const [optSafraConsultoria, setOptSafraConsultoria] = useState([])
  const [dadosDaOs, setDadosDaOs] = useState<OsItensTypes>()

  const [disableCampos, setDisableCampos] = useState(false)

  const [propriedadeSelecionado, setPropriedadeSelecionada] = useState<PropriedadeItens>(
    {} as PropriedadeItens
  )


  const [idRepresentanteLegal, setIdRepresentanteLegal] = useState(null)

  const [dataPrint, setDataPrint] = useState<TypesDataPrint>({} as TypesDataPrint)
  const [totalFinalAnalise, setTotaFinallAnalise] = useState(0)
  const [totalAnalises, setTotalAnalises] = useState(0)
  const [getVisuPontos, setVisuPontos] = useState([])
  const [dadosCliente, setDadosCliente] = useState<ClienteItensTypes>()
  const [loadingTalhao, setLoadingTalhao] = useState(false)
  const [disableTalhaoSelect, setDisableTalhaoSelect] = useState(true)
  const [addAnalise, setAddAnalise] = useState<any>([])
  const [talhaSelecionado, setTalhaoSelecionado] = useState<TalhaoItens[]>([])
  const [propriedadesMatricula, setPropriedadeMatricula] = useState('')
  const [propriedadesHa, setPropriedadeHa] = useState<number>()
  const [grupoAnalises, setGrupoAnalises] = useState([])
  const [propriedadesCod, setPropriedadeCod] = useState<number>()
  const [typePrint, setTypePrint] = useState(false)

  const [dataRepresentateLegal, setDataRepresentateLegal] = useState([])

  const [areaTalhao, setAreaTalhao] = useState<number>()

  const [dataFinish, setDataFinish] = useState<TypesDataFinish>({} as TypesDataFinish)
  const [tipoAnalise, setTipoAnalise] = useState<TypesTipoAnalise>()
  const [loadingShape, setLoadingShape] = useState(false)
  const [areaAmostra, setAreaAmostra] = useState<number>(null)
  const [modalPrint, setModalPrint] = useState(false)
  const [profundidade, setProfundidade] = useState<TypesProfundidades>({
    nome: undefined,
    id: undefined,
  })

  const [valueSolicitante, setvalueSolicitante] = useState('')

  const [modalNewPonto, setModalNewPonto] = useState(false)
  const [indexNewPonto, setIndexNewPonto] = useState<number>()

  const [kmlAnalise, setKmlAnalise] = useState({})

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalVisibleMap, setIsModalVisibleMap] = useState(false)
  const [isModalVisibleVisuMap, setIsModalVisibleVisuMap] = useState(false)
  const [statusVigente, setStatusVigente] = useState(0)

  const [logUser, setLogUser] = useState(false)
  const [usuarioQueCriou, setUsuarioQueCriou] = useState('')
  const [usuarioQueAlterou, setUsuarioQueAlterou] = useState('')
  const [dataCriacao, setDataCriacao] = useState('')
  const [horarioDeCriacao, setHorarioDeCriacao] = useState('')
  const [horarioDeAtualizacao, setHorarioDeAtualizacao] = useState('')
  const [dataAlteracao, setDataAlteracao] = useState('')
  const [diasDesdeCriacao, setDiasDesdeCriacao] = useState(null);

  const [cod_laboratorio_atual, setCod_laboratorio_atual] = useState<number|null>(null);

  const handleDataFromCreation = (dias) => {
    setDiasDesdeCriacao(dias)
  }


  const cliente = async () => {
    const config = localConfig()
    if (config.codCliente !== '') {
      const resp = await getCliente(config.codCliente, '', '', '')
      const dados = resp.data.itens[0]

      if (dados.tipo === 'G') {
        const response = await getRepresentanteLegal('', config.codCliente)
        const repre = response.data.itens

        setDataRepresentateLegal(repre)
      } else {
        setvalueSolicitante(dados.solicitante)
      }

      setDadosCliente(dados)
    } else {
      const resp = await getConsultoria()
      // @ts-ignore
      setDadosCliente(resp.data.itens[0])
    }
  }

  const propriedades = async () => {
    const resp = await getPropriedade('')

    setListPropriedade(resp.data.itens)
  }

  const handleDataDia = () => {
    form.setFieldsValue({ data: dayjs() })
  }

  const equipe = async () => {
    const resp = await getEquipeConta('', 22)

    setListEquipe(resp.data.itens)
  }

  const onChangeUpload = (info: UploadChangeParam) => {
    if (info.file.status === 'done') {
      importMalha(info)
    }
  }

  const handleSelectTalhao = async (data) => {
    const response = await getTalhao(propriedadesCod, data)

    setTalhaoSelecionado(response.data.itens)
    // @ts-ignore
    setKmlAnalise(response.data.itens[0].kml)

    setAreaTalhao(response.data.itens[0].area)
  }

  const talhao = async (propriedade, codTalhao) => {
    setLoadingTalhao(true)
    form.setFieldsValue({ cod_talhao: null })
    const resp = await getTalhao(propriedade, codTalhao)

    setListTalhao(resp.data.itens)
    if (codTalhao !== '') {
      setTalhaoSelecionado(resp.data.itens)
    }
    setLoadingTalhao(false)
  }

  const fornecedor = async () => {
    const resp = await getFornecedor({codConta: 1, codCategoria: 2})

    setListFornecedor(resp.data.itens)
  }

  let totalAna = 0
  const totalAnalise = () => {
    let totalAnalises = 0
    addAnalise.forEach((data) => {
      const valorPonto = data.cod_analises[0]?.valor || data.cod_analises.valor
      const totalPontos = data.pontos.length

      totalAna += valorPonto * totalPontos
      totalAnalises += totalPontos
      return totalAna
    })
    setTotalAnalises(totalAnalises)
    setTotaFinallAnalise(totalAna)
  }

  const handleAddAnalise = async () => {
    setIsVisibleFileShape(true)
    const codLaboratorio = form.getFieldValue('cod_laboratorio')
    const codPropriedade = form.getFieldValue('cod_propriedade')
    const codTalhao = form.getFieldValue('cod_talhao')

    if (codLaboratorio === null || codPropriedade === null || codTalhao === null || malha <= 0) {
      infoAlert(
        (codPropriedade === null ? 'Selecione o Propriedade' : null) ||
        (codTalhao === null ? 'Selecione o Talhão' : null) ||
        (codLaboratorio === null ? 'Selecione o Laboratorio' : null) ||
        (malha <= 0 ? 'Carregue a Malha Geral' : null)
      )
    } else {
      setIsModalVisible(true)

      const resp = await getLabAnalise('', form.getFieldValue('cod_laboratorio'))

      setListFornServico(resp.data.itens)
    }
  }

  const handelChangePropriedade = async (data) => {
    const resp = await getPropriedade(data)

    setPropriedadeMatricula(resp.data.itens[0].matricula)
    setPropriedadeCod(resp.data.itens[0].cod)
    setPropriedadeHa(resp.data.itens[0].area)
    talhao(data, '')
  }

  const hancleDrawerClose = () => {
    setUpdate(0)
    setTypePrint(null)
    setOnClose(false)
    form.resetFields()
    setMalha([])
    setShape([])
    setAddAnalise([])
    setTalhaoSelecionado([])
    setPropriedadeMatricula('')
    setIdRepresentanteLegal(null)
    handleDataDia()
    setvalueSolicitante(dadosCliente.solicitante)
    setStatusVigente(0)
  }

  // const handleClikBtnMalha = () => {
  //   if (addAnalise.length <= 0) {
  //     document.getElementById("fileMalha").click();
  //   } else {
  //     Modal.confirm({
  //       title: "Deseja alterar a Malha Geral?",
  //       content: "Isso irá remover todas as análises",
  //       icon: <ExclamationCircleFilled />,
  //       okText: "Sim",
  //       cancelText: "Não",
  //       onOk: () => {
  //         document.getElementById("fileMalha").click();
  //         setAddAnalise([]);
  //         setMalha([]);
  //       },
  //     });
  //   }
  // };

  const handleOutrosSolicitantes = (data) => {
    form.setFieldsValue({ solicitante: valueSolicitante })
    const print = dataPrint

    setvalueSolicitante(data.target.value)

    print.outrosSolicitantes = data.target.value
  }

  const importMalha = async (data: UploadChangeParam) => {
    if (data.fileList.length > 0) {
      try {
        setLoadingShape(true)
        let newFile = await data.file.originFileObj.arrayBuffer()
        let geojson = await shp(newFile)
        // @ts-ignore
        geojson.features.forEach((data) => {
          if (data.properties.NOME === undefined && data.properties.AUTO_ID === undefined) {
            setMalha([])
            setLoadingShape(false)
            throw new Error('Arquivo Invalido')
          }
          data.color = '#ffffff'
          data.properties.NOME =
            parseInt(data.properties.NOME, 10) || parseInt(data.properties.AUTO_ID, 10)
        })

        setMalha(geojson)
        setLoadingShape(false)

        geojson = null
        newFile = null

        setDisableTalhaoSelect(true)
      } catch (error) {
        Modal.error({
          title: 'Erro',
          content:
            'Arquivo shape inválido! Por favor entre em contato com o suporte técnico para mais informações.',
        })
        setMalha([])
        setLoadingShape(false)
        tryError(error)
      }
    }
  }

  let pontosFiltro: any = []

  const importShape = async (arquivo: UploadChangeParam) => {
    if (arquivo?.fileList?.length > 0) {
      try {
        
        loadingAlert({msg: 'Carregando...', id: 'import_talhao'})

        const newFile = await arquivo.file.originFileObj.arrayBuffer()

        const geojson = await shp(newFile)

        // @ts-ignore
        const pontos = geojson.features

        pontos.forEach((data) => {
          pontosFiltro = [
            ...pontosFiltro,
            {
              NOME: data.properties.NOME || data.properties.AUTO_ID,
              INDICE: data.properties.INDICE,
              COMENTARIO: data.properties.COMENTARIO,
              LATITUDE: data.properties.LATITUDE,
              LONGITUDE: data.properties.LONGITUDE,
            },
          ]

          return pontosFiltro
        })

        setShape(pontosFiltro)

        destroyAlert('import_talhao');
      } catch (error) {
        tryError(error)
      }
    } else if (arquivo.file.status !== 'removed') {
      const geojson = arquivo.fileList

      geojson.forEach((data: any) => {
        pontosFiltro = [
          ...pontosFiltro,
          {
            NOME: data.properties.NOME || data.properties.AUTO_ID,
            INDICE: data.properties.INDICE,
            COMENTARIO: data.properties.COMENTARIO,
            LATITUDE: data.properties.LATITUDE,
            LONGITUDE: data.properties.LONGITUDE,
          },
        ]

        return pontosFiltro
      })

      setShape(pontosFiltro)
    }
  }

  function filtro(data) {
    let pontosFiltro = []
    const geojson = data

    geojson.forEach((data) => {
      pontosFiltro = [
        ...pontosFiltro,
        {
          NOME: data.properties?.NOME || data.properties.AUTO_ID,
          INDICE: data.properties?.INDICE,
          COMENTARIO: data.properties?.COMENTARIO,
          LATITUDE: data.properties?.LATITUDE,
          LONGITUDE: data.properties?.LONGITUDE,
        },
      ]

      return pontosFiltro
    })

    return pontosFiltro
  }

  const onFinish = async (data) => {
    let valorTotal: any = 0
    data.solicitante = valueSolicitante

    const analises = addAnalise

    analises.forEach((info) => {
      let valorPonto = 0
      if (info.cod_analises[0]?.valor === undefined) {
        valorPonto = info.cod_analises.valor
      } else {
        info.cod_analises[0].valor = parseFloat(info.cod_analises[0]?.valor)
        valorPonto = info.cod_analises[0]?.valor
      }

      const qtdaPontos = info.pontos.length
      // @ts-ignore
      const multi = parseFloat(valorPonto) * qtdaPontos

      valorTotal += multi

      return valorTotal
    })

    if (idRepresentanteLegal === null && dadosCliente.tipo === 'G') {
      errorAlert('Selecione um representante Legal!');
      return
    }

    const dados = {
      data: dayjs.isDayjs(form.getFieldValue('data'))
        ? form.getFieldValue('data').format('DD/MM/YYYY')
        : null,
      status: form.getFieldValue('status'),
      cod_laboratorio: form.getFieldValue('cod_laboratorio'),
      cod_representante: idRepresentanteLegal === null ? '' : idRepresentanteLegal,
      copiar: form.getFieldValue('copiar'),
      cod_propriedade: form.getFieldValue('cod_propriedade'),
      cod_talhao: form.getFieldValue('cod_talhao'),
      cod_responsaveis: form.getFieldValue('cod_responsaveis'),
      solicitante: data.solicitante,
      cod_area_amostra: form.getFieldValue('cod_area_amostra'),
      cod_safra_conta: form.getFieldValue('cod_safra_conta'),
      malha_de_pontos: malha,
      data_liberada: dayjs.isDayjs(form.getFieldValue('data_liberada'))
        ? form.getFieldValue('data_liberada').format('DD/MM/YYYY')
        : null,
      data_coleta: dayjs.isDayjs(form.getFieldValue('data_coleta'))
        ? form.getFieldValue('data_coleta').format('DD/MM/YYYY')
        : null,
      analises,
      valor: valorTotal,
    }

    setDataFinish(dados)

    try {
      if (update <= 0) {
        loadingAlert({msg: 'Salvando...', id: 'salvando_os'})

        const response = await postPedLaboratorio(dados)

        if (response.status === 201) {
          successAlert('OS gerada com sucesso!', 5000, 'salvando_os')
          form.resetFields()
          setAddAnalise([])
          setDataFinish({} as TypesDataFinish)
          setMalha([])
          handleResetAnalize()
          setTalhaoSelecionado([])

          handleDataDia()

          setUpdate(0)
        }
      } else {
        loadingAlert({msg: 'Salvando...', id: 'salvando_os'})

        const response = await putPedlaboratorio(update, dados)

        if (response.status === 200) {
          successAlert('OS atualizada com sucesso', 5000, 'salvando_os');
          setUpdate(update)
        }
      }
    } catch (error) {

      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          errorAlert(data?.error ?? 'Falha ao registrar OS', 7000, 'salvando_os');
        } else {
          data.error.forEach((data) => {
            errorAlert(data?.msg?.[0] ?? 'Falha ao registrar OS', 7000, 'salvando_os');
          })
        }
      }
      else {
        destroyAlert();
      }
    }
  }
  const checkMalhaPonto = (nomePonto: number): void => {
    let retorno

    // eslint-disable-next-line array-callback-return
    malha.features?.find((data) => {
      data.properties.NOME = parseInt(data.properties.NOME, 10)
      if (nomePonto === data.properties.NOME) {
        retorno = true
      }
    })

    if (retorno) {
      retorno = true
    } else {
      retorno = false
    }
    return retorno
  }

  const handleOk = () => {
    if (tipoAnalise?.data === undefined) {
      infoAlert('Informe todos os dados acima!');
    } else {
      const id = uuid()
      setLoadingShape(true)
      setAddAnalise([
        ...addAnalise,
        {
          title: tipoAnalise.data,
          cod_analises: tipoAnalise.valorAnalises,
          cod_profundidade: profundidade.id,
          profundidade: profundidade.nome,
          id,
          pontos: shape,
          valor_analise: tipoAnalise.valorPonto * shape.length,
        },
      ])

      setShape([])
      setTipoAnalise({
        valorAnalises: { cod: undefined, valor: undefined },
        data: undefined,
        valorPonto: 0,
      })
      setProfundidade({ id: undefined, nome: undefined })

      form.resetFields(['file_shape', 'analise_tipo', 'cod_profundidade'])
      setIsModalVisible(false)
      setLoadingShape(false)
    }
  }

  const handleClickAddPonto = (index) => {
    setIndexNewPonto(index)
    setModalNewPonto(true)
  }

  const handleOkNewPonto = () => {
    const nameponto = form.getFieldValue('name_ponto')

    if (nameponto === null || nameponto === '') {
      infoAlert('Nome do ponto é obrigatorio!');
    } else {
      const array = JSON.parse(JSON.stringify(addAnalise))

      const verifica = array[indexNewPonto].pontos.find(
        (element) => parseInt(element.NOME, 10) === nameponto
      )

      if (verifica) {
        infoAlert('Ponto ja existe na malha!');
      } else {
        array[indexNewPonto].pontos.push({
          INDICE: parseInt(nameponto, 10),
          NOME: nameponto as number,
          COMENTARIO: '',
        })
        successAlert(`Ponto ${nameponto} inserido!`)

        ordernar(array[indexNewPonto].pontos)

        form.setFieldsValue({ name_ponto: '', ponto_coment: '' })

        setAddAnalise(array)
      }
    }
  }

  const handleOkMap = () => {
    if (form.getFieldValue('cod_laboratorio') === null) {
      infoAlert('Selecione o laboratório!')
    } else {
      setIsModalVisibleMap(true)
    }
  }

  const handelChangeSelectAnalise = (id) => {
    const profundidade = listFornServico.find((data) => data.cod_analise === id)

    setProfundidade(profundidade.cod_profundidade)
    setTipoAnalise({
      valorAnalises: {
        cod: profundidade.cod_analise,
        valor: parseFloat(profundidade.valor),
      },

      data: `${profundidade.profundidade_nome} | ${profundidade.analise_nome}`,
      valorPonto: profundidade.valor,
    })
  }

  const handleClickDuplicar = (index) => {
    const array = addAnalise

    setShape(array[index].pontos)
    setIsVisibleFileShape(false)

    setIsModalVisible(true)
  }

  const handleRemoveAnalise = (id) => {
    const array = addAnalise
    setAddAnalise(array.filter((item) => item.id !== id))
  }

  const handleClickPrint = (data) => {
    setTypePrint(data)

    const codResponsaveis = form.getFieldValue('cod_responsaveis')

    let nomeRespon: any = []

    codResponsaveis.forEach((id) => {
      const temp = listEquipe.find((info) => info.cod_func === id)
      nomeRespon = [...nomeRespon, temp?.usuario_nome]

      return nomeRespon
    })

    dadosPrint({
      talhaoNome: dadosDaOs.talhao_nome,
      codCidade: dadosDaOs.propriedade[0].cod_cidade,
      propriedadeNome: dadosDaOs.propriedade_nome,
      representantes: dadosDaOs.representante[0],
      areaAmostra: areaAmostra?.toFixed(2),
    })

    setDataFinish({
      cod_laboratorio: form.getFieldValue('cod_laboratorio'),
      cod_propriedade: form.getFieldValue('cod_propriedade'),
      cod_talhao: form.getFieldValue('cod_talhao'),
      responsaveis: nomeRespon,
      cod_area_amostra: areaAmostra,
      malha_de_pontos: malha,
      analises: addAnalise,
    })
    
    setModalPrint(true)
  }

  const handleTrashPonto = (ponto, indexAnalise, indexShape) => {
    // @ts-ignore
    const array = JSON.parse(JSON.stringify(addAnalise))

    const newArray = array[indexAnalise].pontos.filter((item, index) => index !== indexShape)

    array[indexAnalise].pontos = newArray

    setAddAnalise(array)

    form.setFieldsValue(array[indexAnalise].pontos[indexShape])
  }

  const dadosPrint = async (data: TypesDadosPrint) => {
    const { codCidade, propriedadeNome, representantes, talhaoNome } = data
    const response = await buscaCidade('', '', codCidade)
    const { nome, uf_sigla } = response[0]

    const dataPrint = {
      propriedadeNome,
      talhaoNome,
      cityUf: `${nome}/${uf_sigla}`,
      // @ts-ignore
      representante: representantes,
      outrosSolicitantes: valueSolicitante,
      cpf: dadosCliente?.tipo === 'G' ? representantes?.cpf : dadosCliente?.cpf_cnpj,
      dataColeta: form.getFieldValue('data_coleta')
        ? dayjs(form.getFieldValue('data_coleta')).format('DD/MM/YYYY')
        : null,
      areaAmostra: areaAmostra?.toFixed(2),
    }
    // @ts-ignore
    setDataPrint(JSON.parse(JSON.stringify(dataPrint)))
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    form.resetFields(['file_shape', 'analise_tipo', 'cod_profundidade'])
  }

  const handelCalcAreaAmostra = (aAmostra, area) => {
    // const ha = propriedadesHa === undefined ? area : propriedadesHa
    const ha = propriedadesHa ?? area;
    const calc = ha / aAmostra
    const total = ha / parseFloat(calc.toFixed(2))

    setAreaAmostra(total)
  }

  const handleAnaliseMapa = async (data) => {
    setIsModalVisibleMap(false)
    data.forEach((info) => {
      const resp = filtro(info.pontos)

      info.pontos = resp
    })

    setAddAnalise(data)
  }

  const handleChangeRepresentanteLegal = (cod, dados: OsItensTypes | null) => {
    setIdRepresentanteLegal(cod)

    if (dados !== null) {
      const print = { ...dataPrint }

      const representante = dados?.representante

      const dataRepe = representante[0]

      const repre = dataRepresentateLegal.find((info) => info.cod_usuario === cod)
      print.representante = dataRepe
      print.cpf = dataRepe?.cpf

      setDataPrint(print)

      setvalueSolicitante(repre?.solicitante)
    }
  }

  const handelChangeLaboratorio = async (data) => {
    setCod_laboratorio_atual(data);
    const analiseLab = await getLabAnalise('', data)
    // @ts-ignore
    setGrupoAnalises(analiseLab.data.itens)

    setListFornServico(analiseLab.data.itens)
  }

  const handleChangeSafraConsultoria = async () => {
    const response = await getSafraConsultoria()

    setOptSafraConsultoria(response.data.itens)
  }

  const handleClickVisuPontos = (index, data) => {
    setVisuPontos(data)
    setIsModalVisibleVisuMap(true)
  }

  const handleClickBaixarKml = async (i, nome, dados) => {
    const response = await geralKmlLab(dados, nome)

    const blob = new Blob([response], {
      type: 'application/octet-stream',
    })
    url.current.href = URL.createObjectURL(blob)
    url.current.download = `${nome}.kml`
    url.current.click()
  }

  const handleSetPontosGeral = () => {
    //
  }

  const handleCancelModalMap = () => {
    setIsModalVisibleMap(false)
  }

  const handleAtualizouPontos = (data) => {
    setMalha(data)
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current.add(1, 'day') < dayjs().endOf('day')
  }

  useEffect(() => {
    if (
      form.getFieldValue('cod_propriedade') === undefined ||
      form.getFieldValue('cod_propriedade') === null ||
      malha?.features?.length > 0
    ) {
      setDisableTalhaoSelect(true)
    } else {
      setDisableTalhaoSelect(false)
    }
  }, [talhao])

  useEffect(() => {
    const analises = listFornServico
    setListFornServico(analises)
    totalAnalise()
  }, [addAnalise])

  useEffect(() => {
    const fetch = async () => {
      if (update > 0) {
        loadingAlert({msg: 'Carregando...', id: 'loading_outro'})
        const resp = await getPedLaboratorio(update)
        const dados = resp.data.itens[0]

        dados.data_liberada = dados.data_liberada === null ? '' : dayjs(dados.data_liberada)
        dados.data_coleta = dados.data_coleta === null ? '' : dayjs(dados.data_coleta)
        dados.data = dados.data === null ? '' : dayjs(dados.data)

        form.setFieldsValue(dados)

        setDadosDaOs(dados)

        if (dadosCliente?.tipo === 'G') {
          handleChangeRepresentanteLegal(dados?.cod_representante, dados)
        } else {
          if(dadosCliente?.solicitante!==undefined) {
            setvalueSolicitante(dadosCliente?.solicitante)
          }
        }

        setStatusVigente(dados.status)

        talhao(form.getFieldValue('cod_propriedade'), dados.cod_talhao)

        handelChangeLaboratorio(dados.cod_laboratorio)
        dadosPrint({
          talhaoNome: dados.talhao_nome,
          codCidade: dados.propriedade[0].cod_cidade,
          propriedadeNome: dados.propriedade_nome,
          representantes: dados.representante[0],
          areaAmostra: areaAmostra?.toFixed(2),
        })

        form.setFieldsValue({
          cod_talhao: dados.talhao_nome,
          solicitante: dados.solicitante,
        })

        setKmlAnalise(dados.talhao_kml)

        setAreaTalhao(parseFloat(dados.talhao_area))

        let responsaveis = []

        // @ts-ignore
        setPropriedadeMatricula(dados.propriedade[0].matricula)
        // @ts-ignore
        setPropriedadeHa(dados.propriedade[0].area)
        // @ts-ignore
        setPropriedadeSelecionada(dados.propriedade[0])

        dados.responsaveis.forEach((data) => {
          responsaveis = [...responsaveis, data.cod_responsavel]

          return responsaveis
        })

        form.setFieldsValue({ cod_responsaveis: responsaveis })

        setTimeout(() => {
          handelCalcAreaAmostra(
            dados.cod_area_amostra,
            // @ts-ignore
            dados.propriedade[0].area
          )

          dados.analises.forEach((data) => {
            ordernar(data.pontos)
          })

          setAddAnalise(dados.analises)
          setMalha(dados.malha_de_pontos)
          destroyAlert('loading_outro')
        }, 1000)
      }

      if (clone > 0) {
        loadingAlert({msg: 'Clonando ordem de serviço...', id: 'clone'})
        const resp = await getPedLaboratorio(clone)
        const dados = resp.data.itens[0]

        // handleClickVisuPontos(1, dados.talhao_kml)
        handelChangeLaboratorio(dados.cod_laboratorio)

        setGrupoAnalises(dados.analises)

        setKmlAnalise(dados.talhao_kml)

        dados.data_liberada = dados.data_liberada === null ? '' : dayjs(dados.data_liberada)
        dados.data_coleta = dados.data_coleta === null ? '' : dayjs(dados.data_coleta)
        dados.data = dados.data === null ? '' : dayjs(dados.data)

        form.setFieldsValue(dados)
        handleChangeRepresentanteLegal(dados.cod_representante, dados)

        talhao(form.getFieldValue('cod_propriedade'), dados.cod_talhao)
        setAreaTalhao(parseFloat(dados.talhao_area))

        if (dadosCliente?.tipo === 'G') {
          handleChangeRepresentanteLegal(dados.cod_representante, dados)
        } else {
          if(dadosCliente?.solicitante!==undefined) {
            setvalueSolicitante(dadosCliente?.solicitante)
          }
        }

        dadosPrint({
          talhaoNome: dados.talhao_nome,
          codCidade: dados.propriedade[0].cod_cidade,
          propriedadeNome: dados.propriedade_nome,
          representantes: dados.representante[0],
          areaAmostra: areaAmostra?.toFixed(2),
        })

        form.setFieldsValue({
          cod_talhao: dados.talhao_nome,
          solicitante: dados.solicitante,
          copiar: dados.cod,
          data: '',
        })

        let responsaveis = []

        const propriedade = listPropriedade.find((data) => data.cod === dados.cod_propriedade)

        setPropriedadeSelecionada(propriedade)

        setPropriedadeMatricula(propriedade?.matricula)

        setPropriedadeHa(propriedade?.area)

        dados.responsaveis.forEach((data) => {
          responsaveis = [...responsaveis, data.cod_responsavel]

          return responsaveis
        })

        form.setFieldsValue({ cod_responsaveis: responsaveis, status: 1 })

        setTimeout(() => {
          handelCalcAreaAmostra(dados?.cod_area_amostra, propriedade?.area)

          setAddAnalise(dados.analises)
          setMalha(dados.malha_de_pontos)
          destroyAlert('clone')
        }, 1000)
      }
    }

    fetch()
  }, [update, clone])

  useEffect(() => {
    if (statusVigente === 3 || statusVigente === 1 || statusVigente === 0) {
      setDisableCampos(false)
    } else {
      setDisableCampos(true)
    }
  }, [statusVigente])

  useEffect(() => {
    handleDataDia()
    cliente()
    propriedades()
    fornecedor()
    handleChangeSafraConsultoria()
    equipe()
  }, [])

  const optPropriedades = listPropriedade.map((data) => (
    <Option key={data.cod} value={data.cod}>
      {`${data.nome} - ${data.cidade[0].nome} / ${data.cidade[0].uf}`}
    </Option>
  ))

  const optTalhoes = listTalhao.map((data) => {
    return (
      <Option key={data.cod} value={data.cod}>
        <Popover
          className='popover'
          placement='left'
          content={() => (
            <Icon component={() => <Svg coordinates={data?.kml?.coordenadas} fill='#70ce9f' />} />
          )}
          trigger='hover'
        >{`${data.nome} - ha: ${data.area.replace('.', ',')} `}</Popover>
      </Option>
    )
  })

  const optLaboratorio = listFornecedor.map((data) => (
    <Option key={data.cod} value={data.cod}>
      {data.nome}
    </Option>
  ))

  const optServico = listFornServico.map((data) => {
    const dados = addAnalise.find((info) => info.cod_analises.cod === data.cod_analise)
    if (dados) {
      data.disable = true
    } else {
      data.disable = false
    }
    return (
      <Option disabled={data.disable} key={data.valor} value={data.cod_analise}>
        {data.profundidade_nome} | {data.analise_nome}
      </Option>
    )
  })

  const optEquipe = listEquipe.map((data) => (
    <Option key={data.cod_func} value={data.cod_func}>
      {data.usuario_nome}
    </Option>
  ))

  const handleResetAnalize = () => {
    //
  }

  

  useEffect(() => {
    if (visible) {
      if (dadosDaOs) {
        if (dadosDaOs.create_user && dadosDaOs.create_user !== undefined) {
          setUsuarioQueCriou(dadosDaOs.create_user)

          const dateOnlyString = dadosDaOs.create_time.split(' ')[0]
          const timeOnlyString = dadosDaOs.create_time.split(' ')[1]

          setDataCriacao(dateOnlyString)
          setHorarioDeCriacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }

        if (dadosDaOs.update_user && dadosDaOs.update_user !== undefined) {
          setUsuarioQueAlterou(dadosDaOs.update_user)

          const dateOnlyString = dadosDaOs.update_time.split(' ')[0]
          const timeOnlyString = dadosDaOs.update_time.split(' ')[1]
          setDataAlteracao(dateOnlyString)
          setHorarioDeAtualizacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }
      }
    } else {
      setUsuarioQueCriou('')
      setUsuarioQueAlterou('')
    }

    if (update === 0) {
      setLogUser(false)
    }
  }, [logUser, visible, usuarioQueAlterou, usuarioQueCriou, update, horarioDeCriacao, dadosDaOs])


  useEffect(() => {
      setAddAnalise([])
  }, [cod_laboratorio_atual])
  
  return (
    <Form.Provider
      onFormFinish={() => {
        //
      }}
    >
      <Drawer
        style={{ position: 'absolute' }}
        bodyStyle={{ overflow: 'hidden' }}
        placement='right'
        width='100%'
        onClose={hancleDrawerClose}
        open={visible}
        closeIcon={false}
        getContainer={false}
        destroyOnClose
  
        title={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {update ? 'Atualizar Ordem de Serviço Coleta' : 'Cadastrar Ordem de Serviço Coleta'}
            {logUser && update > 0 && usuarioQueAlterou !== '' ? (
              <>
                <DiasDesdeData
                  dataCriacao={dataAlteracao}
                  onDataFromCreation={handleDataFromCreation}
                />
                <small style={{ opacity: '0.5', fontSize: '10px' }}>
                  atualizado por: {usuarioQueAlterou}{' '}
                  {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                    ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                    : `às ${horarioDeAtualizacao}h`}
                </small>
              </>
            ) : !logUser && update > 0 ? (
              <>
                {' '}
                {usuarioQueCriou === '' ? null : (
                  <small style={{ opacity: '0.5', fontSize: '10px' }}>
                    criado por: {usuarioQueCriou}{' '}
                    {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                      ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                      : `às ${horarioDeCriacao}h`}
                  </small>
                )}
              </>
            ) : null}
          </div>
        }
        size='default'
        extra={
          <Popconfirm
            title='Selecione'
            okText='Imprimir OS'
            onConfirm={() => handleClickPrint(true)}
            onCancel={() => handleClickPrint(false)}
            placement='bottom'
            icon={<IconAlert type='confirm' size={4} />}
            cancelText='Imprimir Ordem Campo'
            cancelButtonProps={{
              type: 'primary',
            }}
          >
            <Button
              hidden={responsive}
              type='primary'
              disabled={update <= 0 && addAnalise.length <= 0}
              className='info-button'
              icon={<PrinterOutlined />}
            >
              Imprimir Ordem
            </Button>
          </Popconfirm>
        }
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <Button
                hidden={permissions?.inclusao === 0 && permissions?.alteracao === 0}
                type='primary'
                onClick={onFinish}
                htmlType='submit'
              >
                {update > 0 ? 'Atualizar Ordem de serviço' : 'Salvar'}
              </Button>
              {'   '}
              <Button danger type='primary' onClick={hancleDrawerClose} style={{ marginRight: 8 }}>
                Fechar
              </Button>
            </div>
          </div>
        }
      >
        <Container>
          <Col>
            <Row gutter={[10, 6]} style={{ position: 'relative' }}>
              {!responsive ? (
                <>
                  <Col span={10} style={{ position: 'sticky', top: 0 }}>
                    <Card
                      bodyStyle={{ height: 'calc(90vh - 190px)', overflow: 'auto' }}
                      title='Dados cliente'
                      size='small'
                    >
                      <Render condition={visible}>
                        <DataClienteOrder
                          {...{
                            dadosCliente,
                            update,
                            inputRepresentante,
                            dataRepresentateLegal,
                            idRepresentanteLegal,
                            handleChangeRepresentanteLegal,
                            form,
                            onFinish,
                            permissions,
                            valueSolicitante,
                            handleOutrosSolicitantes,
                            propriedadesMatricula,
                            propriedadesHa,
                            handelChangePropriedade,
                            optPropriedades,
                            disableTalhaoSelect,
                            loadingTalhao,
                            handleSelectTalhao,
                            optTalhoes,
                            handelCalcAreaAmostra,
                            handelChangeLaboratorio,
                            optLaboratorio,
                            localConfig,
                            handleChangeSafraConsultoria,
                            optSafraConsultoria,
                            optEquipe,
                            handleAddAnalise,
                            uploadShape,
                            onChangeUpload,
                            dummyRequest,
                            loadingShape,
                            malha,
                            isModalVisibleMap,
                            handleOkMap,
                            disabledDate,
                          }}
                        />
                      </Render>
                      
                    </Card>
                  </Col>
                  <Col span={14}>
                    <Render condition={visible}>
                      <AnalisesOrder
                        {...{
                          addAnalise,
                          totalAnalises,
                          areaTalhao,
                          malha,
                          totalFinalAnalise,
                          handleClickDuplicar,
                          permissions,
                          handleClickVisuPontos,
                          handleClickBaixarKml,
                          handleRemoveAnalise,
                          handleTrashPonto,
                          url,
                          handleClickAddPonto,
                          propriedadeSelecionado,
                          areaAmostra,
                          checkMalhaPonto,
                        }}
                      />
                    </Render>
                    
                  </Col>
                </>
              ) : (
                <Render condition={visible}>
                  <Tabs
                    type='card'
                    size='small'
                    className='ant-tab-green'
                    items={[
                      {
                        label: 'Dados Cliente',
                        key: '1',
                        tabKey: '1',
                        children: (
                          <DataClienteOrder
                            {...{
                              dadosCliente,
                              inputRepresentante,
                              update,
                              dataRepresentateLegal,
                              idRepresentanteLegal,
                              handleChangeRepresentanteLegal,
                              form,
                              onFinish,
                              permissions,
                              valueSolicitante,
                              handleOutrosSolicitantes,
                              propriedadesMatricula,
                              propriedadesHa,
                              handelChangePropriedade,
                              optPropriedades,
                              disableTalhaoSelect,
                              loadingTalhao,
                              handleSelectTalhao,
                              optTalhoes,
                              handelCalcAreaAmostra,
                              handelChangeLaboratorio,
                              optLaboratorio,
                              localConfig,
                              handleChangeSafraConsultoria,
                              optSafraConsultoria,
                              optEquipe,
                              handleAddAnalise,
                              uploadShape,
                              onChangeUpload,
                              dummyRequest,
                              loadingShape,
                              malha,
                              isModalVisibleMap,
                              handleOkMap,
                              disabledDate,
                            }}
                          />
                        ),
                      },
                      {
                        label: 'Analises',
                        key: '2',
                        tabKey: '2',
                        style: { margin: 0, padding: 0 },
                        children: (
                          <AnalisesOrder
                            {...{
                              addAnalise,
                              totalAnalises,
                              checkMalhaPonto,
                              areaTalhao,
                              malha,
                              totalFinalAnalise,
                              handleClickDuplicar,
                              permissions,
                              handleClickVisuPontos,
                              handleClickBaixarKml,
                              handleRemoveAnalise,
                              handleTrashPonto,
                              url,
                              handleClickAddPonto,
                              propriedadeSelecionado,
                              areaAmostra,
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                </Render>
                
              )}
            </Row>
          </Col>
        </Container>

        {/* Hiddenn */}

        <Modal
          title='Nova Análise'
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <>
              <Button onClick={handleOk} type='primary'>
                Salvar
              </Button>
              <Button onClick={handleCancel} danger type='primary'>
                Cancelar
              </Button>
            </>,
          ]}
        >
          {' '}
          <Render condition={visible}>
            <Form form={form} name='tipos_analise' layout='vertical'>
              <Form.Item initialValue={undefined} hidden name='cod_profundidade' />
              <Form.Item initialValue={undefined} name='analise_tipo' label='Análises'>
                <Select
                  ref={valorAnalise.current}
                  onChange={handelChangeSelectAnalise}
                  placeholder='Selecione o tipo de Analise'
                  filterOption={(input, option) =>
                    // @ts-ignore
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {optServico}
                </Select>
              </Form.Item>

              {isVisibleFileShape ? (
                <Form.Item
                  help='compacte os arquivo .dbf .shp .shx, em .zip'
                  name='file_shape'
                  label='Carregue os Pontos de Análise'
                >
                  <Upload
                    ref={uploadShape}
                    accept='.zip'
                    listType='text'
                    onChange={importShape}
                    maxCount={1}
                    multiple={false}
                    customRequest={dummyRequest}
                  >
                    <Button type='primary' icon={<UploadOutlined />}>
                      Importar
                    </Button>
                  </Upload>
                </Form.Item>
              ) : (
                <Card title='Pontos Selecionados' size='small'>
                  <Row gutter={[2, 2]}>
                    {shape.map((data, index) => (
                      <Col style={{ width: '48px' }} key={gerarKey(index)}>
                        <CardBtn>
                          <Card
                            size='small'
                            key={data.INDICE}
                            className='info-button btn-ponto'
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            {parseInt(data.NOME, 10)}
                          </Card>
                        </CardBtn>
                      </Col>
                    ))}
                  </Row>
                </Card>
              )}
            </Form>
          </Render>
         
        </Modal>

        <Modal
          title='Malha Geral'
          style={{
            minHeight: '85vh',
            minWidth: '98vw',
            marginTop: 0,
            top: 0,
          }}
          open={isModalVisibleMap}
          onOk={handleOkMap}
          onCancel={handleCancelModalMap}
          footer={[
              <Button onClick={handleCancelModalMap} danger type='primary' key={Date.now()}>
                Cancelar
              </Button>
          ]}
        >
          {isModalVisibleMap ? (
            <>
              {talhaSelecionado[0]?.kml === null ? (
                <span style={{ color: 'red' }}>Talhão sem Contorno</span>
              ) : null}
              <div>
                {/* @ts-ignore */}
                <GoogleMaps
                  // @ts-ignore
                  grupoDeAnalises={grupoAnalises}
                  resetAnalize={handleResetAnalize}
                  open={isModalVisibleMap}
                  pontosSelecionados={addAnalise}
                  setPontos={handleSetPontosGeral}
                  gerarAnalise={handleAnaliseMapa}
                  atualizoupontos={handleAtualizouPontos}
                  talhaoSelecionado={kmlAnalise}
                  geoLocation={malha}
                  areaTalhao={areaTalhao}
                  status={!disableCampos}
                />
              </div>
            </>
          ) : null}
        </Modal>

        <Modal
          forceRender
          title=''
          style={{
            minHeight: '91vh',
            minWidth: '98vw',
            marginTop: 0,
            top: 0,
          }}
          open={isModalVisibleVisuMap}
          onCancel={() => setIsModalVisibleVisuMap(false)}
          footer={[
              <Button onClick={() => setIsModalVisibleVisuMap(false)} danger type='primary' key={'isModalVisibleVisuMap'}>
                Cancelar
              </Button>
          ]}
          destroyOnClose
        >
          <VisualizarPontosMap
            open={isModalVisibleVisuMap}
            grupoDeAnalises={grupoAnalises}
            pontosSelecionados={getVisuPontos}
            talhaoSelecionado={kmlAnalise}
            geoLocation={malha}
          />
        </Modal>

        <Modal
          title='Novo Ponto'
          open={modalNewPonto}
          onOk={handleOkNewPonto}
          okText='Salvar'
          onCancel={() => setModalNewPonto(false)}
          destroyOnClose
        >
          {' '}
          <Form form={form} name='add_pontos' layout='vertical'>
            <Form.Item name='name_ponto' label='Nome Ponto'>
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Form>
        </Modal>
      </Drawer>
      <Drawer
        destroyOnClose
        width='820px'
        open={modalPrint}
        closeIcon={false}
        onClose={() => {
          setModalPrint(false)
          setDataPrint(null)
          setTypePrint(null)
        }}
        footer={
          <ReactToPrint
            trigger={() => (
              <Button type='primary' title='Imprimir' icon={<PrinterOutlined />}>
                Imprimir
              </Button>
            )}
            content={() => printRef.current}
          />
        }
      >
        <div ref={printRef}>
          {typePrint ? (
            <Print
              dados={dataFinish}
              data={dataPrint}
              propriedade={propriedadeSelecionado}
              areaTalhao={areaTalhao}
              qtdaPontos={malha?.features?.length}
            />
          ) : null}

          {!typePrint ? (
            <PrintOC
              dados={dataFinish}
              data={dataPrint}
              propriedade={propriedadeSelecionado}
              areaTalhao={areaTalhao}
              qtdaPontos={malha?.features?.length}
            />
          ) : null}
        </div>
      </Drawer>
    </Form.Provider>
  )
}

export default Laboratorio
